import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function AddH5p(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickAddH5p = () => {
    console.log("--Add H5p..");
    dispatch(toaster(t("Click a point to add a button Activity"), "warning"));
    props.changeMyTool("addH5");
  };
  return (
    <li onClick={onClickAddH5p}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "addh5p" ? "active" : ""
        }`}
        title={t("Add Activity")}
        data-tip={t("Add Activity")}
      >
        <img
          src={require("../../assets/img/svg/tools-h5p-2.svg")}
          alt={t("Add Activity")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Activity")}</span>}
      </span>
    </li>
  );
}
