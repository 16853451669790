import React, { Component } from "react";
import { connect } from "react-redux";
import { pageNoChangeFromIndex } from "../../actions/pages";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class DrawingItems extends Component {
  render() {
    return (
      <div className="column modal-right">
        <div className="modal-title">
          <h2>
            <span className="modal-title-icon">
              <img
                src={require("../../assets/img/svg/tabs-icon-draw-objects.svg")}
                alt="Annotations"
              />
            </span>
            <span className="modal-title-text">
              {this.props.t("My Annotations")}
            </span>
          </h2>
        </div>
        <table className="table">
          <tbody>
            {this.props.drawing_objects.map((obj, id) => {
              return (
                <tr key={id}>
                  <td>
                    <Link to={obj.toString()}>
                      <span
                        onClick={() => {
                          this.props.pageNoChangeFromIndex(obj);
                        }}
                      >
                        <span className="activity-span">
                          {" "}
                          {this.props.t("Page")} {obj}
                        </span>
                      </span>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const pages = state.pages.pages;
  let drawing_objects = [];
  for (let i = 0; i < pages.length; i++) {
    const itemId = `${state.pages.ebookId}_item_${pages[i].id}`;
    console.log('=========itemId', itemId, JSON.parse(localStorage.getItem(itemId)))
    if (localStorage.getItem(itemId)) {
      JSON.parse(localStorage.getItem(itemId)).objects.map((obj) => {
        if (!drawing_objects.includes(pages[i].page_number)) {
          drawing_objects.push(pages[i].page_number);
        }
      });
    }
  }


  // console.log(drawing_objects);
  return {
    drawing_objects,
    ebookId: state.pages.ebookId,
  };
};

export default connect(mapStateToProps, { pageNoChangeFromIndex })(
  withTranslation()(DrawingItems)
);
