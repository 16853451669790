import React from "react";
import { useTranslation } from "react-i18next";

export default function Pen(props) {
  const { t } = useTranslation();
  return (
    // <li onClick={!props.hideButton && props.onClick}>
    <li onClick={!props.hideButton ? props.onClick : undefined}>
      <span
        className={`button normal-btn zoom-in ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "zoom-in" ? "active" : ""
        }${props.hideButton ? "passive" : ""}`}
        title={t("Zoom in") + " (Z)"}
        data-tip={t("Zoom in")}
      >
        <img
          src={require("../../assets/img/svg/toolbar-zoom-in.svg")}
          alt={t("Zoom in") + " (Z)"}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Zoom in")}</span>}
      </span>
    </li>
  );
}
