import {
  GET_PAGES,
  PAGE_NO_CHANGE,
  GET_ZIP,
  ZIP_DOWN_PROG,
  RESET_PAGES,
  ZOOM_SUB_PAGE_NO_CHANGE,
  SET_MY_CONTENT,
} from "./actionTypes";
import api from "../api";
import axios from "axios";
import configuration from "../config";
import JSZip from "jszip";
import { zipFileDownloadProgress } from "./zipDownProg";
import { closePixabay } from "./pixabay";
import { indexClose } from "./indexWindow";
import { axiosConfig } from "../services/api";

export const pullPages = (url, admin = false) => {
  const baseUrl =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : process.env.REACT_APP_MULTITENANT === "true"
      ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
      : process.env.REACT_APP_API_URL + (admin ? "api/a2/" : "api/v2/");
  // console.log(admin, "admin");
  // console.log("±±±±±±±±±baseUrl: ", baseUrl);
  if (admin) {
    return (dispatch) => {
      api
        .getData(baseUrl + "ebook/" + localStorage.cenibook_id) //
        .then((res) => {
          // console.log("-----------res", res);
          dispatch(getPages(res.data.success));
        })
        .catch((err) => {
          console.error(err);
        });
    };
  } else {
    return (dispatch) => {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          // console.log(">>>>>>fetch data", data);
          dispatch(getPages(data));
          api
            .postWithoutData(
              baseUrl + "my-ebook-content/" + localStorage.cenibook_id,
              axiosConfig
            ) //
            .then((res) => {
              // console.log("0-0-0-0-0-res", res);
              dispatch(
                setMyContent(res.data.success, localStorage.cenibook_id)
              );
            })
            .catch((err) => {
              console.error(err);
            });
        });
    };
  }
};

export const pullZipImages = () => {
  const baseUrl =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : process.env.REACT_APP_MULTITENANT === "true"
      ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
      : process.env.REACT_APP_API_URL + "api/"; //TODO cenibook.com
  return (dispatch) => {
    let blobURLs = [];
    axios
      .get(baseUrl + configuration.zipFileUrl, {
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // this.setState({zipfileProgress: percentCompleted})
          dispatch(zipFileDownloadProgress(percentCompleted));
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.data);
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then((e) => {
        return JSZip.loadAsync(e, { base64: true });
      }) // 3) chain with the zip promise
      .then(function (zip) {
        let numOfFiles = Object.keys(zip.files).length;
        let counter = 0;
        Object.keys(zip.files).forEach(function (filename, index) {
          // let url = zip.files.extractAsBlobUrl(filename, 'image/png');

          if (filename == "__MACOSX/") {
            return;
          }

          zip
            .file(filename)
            .async("uint8array")
            .then(function (a) {
              let blob = new Blob([a], { type: "image/png" });
              let urlCreator = window.URL || window.webkitURL;
              let imageUrl = urlCreator.createObjectURL(blob);
              let imageNumber = filename.split(".")[0] - 1;
              if (imageNumber || imageNumber == 0) {
                blobURLs[imageNumber] = imageUrl;
              } else {
                // console.log(blobURLs);
              }
              if (counter == numOfFiles - 1) {
                dispatch(getZip(blobURLs));
              }
              counter++;
            });
        });
      })
      .then((res) => {
        // console.log(res);
      })
      .catch(function (e) {
        console.log(e);
      });
  };
};

export const getPages = (pages) => {
  // console.log("here 2");
  return {
    type: GET_PAGES,
    payload: pages,
  };
};
export const setMyContent = (buttons, ebookId) => {
  // console.log("here 2");
  return {
    type: SET_MY_CONTENT,
    payload: { buttons, ebookId },
  };
};
export const resetPages = () => {
  // console.log("reset pages");
  return {
    type: RESET_PAGES,
  };
};

export const pageNoChange = (newPageNo) => {
  return (dispatch) => {
    dispatch(updatePageNo(newPageNo));
    // dispatch(closePixabay());
  };
};

export const pageNoChangeFromIndex = (newPageNo) => {
  return (dispatch) => {
    dispatch(updatePageNo(newPageNo));
    dispatch(indexClose());
  };
};
export const updatePageNo = (newPageNo) => {
  return {
    type: PAGE_NO_CHANGE,
    payload: newPageNo,
  };
};
export const getZip = (blobURLs) => {
  return {
    type: GET_ZIP,
    payload: blobURLs,
  };
};
export const zoomSubPageNoChange = (newNo) => {
  return {
    type: ZOOM_SUB_PAGE_NO_CHANGE,
    payload: newNo,
  };
};
