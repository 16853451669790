import {GET_PIXABAY,CLOSE_PIXABAY} from '../actions/actionTypes'

export default (state = {data:{}}, action) => {
    switch (action.type) {

        case GET_PIXABAY:
            // console.log(action.payload);
            return action.payload;

        case CLOSE_PIXABAY:
            // console.log(action.payload);
            return {data:{}};

        default:
            return state;
    }
};