/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

let downCoords={
    x:0,
    y:0,
};
class QHideable extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.defaultCursor = "crosshair";
  }
  doMouseDown(o) {
    // downCoords = this._canvas.getPointer(o);
    // if (this._canvas._iTextInstances) {
    //   this._canvas._iTextInstances.forEach(function (ob) {
    //     ob.exitEditing();
    //   });
    // }
  }

  doMouseUp(o, imagePath) {

    // let actualWidth = this._canvas.backgroundImage.getScaledWidth();
    // let proportion =
    //   this._canvas.backgroundImage.getOriginalSize().width / actualWidth;
    // let proportionY =
    //   this._canvas.backgroundImage.getOriginalSize().height /
    //   this._canvas.backgroundImage.getScaledHeight();

    // if (
    //   Math.abs(o.pointer.x - downCoords.x) < 10 ||
    //   Math.abs(o.pointer.y - downCoords.y) < 10
    // )
    //   return;
    // let canvas = this._canvas;


    // const iWidth = o.e.clientX - downCoords.x; // * proportion;
    // const iHeight = o.e.clientY - downCoords.y; // * proportionY;
    // const cropX = downCoords.x; //* proportion;
    // const cropY = downCoords.y; //* proportion;



    // fabric.Image.fromURL(imagePath, function (img) {
    //   img.set({
    //     left: 0,
    //     top: 0,
    //     cropX,
    //     cropY,
    //     width: iWidth,
    //     height: iHeight,

    //     isCapture: true,
    //   });
    //   canvas.setBackgroundImage(img, () => {
    //     canvas.setWidth(img.width);
    //     canvas.setHeight(img.height);
    //   });

    //   canvas.renderAll();
    // });
  }
}

export default QHideable;
