import {IMAGES_LOADED, IMAGES_UNLOADED} from "./actionTypes";

export const imagesLoaded = () => {
    return {
        type: IMAGES_LOADED
    }
};
export const imagesUnloaded = () => {
    return {
        type: IMAGES_UNLOADED
    }
};