import {
  SET_LOGIN_SUCCESS,
  SET_DOMAIN,
  SET_LIBRARY_FROM_LOCAL,
  SET_LIBRARY_OFFLINE,
  SET_GROUP_EBOOKS,
  SET_CATEGORY_GROUPS,
} from "../actions/actionTypes";

export default (
  state = {
    loggedIn: false,
    library: "",
    domain: "",
    user_profile: "",
    categoryGroups: [],
    // url: 1,
  },
  action
) => {
  switch (action.type) {
    case SET_LOGIN_SUCCESS:
      // console.log(
      //   "process.env.REACT_APP_CATEGORY_ID: ",
      //   process.env.REACT_APP_CATEGORY_ID
      // );
      // console.log("action.type", action.type); //TODO remove
      // console.log("action.payload", action.payload); //TODO remove
      return {
        ...state,
        loggedIn: true,
        // library:
        //   process.env.REACT_APP_CATEGORY_ID !== undefined &&
        //   process.env.REACT_APP_CATEGORY_ID !== "false"
        //     ? action.payload.library.filter((book) => {
        //         return (
        //           book.category.id ===
        //           parseInt(process.env.REACT_APP_CATEGORY_ID)
        //         );
        //       })
        //     : action.payload.library,
        user_profile: action.payload.details,
      };
    case SET_CATEGORY_GROUPS:
      // console.log("SET_CATEGORY_GROUPS action.payload: ", action.payload);
      const groups = action.payload.success.map((gr) => {
        return {
          value: gr.id,
          label: gr.short_name,
        };
      });

      return {
        ...state,
        categoryGroups: groups,
      };
    case SET_GROUP_EBOOKS:
      // console.log("SET_GROUP_EBOOKS action.payload: ", action.payload);
      const ebooks = action.payload.success;
      // process.env.REACT_APP_CATEGORY_ID !== undefined &&
      // process.env.REACT_APP_CATEGORY_ID !== "false"
      // ? action.payload.success.filter((book) => {
      //     return (
      //       book.category?.id && book.category.id === parseInt(process.env.REACT_APP_CATEGORY_ID)
      //     );
      //   })
      // :
      return {
        ...state,
        library: [...state.library, ...ebooks],
      };
    case SET_LIBRARY_OFFLINE:
      // console.log("action.type", action.type); //TODO remove
      // console.log("action.payload", action.payload); //TODO remove
      return {
        ...state,
        library: action.payload,
      };
    case SET_DOMAIN:
      // console.log("action.type", action.type); //TODO remove
      // console.log("action.payload", action.payload); //TODO remove
      return {
        ...state,
        domain: action.payload,
        // url: action.payload.url,//TODO
      };
    case SET_LIBRARY_FROM_LOCAL:
      // console.log(`localStorage.getItem("auth_token")`);
      if (!localStorage.getItem("auth_token")) {
        window.location.hash = `#/login`;
        return {
          ...state,
        };
      }
      // console.log("localStorage.library", localStorage.library);
      return {
        ...state,
        loggedIn: true,
        // library: JSON.parse(localStorage.library),
        // user_profile: JSON.parse(localStorage.user_profile),
        // url: localStorage.url,
        domain: localStorage.domain,
        theme: localStorage.theme || "dark",
      };

    default:
      return state;
  }
};
