import React from "react";
import { useTranslation } from "react-i18next";

export default function ImageButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object"
      style={{ transform, "transformOrigin": "left top" }}
    >
      <span className="object-icon">
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="white"
            stroke="#FD6D87"
            strokeWidth="0" // Was 2 = Mustafa changed to 0
          />
          {(object?.style?.includes("styleinfo")) ? 
              <path d="M14.1148 4C15.28 4 15.8626 4.7296 15.8626 5.5656C15.8626 6.6096 14.8504 7.5752 13.533 7.5752C12.4296 7.5752 11.7861 6.9752 11.8165 5.9832C11.8165 5.1488 12.5826 4 14.1148 4ZM10.5296 20C9.60956 20 8.93565 19.4784 9.57913 17.1808L10.6348 13.1072C10.8183 12.456 10.8487 12.1944 10.6348 12.1944C10.3591 12.1944 9.16609 12.644 8.45913 13.088L8 12.384C10.2365 10.6352 12.8096 9.6104 13.9139 9.6104C14.833 9.6104 14.9861 10.6288 14.527 12.1944L13.3174 16.476C13.1035 17.232 13.1948 17.4928 13.4096 17.4928C13.6852 17.4928 14.5896 17.1792 15.4783 16.5272L16 17.1784C13.8243 19.216 11.4478 20 10.5296 20Z" fill="white">            
              </path>:
              <>
               <path
               d="M14.0858 8.13577C13.687 7.57859 12.8585 7.57859 12.4596 8.13577L11.3031 9.75122C11.0539 10.0993 11.0539 10.5674 11.3031 10.9154L12.3398 12.3635C12.5413 12.645 12.4856 13.0351 12.2135 13.249C11.9237 13.4766 11.5029 13.4171 11.2883 13.1175C10.7464 12.3612 10.1184 11.4839 9.63124 10.8031C9.23236 10.2457 8.40372 10.2456 8.00472 10.8029L6.13266 13.4179C5.6589 14.0796 6.1319 15 6.94577 15H17.0543C17.8681 15 18.3411 14.0796 17.8674 13.4179L14.0858 8.13577Z"
               fill="#FD6D87"
             />
             <path
               d="M10.5 8.25C10.5 8.94036 9.94037 9.5 9.25001 9.5C8.55965 9.5 8.00001 8.94036 8.00001 8.25C8.00001 7.55964 8.55965 7 9.25001 7C9.94037 7 10.5 7.55964 10.5 8.25Z"
               fill="#FD6D87"
             />
             </>
            }
         
        </svg>
      </span>
      <span className="object-text">{(!object.description || object.description===undefined || object.description==='undefined')? t("Image") :object.description}</span>

    </div>
  );
}
