import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toggleSideBar, sideBarOpen, sideBarClose } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
import { IoRadioButtonOnOutline } from "react-icons/io5";
import { changeSelectedTool } from "../actions/selectedTool";

import ExitButton from "./SketchSelectedButtons/ExitButton";

import { setPagePartZoomMode } from "../actions/settings";

const CurtainMenu = (props) => {
  const { t, i18n } = useTranslation();
  const [curtainColor, setCurtainColor] = useState("black");

  useEffect(() => {
    setBlackCurtainMode();
    //   return () => {
    //       cleanup
    //   }
  }, []);
  const setBlackCurtainMode = () => {
    // props.toggleSearchBar(true);
    document.getElementById("parentOne").classList.remove("content");
    document
      .getElementById("parentOne")
      .classList.remove("contentCurtainWhite");
    document.getElementById("parentOne").classList.add("contentCurtainBlack");
    document.getElementById("mainOne").style.display = "none";
    setCurtainColor("black");
  };
  const setWhiteCurtainMode = () => {
    // props.toggleSearchBar(true);
    document
      .getElementById("parentOne")
      .classList.remove("contentCurtainBlack");
    document.getElementById("parentOne").classList.add("contentCurtainWhite");
    setCurtainColor("white");
  };
  const exitCurtainMode = () => {
    document.getElementById("mainOne").style.display = "block";
    document
      .getElementById("parentOne")
      .classList.remove("contentCurtainWhite");
    document
      .getElementById("parentOne")
      .classList.remove("contentCurtainBlack");
    document.getElementById("parentOne").classList.add("content");
    props.toggleSideBar(false);
    props.changeSelectedTool("hand");
    // props.toggleButtons(true);
    // console.log(this.state.curtainState);
    // this.onClickHand();
  };

  return (
    <div className="navbar is-fixed-bottom  bottom-annotation-menu open">
      <button
        className={`button is-small modal-button ${
          curtainColor === "black" ? "green-bottom-border" : ""
        }`}
        onClick={setBlackCurtainMode}
        title={t("Black Curtain")}
      >
        <span className="icon  has-text-black">
          <IoRadioButtonOnOutline className="fas fa-2x" />
        </span>
      </button>
      <button
        className={`button is-small modal-button ${
          curtainColor === "white" ? "green-bottom-border" : ""
        }`}
        onClick={setWhiteCurtainMode}
        title={t("White Curtain")}
      >
        <span className="icon  has-text-light">
          <IoRadioButtonOnOutline className="fas fa-2x" />
        </span>
      </button>

      <ExitButton onClickHandler={exitCurtainMode} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // theme: state.theme,
    // pages: state.pages.pages,
    // pagesState: state.pages,
    // totalPageNumber: state.pages.totalPageNumber,
    // currentPageNumber: state.pages.currentPageNo,
    // currentPageId: state.pages.currentPage[0]?.id,
    // currentPage: state.pages.currentPage,
    // pageLoaded: state.pageLoaded,
    // imageCapture: state.imageCapture,
    // imagesLoaded: state.imagesLoaded,
    // scaleZoomFactorVal: state.scaleZoomFactor,
    // selectedTool: state.selectedTool.selectedTool,
    // searchBarOpened: state.searchBarOpen,
    // annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    // editSelectionMode: state.annotationSelections.editSelectionMode,
    // currentSelection: state.annotationSelections.currentSelection,
    // annotations: state.annotationSelections.annotations,
    // domain: state.login.user_profile.domain_name,
    // ebookId: state.pages.ebookId,
    // hideInteractiveObjectsVal: state.hideInteractiveObjects,
    // showAnnotations: state.settings.showAnnotations,
    // canvasVals: state.scaleZoomFactor.canvas,
    // pageZoomPartMode: state.settings.pageZoomPartMode,
    // pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    pageZoomPartObject: state.settings.pageZoomPartObject,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  toggleSearchBar,
  sideBarOpen,
  sideBarClose,
  setPagePartZoomMode,
  changeSelectedTool,

  //   showInteractiveObjects,
})(CurtainMenu);

// function exitZoomMode(props) {
//   console.log("exitZoomModeeeeee");
//   if (props.pageZoomPartMode) {
//     props.setPagePartZoomMode(null);
//     props.setPagePartZoomObjectToShow(
//       null,
//       { x: null, y: null },
//       { x: null, y: null }
//     );
//   } else {
//     props.resetAnnotationSelectionMode();
//     props.showInteractiveObjects();
//   }
// }
