import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class CaptureTool extends Component {
  render() {
    return (
      // <li onClick={!this.props.hideButton && this.props.onClick}>
      <li onClick={!this.props.hideButton ? this.props.onClick : undefined}>
        <span
          className={`button normal-btn down is-hidden-mobile ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          } ${
            this.props.activeButton === "capture" ? "active" : ""
          }${this.props.hideButton ? "passive" : ""}`}
          title={this.props.t("Capture") + " (C)"}
        >
          <img
            src={require("../../assets/img/svg/selector.svg")}
            alt={this.props.t("Capture") + " (C)"}
          />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">{this.props.t("Capture")}</span>}
        </span>
      </li>
    );
  }
}
export default withTranslation()(CaptureTool);
