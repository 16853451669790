import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class VerticalToolbar extends Component {
   render() {
    return (
      <li onClick={this.props.onClick}>
        <span
          className={`button normal-btn vertical-toolbar  ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          } ${
            this.props.activeButton === "vertical-toolbar" ? "active" : ""
          }`}
          title={this.props.t("Change Toolbar Position")}
          data-tip={this.props.t("Change Toolbar Position")}
        >
          <img
            src={require("../../assets/img/svg/toolbar-vertical.svg")}
            alt={this.props.t("Change Toolbar Position")}
          />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">
              {this.props.toolbarPosition ? this.props.t("Horizontal") : this.props.t("Vertical") }           
            </span>}
        </span>
      </li>
    );
  }
}

export default withTranslation()(VerticalToolbar);
