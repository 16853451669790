import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import PasteButton from "./SketchSelectedButtons/PasteButton";
import RemoveButton from "./SketchSelectedButtons/RemoveButton";
import ExitButton from "./SketchSelectedButtons/ExitButton";
/* eslint-disable jsx-a11y/anchor-is-valid */
import Hand from "./ToolbarItems/Hand";
import ZoomOut from "./ToolbarItems/ZoomOut";
import ZoomIn from "./ToolbarItems/ZoomIn";
import ZoomNormal from "./ToolbarItems/ZoomNormal";
import FullScreen from "./ToolbarItems/FullScreen";
import DrawToolbar from "./ToolbarItems/DrawToolbar";
import ZoomToolbar from "./ToolbarItems/ZoomToolbar";
// import Layers from "./ToolbarItems/Layers";
import Menu from "./ToolbarItems/Menu";
import VerticalToolbar from "./ToolbarItems/VerticalToolbar";
import PinToolbar from "./ToolbarItems/PinToolbar";
import Pen from "./ToolbarItems/Pen";
import Highliter from "./ToolbarItems/Highliter";
import Text from "./ToolbarItems/Text";
import Eraser from "./ToolbarItems/Eraser";
import Shapes from "./ToolbarItems/Shapes";
import StrokeSize from "./ToolbarItems/StrokeSize";
import TextStrokeColor from "./ToolbarItems/TextStrokeColor";
import FillColor from "./ToolbarItems/FillColor";
// import UndoRedo from "./ToolbarItems/UndoRedo";
import Draggable from "react-draggable";
import Selecter from "./ToolbarItems/Selecter";
// import TransparentFillColor from "./ToolbarItems/TransparentFillColor";
import { CompactPicker } from "react-color";
// import { SketchPicker } from "react-color";
import AddNote from "./ToolbarItems/AddNote";
import ActivityOptions from "./ToolbarItems/ActivityOptions";
import TeacherTools from "./ToolbarItems/TeacherTools";
import QuestionOptions from "./ToolbarItems/QuestionOptions";
import { isMobile } from "react-device-detect";
import { setScalePageReset } from "../actions/scalePageReset";
import AddHideableAnnotation from "./ToolbarItems/AddHideableAnnotation";
import AddHideArea from "./ToolbarItems/AddHideArea";
import AddBlankPage from "./ToolbarItems/AddBlankPage";
import AddTimer from "./ToolbarItems/AddTimer";

// import { CompactPickerColor } from "react-color/color";
import Undo from "./ToolbarItems/Undo";
import Redo from "./ToolbarItems/Redo";

// import Up from "./ToolbarItems/Up";
// import Down from "./ToolbarItems/Down";
import NextPage from "./ToolbarItems/NextPage";
import PrevPage from "./ToolbarItems/PrevPage";
// import PageOptions from "./ToolbarItems/PageOptions";
import { pageNoChange } from "../actions/pages";
import { toggleButtons } from "../actions/buttonShowHide";
import {
  setToolbarPosition,
  resetToolbarPosition,
  pinToolbar,
  unpinToolbar,
  setToolbarVertical,
} from "../actions/toolbar";

import { changeSelectedTool } from "../actions/selectedTool";
import { setAnnotationSelectionMode } from "../actions/annotationSelection";

// import { toggleSideBar } from "../actions/sidebar";
// import { pullZipImages } from "../actions/pages";
// import { hideInteractiveObjects as hideInteractiveObjectsAction } from "../actions/interactiveObjects";
// import { imagesLoaded as imagesLoadedAction } from "../actions/imageLoad";
import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../actions/interactiveObjects";
import {
  showAnnotations,
  hideAnnotations,
  showArea,
  hideArea,
  triggerRedo,
  triggerUndo,
  triggerRemove,
  triggerRemoveAll,
  triggerZoomIn,
  triggerZoomOut,
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  showTimer,
} from "../actions/settings.js";
// import { captureInProcess, hideCaptureImage } from "../actions/imageCapture";
import Capture from "./ToolbarItems/CaptureTool";
import Curtain from "./ToolbarItems/CurtainTool";
// import AddNote from "./ToolbarItems/AddNote";
import AddVideo from "./ToolbarItems/AddVideo";
import AddH5p from "./ToolbarItems/AddH5p";
import AddAudio from "./ToolbarItems/AddAudio";
import AddImage from "./ToolbarItems/AddImage";
import AddLink from "./ToolbarItems/AddLink";
import AddMCQuestion from "./ToolbarItems/AddMCQuestion";
import AddMRQuestion from "./ToolbarItems/AddMRQuestion";
import Mouse from "./ToolbarItems/Mouse";
import HardDragger from "./ToolbarItems/HardDragger";
import DevModeTools from "./ToolbarItems/DevModeTools";
import { setLineWidth, setLineColor, setFillColor } from "../actions/toolbar";
import AddZoom from "./ToolbarItems/AddZoom";
import { insertPage } from "../actions/addActivity";
import { guidGenerator } from "../services/util";
import configuration from "../config.json";
import { toastWarning } from "../actions/showToast";
import ReactGA from "react-ga";
import { selectCurrentPageWithMyContent } from "../store/configureStore";
// import RecordView from "./RecordView";

// import { useSwipeable } from "react-swipeable";
const CompactPickerColors = (<CompactPicker />).props.colors;

function NewToolbar(props) {
  const _pdfPageList = useRef(null);
  const { annotationSelectionMode } = props;
  const [showColor, setShowColor] = useState(false);
  const [localControlledPosition, setLocalControlledPosition] = useState(
    props.toolbar.toolbarPosition
  );
  const [state, setState] = useState({
    curtainState: 0,
    verticalToolbar: props.toolbar.verticalToolbar,
    toolbarPin: props.toolbar.toolbarPin,
    activeButton: props.toolbar.activeButton,
    activeSubButton: null,
    activeSubChildButton: null,
    activeMouseButton: null,
    activeDrags: 0,
    // localControlledPosition: props.toolbar.toolbarPosition,
  });

  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //  props.annotationIdToShow &&
  // }, [props.annotationIdToShow]);

  const onClickSubButton = (buttonName) => {
    setState(
      (prevState) => {
        console.log(
          " prevState.activeSubButton, buttonName",
          prevState.activeSubButton,
          buttonName
        );
        return {
          ...prevState,
          activeSubButton:
            prevState.activeSubButton === buttonName ? null : buttonName,
          activeSubChildButton: null,
        };
      },
      () => {
        props.changeSelectedTool(buttonName);
      }
    );
  };
  const onStart = () => {
    setState({ activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    console.log(`state.localControlledPosition`, state.localControlledPosition);
    console.log(`state`, state);
    setState({ activeDrags: --state.activeDrags });
    const { x, y } = localControlledPosition;
    props.setToolbarPosition({ x, y });
  };

  const onControlledDrag = (e, position) => {
    const { x, y } = position;
    setLocalControlledPosition({ x, y });
  };
  let userRole = "student";

  if (props.roles && props.roles[0])
    userRole = String(props.roles[0].name).toLowerCase();
  // console.log("userRole", userRole);
  const showTeacherTools = userRole !== "student";
  const dragHandlers = { onStart: onStart, onStop: onStop };
  let toolbarClass = "";
  switch (props.toolbarSize) {
    case "1.5":
      toolbarClass = "toolbarsize-1-5x";
      break;
    case "2":
      toolbarClass = "toolbarsize-2x";
      break;
    default:
      break;
  }

  const devMode = process.env.REACT_APP_DEV_MODE === "true";

  // devMode ? (props.showLabels = true) : (props.showLabels = false);
  //const advancedMode = false;
  // const { controlledPosition } = props;
  const isSmallScreen = props.canvasVals ? window.innerWidth < 641 : false;
  const isVerySmallScreen = props.canvasVals ? window.innerWidth < 341 : false;

  const renderToolbar = () => {
    return (
      <div
        //    className="navbar is-fixed-top top-annotation-menu hide "
        style={{ position: "absolute", top: "10px", left: "10px" }}
      >
        <Draggable
          // disabled={false}
          handle=".drag"
          // bounds=".columns"
          // defaultPosition={(100, 100)}
          // defaultPosition={isSmallScreen ? { x: 0, y: 0 } : null}
          scale={1}
          // axis={isSmallScreen ? "x" : "both"}
          axis={"both"}
          grid={[2, 2]}
          // defaultClassName="toolbar-draggable-handle"
          // position={isSmallScreen ? null : controlledPosition}
          // defaultPosition={{ x: 0, y: 0 }}
          defaultPosition={localControlledPosition}
          position={localControlledPosition}
          {...dragHandlers}
          onDrag={onControlledDrag}
          onStart={onStart}
          onStop={onStop}
          // disabled={isSmallScreen}
        >
          <div className={` toolbar-items    ${true ? "open" : "hide"} `}>
            <div
              className=" main-toolbar buttons has-addons"
              style={{ width: "100px", height: "400px" }}
            >
              <ul
                style={{
                  columns: 2,
                  webkitColumns: 2,
                  mozColumns: 2,
                }}
                className="p-2"
              >
                <li
                  className="drag-element"
                  // onMouseDown={() => onCloseAllxOpenItems()}
                >
                  <span
                    className="button drag"
                    id="drag1"
                    // title="Drag"
                    data-tip={"Drag"}
                  >
                    <img
                      src={require("../assets/img/svg/toolbar-drag.svg")}
                      alt="Drag"
                      draggable="false"
                    />
                  </span>
                </li>
                <Hand changeMyTool={(toolName) => onClickSubButton(toolName)} />
                <Mouse
                  // onClick={this.onClickSelecter}
                //   onClick={() => this.onClickMouseSelectButton("mouse-options")}
                //   activeButton={this.state.activeButton}
                //   hideButton={this.props.isButtonsShown}
                //   activeMouseButton={this.state.activeMouseButton}
                />
                {/* <PrevPage onClick={this.onClickPrevPage}></PrevPage>
                      <NextPage onClick={this.onClickNextPage}></NextPage> */}
                <PrevPage
                //   onClick={this.onClickPrevPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   TotalPageNumber={this.props.totalPageNumber}
                ></PrevPage>
                <NextPage
                //   onClick={this.onClickNextPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   totalPageNumber={this.props.totalPageNumber}
                ></NextPage>
                <PrevPage
                //   onClick={this.onClickPrevPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   TotalPageNumber={this.props.totalPageNumber}
                ></PrevPage>
                <NextPage
                //   onClick={this.onClickNextPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   totalPageNumber={this.props.totalPageNumber}
                ></NextPage>
                <PrevPage
                //   onClick={this.onClickPrevPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   TotalPageNumber={this.props.totalPageNumber}
                ></PrevPage>
                <NextPage
                //   onClick={this.onClickNextPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   totalPageNumber={this.props.totalPageNumber}
                ></NextPage>
                <PrevPage
                //   onClick={this.onClickPrevPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   TotalPageNumber={this.props.totalPageNumber}
                ></PrevPage>
                <NextPage
                //   onClick={this.onClickNextPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   totalPageNumber={this.props.totalPageNumber}
                ></NextPage>
                <PrevPage
                //   onClick={this.onClickPrevPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   TotalPageNumber={this.props.totalPageNumber}
                ></PrevPage>
                <NextPage
                //   onClick={this.onClickNextPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   totalPageNumber={this.props.totalPageNumber}
                ></NextPage>
                <PrevPage
                //   onClick={this.onClickPrevPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   TotalPageNumber={this.props.totalPageNumber}
                ></PrevPage>
                <NextPage
                //   onClick={this.onClickNextPage}
                //   currentPageNumber={this.props.currentPageNumber}
                //   totalPageNumber={this.props.totalPageNumber}
                ></NextPage>
                <Capture
                  changeMyTool={(toolName) => onClickSubButton(toolName)}
                />
                <Curtain
                  changeMyTool={(toolName) => onClickSubButton(toolName)}
                />
                <AddVideo
                  changeMyTool={(toolName) => onClickSubButton(toolName)}
                />
                <AddH5p
                  changeMyTool={(toolName) => onClickSubButton(toolName)}
                />
                <li
                  className="drag-element"
                  // onMouseDown={() => onCloseAllxOpenItems()}
                >
                  <span
                    className="button drag"
                    id="drag1"
                    // title="Drag"
                    data-tip={"Drag"}
                  >
                    <img
                      src={require("../assets/img/svg/toolbar-drag.svg")}
                      alt="Drag"
                      draggable="false"
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </Draggable>
      </div>
    );
  };

  return <div>{renderToolbar()}</div>;
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    // currentPage: state.pages.currentPage,
    currentPage: selectCurrentPageWithMyContent(state),
    TotalPageNumber: state.pages.totalPageNumber,
    scaleZoomFactorVal: state.scaleZoomFactor,
    isButtonsShown: state.isButtonsShown,
    selectedTool: state.selectedTool.selectedTool,
    selectedToolChangedFromOutside:
      state.selectedTool.selectedToolChangedFromOutside,
    selectedToolChangedFromOutsideShouldCloseTheMenu:
      state.selectedTool.shouldCloseTheMenu,
    searchBarOpen: state.searchBarOpen,
    // imageCapture: state.imageCapture,
    canvasVals: state.scaleZoomFactor.canvas,
    controlledPosition: state.toolbar.toolbarPosition,
    showAnnotations: state.settings.showAnnotations,
    toolbar: state.toolbar,
    showLabels: state.toolbar.showLabels,
    toolbarSize: state.toolbar.toolbarSize,
    roles: state.login.user_profile.roles,
    ebookId: state.pages.ebookId,
    advancedMode: state.toolbar.advancedMode,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    pageZoomPartObject: state.settings.pageZoomPartObject,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  pageNoChange,
  toggleButtons,
  // captureInProcess,
  // hideCaptureImage,
  hideInteractiveObjects,
  showInteractiveObjects,
  setToolbarPosition,
  resetToolbarPosition,
  pinToolbar,
  unpinToolbar,
  setScalePageReset,
  changeSelectedTool,
  setAnnotationSelectionMode,
  showAnnotations,
  hideAnnotations,
  triggerRedo,
  triggerUndo,
  triggerRemove,
  triggerRemoveAll,
  triggerZoomIn,
  triggerZoomOut,
  setLineWidth,
  setLineColor,
  setFillColor,
  setToolbarVertical,
  insertPage,
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  showTimer,
})(NewToolbar);
