import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ToolbarMode extends Component {
  
  render() {
    return (
      <li onClick={this.props.onClick}>
        <span
          className={`button normal-btn  ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          } ${
            this.props.activeButton === "toolbar-mode" ? "active" : ""
          }`}
          title={this.props.t("Change Toolbar Mode")}
          data-tip={this.props.t("Change Toolbar Mode")}
        >
          <img
            src={this.props.toolbarAlternative? require("../../assets/img/svg/tools-toolmode.svg") : require("../../assets/img/svg/tools-toolmode-large.svg") }
            alt={this.props.t("Change Toolbar Mode")}
          />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">{ this.props.toolbarAlternative? this.props.t("Simple") : this.props.t("Advanced") }</span>}
        </span>
      </li>
    );
  }
}

export default withTranslation()(ToolbarMode);
