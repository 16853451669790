import React from "react";
import {
  // HiXCircle,
  BsGrid1X2Fill,
  // MdSkipPrevious,
  // MdExitToApp
} from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function ShowZoomAreasButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button  is-small modal-button ${
          props.disabled ? "button-disabled" : ""
        } ${props.shouldShowButtonLabels ? " show-label-top-menu " : ""}`}
        onClick={props.onClickHandler}
        title={t("Show/Hide Zoom Areas")}
      >
        <span
          className={`icon is-small ${
            props.show ? "has-text-light" : "has-text-grey"
          } `}
        >
          <BsGrid1X2Fill className="fas fa-2x" />
        </span>
        {props.shouldShowButtonLabels && (
          <span className=" mr-1 show-label-text">
            {t((props.show ? "Hide" : "Show") + " Zoom Areas")}
          </span>
        )}
      </button>
    </>
  );
}
