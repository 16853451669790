import { IMAGES_LOADED, IMAGES_UNLOADED } from "../actions/actionTypes";

export default (state = false, action) => {
  switch (action.type) {
    case IMAGES_LOADED:
      return true;
    case IMAGES_UNLOADED:
      return false;

    default:
      return state;
  }
};
