import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  setLoginSuccess,
  setDomain,
  setGroupEbooks,
  setCategoryGroups,
} from "../actions/login";
import { toaster } from "../actions/showToast";

import Spinner from "./Spinner";
// import { spinner } from "../actions/spinner";
import {
  login,
  socialLogin,
  getGroupEbooks,
  getCategoryGroups,
  registerUser,
  resendVerification,
  verifyUser,
} from "../services/api";
import { confirmAlert } from "react-confirm-alert";
import { Offline, Online } from "react-detect-offline";
import Select from "react-select";
import SmsTimer from "./SketchSelectedButtons/SmsTimer";
import { connect, useSelector, useDispatch } from "react-redux";
import ChangeLanguage from "../components/SubComponents/ChangeLanguage";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  control: (base, state) => ({
    ...base,
    background: "#f7f7f7",
    placeholder: "red",
    // paddingRight: "30px",
    border: state.menuIsOpen ? "0px solid #ccc" : "0px solid #ccc ",
    boxShadow: "none",
    "&:hover": {
      border: state.menuIsOpen ? "1px solid #ccc" : "1px solid #ccc",
    },
  }),

  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

function LoginPage(props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [cenibook_domain, setCenibookDomain] = useState("");
  const [warning, setWarning] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [signUp, setSignUp] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showLoggedInVerify, setShowLoggedInVerify] = useState(false);
  const [course, setCourse] = useState("");

  const schema = yup.object().shape({
    // type: yup.string().required(),
    // email: !showVerify ? yup.string().email().required() : yup.string(),
    // groupId: !showVerify && signUp ? yup.string().required() : yup.string(),
    // name: !showVerify && signUp ? yup.string().required() : yup.string(),
    // phone: !showVerify && signUp ? yup.string().required() : yup.string(),
    // password:
    //   !showVerify && signUp
    //     ? yup
    //         .string()
    //         .min(8, "Password is too short - should be 8 chars minimum.")
    //         .required()
    //     : yup.string().required(),
    verify: showVerify ? yup.string().required() : yup.string(),
  });

  const { register, handleSubmit, watch, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   control,
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  const onSubmit = (data) => {
    console.log("deneme");
    setIsLoading(true);
    if (!showLoggedInVerify && showVerify) {
      verifyHandler(data);
      return;
    }
    if (signUp) {
      registerHandler(data);
    } else {
      loginHandler(data);
    }
    console.log(data);
  };
  const gotoLibrary = async (response, cenibook_domain, social = false) => {
    if (social) {
      localStorage.domain = await cenibook_domain;
      const status = await localStorage.auth_token;
      console.log("status", status);
      console.log("logged in successfully");
      await props.setLoginSuccess(response.success);
      await props.setDomain(cenibook_domain);
    } else if (response.success.token) {
      localStorage.auth_token = await response.success.token;
      localStorage.domain = await cenibook_domain;
      // setState({
      //   modalText: "Something went wrong please try again later",
      // });
      const status = await localStorage.auth_token;
      console.log("status", status);
      console.log("logged in successfully");
      await props.setLoginSuccess(response.success);
      await props.setDomain(cenibook_domain);
      const groups = await response?.success?.details?.groups;
      if (groups.length > 0) {
        groups.map((group) => {
          getGroupEbooks(group["id"])
            .then(async (response) => {
              console.log(" getGroupEbookResponse >>", response);
              await props.setGroupEbooks(response);
            })
            .catch((error) => {
              console.log("logged error", error);
            })
            .finally(() => {});
        });
      }
      props.history.push("/library");
    }
  };
  const verifyHandler = (data) => {
    const token = String(data.verify).toUpperCase();
    // e.preventDefault();

    verifyUser(token)
      .then(async (response) => {
        console.log("response", response);
        const isVerified = response?.status==='success'
        if (isVerified) {
          gotoLibrary(response, cenibook_domain);
        } else {
          if (response.success.token) {
            localStorage.auth_token = await response.success.token;
          }
          setIsLoading(false);
          setShowVerify(true);
          setShowLoggedInVerify(true);
        }
      })
      .catch((error) => {
        let errorText = "Please check your login credentials.";
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 422) {
            errorText = "Your phone is now verified. You can login.";
            setShowVerify(false);
            setShowLoggedInVerify(false);
            setSignUp(false);
          } else if (error.response.status === 404) {
            errorText = "Wrong code.";
            setShowVerify(true);
            setShowLoggedInVerify(false);
          } else if (error.response.status !== 401) {
            errorText = "Server Error. Please try again later.";
          }
          console.log(error.response.headers);
        }

        confirmAlert({
          // title: t("User Login"),
          message: t(errorText),
          buttons: [
            {
              label: t("Ok"),
              onClick: () => {
                console.log("true");
              },
            },
          ],
        });

        console.log("logged error", error);
        props.setLoginSuccess(false);
      })
      .finally(() => {
        //props.spinner(false);
      });
  };
  /**
   * does the form submit process
   */
  const loginHandler = (data) => {
    const { email, name, password } = data;
    // e.preventDefault();

    login(email, password, cenibook_domain)
      .then(async (response) => {
        console.log("response", response);
        const isVerified = response?.success?.details?.email_verified_at;
        // const isVerified = response?.status==='success'
        if (isVerified) {
          gotoLibrary(response, cenibook_domain);
        } else {
          if (response.success.token) {
            localStorage.auth_token = await response.success.token;
          }
          setIsLoading(false);
          setShowVerify(true);
          setShowLoggedInVerify(true);
        }
      })
      .catch((error) => {
        let errorText = "Please check your login credentials.";
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status !== 401) {
            errorText = "Server Error. Please try again later.";
          }
          console.log(error.response.headers);
        }

        confirmAlert({
          // title: t("User Login"),
          message: t(errorText),
          buttons: [
            {
              label: t("Ok"),
              onClick: () => {
                console.log("true");
              },
            },
          ],
        });

        console.log("logged error", error);
        props.setLoginSuccess(false);
      })
      .finally(() => {
        //props.spinner(false);
      });
  };

  /**
   * does the form submit process
   */
  const registerHandler = (data) => {
    const { name, email, password, groupId, phone } = data;
    console.log("groupId: ", groupId);

    registerUser(name, email, password, groupId, phone)
      .then(async (response) => {
        console.log("response", response);
        if (response.status === "failed") {
          let errorText = "";
          setIsLoading(false);
          if (response.validation_errors) {
            errorText += response.validation_errors.name
              ? " " + response.validation_errors.name
              : "";
            errorText += response.validation_errors.email
              ? " " + response.validation_errors.email
              : "";
            errorText += response.validation_errors.phone
              ? " " + response.validation_errors.phone
              : "";
            errorText += response.validation_errors.groupId
              ? " " + response.validation_errors.groupId
              : "";
            errorText += response.validation_errors.password
              ? " " + response.validation_errors.password
              : "";
          }

          confirmAlert({
            // title: t("User Login"),
            message: t(errorText),
            buttons: [
              {
                label: t("Ok"),
                onClick: () => {
                  console.log("true");
                },
              },
            ],
          });
        } else {
          setIsLoading(false);
          localStorage.auth_token = await response.token;
          setShowVerify(true);
          setShowLoggedInVerify(false);
        }
      })
      .catch((error) => {
        let errorText = "Please";
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status !== 401) {
            errorText = "Server Error. Please try again later.";
          }
          console.log(error.response.headers);
        }

        confirmAlert({
          // title: t("User Login"),
          message: t(errorText),
          buttons: [
            {
              label: t("Ok"),
              onClick: () => {
                console.log("true");
              },
            },
          ],
        });

        console.log("logged error", error);
        props.setLoginSuccess(false);
      })
      .finally(() => {
        //props.spinner(false);
      });
    //props.spinner(true);
  };

  /**
   * shows and hides modal
   */
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const loginWithGoogle = (e) => {
    e.preventDefault();
    console.log("Log in with google");
  };
  const signUpToggler = () => {
    setSignUp(!signUp);
  };

  useEffect(() => {
    console.log("useffee");
    if (signUp && props.categoryGroups?.length === 0) {
      setIsLoading(true);
      const catId =
        process.env.REACT_APP_CATEGORY_ID !== "false"
          ? process.env.REACT_APP_CATEGORY_ID
          : 1;
      console.log(
        "process.env.REACT_APP_CATEGORY_ID: ",
        process.env.REACT_APP_CATEGORY_ID
      );
      console.log("catId: ", catId);
      getCategoryGroups(catId)
        .then(async (response) => {
          console.log(" getGroupEbookResponse >>", response);
          await props.setCategoryGroups(response);
          // const groups=response.success;
          // options=groups;
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("logged error", error);
        })
        .finally(() => {});
    }
  }, [signUp]);

  const _onTextChange = (e, key) => {
    if (key === "cenibook_domain") {
      setCenibookDomain(e);
    } else if (key === "email") {
      setEmail(e);
    } else {
      setPassword(e);
    }
  };
  const onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  /**
   * renders modal
   */
  const renderModal = () => {
    return (
      <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
        <ModalHeader>
          <b>Oops!</b>
        </ModalHeader>
        <ModalBody>{modalText}</ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={toggleModal}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      {/* {renderModal()} */}

      <div className="login-form-area">
        <div
          className={signUp ? "login-form-sign-up" : "login-form"}
          id="login-form"
        >
          <Spinner color={"#c4c4c4"} size={150} spin={isLoading} />
          <div className={` ${!signUp ? "lf-left" : "lf-left-sign-up"} `}>
            <figure
              className={`welcome-logo${
                process.env.REACT_APP_COMPANY_LOGO_PATH ? "-company" : ""
              }`}
            >
              <img
                src={
                  process.env.REACT_APP_COMPANY_LOGO_PATH
                    ? process.env.REACT_APP_COMPANY_LOGO_PATH //require("../assets/img/company_logo.png")
                    : process.env.REACT_APP_INDIVIBOOK_LOGO_PATH
                }
                alt="Logo"
              />
            </figure>
            <div className="lf-left-title">
              <span>{t("Welcome")}</span>{" "}
            </div>
            <div className="lf-left-text">
              <Online>
                {process.env.REACT_APP_MULTITENANT === "true" &&
                  t("Please enter your email, password and domain name.")}
              </Online>
              <Offline>
                <div className="no-internet-login">
                  {t("No internet connection.")}
                </div>
              </Offline>
            </div>
            <div className="lf-left-notice">
              <p>
                {process.env.REACT_APP_MULTITENANT === "true" &&
                  t(
                    "If you experience a problem, please contact your domain administrator."
                  )}
              </p>
            </div>
            <div className="library-selection is-pulled-left mt-5 login-selection">
              <div className="select-area">
                {/* <label htmlFor="do-switchSmall">Theme</label> */}
                <div className="select is-small">
                  <ChangeLanguage />
                  <span className="icon">
                    <img
                      src={require("../assets/img/svg/pagi-combobox-arrow.svg")}
                      alt="First Page"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${!signUp ? "lf-right" : "lf-right-sign-up"}`}>
            {!signUp && (
              <div className="lf-right-title">
                {" "}
                {signUp ? t("Sign Up") : t("Sign in")}
              </div>
            )}

            {!showVerify ? (
              <>
                <form
                  className="login-form-box"
                  onSubmit={handleSubmit(onSubmit)}
                  // onSubmit={!signUp ? loginHandler : registerHandler}
                >
                  {signUp && (
                    <>
                      <label className="login-form-label" htmlFor="name">
                        {t("Name")}
                      </label>
                      <div
                        className="validate-input"
                        data-validate={t("Name is required")}
                      >
                        <input
                          disabled={isLoading}
                          className="login-form-input"
                          ref={register}
                          type="text"
                          name="name"
                          placeholder={t("Please enter your name")}
                          autoComplete="book-name"
                        />
                        <p className="login-error">
                          {errors.name && t(`${errors.name?.message}`)}
                        </p>
                      </div>
                    </>
                  )}
                  <label className="login-form-label" htmlFor="email">
                    {t("Email")}
                  </label>
                  <div
                    className="validate-input"
                    data-validate={t("Email is required")}
                  >
                    <input
                      disabled={isLoading}
                      className="login-form-input"
                      ref={register}
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("Please enter your email")}
                      autoComplete="book-email"
                    />

                    <p className="login-error">
                      {errors.email && t(`${errors.email?.message}`)}
                    </p>
                  </div>
                  {signUp && (
                    <>
                      <label className="login-form-label" htmlFor="phone">
                        {t("Phone Number") + " "}
                        <span className="sign-up-phone-confirmation">
                          {" *" +
                            t(
                              "We will send a pin code to your phone for confirmation"
                            )}
                        </span>
                      </label>
                      <div
                        className="validate-input"
                        data-validate={t("Phone number is required")}
                      >
                        <input
                          disabled={isLoading}
                          className="login-form-input"
                          ref={register}
                          type="tel"
                          name="phone"
                          maxLength={10}
                          placeholder={"5XXXXXXXXX"}
                        />

                        <p className="login-error">
                          {errors.phone?.message &&
                            t(`${errors.phone?.message}`)}
                        </p>
                      </div>
                    </>
                  )}
                  {signUp && (
                    <>
                      <label className="login-form-label" htmlFor="groupId">
                        {t("Course Group")}
                      </label>
                      <div
                        className="validate-input"
                        data-validate={t("Course Group is required")}
                      >
                        <Controller
                          // as={Select}
                          name="groupId"
                          defaultValue={props.categoryGroups.map(
                            (c) => c.value
                          )}
                          // options={props.categoryGroups}

                          // value={course}
                          control={control}
                          render={({ onChange, value, name, ref }) => (
                            <Select
                              inputRef={ref}
                              value={props.categoryGroups.find(
                                (c) => c.value === value
                              )}
                              onChange={(val) => onChange(val.value)}
                              options={props.categoryGroups}
                              placeholder={
                                <div className="select-placeholder-text">
                                  {t("Select a course group")}
                                </div>
                              }
                              styles={customStyles}
                            />
                          )}
                          styles={customStyles}
                        />
                        {/* <Select
                          options={options}

                          value={course}
                          // onChange={handleChange}
                        /> */}
                        <p className="login-error">
                          {errors.groupId?.message &&
                            t(`${errors.groupId?.message}`)}
                        </p>
                      </div>
                    </>
                  )}
                  <label className="login-form-label" htmlFor="password">
                    {t("Password")}
                  </label>
                  <div
                    className="validate-input"
                    data-validate={t("Password is required")}
                  >
                    <input
                      disabled={isLoading}
                      className="login-form-input"
                      ref={register}
                      type="password"
                      name="password"
                      placeholder={t("Please enter your password")}
                    />

                    <p className="login-error">
                      {errors.password?.message &&
                        t(`${errors.password?.message}`)}
                    </p>

                    {warning && (
                      <p className="help is-danger">{t("Caps Lock On")}</p>
                    )}
                  </div>
                  {/* {process.env.REACT_APP_MULTITENANT === "true" && (
                    <>
                      <label
                        className="login-form-label"
                        htmlFor="cenibook_domain-id"
                      >
                        {t("IndiviBook Domain")}
                      </label>
                      <div
                        className="validate-input"
                        data-validate={t("IndiviBook domain is required")}
                      >
                        <input
                        disabled={isLoading}
                          className="login-form-input"
                          type="cenibook_domain"
                          name="cenibook_domain"
                          placeholder={t("Please enter IndiviBook Domain")}
                          autoComplete="cenibook_domain"
                          value={cenibook_domain}
                          onChange={(e) =>
                            _onTextChange(
                              e.target.value.toLowerCase(),
                              "cenibook_domain"
                            )
                          }
                        />
                      </div>
                    </>
                  )} */}
                  <div className="login-form-footer">
                    <button
                      className="login-form-btn"
                      //   className={`${
                      //     signUp
                      //       ? "login-form-btn-signup"
                      //       : "login-form-btn"
                      //   }`
                      // }
                    >
                      {!signUp ? t("Login") : t("Register")}
                    </button>
                  </div>
                  {process.env.REACT_APP_LOGIN_PAGE === "true" && (
                      <>
                  <div className="field mt-1">
                    <div className="control has-text-centered	">
                      <label className={`is-size-7`}>
                        {!signUp
                          ? t("Don't you have an account?") + "  "
                          : t("Do you have an account?") + " "}
                      </label>
                      <span
                        className="pl-2 has-text-weight-bold	sign-up-link is-size-7"
                        onClick={signUpToggler}
                      >
                        {!signUp ? t("Sign up for free!") : t("Log In")}
                      </span>
                    </div>
                  </div>
                  </>
                    )}
                </form>
              </>
            ) : (
              <>
                {!showLoggedInVerify && (
                  <>
                    <form
                      className="login-form-box"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <label className="login-form-label" htmlFor="smsCode">
                        {t("SMS Verification Code")}
                      </label>
                      <div
                        className="validate-input"
                        data-validate={t("SMS Code is required")}
                      >
                        <input
                          disabled={isLoading}
                          className="login-form-input is-capitalized"
                          ref={register}
                          type="text"
                          name="verify"
                          placeholder={t("Please enter verification code")}
                        />

                        <p className="login-error">
                          {errors.verify?.message &&
                            t(`${errors.verify?.message}`)}
                        </p>
                      </div>
                      <div className="login-form-footer">
                        <button
                          onClick={() => console.log(showVerify)}
                          className="login-form-btn"
                        >
                          {t("Verify")}
                        </button>
                      </div>
                    </form>
                  </>
                )}

                <div className="field mt-1 has-text-centered">
                  <SmsTimer
                    seconds={180}
                    setShowLoggedInVerify={setShowLoggedInVerify}
                    showLoggedInVerify={showLoggedInVerify}
                    sendSms={() => console.log("sending sms again")}
                    logOut={() => {
                      setShowLoggedInVerify(false);
                      setShowVerify(false);
                      localStorage.auth_token = null;
                    }}
                    verifyUser={verifyUser}
                    resendVerification={resendVerification}
                  />
                </div>
              </>
            )}

            <div className="login-form-footer">
              {/* <button
                    className="login-form-btn"
                    onClick={loginWithGoogle}
                  >
                    {" "}
                    {t("Login with Google")}{" "}
                  </button> */}
              {/* <Login onSuccess={onSuccess} onFailure={onFailure} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoginPending: state.isLoginPending,
    isLoginSuccess: state.isLoginSuccess,
    categoryGroups: state.login.categoryGroups,
  };
};

export default connect(mapStateToProps, {
  // spinner,
  setLoginSuccess,
  setDomain,
  setGroupEbooks,
  setCategoryGroups,
  toaster,
})(LoginPage);
