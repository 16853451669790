import React, { Component, useState, useRef } from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { HiOutlineSun, HiOutlineX } from "react-icons/hi";
import {
  setBackgroundImageOpacity,
  setAddImageToCanvas,
} from "../actions/settings";
import EditBtn from "./ModalButtons/EditBtn";
import DeleteBtn from "./ModalButtons/DeleteBtn";
import { deleteButtonService } from "../services/api";
import { confirmAlert } from "react-confirm-alert";
import { renderModalData } from "./ModalButtons/RenderButtons";
import { deleteActivity, editActivity } from "../actions/addActivity";
import { changeSelectedToolFromOutside } from "../actions/selectedTool";
import { addButtonModalOpen } from "../actions/addButton";
import InsertBtn from "./ModalButtons/InsertButton";
import { selectCurrentPageId, selectUserId } from "../store/configureStore";
// import WinBoxWrapper from "./WinBoxWrapper";

const WinInnerModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const data = props.data;
  console.log("@@@@ data: ", data);

  const deleteContent = (id) => {
    confirmAlert({
      title: t("Delete Activity"),
      message: t("Are you sure you want to delete this content?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => {
            setIsLoading(true);
            deleteButtonService(String(props.domain).toLowerCase(), id)
              .then(async (response) => {
                props.deleteActivity(id, props.currentPageId);
                setIsLoading(false);
                closeModal();
                //  window.location.reload();
              })
              .catch((error) => {
                console.log("logged error", error);
                setIsLoading(false);
                closeModal();
              });
          },
        },
        {
          label: t("No"),
          onClick: () => {
            setIsLoading(false);
            // closeModal();
          },
        },
      ],
    });
  };

  const editContent = (data) => {
    console.log("datadata", data);
    props.editActivity(
      data.id,
      data.button_type,
      data.left,
      data.top,
      data.style,
      data.show
    );
    closeModal();
    // addButtonModalOpen
    props.addButtonModalOpen();
  };
  const closeModal = () => {
    props.toggleModal();
    props.setBackgroundImageOpacity(1);
  };
  const toggleBackgroundOpacity = () => {
    switch (props.backgroundImageOpacity) {
      case 0:
        props.setBackgroundImageOpacity(1);
        break;
      case 0.5:
        props.setBackgroundImageOpacity(0);
        break;
      case 1:
        props.setBackgroundImageOpacity(0.5);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <nav
        className="is-flex  is-justify-content-space-between is-align-items-between dragger modal-head "
        style={{ zIndex: "1000" }}
      >
        <span className="mx-2 dragger" id="drag1" data-tip={"Drag"}>
          <img
            src={require("../assets/img/svg/toolbar-drag.svg")}
            alt="Drag"
            draggable="false"
          />
        </span>
        <div className="is-flex  is-justify-content-space-between is-align-items-between">
          {/* HiXCircle, HiOutlineSun, HiOutlineTrash, HiOutlineArrowsExpand, HiLightBulb, HiPencilAlt */}
          {(data?.user_id === props.userId || props.userCanEditActivity) && (
            <>
              {(data?.button_type === "text" ||
                data?.button_type === "h5p" ||
                data?.button_type === "video" ||
                data?.button_type === "zoomVideoAnswer" ||
                data?.button_type === "link") && (
                <EditBtn
                  closeModal={closeModal}
                  isLoading={isLoading}
                  editContent={() => {
                    editContent(data);
                  }}
                />
              )}
              <DeleteBtn
                closeModal={closeModal}
                isLoading={isLoading}
                deleteContent={() => {
                  deleteContent(data.id);
                }}
              />
            </>
          )}

          <button
            className="button is-small modal-button"
            onClick={toggleBackgroundOpacity}
          >
            <span className="icon is-small has-text-warning">
              <HiOutlineSun className="fas fa-2x" />
            </span>
          </button>
          {data?.button_type === "image" && !data?.id && (
            <>
              {/* <EditBtn
                  closeModal={closeModal}
                  isLoading={isLoading}
                  editContent={() => {
                    editContent(data);
                  }}
                /> */}
              <InsertBtn
                closeModal={closeModal}
                isLoading={isLoading}
                // data={data}
                handleClick={() => {
                  props.setAddImageToCanvas(true);
                  closeModal();
                  props.changeSelectedToolFromOutside("select");
                }}
              />
            </>
          )}
        </div>
        <div>
          <button
            className="button is-small modal-button mr-2"
            onClick={closeModal}
          >
            <span className="icon is-small has-text-light ">
              <HiOutlineX className="fas fa-2x" />
            </span>
          </button>
        </div>
      </nav>
      <div className="m-1">{renderModalData(data)}</div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activityTitle: state.activities.activityTitle,
    theme: state.theme,
    backgroundImageOpacity: state.settings.backgroundImageOpacity,
    domain: state.login.user_profile.domain_name,
    userCanEditActivity: state.login.user_profile.userCanEditActivity,
    userId: selectUserId(state),
    currentPageId: selectCurrentPageId(state),
  };
};

export default connect(
  mapStateToProps,
  {
    setBackgroundImageOpacity,
    deleteActivity,
    editActivity,
    addButtonModalOpen,
    setAddImageToCanvas,
    changeSelectedToolFromOutside,
  }
  // mapDispatchToProps
)(WinInnerModal);
