import axios from "axios";
import { insertActivity } from "../actions/addActivity";
import { INSERT_ACTIVITY } from "../actions/actionTypes";

export const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};
export const axiosConfigAuth = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + localStorage.auth_token,
  },
};
export const login = (email, password, domain) => {
  const base_url =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : process.env.REACT_APP_MULTITENANT === "true"
      ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
      : process.env.REACT_APP_API_URL + "api/v2/"; //TODO cenibook.com
  return new Promise((resolve, reject) => {
    let params = { email: email, password: password };

    axios
      .post(base_url + "login", params)
      .then((res) => {
        const data = res.data;
        console.log("data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const verifyUser = (token) => {
  const base_url =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : process.env.REACT_APP_MULTITENANT === "true"
      ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
      : process.env.REACT_APP_API_URL + "api/v2/"; //TODO cenibook.com
  return new Promise((resolve, reject) => {
    let params = { token };
    axios
      .post(base_url + "sms/verify", params)
      .then((res) => {
        const data = res.data;
        console.log("sms/verify data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const registerUser = (name, email, password, groupId, phone) => {
  const base_url =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : process.env.REACT_APP_MULTITENANT === "true"
      ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
      : process.env.REACT_APP_API_URL + "api/v2/"; //TODO cenibook.com
  return new Promise((resolve, reject) => {
    let params = { email, password, name, groupId, phone };

    axios
      .post(base_url + "register", params)
      .then((res) => {
        const data = res.data;
        console.log("data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resendVerification = () => {
  const base_url = getApiUrlV2(null);
  // console.log("axiosConfigAuth: ", axiosConfigAuth);
  return new Promise((resolve, reject) => {
    axios
      .get(base_url + "sms/resend-verification")
      .then((res) => {
        const data = res.data;
        console.log("resend-verification data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getCategoryGroups = (categoryId) => {
  const base_url = getApiUrlV2(null);
  // console.log("axiosConfig: ", axiosConfig);
  return new Promise((resolve, reject) => {
    axios
      // .headers({"Access-Control-Allow-Origin": "*"}
      .get(base_url + "category-groups/" + categoryId, axiosConfig)
      .then((res) => {
        const data = res.data;
        // console.log("getCategoryGroups data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getGroupEbooks = (groupId) => {
  const base_url = getApiUrlV2(null);
  return new Promise((resolve, reject) => {
    axios
      // .headers({"Access-Control-Allow-Origin": "*"}
      .get(base_url + "group-ebooks/" + groupId)
      .then((res) => {
        const data = res.data;
        // console.log("getGroupEbooks data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getEbook = (url) => {
  // const base_url = getApiUrlV2(null);
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        // console.log("ebook json data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const socialLogin = (accessToken) => {
  const base_url =
    process.env.NODE_ENV === "_development"
      ? "http://" + localStorage.domain + ".localhost:8003/api/"
      : process.env.REACT_APP_MULTITENANT === "true"
      ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
      : process.env.REACT_APP_API_URL + "api/"; //TODO cenibook.com
  return new Promise((resolve, reject) => {
    let params = { accessToken };

    axios
      .post(base_url + "social-login", params)
      .then((res) => {
        const data = res.data;
        // console.log("data", data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addButtonService = (formData, domain, pageNumber) => {
  const base_url = getApiUrl(domain);
  // var formData = new FormData();
  // formData.append("content", data.fileupload);
  // formData.append("type", data.type);
  // formData.append("link", data.link);
  // formData.append("left", data.left);
  console.log("--", formData, domain, pageNumber);

  return new Promise((resolve, reject) => {
    // resolve({});

    //   // let params = { email: email, password: password };

    axios
      .post(base_url + "cenibook-pages/store/" + pageNumber, formData)

      .then((res) => {
        const data = res.data;
        console.log(" return data", data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addButtonFormService = (sendData, domain, pageNumber) => {
  console.log("api----sendData", sendData, domain, pageNumber);
  // return;
  // const base_url = "https://" + domain + ".uppybook.com/api/";
  const base_url = getApiUrl(domain);
  console.log(
    'base_url + "cenibook-pages/store/" + pageNumber',
    base_url + "cenibook-pages/store/" + pageNumber
  );
  const formData = new FormData();
  formData.append("content", sendData.content);
  formData.append("button_type", sendData.button_type);
  formData.append("style", sendData.style);
  formData.append("top", parseInt(sendData.top));
  formData.append("left", parseInt(sendData.left));
  formData.append("width", parseInt(sendData.width));
  formData.append("height", parseInt(sendData.height));
  formData.append("show", parseInt(sendData.show));
  formData.append("status", parseInt(sendData.status));
  formData.append("sharing", parseInt(sendData.sharing));
  formData.append("description", sendData.description);
  console.log("formData", formData);
  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/store/" + pageNumber, formData)
      .then((res) => {
        const data = res.data;
        console.log(" return data", data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateButtonService = (formData, domain, attributeNumber) => {
  const base_url = getApiUrl(domain);
  console.log("--", formData, domain, attributeNumber);

  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/update/" + attributeNumber, formData)
      .then((res) => {
        const data = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteButtonService = (domain, attributeId) => {
  // const base_url = "https://" + domain + ".uppybook.com/api/";
  const base_url = getApiUrl(domain);
  ///api/cenibook-pages/delAttr/8985
  console.log("-- delete Attr", attributeId);

  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/delAttr/" + attributeId)
      .then((res) => {
        const data = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteAllActivitiesOfPageService = (domain, pageId) => {
  const base_url = getApiUrl(domain);
  console.log("-- delete ALL PAGE Activities");
  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/delAllAttrOfPage/" + pageId)
      .then((res) => {
        const data = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteAllZoomActivitiesOfPageService = (domain, pageId) => {
  const base_url = getApiUrl(domain);
  console.log("-- delete ALL PAGE Activities");
  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "cenibook-pages/delAllZoomsOfPage/" + pageId)
      .then((res) => {
        const data = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
function getApiUrl(domain) {
  return process.env.REACT_APP_MULTITENANT === "true"
    ? "https://" + domain + ".uppybook.com/api/"
    : process.env.REACT_APP_API_URL + "api/";
}
function getApiUrlV2(domain) {
  return process.env.REACT_APP_MULTITENANT === "true"
    ? "https://" + domain + ".uppybook.com/api/"
    : process.env.REACT_APP_API_URL + "api/v2/";
}
