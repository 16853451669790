import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function SmsTimer(props) {
  const { t } = useTranslation();
  const timerRefForSms = useRef(null);
  const [time, setTime] = React.useState(props.seconds);
  const [loggedInVerify, setLoggedInVerify] = React.useState(false);
  React.useEffect(() => {
    if (time <= 0) {
      window.clearInterval(timerRefForSms.current);
    } else {
      timerRefForSms.current = window.setInterval(() => {
        setTime((prevTime) => prevTime - 1); // <-- Change this line!
      }, 1000);
      setLoggedInVerify(false);
      return () => {
        window.clearInterval(timerRefForSms.current);
      };
    }
    return () => {
      window.clearInterval(timerRefForSms.current);
    };
  }, [time, timerRefForSms]);
  React.useEffect(() => {
    if (props.showLoggedInVerify) {
      window.clearInterval(timerRefForSms.current);
      setTime(0);
      setLoggedInVerify(true);
    }
  }, [props.showLoggedInVerify]);

  const seconds = Math.floor(time % 60);
  const minutes = Math.floor((time / 60) % 60);

  return (
    <span className={``}>
      {!loggedInVerify ? (
        <>
          <span
            className={`is-size-5  mx-2 ${
              time < 10 ? "has-text-danger" : "has-text-dark"
            }`}
          >
            {time > 0 ? ("0" + minutes).slice(-2) : "00"}
          </span>
          <span
            className={`is-size-5   ${
              time < 10 ? "has-text-danger" : "has-text-dark"
            }`}
          >
            {time > 0 ? (": 0" + seconds).slice(-2) : ": 00"}
          </span>
          {time <= 0 && (
            <div className="control has-text-centered	">
              <label className={`is-size-7`}>
                {t("Didn't you have received a code?")}
              </label>
              <div
                className="pl-2 has-text-weight-bold	sign-up-link is-size-7"
                onClick={() => {
                  props.resendVerification();
                  setTime(props.seconds);
                  setLoggedInVerify(false);
                  props.setShowLoggedInVerify(false);
                }}
              >
                {t("Send the code again")}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="control has-text-centered	">
          <div>
            <label className={`is-size-7`}>{t("Hello")}</label>
          </div>
          <label className={`is-size-7`}>
            {t("Your phone is not verified")}
          </label>
          <div
            className="pl-2 has-text-weight-bold	sign-up-link is-size-7"
            onClick={() => {
              props.resendVerification();
              setTime(props.seconds);
              setLoggedInVerify(false);
              props.setShowLoggedInVerify(false);
            }}
          >
            {t("Send the code again")}
          </div>

          <div className="login-form-footer mt-4">
            <button
              className="login-form-btn"
              onClick={() => {
                window.clearInterval(timerRefForSms.current);
                props.logOut();
              }}
              //   className={`${
              //     signUp
              //       ? "login-form-btn-signup"
              //       : "login-form-btn"
              //   }`
              // }
            >
              {t("Sign Out")}
            </button>
          </div>
        </div>
      )}
    </span>
  );
}
