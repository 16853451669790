/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

let downCoords = {
  x: 0,
  y: 0,
};
class QShowable extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    this._width = props.toolbar.lineWidth;
    this._color = props.toolbar.lineColor;
    this._fill = props.toolbar.fillColor;
    canvas.defaultCursor = "crosshair";
  }
  doMouseDown(o) {
    // this._canvas.requestRenderAll();
    // let canvas = this._canvas;
    // this.isDown = true;
    // let pointer = canvas.getPointer(o.e);
    // this.startX = pointer.x;
    // this.startY = pointer.y;
    // this.rect = new fabric.Rect({
    //   name: "rectangle",
    //   left: this.startX,
    //   top: this.startY,
    //   originX: "left",
    //   originY: "top",
    //   width: pointer.x - this.startX,
    //   height: pointer.y - this.startY,
    //   stroke: 'purple',
    //   strokeDashArray: [6, 6],
    //   strokeWidth: 2,
    //   fill: 'white',
    //   //fill: 'rgba(255,0,0,0.5)',
    //   transparentCorners: false,
    //   selectable: false,
    //   evented: false,
    //   angle: 0,
    //   iType:'qShow',
    // });
    // canvas.add(this.rect);

    // if (this._canvas._iTextInstances) {
    //   this._canvas._iTextInstances.forEach(function (o) {
    //     o.exitEditing();
    //   });
    // }
  }

  doMouseMove(o) {
    // if (!this.isDown) return;
    // let canvas = this._canvas;
    // let pointer = canvas.getPointer(o.e);
    // if (this.startX > pointer.x) {
    //   this.rect.set({ left: Math.abs(pointer.x) });
    // }
    // if (this.startY > pointer.y) {
    //   this.rect.set({ top: Math.abs(pointer.y) });
    // }
    // this.rect.set({ width: Math.abs(this.startX - pointer.x) });
    // this.rect.set({ height: Math.abs(this.startY - pointer.y) });
    // this.rect.setCoords();
    // canvas.requestRenderAll();
  }

  doMouseUp(o) {
    // this.isDown = false;
    // this._canvas.requestRenderAll();

  }
}

export default QShowable;
