import {
  ENABLE_EDIT_MODE,
  DISABLE_EDIT_MODE,
  ENABLE_BUTTON_MOVE,
  DISABLE_BUTTON_MOVE,
  ENABLE_STUDIO_MODE,
  DISABLE_STUDIO_MODE,
  SET_BACKGROUND_IMAGE_OPACITY,
  SHOW_ANNOTATIONS,
  HIDE_ANNOTATIONS,
  SHOW_AREA,
  HIDE_AREA,
  UNDO_TRIGGERED,
  REDO_TRIGGERED,
  REMOVE_TRIGGERED,
  REMOVE_ALL_TRIGGERED,
  ZOOM_IN_TRIGGERED,
  ZOOM_OUT_TRIGGERED,
  IMAGE_LOADED,
  SET_PAGE_PART_ZOOM_MODE,
  SET_PAGE_PART_ZOOM_FULL_SCALE_VAL,
  SET_PAGE_PART_ZOOM_OBJECT,
  SET_PAGE_PART_ZOOM_OBJECT_TO_SHOW,
  SET_PAGE_PART_ZOOM_OBJECT_LOADING,
  ADD_IMAGE_TO_CANVAS,
  SET_INPUT_FOCUSED,
  SET_PAGE_PART_ZOOM_CUT,
  SET_TIMER_DISPLAY,
  SET_PAGE_CONTRAST,
  SET_PAGE_BRIGHTNESS,
  SET_BRIGHTNESS_MENU_OPEN,
  SET_ZOOM_MODE_MENU_OPEN,
  SET_SHOW_MARGINS,
  ADD_MARGIN_A,
  ADD_MARGIN_B,
  REMOVE_MARGIN_A,
  REMOVE_MARGIN_B,
  SET_ADD_INTERACTIVITY_MODE,
  SAVE_BATCH_INTERACTIVITIES_TO_BUTTONS,
  ADD_INNER_MARGIN_TRIGGERED,
  ADD_OUTER_MARGIN_TRIGGERED,
  PAGE_IS_LOADING
} from "../actions/actionTypes";
import { parseBoolean } from "../services/util";

const defaultState = {
  editMode: parseBoolean(localStorage.editMode),
  moveButtons: parseBoolean(localStorage.moveButtons),
  studioMode: parseBoolean(localStorage.studioMode), //TODO SET false after development // full-studio, mini-studio, student, author
  backgroundImageOpacity: 1,
  showAnnotations: true,
  showArea: true,
  undo: false,
  redo: false,
  remove: false,
  removeAll: false,
  zoomIn: false,
  zoomOut: false,
  imageLoaded: false,
  pageZoomPartMode: null, // can be null, zoomed
  pageZoomPartShowLoading: false, // can be null, zoomed
  pageZoomPartObject: {
    orderId: null,
    downCoords: null,
    upCoords: null,
    cutCoords: { x: null, y: null },
    cutCoordsUp: { x: null, y: null },
  },
  pageZoomPartObjectToShow: {
    orderId: null,
    downCoords: { x: null, y: null },
    upCoords: null,
    cutCoordsUp: { x: null, y: null },
  },
  pageZoomPartFullScaleVal: null,
  pageZoomPartInitialScaleX: null,
  addImageToCanvas: false,
  inputFocused: false, // if in searchbar or etc focused -- if user is writing something
  timerMode: false,
  pageBrightness: 0,
  pageContrast: 0,
  brightnessMenuOpen: false,
  zoomModeMenuOpen: false,
  marginA: [],
  marginB: [],
  showMargins: false,
  addInteractivityMode: null,
  addInteractivityType: null,
  interactivitiesToAddAsButtons: null,
  interactivityTypeToAddAsButtons: null,
  addInnerMargin: null,
  addOuterMargin: null,
  pageIsLoading:null
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case ENABLE_EDIT_MODE:
      return {
        ...state,
        editMode: true,
      };
    case DISABLE_EDIT_MODE:
      return {
        ...state,
        editMode: false,
      };

    case ENABLE_BUTTON_MOVE:
      return {
        ...state,
        moveButtons: true,
      };
    case DISABLE_BUTTON_MOVE:
      return {
        ...state,
        moveButtons: false,
      };
    case ENABLE_STUDIO_MODE:
      return {
        ...state,
        studioMode: true,
      };
    case DISABLE_STUDIO_MODE:
      return {
        ...state,
        studioMode: false,
      };
    case SET_BACKGROUND_IMAGE_OPACITY:
      return {
        ...state,
        backgroundImageOpacity: action.payload,
      };
    case SHOW_ANNOTATIONS:
      return {
        ...state,
        showAnnotations: true,
      };
    case HIDE_ANNOTATIONS:
      return {
        ...state,
        showAnnotations: false,
      };
    case SHOW_AREA:
      return {
        ...state,
        showArea: true,
      };
    case HIDE_AREA:
      return {
        ...state,
        showArea: false,
      };
    case UNDO_TRIGGERED:
      return {
        ...state,
        undo: action.payload,
      };
    case REDO_TRIGGERED:
      return {
        ...state,
        redo: action.payload,
      };
    case REMOVE_TRIGGERED:
      console.log("REMOVETRGIGGERED");
      return {
        ...state,
        remove: action.payload,
      };
    case REMOVE_ALL_TRIGGERED:
      return {
        ...state,
        removeAll: action.payload,
      };
    case ZOOM_IN_TRIGGERED:
      return {
        ...state,
        zoomIn: action.payload,
      };
    case ZOOM_OUT_TRIGGERED:
      return {
        ...state,
        zoomOut: action.payload,
      };
    case IMAGE_LOADED:
      return {
        ...state,
        imageLoaded: action.payload,
      };
    case SET_PAGE_PART_ZOOM_MODE:
      return {
        ...state,
        pageZoomPartMode: action.payload,
      };
    case ADD_IMAGE_TO_CANVAS:
      return {
        ...state,
        addImageToCanvas: action.payload,
      };
    case SET_BRIGHTNESS_MENU_OPEN:
      return {
        ...state,
        brightnessMenuOpen: action.payload,
      };
    case SET_PAGE_BRIGHTNESS:
      return {
        ...state,
        pageBrightness: action.payload,
      };
    case SET_PAGE_CONTRAST:
      return {
        ...state,
        pageContrast: action.payload,
      };
    case SET_INPUT_FOCUSED:
      return {
        ...state,
        inputFocused: action.payload,
      };
    case SET_PAGE_PART_ZOOM_OBJECT: {
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords = { x: null, y: null },
      } = action.payload;
      return {
        ...state,
        pageZoomPartObject: {
          orderId,
          downCoords,
          upCoords,
          cutCoords,
        },
      };
    }
    case SET_PAGE_PART_ZOOM_CUT: {
      const { cutCoords, cutCoordsUp } = action.payload;
      return {
        ...state,
        pageZoomPartObject: {
          ...state.pageZoomPartObject,
          cutCoords,
          cutCoordsUp,
        },
      };
    }
    case SET_TIMER_DISPLAY: {
      return {
        ...state,
        timerMode: action.payload,
      };
    }
    case SET_ZOOM_MODE_MENU_OPEN:
      return {
        ...state,
        zoomModeMenuOpen: action.payload,
      };
    case SET_PAGE_PART_ZOOM_OBJECT_TO_SHOW: {
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords,
        cutCoordsUp,
      } = action.payload;
      console.log(
        "SET_PAGE_PART_ZOOM_OBJECT_TO_SHOW action.payload",
        action.payload
      );
      return {
        ...state,
        pageZoomPartObjectToShow: {
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp,
        },
      };
    }
    case ADD_MARGIN_A: {
      let oldMarginA = state.marginA;
      const newMargin = action.payload;
      if (newMargin.marginId) {
        oldMarginA = state.marginA.filter(
          (mA) => mA.marginId !== newMargin.marginId
        );
      }
      const newMargins = [...oldMarginA, newMargin].sort(
        (a, b) => a.left - b.left
      );

      return {
        ...state,
        marginA: newMargins,
      };
    }
    case ADD_MARGIN_B: {
      let oldMarginB = state.marginB;
      const newMargin = action.payload;
      if (newMargin.marginId) {
        oldMarginB = state.marginB.filter(
          (mB) => mB.marginId !== newMargin.marginId
        );
      }
      const newMargins = [...oldMarginB, newMargin].sort(
        (a, b) => a.left - b.left
      );
      return {
        ...state,
        marginB: newMargins,
      };
    }
    case REMOVE_MARGIN_A: {
      const newMargins = state.marginA
        .filter((mB) => mB.marginId !== action.payload)
        .sort((a, b) => a.left - b.left);
      return {
        ...state,
        marginA: newMargins,
      };
    }
    case REMOVE_MARGIN_B: {
      const newMargins = state.marginB
        .filter((mB) => mB.marginId !== action.payload)
        .sort((a, b) => a.left - b.left);
      return {
        ...state,
        marginB: newMargins,
      };
    }

    case SET_SHOW_MARGINS: {
      return {
        ...state,
        showMargins: action.payload,
      };
    }
    case SET_PAGE_PART_ZOOM_FULL_SCALE_VAL: {
      // console.log(`action.payload.fullScale`, action.payload.fullScale)
      // console.log(`action.payload`, action.payload)
      return {
        ...state,
        pageZoomPartFullScaleVal: action.payload.fullScale,
        pageZoomPartInitialScaleX: action.payload.scaleX,
      };
    }
    case SET_PAGE_PART_ZOOM_OBJECT_LOADING: {
      return {
        ...state,
        pageZoomPartShowLoading: action.payload,
      };
    }
    case PAGE_IS_LOADING: {
      return {
        ...state,
        pageIsLoading: action.payload,
      };
    }
    case SET_ADD_INTERACTIVITY_MODE: {
      const mode = action.payload[0];
      const type = action.payload[1];
      return {
        ...state,
        addInteractivityMode: mode,
        addInteractivityType:
          type === "submit" || type === "cancel"
            ? state.addInteractivityType
            : type, //Keep old value if the type is submit
      };
    }
    case SAVE_BATCH_INTERACTIVITIES_TO_BUTTONS: {
      return {
        ...state,
        interactivitiesToAddAsButtons: action.payload[0],
        interactivityTypeToAddAsButtons: action.payload[1],
      };
    }
    case ADD_INNER_MARGIN_TRIGGERED:
      return {
        ...state,
        addInnerMargin: action.payload,
      };
    case ADD_OUTER_MARGIN_TRIGGERED:
      return {
        ...state,
        addOuterMargin: action.payload,
      };
    default:
      return state;
  }
};
