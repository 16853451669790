import {GET_QWANT,CLOSE_QWANT} from '../actions/actionTypes'

export default (state = false, action) => {
    switch (action.type) {

        case GET_QWANT:
            return true;

        case CLOSE_QWANT:
            return false;

        default:
            return state;
    }
};