import React, {Component} from "react";
import {connect} from "react-redux";
import CustomModal from "./CustomModal";
import {closeMerriamModal} from '../actions/merriamModal';


class MerriamModal extends Component {

    render() {
        return (
            <CustomModal
                isOpen={this.props.modalIsOpen}
                toggleModal={e => this.props.toggleModal(e)}
                data={{button_type:"link",content:this.props.url,label:""}}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        scaleZoomFactorVal: state.scaleZoomFactor
    };
};

export default connect(mapStateToProps, {closeMerriamModal})(MerriamModal);