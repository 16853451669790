import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineTrash,
} from "react-icons/hi";
import {
  setTempActivityOrder,
  removeTempActivity,
} from "../../actions/temp.js";
// import { deleteActivity } from "../../actions/addActivity";
import { BiLink } from "react-icons/bi";
import { deleteButtonService } from "../../services/api";
import { showAnnotationsById } from "../../actions/annotationSelection";

const RenderTempInteractivityButtons = (props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  let scaleProperty = props.canvasVals
    ? props.canvasVals.canvasWidth / 1300
    : 1;

  scaleProperty = scaleProperty > 2 ? 2 : scaleProperty;
  scaleProperty = isMobile && scaleProperty > 1.5 ? 1 : scaleProperty;

  const removeHandler = (tempId) => {
    props.removeTempActivity(tempId);
  };

  const buttons = props.temp.tempInteractivities
    .filter((filteredButton) => {
      if (props.settings.addInteractivityType === "zoomarea") {
        return (
          filteredButton.iType === "zmArea" || filteredButton.iType === "zmAreaGroup"
        );
      } else {
        return filteredButton.iType === "qShow";
      }
    })
    .map((object, index) => {
      const orderId = props.temp.tempInteractivityOrders.find(
        (el) => el.tempId === object.tempId
      ).orderId;
      const buttonShow = true;
      let left = parseInt(object.left) + parseInt(object.width) - 40;
      let top = parseInt(object.top) + 5;
      // console.log(`object`, orderId, object.tempId, left, top, object);

      return (
        <div
          className={`interactive-object-area handle ${object.button_type}-object ${object.style} `}
          style={{ top, left }}
          key={index}
        >
          {buttonShow && (
            <div className="temp-interactivity-menu">
              {props.settings.addInteractivityType === "zoomarea" && (
                <>
                  <button
                    className={`button is-small modal-button`}
                    onClick={props.deleteContent}
                  >
                    <span className="icon is-small has-text-light">
                      {orderId}
                    </span>
                  </button>
                  <button
                    className={`button is-small modal-button`}
                    onClick={() => {
                      props.setTempActivityOrder("down", object.tempId);
                    }}
                    disabled={orderId === 1}
                  >
                    <span className="icon is-small has-text-info">
                      <HiOutlineChevronUp className="fas fa-2x" />
                    </span>
                  </button>
                  <button
                    className={`button is-small modal-button`}
                    disabled={orderId >= props.temp.tempInteractivities.length}
                    onClick={() => {
                      props.setTempActivityOrder("up", object.tempId);
                    }}
                  >
                    <span className="icon is-small has-text-info has-text-weight-bold	">
                      <HiOutlineChevronDown className="fas fa-2x" />
                    </span>
                  </button>
                </>
              )}
              <button
                className={`button is-small modal-button`}
                onClick={() => {
                  removeHandler(object.tempId);
                }}
              >
                <span className="icon is-small has-text-danger">
                  <HiOutlineTrash className="fas fa-2x" />
                </span>
              </button>

              {object.iType === "zmAreaGroup" && (
                <span
                  className={`button is-small modal-button`}
                  // onClick={() => {
                  //   props.removeTempActivity(object.tempId);
                  // }}
                >
                  <span className="icon icon-tema-color is-small has-text-warning">
                    <BiLink className="fas fa-2x" />
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      );
    });

  return buttons;
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    temp: state.temp,
    canvasVals: state.scaleZoomFactor.canvas,
    scaleZoomFactorVal: state.scaleZoomFactor,
    currentPage: state.pages.currentPage,
    domain: state.login.user_profile?.domain_name,
    // currentPageId: selectCurrentPageId(state),

  };
};

export default connect(mapStateToProps, {
  setTempActivityOrder,
  removeTempActivity,
  // deleteActivity,
  showAnnotationsById,
})(RenderTempInteractivityButtons);
