import React from "react";
import { useTranslation } from "react-i18next";

export default function QButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  // const svgPath = props.eyeOff
  //   ? "M12.8694 6.43927C8.98671 6.43927 5.83812 10.4872 5.83812 11.8843C5.83812 13.2814 8.98671 17.3293 12.8694 17.3293C13.951 17.3293 14.9752 17.0145 15.8909 16.5312L18.3959 18.158V14.5688C19.3374 13.5376 19.9006 12.4747 19.9006 11.8843C19.9006 10.4872 16.7525 6.43927 12.8694 6.43927ZM12.8694 15.4355C10.928 15.4355 9.35374 13.8455 9.35374 11.8845C9.35374 9.92302 10.928 8.33349 12.8694 8.33349C14.8112 8.33349 16.385 9.92302 16.385 11.8845C16.385 13.8455 14.8112 15.4355 12.8694 15.4355Z"
  //   : "M12.8694 6.43927C8.98671 6.43927 5.83812 10.4872 5.83812 11.8843C5.83812 13.2814 8.98671 17.3293 12.8694 17.3293C13.951 17.3293 14.9752 17.0145 15.8909 16.5312L18.3959 18.158V14.5688C19.3374 13.5376 19.9006 12.4747 19.9006 11.8843C19.9006 10.4872 16.7525 6.43927 12.8694 6.43927ZM12.8694 15.4355C10.928 15.4355 9.35374 13.8455 9.35374 11.8845C9.35374 9.92302 10.928 8.33349 12.8694 8.33349C14.8112 8.33349 16.385 9.92302 16.385 11.8845C16.385 13.8455 14.8112 15.4355 12.8694 15.4355Z";
  return (
    <div
    className="interactive-object-2 qshow"
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className="object-icon icon-only">
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          // fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="#ffffff"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M19 13.6667V6.73333C19 6.27362 18.8156 5.83274 18.4874 5.50768C18.1592 5.18262 17.7141 5 17.25 5H6.75C6.28587 5 5.84075 5.18262 5.51256 5.50768C5.18437 5.83274 5 6.27362 5 6.73333V13.6667C5 14.1264 5.18437 14.5673 5.51256 14.8923C5.84075 15.2174 6.28587 15.4 6.75 15.4H9.375L12 18L14.625 15.4H17.25C17.7141 15.4 18.1592 15.2174 18.4874 14.8923C18.8156 14.5673 19 14.1264 19 13.6667ZM7.625 8.46667C7.625 8.23681 7.71719 8.01637 7.88128 7.85384C8.04538 7.69131 8.26794 7.6 8.5 7.6H15.5C15.7321 7.6 15.9546 7.69131 16.1187 7.85384C16.2828 8.01637 16.375 8.23681 16.375 8.46667C16.375 8.69652 16.2828 8.91696 16.1187 9.07949C15.9546 9.24202 15.7321 9.33333 15.5 9.33333H8.5C8.26794 9.33333 8.04538 9.24202 7.88128 9.07949C7.71719 8.91696 7.625 8.69652 7.625 8.46667ZM8.5 11.0667C8.26794 11.0667 8.04538 11.158 7.88128 11.3205C7.71719 11.483 7.625 11.7035 7.625 11.9333C7.625 12.1632 7.71719 12.3836 7.88128 12.5462C8.04538 12.7087 8.26794 12.8 8.5 12.8H11.125C11.3571 12.8 11.5796 12.7087 11.7437 12.5462C11.9078 12.3836 12 12.1632 12 11.9333C12 11.7035 11.9078 11.483 11.7437 11.3205C11.5796 11.158 11.3571 11.0667 11.125 11.0667H8.5Z" fill="#FFC43D"/>
   </svg>
      </span>
    </div>
  );
}
