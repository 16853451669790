import {SIDEBAR_OPEN, SIDEBAR_CLOSE} from '../actions/actionTypes'

export default (state = true, action) => {
    switch (action.type) {

        case SIDEBAR_OPEN:
            return true;

        case SIDEBAR_CLOSE:
            return false;

        default:
            return state;
    }
};