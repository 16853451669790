import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createSelector } from "reselect";
import { persistStore, persistReducer } from "redux-persist"; //TODO Add Persist

import thunk from "redux-thunk";
import sidebarReducer from "../reducers/sidebarReducer";
import themeReducer from "../reducers/themeReducer";
import pagesReducer from "../reducers/pagesReducer";
// import pageLoadedReducer from "../reducers/pageLoadedReducer";
import zipDownProgReducer from "../reducers/zipDownProgReducer";
import toolbarReducer from "../reducers/toolbarReducer";
import interactiveObjectReducer from "../reducers/interactiveObjectReducer";
import scalePageReducer from "../reducers/scalePageReducer";
import buttonsReducer from "../reducers/buttonsReducer";
import imageLoadReducer from "../reducers/imageLoadReducer";
import selectedToolReducer from "../reducers/selectedToolReducer";
import pixabayReducer from "../reducers/pixabayReducer";
import unsplashReducer from "../reducers/unsplashReducer";
import pixabayVideoReducer from "../reducers/pixabayVideoReducer";
import searchBarReducer from "../reducers/searchBarReducer";
import wikizeroReducer from "../reducers/wikizeroReducer";
import wikipediaReducer from "../reducers/wikipediaReducer";
import wiktionaryReducer from "../reducers/wiktionaryReducer";
import qwantReducer from "../reducers/qwantReducer";
import indexReducer from "../reducers/indexReducer";
import merriamReducer from "../reducers/merriamReducer";
import merriamModalReducer from "../reducers/merriamModalReducer";
// import imageCaptureReducer from "../reducers/imageCaptureReducer";
import loginReducer from "../reducers/loginReducer";
// import captureReducer from "../reducers/captureReducer";
import addButtonReducer from "../reducers/addButtonReducer";
import activitiesReducer from "../reducers/activitiesReducer";
import quizReducer from "../reducers/quizReducer";
import settingsReducer from "../reducers/settingsReducer";
import annotationSelectionReducer from "../reducers/annotationSelectionReducer";
import { isMobile } from "react-device-detect";
import tempReducer from "../reducers/tempReducer";
import keyboardReducer from "../reducers/keyboardReducer";

export default () => {
  const userAgent = navigator.userAgent.toLowerCase();
  // console.log(`process.env.NODE_ENV`, process.env.NODE_ENV);
  const store = createStore(
    combineReducers({
      sideBarOpened: sidebarReducer,
      theme: themeReducer,
      pages: pagesReducer,
      // pageLoaded: pageLoadedReducer,
      toolbar: toolbarReducer,
      hideInteractiveObjects: interactiveObjectReducer,
      scaleZoomFactor: scalePageReducer,
      isButtonsShown: buttonsReducer,
      imagesLoaded: imageLoadReducer,
      selectedTool: selectedToolReducer,
      login: loginReducer,
      addButton: addButtonReducer,
      activities: activitiesReducer,
      quiz: quizReducer,
      settings: settingsReducer,
      temp: tempReducer,
      annotationSelections: annotationSelectionReducer,
      pixabay: pixabayReducer,
      unsplash: unsplashReducer,
      pixabayVideo: pixabayVideoReducer,
      searchBarOpen: searchBarReducer,
      wikizero: wikizeroReducer,
      wikipedia: wikipediaReducer,
      wiktionary: wiktionaryReducer,
      merriamModal: merriamModalReducer,
      qwant: qwantReducer,
      indexOpened: indexReducer,
      merriam: merriamReducer,
      // imageCapture: imageCaptureReducer,
      // captured: captureReducer,
      keyboard: keyboardReducer,
      zipDownloadProg: zipDownProgReducer,
    }),

    compose(
      applyMiddleware(thunk),
      process.env.NODE_ENV === "development" && typeof window === "object"
        ? window.navigator.userAgent.includes("Chrome") &&
          userAgent.indexOf(" electron/") === -1 &&
          !isMobile
          ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
          : compose
        : compose
      // ,window.__REDUX_DEVTOOLS_EXTENSION__ &&
      // window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
    )
  );

  return store;
};

/*
ROLES
*/
export const selectUserRoles = (state) => state.login?.user_profile?.roles;

export const selectUserHasNoRole = createSelector(selectUserRoles, (roles) => {
  return !roles ? false : roles.length === 0;
});
export const selectUserHasStudentRole = createSelector(
  selectUserRoles,
  (roles) => {
    return roles?.findIndex((role) => role.name === "Student") === 0;
  }
);
export const selectUserHasTeacherRole = createSelector(
  selectUserRoles,
  (roles) => {
    return roles?.findIndex((role) => role.name === "Teacher") === 0;
  }
);
export const selectUserHasSuperAdminRole = createSelector(
  selectUserRoles,
  (roles) => {
    return roles?.findIndex((role) => role.name === "Super Admin") === 0;
  }
);
export const selectUserHasDomainAdminRole = createSelector(
  selectUserRoles,
  (roles) => {
    return roles?.findIndex((role) => role.name === "Domain Admin") === 0;
  }
);
export const selectUserHasDeveloperRole = createSelector(
  selectUserRoles,
  (roles) => {
    return roles?.findIndex((role) => role.name === "Developer") === 0;
  }
);
export const selectUserHasProgramCoordinatorRole = createSelector(
  selectUserRoles,
  (roles) => {
    return (
      roles?.findIndex((role) => role.name === "Program Coordinator") === 0
    );
  }
);
export const selectUserHasAnyAdminRole = createSelector(
  selectUserHasSuperAdminRole,
  selectUserHasDomainAdminRole,
  selectUserHasProgramCoordinatorRole,
  (superAdmin, domainAdmin, progCoord) => {
    // console.log("progCoord: ", progCoord);
    // console.log("domainAdmin: ", domainAdmin);
    // console.log("superAdmin: ", superAdmin);
    // console.log("ADMIN", superAdmin || domainAdmin || progCoord);
    return superAdmin || domainAdmin || progCoord;
  }
);
export const selectUsersRoles = createSelector(selectUserRoles, (userRoles) => {
  const roles = [];
  userRoles && userRoles.map((role) => roles.push(role?.name));
  return roles.join();
});
/*
----ROLES
*/

/* Reselect Selector Starts */
export const selectMyContent = (state) => state.pages.myContent;
export const selectUserId = (state) => state.login.user_profile.id;
export const selectPages = (state) => state.pages.pages;
export const selectCurrentPageNo = (state) => state.pages.currentPageNo;

export const selectCurrentPage = createSelector(
  selectPages,
  selectCurrentPageNo,
  (pages, currPageNo) => {
    return pages?.filter((page) => page.page_number === currPageNo);
  }
);
export const selectCurrentPageButtons = createSelector(
  selectCurrentPage,
  (currPage) => {
    return currPage[0]?.buttons;
  }
);
export const selectMyContentForCurrentPage = createSelector(
  selectMyContent,
  selectCurrentPage,
  (myCont, currentPage) => {
    // console.log('~~~~~~~currentPage id: ', currentPage[0].id);

    return myCont?.filter(
      (content) => content.ebook_pages_id === currentPage[0]?.id
    );
  }
);

export const selectMergedButtonsForCurrentPage = createSelector(
  selectUserHasAnyAdminRole,
  selectCurrentPageButtons,
  selectMyContentForCurrentPage,
  (isAdmin, currPageButtons, myContentButtons) => {
    // console.log("selectMergedButtonsForCurrentPage: ");

    // console.log("myContentButtons: ", myContentButtons);
    if (isAdmin) {
      return currPageButtons;
    } else {
      const newButtons = [];
      myContentButtons.map((myBtn) => {
        const foundButton = currPageButtons?.find((currBtn) => {
          // console.log('++++ ', currBtn.page_number,myBtn.ebook_pages_id)
          return currBtn.id === myBtn.id;
        });
        if (foundButton) {
          foundButton.user_id = myBtn.user_id;
          foundButton.own_activity = true;
        } else {
          myBtn.page_number = myBtn.ebook_pages_id;
          newButtons.push(myBtn);
          // console.log("myBtn: ", myBtn);
        }
      });
      currPageButtons = currPageButtons ? currPageButtons : [];

      return [...currPageButtons, ...newButtons];
    }
  }
);
export const selectCurrentPageWithMyContent = createSelector(
  selectMergedButtonsForCurrentPage,
  selectCurrentPage,
  (buttons, currPage) => {
    const newCurrentPage = currPage;
    if (newCurrentPage && newCurrentPage[0]) {
      newCurrentPage[0].buttons = buttons;
    }
    return newCurrentPage;
  }
);
export const selectCurrentPageId = createSelector(
  selectCurrentPage,
  (currPage) => {
    return currPage && currPage[0]?.id;
  }
);

/* Reselect Selector Ends */
export const selectZoomAreas = createSelector(
  selectMergedButtonsForCurrentPage,
  (buttons) => {
    const zoomButtons = buttons?.filter(
      (btn) => btn.button_type === "zoomPagePart"
    );
    return zoomButtons;
  }
);
export const selectZooms = createSelector(selectZoomAreas, (buttons) => {
  const zoomButtons = buttons
    ?.map((btz) => {
      const newObj = JSON.parse(btz.content);
      newObj.id = btz.id;
      return newObj;
    })
    .sort((a, b) => a.orderId - b.orderId);
  return zoomButtons;
});
export const selectZoomAnswers = createSelector(
  selectMergedButtonsForCurrentPage,
  (buttons) => {
    const zoomButtons = buttons
      .filter((btn) => btn.button_type === "zoomAnswer")
      .map((btz) => {
        const newObj = JSON.parse(btz.content);
        // newObj.id = btz.id;
        return newObj;
      })
      .sort((a, b) => a.orderId - b.orderId);
    return zoomButtons;
  }
);
export const selectZoomVideoAnswers = createSelector(
  selectMergedButtonsForCurrentPage,
  (buttons) => {
    const zoomButtons = buttons
      .filter((btn) => btn.button_type === "zoomVideoAnswer")
      .map((btz) => {
        const newObj = JSON.parse(btz.content);
        // newObj.id = btz.id;
        return newObj;
      })
      .sort((a, b) => a.orderId - b.orderId);
    return zoomButtons;
  }
);
export const selectAllActivitiesArrayPerPage = createSelector(
  selectMyContent,
  selectPages,
  (myActivities, pages) => {
    let interactiveObjects = [];

    for (let i = 0; i < pages.length; i++) {
      const ownActivitiesOnCurrentPage = myActivities?.filter(
        (activity) => activity.ebook_pages_id === pages[i].id
      );
      // console.log("!ownActivitiesOnCurrentPage: ", ownActivitiesOnCurrentPage);
      const hasPublicActivities =
        pages[i].buttons && pages[i].buttons.length > 0;
      // console.log("!hasPublicActivities: ", hasPublicActivities);

      if (hasPublicActivities || ownActivitiesOnCurrentPage.length > 0) {
        interactiveObjects[i] = new Array();
        for (let j = 0; j < pages[i].buttons.length; j++) {
          if (interactiveObjects[i][pages[i].buttons[j].button_type]) {
            interactiveObjects[i][pages[i].buttons[j].button_type]++;
          } else {
            interactiveObjects[i][pages[i].buttons[j].button_type] = 1;
          }
        }
        for (let j = 0; j < ownActivitiesOnCurrentPage.length; j++) {
          //           if (interactiveObjects[i][ownActivitiesOnCurrentPage[j].button_type]) {
          //             interactiveObjects[i][ownActivitiesOnCurrentPage[j].button_type]++;
          //           } else {
          //             interactiveObjects[i][ownActivitiesOnCurrentPage[j].button_type] = 1;
          if (interactiveObjects[i][ownActivitiesOnCurrentPage.button_type]) {
            interactiveObjects[i][ownActivitiesOnCurrentPage.button_type]++;
          } else {
            interactiveObjects[i][ownActivitiesOnCurrentPage.button_type] = 1;
          }
        }
      }
    }

    // console.log("!interactiveObjects: ", interactiveObjects);
    return interactiveObjects;
  }
);
