import React from "react";
import { useTranslation } from "react-i18next";

export default function TourIntro3(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  return (
    <div
      id="toolbar-intro-sudo-div"
      className="toolbar-intro-sudo-class"
      data-step="4"
      data-intro={t("intro_3")}
    ></div>
  );
}
