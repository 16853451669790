import FabricCanvasTool from "./fabrictool";

class Highlighter extends FabricCanvasTool {
  configureCanvas(props) {
    // console.log(props.fillColor);
    this._canvas.isDrawingMode = true;
    this._canvas.freeDrawingBrush.width = 20;
    this._canvas.freeDrawingBrush.color = this.hexToRgbA(props.toolbar.fillColor); //"rgba(0, 255, 0, 0.2)";
  }
  doMouseDown(o) {
    // console.log(this._canvas);
    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function(ob) {
        ob.isEditing && ob.exitEditing();
      });
    }
  }
  doMouseMove(o) {}

  hexToRgbA(hex) {
    var c;
    if (hex == "transparent") return;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.2)"
      );
    }
    // throw new Error("Bad Hex");
  }
}

export default Highlighter;
