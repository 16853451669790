import React, { Component } from "react";
import { withTranslation } from "react-i18next";

 class CurtainTool extends Component {
  render() {
    return (
      <li onClick={this.props.onClick}>
        <span
          className={`button normal-btn down ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          } ${
            this.props.activeButton === "curtain" ? "active" : ""
          }`}
          title={this.props.t("Curtain")}
        >
          <img src={require("../../assets/img/svg/curtain.svg")} alt={this.props.t("Curtain")} />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">{this.props.t("Curtain")}</span>}
        </span>
        
      </li>
    );
  }
}
export default withTranslation()(CurtainTool);
