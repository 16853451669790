import configration from "../config.json";
import dompurify from "dompurify";


export const guidGenerator = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
export const parseBoolean = (boolValue) => {
  return boolValue === 1 || boolValue==='true' ? true : false;
};

export const isEven = (n) => {
  return n % 2 === 0;
};

export const isOdd = (n) => {
  return Math.abs(n % 2) === 1;
};
export const isLogin = localStorage.auth_token
  ? localStorage.auth_token
  : false;

export const login = (username, password) => {
  if (username == configration.username && password == configration.password) {
    localStorage.isLoggedIn = true;
    return true;
  }

  return false;
};

export const logout = () => {
  localStorage.isLoggedIn = false;
  return true;
};

export const bookmarkPage = (page) => {
  console.log(page);
  if (!localStorage.bookmarked_pages) {
    localStorage.bookmarked_pages = JSON.stringify([]);
  }
  console.log(localStorage.bookmarked_pages);
  var parsed = JSON.parse(localStorage.bookmarked_pages);
  console.log(parsed);

  parsed.push(page);
  localStorage.bookmarked_pages = JSON.stringify(parsed);
  console.log(localStorage.bookmarked_pages);
};

export const unBookmarkPage = (page) => {
  console.log(page);
  if (!localStorage.bookmarked_pages) {
    localStorage.bookmarked_pages = JSON.stringify([]);
  }
  console.log(localStorage.bookmarked_pages);
  var parsed = JSON.parse(localStorage.bookmarked_pages);
  console.log(parsed);

  parsed.splice(parsed.indexOf(page), 1);
  localStorage.bookmarked_pages = JSON.stringify(parsed);
  console.log(localStorage.bookmarked_pages);
};

export const isPageBookmarked = (page) => {
  // console.log(typeof localStorage.bookmarked_pages);
  if (
    localStorage.bookmarked_pages &&
    localStorage.bookmarked_pages != undefined
  ) {
    var parsed = JSON.parse(localStorage.bookmarked_pages);

    if (parsed.includes(page)) {
      console.log(parsed);
      return true;
    }
  }

  return false;
};

export const bookmarkedPages = () => {
  if (
    localStorage.bookmarked_pages &&
    localStorage.bookmarked_pages != undefined
  ) {
    return JSON.parse(localStorage.bookmarked_pages);
  }
  return [];
};

export const iframe = (iframe) => {
  const sanitizer = dompurify.sanitize;
  const cleanedIframe = sanitizer(iframe, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
  });

  return {
    __html: cleanedIframe,
    // onLoad: console.log('Loaded')
  };
};
// export const activiteScroll = (selectedTool) => {
// if(document.getElementsByTagName("canvas")[0] && document.getElementsByTagName("canvas")[1]){
//     if(selectedTool == "pan"){
//         document.getElementsByTagName("canvas")[1].style.display = "none";
//         document.getElementsByTagName("canvas")[0].style.touchAction = "auto"
//     }else{
//         document.getElementsByTagName("canvas")[1].style.display = "block";
//         document.getElementsByTagName("canvas")[0].style.touchAction = "none"
//
//     }
// }
// }
