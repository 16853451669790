import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./mobiletools.css";
import {
  changeSelectedTool,
  updatePageNo,
  triggerZoomIn,
  triggerZoomOut,
  changeSelectedToolFromOutside,
} from "../actions/selectedTool";
import { setLineWidth, setLineColor, setFillColor } from "../actions/toolbar";
import Pen from "./ToolbarItems/Pen";
import EraseOne from "./ToolbarItems/EraseOne";
import Highliter from "./ToolbarItems/Highliter";
import TextStrokeColor from "./ToolbarItems/TextStrokeColor";
import NextPage from "./ToolbarItems/NextPage";
import PrevPage from "./ToolbarItems/PrevPage";
import PresentationMode from "./ToolbarItems/PresentationMode";
import Hand from "./ToolbarItems/Hand";
import Selecter from "./ToolbarItems/Selecter";
import ZoomIn from "./ToolbarItems/ZoomIn";
import ZoomOut from "./ToolbarItems/ZoomOut";
import {
  setPagePartZoomMode,
  setPagePartZoomObject,
  setPagePartZoomObjectToShow,
} from "../actions/settings";
import { pageNoChange } from "../actions/pages";
import { toastWarning } from "../actions/showToast";
import { resetAnnotationSelectionMode } from "../actions/annotationSelection";
import { showInteractiveObjects } from "../actions/interactiveObjects";
import { selectZooms } from "../store/configureStore";

import ZoomModeNextButton from "./ToolbarItems/ZoomModeNextButton";
import ZoomModePrevButton from "./ToolbarItems/ZoomModePrevButton";
import { isMobileOnly } from "react-device-detect";
import { CompactPicker } from "react-color";

const MobileTools = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [toolbarVisibility, setToolbarVisibility] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [activeSubButton, setActiveSubButton] = useState(null);
  const [activeSubChildButton, setActiveSubChildButton] = useState(null);
  const [menuHeight, setMenuHeight] = useState("");
  const [compactPickerVisible, setCompactPickerVisible] = useState(false);

  const { currentPageNo, currentPage, totalPageNumber } = useSelector(
    (state) => state.pages
  );
  // const { selectedTool } = useSelector((state) => state.selectedTool);
  const { showLabels, lineColor } = useSelector((state) => state.toolbar);
  // showLabels: state.toolbar.showLabels

  const {
    pageZoomPartMode,
    pageIsLoading,
    pageZoomPartObjectToShow,
  } = useSelector((state) => state.settings);

  const zoomMode = pageZoomPartMode === "zoom-show";
  const zooms = useSelector(selectZooms);
  let mobileDivRef = useRef(null);
  let parentMobileDivRef = useRef(null);

  const currentZoomObjectOrderId = zoomMode
    ? parseInt(pageZoomPartObjectToShow.orderId)
    : undefined;

  const currentZoomButtonId = zoomMode
    ? currentZoomObjectOrderId &&
      zooms &&
      zooms.findIndex((qz) => qz.orderId === currentZoomObjectOrderId)
    : undefined;

  const showPrevButton = zoomMode
    ? currentZoomButtonId !== 0 || currentPageNo > 1
    : false;
  const showNextButton = zoomMode
    ? currentZoomButtonId <= zooms.length - 1 || currentPageNo < totalPageNumber
    : false;

  const qZoomButtons = zooms;
  // console.log(`----qZoomButtons`, qZoomButtons);

  const zoomGotoPrev = () => {
    // console.log(`----zoomGotoPrev`, currentZoomButtonId);
    if (currentZoomButtonId <= 0) {
      dispatch(pageNoChange(parseInt(currentPageNo) - 1));
    } else {
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords,
        cutCoordsUp,
      } = qZoomButtons[currentZoomButtonId - 1];
      dispatch(
        setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp
        )
      );
    }
  };

  const zoomGotoNext = () => {
    // console.log(`zoomGotoNext currentZoomButtonId`, currentZoomButtonId);
    if (currentZoomButtonId >= qZoomButtons.length - 1) {
      dispatch(pageNoChange(parseInt(currentPageNo) + 1));
    } else {
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords,
        cutCoordsUp,
      } = qZoomButtons[currentZoomButtonId + 1];
      dispatch(
        setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp
        )
      );
    }
  };

  // zooms: selectZooms(state),
  useEffect(() => {
    _resize();
    window.addEventListener("resize", handleResize);
    handleResize.call();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const _resize = () => {
    setToolbarVisibility(isMobileOnly);
  };
  const handleResize = () => {
    _resize();
    if (window.innerHeight < 760 && window.innerHeight > 550) {
      setMenuHeight(window.innerHeight - 100);
    } else if (window.innerHeight <= 550) {
      setMenuHeight(230);
    } else {
      setMenuHeight("");
    }
  };
  const onClickCapture = (menuType) => {
    setActiveButton(menuType);
    onCloseAllOpenItems.call();
    console.log("menuType: ", menuType);
    dispatch(changeSelectedToolFromOutside(menuType));
  };
  const prevPageHandler = () => {
    if (!pageIsLoading && currentPageNo > 1) {
      dispatch(updatePageNo(parseInt(currentPageNo) - 1));
    }
  };
  const nextPageHandler = (pageNo) => {
    if (!pageIsLoading && currentPageNo < totalPageNumber) {
      dispatch(updatePageNo(parseInt(currentPageNo) + 1));
    }
  };
  const zoomInOut = (zoomType) => {
    console.log(zoomType);
    if (zoomType == "in") {
      dispatch(triggerZoomIn(true));
    } else {
      dispatch(triggerZoomOut(true));
    }
  };
  const setColorAction = (action) => {
    dispatch(action && action());
  };
  const openCompactPicker = () => {
    setCompactPickerVisible(true);
  };
  const onCloseAllOpenItems = () => {
    setActiveSubButton(null);
    setActiveSubChildButton(null);
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (compactPickerVisible) {
      setCompactPickerVisible(false);
    }
  };

  const currentActive = (currentMenu) => {
    console.log("currentMenu: ", currentMenu);
    console.log("activeButton: ", activeButton);

    if (activeButton === currentMenu) {
      return true;
    } else {
      return false;
    }
  };

  const presentationMode = () => {
    /* const qZoomButtons = currentPage[0].buttons
      .filter((bt) => bt.button_type === "zoomPagePart")
      .map((btz) => {
        return JSON.parse(btz.content);
      })
      .sort((a, b) => a.orderId - b.orderId); */
    // this.props.setPagePartZoomMode("zoom-show");
    dispatch(setPagePartZoomMode("zoom-show"));
    if (qZoomButtons.length === 0) {
      toastWarning("You switched to the Presentation Mode");
    } else {
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords,
        cutCoordsUp,
      } = qZoomButtons[0];
      // this.props.setPagePartZoomObjectToShow(
      dispatch(
        setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp
        )
      );
    }
  };
  const exitHandler = () => {
    dispatch(
      setPagePartZoomObject(null, { x: null, y: null }, { x: null, y: null })
    );
    exitZoomMode();
  };

  const exitZoomMode = () => {
    if (pageZoomPartMode) {
      dispatch(setPagePartZoomMode(null));
      dispatch(
        setPagePartZoomObjectToShow(
          null,
          { x: null, y: null },
          { x: null, y: null },
          { x: null, y: null },
          { x: null, y: null }
        )
      );
    } else {
      dispatch(resetAnnotationSelectionMode());
      dispatch(showInteractiveObjects());
    }
  };
  const bottomScroll = () => {
    const top = parentMobileDivRef.current.scrollTop;
    const updataValue = top - 50 < 0 ? 0 : top - 50;
    parentMobileDivRef.current.scrollTo(0, Math.abs(updataValue));
  };
  const topScroll = () => {
    const top = parentMobileDivRef.current.scrollTop;
    parentMobileDivRef.current.scrollTo(0, top + 50);
  };

  const onChangeLineColor = (color) => {
    // console.log(this.props.toolbar.lineColor);
    if (lineColor === color.hex) {
      dispatch(setLineColor(null));
      setTimeout(() => {
        dispatch(setLineColor(color.hex));
      }, 100);
    } else {
      dispatch(setLineColor(color.hex));
    }
    setCompactPickerVisible(false);
    dispatch(changeSelectedToolFromOutside('pencil'))
  };

  return (
    <Fragment>
      {!isOpen && isMobileOnly && (
        // {
        <div onClick={toggleMenu} className="openMenuHandler">
          <span className="menuHandler">
            <img
              src={require("../assets/img/svg/sidebar-collapse-left.svg")}
              alt="Collapse Sidebar"
              className="smallMenuClose notransform"
            />
          </span>
        </div>
      )}
      <div className={`mobileToolMenu ${!!isOpen ? "openMenu" : ""}`}>
        <div className="mobileToolMenu_inner">
          <div
            className={
              menuHeight != "" ? "has-addons smallscroll" : "has-addons"
            }
            style={{ height: menuHeight ? menuHeight : "auto" }}
            ref={parentMobileDivRef}
          >
            {/* {menuHeight != "" && ( */}
            <>
              <div
                className="top-menu-closer scroll-bar-icons"
                onClick={toggleMenu}
                ref={mobileDivRef}
              >
                <span>
                  <img
                    src={require("../assets/img/svg/sidebar-collapse-left.svg")}
                    alt="Collapse Sidebar"
                    className="smallMenuClose"
                  />
                </span>
              </div>
              <div className="topscroll scroll-bar-icons" onClick={topScroll}>
                <i className="fas fa-sort-up"></i>
              </div>
              <div
                className="bottomscroll scroll-bar-icons"
                onClick={bottomScroll}
              >
                <i className="fas fa-sort-down"></i>
              </div>
            </>
            {/* )} */}
            <ul
              className={`${
                menuHeight !== "" ? "mobile-menu-top-margin" : ""
              } menu-li-ul`}
            >
              {menuHeight === "" && (
                <li
                  className="drag-element alternative-drag-element"
                  onClick={toggleMenu}
                  ref={mobileDivRef}
                >
                  <span>
                    <img
                      src={require("../assets/img/svg/sidebar-collapse-left.svg")}
                      alt="Collapse Sidebar"
                      className="smallMenuClose"
                    />
                  </span>
                </li>
              )}
              {compactPickerVisible && (
                <CompactPicker onChange={onChangeLineColor} />
              )}
              <Hand
                onClick={onClickCapture.bind(this, "pan")}
                activeButton={activeButton}
                shouldShowButtonLabels={showLabels}
              />
              <Selecter
                onClick={onClickCapture.bind(this, "select")}
                activeButton={activeButton}
                shouldShowButtonLabels={showLabels}
              />
              <Pen
                onClick={onClickCapture.bind(this, "pencil")}
                activeButton={activeButton}
                shouldShowButtonLabels={showLabels}
              />
              <Highliter
                onClick={onClickCapture.bind(this, "highlighter")}
                activeButton={activeButton}
                shouldShowButtonLabels={showLabels}
              />
              <TextStrokeColor
                onClick={openCompactPicker}
                activeButton={activeButton}
                color={lineColor}
                // activeButton={this.props.selectedTool}
                shouldShowButtonLabels={showLabels}
              />

              <EraseOne
                onClick={onClickCapture.bind(this, "eraseselected")}
                activeButton={activeButton}
                //activeButton={this.props.selectedTool}
                shouldShowButtonLabels={showLabels}
              />
              <PresentationMode
                onClick={
                  zoomMode ? () => exitHandler() : () => presentationMode()
                }
                // activeButton={this.props.selectedTool}
                // activeButton={currentActive("pan")}
                // hideButton={this.props.isButtonsShown}
                shouldShowButtonLabels={showLabels}
                active={zoomMode ? true : false}
                disabled={zooms && zooms.length > 0 ? false : true}
              />
              <ZoomIn
                onClick={zoomInOut.bind(this, "in")}
                activeButton={activeButton}
                // hideButton={this.props.isButtonsShown}
                shouldShowButtonLabels={showLabels}
              />
              <ZoomOut
                onClick={zoomInOut.bind(this, "out")}
                activeButton={activeButton}
                //  hideButton={this.props.isButtonsShown}
                shouldShowButtonLabels={showLabels}
              />
              {!zoomMode && (
                <>
                  <PrevPage
                    onClick={prevPageHandler}
                    currentPageNumber={currentPageNo}
                    TotalPageNumber={totalPageNumber}
                    shouldShowButtonLabels={showLabels}
                  />
                  <NextPage
                    onClick={nextPageHandler}
                    currentPageNumber={currentPageNo}
                    TotalPageNumber={totalPageNumber}
                    shouldShowButtonLabels={showLabels}
                  />
                </>
              )}
              {zoomMode && (
                <>
                  <ZoomModePrevButton
                    disabled={!showPrevButton}
                    // disabled={!showPrevButton || isLoading}
                    onClick={zoomGotoPrev}
                    shouldShowButtonLabels={showLabels}
                  />
                  <ZoomModeNextButton
                    disabled={!showNextButton}
                    // disabled={!showNextButton || isLoading}
                    onClick={zoomGotoNext}
                    shouldShowButtonLabels={showLabels}
                  />
                </>
              )}

              {/* <li
              className="drag-element alternative-drag-element"
              onClick={onClickCapture.bind(this, "capturezoom")}
            >
              <span
                className="button drag"
                id="drag1"
              >
                <img
                  src={require("../assets/img/svg/toolbar-drag.svg")}
                  alt="Drag"
                  draggable="false"
                />
              </span>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export { MobileTools };
