import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Loading extends Component {
  render() {
    return (
      <div className="preloader-area">
        <div className="preloader-frame">
          <div className="preloader-circle">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="preloader-text">~ {this.props.t("Loading")} ~</div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Loading);
