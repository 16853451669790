import React from "react";
import { useTranslation } from "react-i18next";

export default function PinToolbar(props) {
  const { t } = useTranslation();

  return (
    <li onClick={props.onClick}>
      <span
        className={`button normal-btn toolbar-pin ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "toolbar-pin" ? "active" : ""
        }`}
        title={t("Toolbar Pin")}
      >
        <img
          src={props.pinToolbarSelect? require("../../assets/img/svg/tools-pin-close.svg") : require("../../assets/img/svg/tools-pin-open.svg") }
          alt={t("Toolbar Pin")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{props.pinToolbarSelect?  t("Hide"):t("Show") }</span>}
      </span>
    </li>
  );
}
