/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;
var downCoords;
class Curtain extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.defaultCursor = "crosshair";
  }
  doMouseDown(o) {

    // downCoords = o.pointer;
    // // console.log(o.pointer);
    // // console.log(o);
    // // console.log("Canvas current width: " + this._canvas.width);
    // if (this._canvas._iTextInstances) {
    //   this._canvas._iTextInstances.forEach(function(ob) {
    //     ob.exitEditing();
    //   });
    // }
  }
  // doMouseDblClick(o) {}

  doMouseUp(o, imagePath) {
    // console.log(this._canvas._selectedTool);
    // console.log(this._canvas.backgroundImage._lastScaleX);
    // console.log(this._canvas.backgroundImage._lastScaleY);
    // console.log(this._canvas.backgroundImage.getOriginalSize().width);
    // console.log(this._canvas.backgroundImage.getScaledHeight());
    // console.log(this._canvas.backgroundImage.getScaledWidth());

    // let actualWidth = this._canvas.backgroundImage.getScaledWidth();
    // let proportion =
    //   this._canvas.backgroundImage.getOriginalSize().width / actualWidth;
    // let proportionY = this._canvas.backgroundImage.getOriginalSize().height /
    //   this._canvas.backgroundImage.getScaledHeight();
    // // console.log(proportion);
    // // console.log(proportionY);
    // // console.log(this.props);
    // // this._canvas._selectedTool= this._tools[Tool.Select]
    // if (
    //   Math.abs(o.pointer.x - downCoords.x) < 10 ||
    //   Math.abs(o.pointer.y - downCoords.y) < 10
    // )
    //   return;
    // let canvas = this._canvas;

    // console.log("--Mouse Double Click--");
    // console.log(o.pointer);
    // console.log(o);
    // console.log("newParam: " + imagePath);
    // console.log("Canvas current width: " + this._canvas.width);
    // // var clipPath = new fabric.Rect({
    // //   width: 800,
    // //   height: 600,
    // //   top: -40,
    // //   left: -40
    // // });

    // console.log("--Mouse Up--");
    // console.log(o.pointer);
    // console.log(downCoords);

    // console.log("Canvas current width: " + this._canvas.width);
    // fabric.Image.fromURL(imagePath, function(img) {
    //   img.scale(1).set({
    //     left: downCoords.x * proportion,
    //     top: downCoords.y* proportionY,
    //     cropX: downCoords.x * proportion,
    //     cropY: downCoords.y* proportionY,
    //     // angle: 0,
    //     width: (o.pointer.x - downCoords.x)*proportion,
    //     height: (o.pointer.y - downCoords.y)*proportionY,
    //     stroke : 'green',
    //     strokeDashArray: [5, 5],
    //     strokeWidth : 1,
    //   });
      // canvas.setOverlayColor('rgba(0, 0, 0, 1)', canvas.renderAll.bind(canvas));
      // canvas.backgroundImage.opacity=0;

    //   console.log(canvas.backgroundImage.opacity);
//       canvas.add(img).setActiveObject(img);
//       canvas.backgroundImage.visible = false;
//       canvas.renderAll();
//     });
  }
}

export default Curtain;
