import React from "react";
import { useTranslation } from "react-i18next";

export default function VideoAnswerButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object-2 qzoom" //qshow makes bg transparent
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className="object-icon icon-only">
      <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          // fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle  cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2} fill="#ffffff" />
          <path
            d="M7.53125 5.5C6.99253 5.5 6.47587 5.74458 6.09494 6.17993C5.71401 6.61528 5.5 7.20575 5.5 7.82143V16.1786C5.5 16.7943 5.71401 17.3847 6.09494 17.8201C6.47587 18.2554 6.99253 18.5 7.53125 18.5H16.4688C17.0075 18.5 17.5241 18.2554 17.9051 17.8201C18.286 17.3847 18.5 16.7943 18.5 16.1786V7.82143C18.5 7.20575 18.286 6.61528 17.9051 6.17993C17.5241 5.74458 17.0075 5.5 16.4688 5.5H7.53125ZM10.9966 9.28486L14.2466 11.6081C14.3041 11.6494 14.3516 11.7064 14.3849 11.7741C14.4181 11.8417 14.436 11.9178 14.4369 11.9954C14.4378 12.0729 14.4216 12.1495 14.39 12.2181C14.3583 12.2868 14.312 12.3452 14.2555 12.3881L11.0055 14.8479C10.9443 14.8942 10.8732 14.9207 10.7999 14.9246C10.7265 14.9284 10.6536 14.9095 10.5889 14.8698C10.5243 14.83 10.4702 14.771 10.4325 14.699C10.3949 14.627 10.375 14.5446 10.375 14.4607V9.67857C10.375 9.59569 10.3944 9.51433 10.4312 9.44292C10.468 9.37151 10.5209 9.31266 10.5843 9.27249C10.6477 9.23231 10.7194 9.21227 10.7919 9.21444C10.8644 9.21662 10.9351 9.24093 10.9966 9.28486Z"
            fill="#FF595E"
          />
          
        </svg>
      </span>
      {/* <span className="object-text">{t("Image")}</span> */}
    </div>
  );
}
