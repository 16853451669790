import React from "react";
import {
  // HiOutlineSun,
  // HiOutlineTrash,
  // HiOutlineArrowsExpand,
  // HiLightBulb,
  FaRegClone,
  // HiOutlineX,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function PasteButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button ${
          props.shouldShowButtonLabels ? " show-label-top-menu" : ""
          }`}
        onClick={props.onClickHandler}
        title={t("Duplicate Selection")}
      >
        <span className="icon is-small has-text-light">
          <FaRegClone className="fas fa-2x" />
        </span>
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Duplicate")}</span>}
      </button>
    </>
  );
}
