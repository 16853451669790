import React, { Component, useState, useRef } from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
// import { HiOutlineSun, HiOutlineX } from "react-icons/hi";
// import { selectAllActivitiesArrayPerPage } from "../store/configureStore";

import { pageNoChangeFromIndex } from "../../actions/pages";
import { Link } from "react-router-dom";
import { selectAllActivitiesArrayPerPage } from "../../store/configureStore";
import {
  AiFillCheckCircle,
  AiFillCheckSquare,
  AiFillSound,
} from "react-icons/ai";
import { HiAnnotation } from "react-icons/hi";
import { ImEmbed2, ImEyeBlocked, ImImage } from "react-icons/im";
import { GrCirclePlay, GrTextAlignFull, GrZoomIn } from "react-icons/gr";
import { FiExternalLink } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { RiZoomInFill } from "react-icons/ri";
import { MdAudiotrack } from "react-icons/md";
import { RiPlayCircleLine } from "react-icons/ri";
import { FaFileCode } from "react-icons/fa";
import { BsImage } from "react-icons/bs";



// import InteractiveObjects from "./_SidebarIndex/InteractiveObjects";

function InteractiveObjects(props) {
  const { t } = useTranslation();

  // console.log("!InteractiveObjects: ", InteractiveObjects);
  console.log('!props.interactiveObjects: ', props.interactiveObjects);
  // questionHideable: 4
  // questionShowable: 6
  // zoomAnswer: 4
  // zoomPagePart: 4

  return (
    <div className="column modal-right">
      <div className="modal-title">
        <h2>
          <span className="modal-title-icon">
            <img
              src={require("../../assets/img/svg/tabs-icon-draw-objects.svg")}
              alt={t("Activities")}
            />
          </span>
          <span className="modal-title-text">{t("Activities")}</span>
        </h2>
      </div>
      <table className="table">
        <tbody>
          {props.interactiveObjects.map((obj, ind) => {
            return (
              <tr key={ind}>
                <td
                  onClick={() => {
                    props.pageNoChangeFromIndex(ind + 1);
                  }}
                  key={ind}
                >
                  {" "}
                  <Link to={(ind + 1).toString()}>
                    <span className="activity-span">
                      {t("Page")} {ind + 1}
                    </span>
                  </Link>
                  {
                    Object.keys(obj).map((a, id) => {
                      // a=  interactivity type

                      let icon;
                      switch (a) {
                        case "zoomAnswer":
                          icon = (
                            <>
                            <span className="modal-title-text-small zoomAnswer-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 zoomAnswer-icon-color"
                              key={id}
                            >
                              <FaCheckCircle className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "zoomPagePart":
                          icon = (
                            <>
                            <span className="modal-title-text-small zoomPagePart-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3  zoomPagePart-icon-color"
                              key={id}
                            >
                              <RiZoomInFill className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "questionShowable":
                          icon = (
                            <>
                            <span className="modal-title-text-small questionShowable-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3  questionShowable-icon-color"
                              key={id}
                            >
                              <ImEyeBlocked className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "questionHideable":
                          icon = (
                            <>
                            <span className="modal-title-text-small questionHideable-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 questionHideable-icon-color"
                              key={id}
                            >
                              <HiAnnotation className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "audio":
                          icon = (
                            <>
                            <span className="modal-title-text-small audio-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3  audio-icon-color"
                              key={id}
                            >
                              <MdAudiotrack className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "text":
                          icon = (
                            <>
                            <span className="modal-title-text-small text-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3  text-icon-color"
                              key={id}
                            >
                              <GrTextAlignFull className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "video":
                          icon = (
                            <>
                            <span className="modal-title-text-small video-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 video-icon-color"
                              key={id}
                            >
                              <RiPlayCircleLine className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "h5p":
                          icon = (
                            <>
                            <span className="modal-title-text-small h5p-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 h5p-icon-color"
                              key={id}
                            >
                              <FaFileCode className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "answer":
                          icon = (
                            <>
                            <span className="modal-title-text-small answer-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 answer-icon-color"
                              key={id}
                            >
                              <AiFillCheckSquare className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "link":
                          icon = (
                            <>
                            <span className="modal-title-text-small link-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 link-icon-color"
                              key={id}
                            >
                              <FiExternalLink className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "h5p-answer":
                          icon = (
                            <>
                            <span className="modal-title-text-small h5p-answer-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 h5p-answer-icon-color"
                              key={id}
                            >
                              <AiFillCheckSquare className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "image":
                          icon = (
                            <>
                            <span className="modal-title-text-small image-icon-color">
                            {obj[a]}
                            </span>
                            <span
                              className="modal-title-text-small mr-3 image-icon-color"
                              key={id}
                            >
                              <BsImage className="fas fa-x" />
                            </span>
                            </>
                          );
                          break;
                        case "words":
                          return;
                          break;
                        default:
                          break;
                      }

                      return (
                        <div className="activities" key={id}>
                          {/* <span className="modal-title-icon"></span> */}
                          {icon}
                        </div>
                      );
                    })
                    //     obj.map((btn,BtnName) => {
                    //     return  "("+btn+")"+BtnName;
                    // })
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    interactiveObjects: selectAllActivitiesArrayPerPage(state),
  };
};

export default connect(mapStateToProps, {
  pageNoChangeFromIndex,
})(InteractiveObjects);
