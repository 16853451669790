import React from "react";
import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LoginPage from "./components/LoginPage";
import MainPage from "./components/MainPage";
import Library from "./components/Library";

import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
// import { isLogin } from "./services/util";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import {WinboxProvider} from "./components/WinBoxWrapper";
// import withClearCache from "./ClearCache";

const store = configureStore();
// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }
// if(process.env.REACT_APP_OFFLINE==='true') {
//   return MainApp();
// } else {
//   return <ClearCacheComponent />;
// }
// function MainApp(props) {
function App(props) {
  return (
    <Provider store={store}>
      <WinboxProvider>
      <Router>
        <Switch>
          <ProtectedRoute exact={true} path="/library" component={Library} />
          <ProtectedRoute path="/" exact={true} component={Library} />
          <ProtectedRoute
            path="/indivibook/:page"
            component={MainPage}
          />
          {process.env.REACT_APP_OFFLINE === "false" && (
            <Route path="/login" component={LoginPage} />
          )}
        </Switch>
      </Router>
      </WinboxProvider>
    </Provider>
  );
}

export default App;
