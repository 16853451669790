import React from "react";
import { useTranslation } from "react-i18next";

export default function Text(props) {
  const { t } = useTranslation();
 // const shouldShowButtonLabels = true;
  return (
    <li onClick={props.onClick}>
      <span
        className={`button normal-btn text ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${props.activeButton === "text" ? "active" : ""} `}
        title={t("Text") + " (T)"}
        data-tip={t("Text")}
      >
        <img
          src={require("../../assets/img/svg/tools-text.svg")}
          alt={t("Text") + " (T)"}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Text")}</span>}
      </span>
    </li>
  );
}
