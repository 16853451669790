import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function AddAudio(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickAddAudio = () => {
    console.log("--Add Audio..");
    dispatch(toaster(t("Click a point to add a button Audio"), "warning"));
    props.changeMyTool("addaudio");
  };
  return (
    <li onClick={onClickAddAudio}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "addaudio" ? "active" : ""
        }`}
        title={t("Add Audio")}
        data-tip={t("Add Audio")}
      >
        <img
          src={require("../../assets/img/svg/tools-audio2.svg")}
          alt={t("Add Audio")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Audio")}</span>}
      </span>
    </li>
  );
}
