import React from "react";
import { useTranslation } from "react-i18next";

export default function Shapes(props) {
  const { t } = useTranslation();
  return (
    <li
      className={`sub-menu ${
        // props.openableItems.shapesOpened ? "open" : ""
        props.activeButton === "shapes" ? "open" : ""
      }`}
    >
      <span
        className={`button normal-btn shapes ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${props.activeButton === "shapes" ? "active" : ""}`}
        title={t("Shapes")}
        onClick={props.onClick}
        data-tip={t("Shapes")}
      >
        <img
          src={require("../../assets/img/svg/shapes-square.svg")}
          alt={t("Shapes")}
        />
        {props.shouldShowButtonLabels && (
          <span className="show-label-text">{t("Shapes")}</span>
        )}
      </span>
      <div
        className={`shapes-menu ${
          props.alternative
            ? props.vertical
              ? "alternative-shapes-menu-vertical"
              : "alternative-shapes-menu"
            : ""
        }`}
      >
        <div className=" has-addons">
          <ul>
            <li onClick={props.onClickShapeLine}>
              <span
                className={`button normal-btn line ${
                  props.activeSubChildButton === "shapeline" ? "active" : ""
                }`}
                title={t("Line")}
                data-tip={t("Line")}
              >
                <img
                  src={require("../../assets/img/svg/shapes-line.svg")}
                  alt={t("Line")}
                />
              </span>
            </li>

            <li onClick={props.onClickEmptyCircle}>
              <span
                className={`button normal-btn empty-circle ${
                  props.activeSubChildButton === "emptycircle" ? "active" : ""
                }`}
                title={t("Empty Circle")}
                data-tip={t("Empty Circle")}
              >
                <img
                  src={require("../../assets/img/svg/shapes-ellipse-empty.svg")}
                  alt={t("Empty Circle")}
                />
              </span>
            </li>
            <li onClick={props.onClickShapeCircle}>
              <span
                className={`button normal-btn ellipse ${
                  props.activeSubChildButton === "circle" ? "active" : ""
                }`}
                title={t("Circle")}
                data-tip={t("Circle")}
              >
                <img
                  src={require("../../assets/img/svg/shapes-ellipse.svg")}
                  alt={t("Circle")}
                />
              </span>
            </li>
            <li onClick={props.onClickEmptySquare}>
              <span
                className={`button normal-btn empty-rectangle ${
                  props.activeSubChildButton === "emptysquare" ? "active" : ""
                }`}
                title={t("Empty Rectangle")}
                data-tip={t("Empty Rectangle")}
              >
                <img
                  src={require("../../assets/img/svg/shapes-square-line.svg")}
                  alt={t("Empty Rectangle")}
                />
              </span>
            </li>
            <li onClick={props.onClickShapeSquare}>
              <span
                className={`button normal-btn shapesquare ${
                  props.activeSubChildButton === "rectangle" ? "active" : ""
                }`}
                title={t("Rectangle")}
                data-tip={t("Rectangle")}
              >
                <img
                  src={require("../../assets/img/svg/shapes-square.svg")}
                  alt={t("Rectangle")}
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
}
