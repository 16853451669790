import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

 class ZoomToolbar extends Component {
  render() {
    return (
      <li className="draw-toolbar-icon">
        <span
          className={`button normal-btn main-button ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          } ${this.props.activeButton === 'zoom-options' ? 'active' : ''}${
            this.props.hideButton ? "passive" : ""
          }`}
          title={this.props.t("Zoom Tools")}
          onClick={this.props.onClick}>
          <img
            src={require('../../assets/img/svg/toolbar-zoom-in.svg')}
            alt={this.props.t("Zoom Tools")}
             />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">{this.props.t("Büyüklük")}</span>}
        </span>
      </li>
    )
  }
}
export default withTranslation()(ZoomToolbar);
