import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function PresentationMode(props) {
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  const onClickAddTimer = () => {
    // console.log("--Add a note..", props);
    // dispatch(toaster(t("Click a point to add a Timer"), "warning"));
    // props.changeMyTool("addtimer");
  };
  return (
    <li onClick={!props.disabled? props.onClick : undefined}>
      <span
      disabled={props.disabled}
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "addtimer" ? "active" : ""
        } ${
          props.active ? "active" : ""
        }`}
        title={t("Presentation Mode")}
        data-tip={t("Presentation Mode")}
      >
     <img
        src={require("../../assets/img/svg/tools-area-zoom.svg")}
        alt={"Presentation"}
      />
      {props.shouldShowButtonLabels && <span className="show-label-text">{t("Presentation")}</span>}
    </span>

  </li>
  );
}
