import React from "react";
import { BiScreenshot } from "react-icons/bi";
import { useTranslation } from "react-i18next";
export default function BottomBatchPresentation(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`button is-small modal-button ${
          props.isLoading ? "is-loading" : ""
        }`}
        onClick={!props.isLoading ? props.addZoomHandler : undefined}
        title={t("Add Zoom Area")}
      >
        <span className="icon mb-2 has-text-light">
          <BiScreenshot className="fas fa-2x" />
        </span>
        {props.shouldShowButtonLabels && (
          <span className="has-text-light ml-2 is-hidden-tablet-only  is-hidden-mobile mb-2">
            {t("Add Zoom Area")}
          </span>
        )}
      </button>
    </>
  );
}
