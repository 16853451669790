import React from "react";
import { BiTrash } from "react-icons/bi";
import { useTranslation } from "react-i18next";
export default function DeleteAllButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`button is-small modal-button ${
          props.isLoading ? "is-loading" : ""
        }`}
        onClick={!props.isLoading ? props.deleteContent : undefined}
        title={t("Delete Activity")}
      >
        <span className="icon  has-text-danger mb-2">
          <BiTrash className="fas fa-2x" />
        </span>{" "}
        {props.shouldShowButtonLabels && (
          <span className="has-text-light ml-2 is-hidden-tablet-only  is-hidden-mobile mb-2">
            {t("Delete All Activities")}
          </span>
        )}
      </button>
    </>
  );
}
