import { SHOW_KEYBOARD } from "../actions/actionTypes";
import { parseBoolean } from "../services/util";

const defaultState = {
  showKeyboard: false,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_KEYBOARD: {
      return {
        ...state,
        showKeyboard: action.payload,
      };
    }

    default:
      return state;
  }
};
