import React, { useState, useEffect, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
// import { pullPages, pageNoChange } from "../actions/pages";
import {
  deleteButtonService,
  deleteAllActivitiesOfPageService,
} from "../services/api";
import DeleteButton from "./SketchSelectedButtons/DeleteButton";
// import ExitButton from "./SketchSelectedButtons/ExitButton";
import {
  deleteActivity,
  editActivity,
  deleteAllActivitiesOfPage,
} from "../actions/addActivity";
import { showAnnotationsById } from "../actions/annotationSelection";
import { changeSelectedToolFromOutside } from "../actions/selectedTool";
import DeleteAllButton from "./SketchSelectedButtons/DeleteAllButton";
import BottomBatchPresentation from "./SketchSelectedButtons/BottomBatchPresentationButton";
import BottomBatchQShowableButton from "./SketchSelectedButtons/BottomBatchQShowableButton";
import ExitButton from "./SketchSelectedButtons/ExitButton";
import { setEditorMode } from "../actions/toolbar";
import EditorMode from "./SketchSelectedButtons/EditorMode";
import { setReloadActivities } from "../actions/temp";
import { setAddInteractivityMode } from "../actions/settings";
import { selectCurrentPageId } from "../store/configureStore";

const BottomEditMenu = (props) => {
  // console.log(`props.`, props.userCanDeleteActivity);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const shouldMenuOpened = props.userCanDeleteActivity || props.editorMode;
  const deleteClickHandler = () => {
    if (props.annotationIdsToShow.length !== 1) {
      return;
      // toastWarning("Sorry no ....");
    }
    const id = props.annotationIdsToShow[0];
    confirmAlert({
      title: t("Delete Activity"),
      message: t("Are you sure you want to delete this content?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => {
            setIsLoading(true);
            deleteButtonService(String(props.domain).toLowerCase(), id)
              .then(async (response) => {
                console.log("response FORMMM", response);
                await props.deleteActivity(id, props.currentPageId);
                await props.setReloadActivities(true);
                await setIsLoading(false);
                await props.showAnnotationsById(id);
              })
              .catch((error) => {
                console.log("logged error", error);
                setIsLoading(false);
              });
          },
        },
        {
          label: t("No"),
          onClick: () => {
            setIsLoading(false);
          },
        },
      ],
    });
  };
  const exitHandler = () => {
    props.setEditorMode(false);
  };
  const addZoomHandler = () => {
    props.changeSelectedToolFromOutside("zoomarea");
    props.setAddInteractivityMode("init", "zoomarea");
  };
  const addQShowableHandler = () => {
    props.changeSelectedToolFromOutside("qshowable");
  };
  // console.log('!!!!!!!!!!props.currentPageId: ', props.currentPageId);
  // const addQHideableHandler = () => {
  //   props.changeSelectedToolFromOutside("qhideable");
  //   props.setAddInteractivityMode("init", "qhideable");
  //   props.changeSelectedTool("emptycircle");
  // };
  const deleteAllClickHandler = () => {
    if (!props.annotationIdsToShow && props.annotationIdsToShow.length === 0) {
      return;
      // toastWarning("Sorry no ....");
    }
    const id = props.annotationIdsToShow[0];
    confirmAlert({
      title: t("Delete All Activities"),
      message: t(
        "Are you sure you want to delete all the activities in this page?"
      ),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => {
            setIsLoading(true);
            deleteAllActivitiesOfPageService(
              String(props.domain).toLowerCase(),
              props.currentPageId
            )
              .then(async (response) => {
                console.log("response FORMMM", response);
                console.log('!props.currentPageId: ', props.currentPageId);
                await props.deleteAllActivitiesOfPage(props.currentPageId);
                await props.setReloadActivities(true);
                await setIsLoading(false);
              })
              .catch((error) => {
                console.log("logged error", error);
                setIsLoading(false);
              });
          },
        },
        {
          label: t("No"),
          onClick: () => {
            setIsLoading(false);
          },
        },
      ],
    });
  };
  return (
    <>
      {props.pageZoomPartMode !== "zoom-show" && (
        <div
          className={`navbar is-fixed-bottom  bottom-annotation-menu bottom-edit-menu
           ${
             shouldMenuOpened && props.selectedTool !== "curtain"
               ? "open"
               : "hide"
           } `}
        >
          <EditorMode shouldShowButtonLabels={props.showLabels} />
          {props.userCanDeleteActivity && (
            <DeleteButton
              isLoading={isLoading}
              deleteContent={deleteClickHandler}
              shouldShowButtonLabels={props.showLabels}
            />
          )}
          {props.editorMode && (
            <>
              {props.currentPageButtonsLength > 0 && (
                <DeleteAllButton
                  isLoading={isLoading}
                  deleteContent={deleteAllClickHandler}
                  shouldShowButtonLabels={props.showLabels}
                />
              )}
              <BottomBatchPresentation
                isLoading={isLoading}
                addZoomHandler={addZoomHandler}
                // deleteContent={deleteAllClickHandler}
                shouldShowButtonLabels={props.showLabels}
              />
              <BottomBatchQShowableButton
                isLoading={isLoading}
                addZoomHandler={addQShowableHandler}
                // deleteContent={deleteAllClickHandler}
                shouldShowButtonLabels={props.showLabels}
              />
              <ExitButton onClickHandler={exitHandler} />
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    annotationIdsToShow: state.annotationSelections.annotationIdsToShow,
    selectedTool: state.selectedTool.selectedTool,
    showLabels: state.toolbar.showLabels,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    // currentPageId: state.pages.currentPage[0]?.id,
    currentPageId: selectCurrentPageId(state),
    domain: state.login.user_profile?.domain_name,
  };
};

export default connect(mapStateToProps, {
  deleteActivity,
  editActivity,
  showAnnotationsById,
  deleteAllActivitiesOfPage,
  setEditorMode,
  setReloadActivities,
  changeSelectedToolFromOutside,
  setAddInteractivityMode,
})(BottomEditMenu);
