import React from "react";
import { useTranslation } from "react-i18next";

export default function Pen(props) {
  const { t } = useTranslation();
  return (
    // <li onClick={!props.hideButton && props.onClick}>
    <li onClick={!props.hideButton ? props.onClick : undefined}>
      <span
        className={`button normal-btn zoom-out ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "zoom-out" ? "active" : ""
        }${props.hideButton ? "passive" : ""}`}
        title={t("Zoom out") + " (X)"}
        data-tip={t("Zoom out")}
      >
        <img
          src={require("../../assets/img/svg/toolbar-zoom-out.svg")}
          alt={t("Zoom out") + " (X)"}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Zoom out")}</span>}
      </span>
    </li>
  );
}
