import React from "react";
import {
  HiXCircle,
  // HiOutlineSun,
  // HiOutlineTrash,
  // HiOutlineArrowsExpand,
  // HiLightBulb,
  HiPencilAlt,
  // HiOutlineX,
} from "react-icons/hi";

export default function EditBtn(props) {
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button`}
        onClick={props.editContent}
      >
        <span className="icon is-small has-text-link">
          <HiPencilAlt className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
