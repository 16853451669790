import React, { Component } from "react";
// import CustomModal from "./CustomModal";
import NewModal from "./NewModal";
// import NewModalCore from "./NewModalCore";
import "flexboxgrid";
import "./Sketch/main.css";
import Sketch from "./Sketch/NewSketch";
import { setScalePageDown } from "../actions/scalePageDown";
import { setScalePageUp } from "../actions/scalePageUp";
import { setScalePageReset } from "../actions/scalePageReset";
import { pageNoChange } from "../actions/pages";
import { connect } from "react-redux";
import { toggleCapture } from "../actions/captureStatus";
import Loading from "./Loading";
import VideoButton from "./SubComponents/VideoButton";
import QButton from "./SubComponents/QButton";
import QShowButton from "./SubComponents/QShowButton";
import AudioButton from "./SubComponents/AudioButton";
import ImageButton from "./SubComponents/ImageButton";
import H5pButton from "./SubComponents/H5pButton";
import H5pAnswerButton from "./SubComponents/H5pAnswerButton";
import AnswerButton from "./SubComponents/AnswerButton";
import MCQuestionButton from "./SubComponents/MCQuestionButton";
import MRQuestionButton from "./SubComponents/MRQuestionButton";
import LinkButton from "./SubComponents/LinkButton";
import TextButton from "./SubComponents/TextButton";
import VideoAnswerButton from "./SubComponents/VideoAnswerButton";

// import MCQuestion from "./Quiz/MCQuestion";
// import ShowQuestion from "./Quiz/ShowQuestion";
import ShowMCQuestionForm from "./AddButton/ShowMCQuestionForm";
import { mcQuestionModalOpen, mcQuestionModalClose } from "../actions/quiz";
import Draggable from "react-draggable";
import { deleteButtonService, updateButtonService } from "../services/api";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { confirmMessages } from "../services/constants";
import {
  triggerZoomIn,
  triggerZoomOut,
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  setBackgroundImageOpacity,
} from "../actions/settings";

import {
  bookmarkPage,
  guidGenerator,
  iframe,
  isPageBookmarked,
  unBookmarkPage,
} from "../services/util";
import AddButton from "./AddButton/AddButton";
import { isMobile } from "react-device-detect";
import { toggleSideBar } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
import { showAnnotationsById } from "../actions/annotationSelection";
import ZoomButton from "./SubComponents/ZoomButton";
import ZoomAnswerButton from "./SubComponents/ZoomAnswerButton";
// import LoadingAnim from "./LoadingAnim";
import {
  deletePageActivityKeepCoordsByPage,
  updateActivity,
  setClearKeepCoords,
  editActivity,
  deleteActivity,
} from "../actions/addActivity";
import RenderTempInteractivityButtons from "./SubComponents/RenderTempInteractivityButtons";
import { toaster } from "../actions/showToast";
import {
  selectCurrentPageId,
  selectUserHasAnyAdminRole,
  selectUserId,
} from "../store/configureStore";
// import {con} from "./WinModal";
import { WinboxContext } from "./WinBoxWrapper";
import WinInnerModal from "./WinInnerModal";
import { renderModalData } from "./ModalButtons/RenderButtons";
import { addButtonModalOpen } from "../actions/addButton";
import { isDOMComponent } from "react-dom/test-utils";


class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalData: {},
      pageNumber: this.props.pageNumber,
      buttonSizeFactor: 1,
      zoomClass: "zoomPage1",
      _isMounted: false,
      buttonsDragging: false,
      isPageBookmarked: isPageBookmarked(this.props.pageNumber),
      activeDrags: 0,
      recordNewButtonCoordinats: false,
      deltaPosition: {
        x: 0,
        y: 0,
      },
      controlledPosition: {
        x: -400,
        y: 200,
      },
      newCoords: {
        newLeft: null,
        newTop: null,
        objId: null,
        object: null,
      },
      localBackgroundOpacity: 1,
      isLoading: false,
    };
  }
  static contextType = WinboxContext;

  onLoadImage = () => {
    this.setState({ imageIsReady: true });
  };

  // toggles modal
  toggleModal = (e, modalData) => {
    // console.log("==================modalData");
    if (!this.state.buttonsDragging) {
      this.setState({ modalIsOpen: !this.state.modalIsOpen, modalData });
    } else {
      console.log("bLocked", this.state.buttonsDragging);
    }
  };
  resetBackgroundOpacity = () => {
    // alert('resetBackgroundOpacity')
    if (this.props.backgroundImageOpacity !== 1) {
      this.props.setBackgroundImageOpacity(1);
    }
  };
  toggleBackgroundOpacity = () => {
    switch (this.props.backgroundImageOpacity) {
      case 0:
        this.props.setBackgroundImageOpacity(1);
        break;
      case 0.5:
        this.props.setBackgroundImageOpacity(0);
        break;
      case 1:
        this.props.setBackgroundImageOpacity(0.5);
        break;

      default:
        break;
    }
  };

  deleteContent = (data) => {
    if (this.state.isLoading) {
      return;
    }
    console.log("data: ", data);
    const winBoxData = data[0];
    const winBoxReference = data[1];
    // console.log("winBoxReference: ", winBoxReference);
    const { id, ebook_pages_id } = winBoxData;
    confirmAlert({
      title: confirmMessages.deleteActivity,
      message: confirmMessages.deleteActivityConfirm,
      buttons: [
        {
          label: confirmMessages.deleteActivityYes,
          onClick: () => {
            this.setState({
              isLoading: true,
            });
            deleteButtonService(String(this.props.domain).toLowerCase(), id)
              .then(async (response) => {
                console.log("response FORMMM", response);

                this.props.deleteActivity(id, ebook_pages_id);
                this.setState({
                  isLoading: false,
                });
                winBoxReference && winBoxReference.close(true);
              })
              .catch((error) => {
                console.log("logged error", error);
                this.setState({
                  isLoading: false,
                });
                winBoxReference && winBoxReference.close(true);
              });
          },
        },
        {
          label: confirmMessages.deleteActivityNo,
          onClick: () => {
            this.setState({
              isLoading: false,
            });
            // winBoxReference && winBoxReference.close(true);
          },
        },
      ],
    });
  };
  winBoxEditHandler = (data) => {
    console.log("data: ", data);
    const winBoxData = data[0];
    const winBoxReference = data[1];
    if (data[2] == "edit") {
      console.log({ modalIsOpen: true, modalData: winBoxData });
      this.setState({ modalIsOpen: true, modalData: winBoxData });
      this.props.editActivity(
        winBoxData.id,
        winBoxData.button_type,
        winBoxData.left,
        winBoxData.top,
        winBoxData.style,
        winBoxData.show
      );
      // addButtonModalOpen
      this.props.addButtonModalOpen();
    }
    if (data[2] == "sun") {
      // console.log(winBoxData);
      // console.log("delete");
      this.toggleBackgroundOpacity();
      return;
    }
    if (data[2] == "delete") {
      console.log(winBoxData);
      console.log("delete");
    }
    if (data[2] == "save") {
      console.log(winBoxData);
      console.log("save");
    }
    winBoxReference && winBoxReference.close(true);
  };

  calculateH5pWidthHeight = (data) => {
    const minWindowHeight = window.innerHeight / 2;
    const minWindowWidth = window.innerWidth / 2;
    let myData = iframe(data.content);

    var s = myData.__html;
    var temp = document.createElement("div");
    temp.innerHTML = s;
    const isBiggerThanWindow = isDOMComponent(temp.firstChild)
      ? window.innerHeight - 100 <
          parseInt(temp.firstChild?.getAttribute("height")) ||
        window.innerWidth - 50 <
          parseInt(temp.firstChild?.getAttribute("width"))
      : true;

    const smallScale = isDOMComponent(temp.firstChild)
      ? Math.min(
          (window.innerWidth - 50) /
            parseInt(temp.firstChild.getAttribute("width")),
          (window.innerHeight - 50) /
            parseInt(temp.firstChild.getAttribute("height"))
        )
      : 1;

    const h5pMinHeight = parseInt(
      isDOMComponent(temp.firstChild)
        ? !isBiggerThanWindow
          ? parseInt(temp.firstChild.getAttribute("height")) + 50 //40 is padding top
          : parseInt(temp.firstChild.getAttribute("height")) * smallScale + 60
        : minWindowHeight
    );
    const h5pMinWidth = parseInt(
      isDOMComponent(temp.firstChild)
        ? !isBiggerThanWindow
          ? parseInt(temp.firstChild.getAttribute("width"))
          : parseInt(temp.firstChild.getAttribute("width")) * smallScale
        : minWindowWidth
    );
    return [h5pMinWidth, h5pMinHeight];
  };
  openModal = async(e, modalData) => {
    console.log("modalData: ", modalData);
    const showDelete =
      modalData?.user_id === this.props.userId ||
      !this.props.userCanEditActivity;
    const showEdit =
      showDelete &&
      (modalData?.button_type === "text" ||
        modalData?.button_type === "h5p" ||
        modalData?.button_type === "video" ||
        modalData?.button_type === "zoomVideoAnswer" ||
        modalData?.button_type === "link");
    /**
     * @description there 3 methods to call the winbox
     * call only url
     * pass winbox objec
     * pass component
     */
    // this.context.CallWinBox({url:"http://localhost:3000/#/indivibook/3"});
    // this.context.CallWinBox({fullObj:modalData});
    if (
      modalData.button_type === "questionHideable" ||
      modalData.button_type === "questionShowable"
    ) {
      // console.log(`modalData.id`, modalData.id);
      this.props.showAnnotationsById(modalData.id);
      return;
    } else if (modalData.button_type === "zoomAnswer") {
      const zoomAnswer = JSON.parse(modalData.content);
      // console.log(`modalData.id`, modalData.id);
      this.props.toaster(
        "Doğru Cevap: " + String(zoomAnswer.answer).toLocaleUpperCase(),
        "warning",
        3000,
        true,
        "center",
        true
      );
      return;
    }
    if (modalData.button_type === "zoomPagePart") {
      // console.log("zoompagePart", modalData.content);
      this.props.setPagePartZoomMode("zoom-show");

      this.props.toggleSideBar(true);

      setTimeout(() => {
        this.props.setPagePartZoomMode("zoom-show");
        const {
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp,
        } = JSON.parse(modalData.content);
        this.props.setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp
        );
      }, 500);
      // if (this.props.annotationIdToShow === modalData.id) {
      //   this.props.showAnnotationsById(null);
      //   this.props.showAnnotationsById(modalData.id);
      // } else {
      //   this.props.showAnnotationsById(modalData.id);

      return;
    }
    // console.log("????");
    if (!this.state.buttonsDragging) {
      this.context.CallWinBox({
        childComp: <Component2 data={modalData} />,
        classNames: ["no-full", "my-theme-" + this.props.theme],
        title: modalData.description || "",
        height:
          modalData.button_type === "audio"
            ? 100
            : modalData.button_type === "text"
            ? 300
            : modalData.button_type === "h5p" ||modalData.button_type === "h5p-answer"
            ? await this.calculateH5pWidthHeight(modalData)[1]
            : undefined,
        width:
        modalData.button_type === "h5p" ||modalData.button_type === "h5p-answer"
            ? await this.calculateH5pWidthHeight(modalData)[0]
            : undefined,
        // minheight:  modalData.button_type === "video" ? 100 : 100,
        minwidth: 250,
        handler1: this.setBgOpacity,
        itemId: modalData,
        editHandler: showEdit ? this.winBoxEditHandler : undefined,
        sunHandler: this.winBoxEditHandler,
        currentPageId: !isMobile ? this.props.currentPageId : undefined,
        closeHelperFunc: this.resetBackgroundOpacity,
        // submitHandler:this.winBoxEditHandler,
        deleteHandler: showDelete ? this.deleteContent : undefined,
        winboxX: isMobile ? "0px" : "center",
        // winboxY: "top",
      });
    } else {
      console.log("bLocked", this.state.buttonsDragging);
    }
  };
  setBgOpacity = () => {
    this.props.setBackgroundImageOpacity(0.5);
  };
  closeModal = (e, modalData) => {
    // console.log("Modal closed!!!");
    this.setState({ modalIsOpen: false, modalData });
  };
  componentDidMount(prev) {
    this.setState({ _isMounted: true });
    // this.props.onChangeTool("pan");
    this.onClickDrag([0, 0]);
    // if (this.props.captured) this.props.toggleCapture();
  }
  componentWillUnmount() {
    this.setState({ _isMounted: false });
  }
  renderObjectIcon(object, index) {
    object.width = 24;
    object.height = 24;

    switch (object.button_type) {
      case "video":
        return <VideoButton object={object} key={index} />;

      case "audio":
        return <AudioButton object={object} />;

      case "image":
        return <ImageButton object={object} />;

      case "h5p":
        return <H5pButton object={object} />;

      case "h5p-answer":
        return <H5pAnswerButton object={object} />;

      case "link":
        return <LinkButton object={object} />;

      case "text":
        return <TextButton object={object} key={index} />;

      case "answer":
        return <AnswerButton object={object} />;

      case "mc_question":
        return <MCQuestionButton object={object} />;

      case "mr_question":
        return <MRQuestionButton object={object} />;

      case "words":
        return <div className="dOff"></div>;

      case "questionHideable":
        object.style = "styleQHide";
        return <QButton object={object} />;

      case "questionShowable":
        object.style = "styleQShow";
        return <QShowButton object={object} />;

      case "zoomAnswer":
        object.style = "styleAnswer";
        return <ZoomAnswerButton object={object} />;

      case "zoomPagePart":
        object.style = "styleZoom";
        return <ZoomButton object={object} />;

      case "zoomVideoAnswer":
        object.style = "styleVideoAnswer";
        return <VideoAnswerButton object={object} />;

      default:
        return;
    }
  }

  onClickZoomIn() {
    this.props.setScalePageUp(this.props.scaleZoomFactorVal.scaleFactor);
  }
  onClickDrag(params) {
    let paGe = document.getElementById("pdfPageList");
    paGe.scrollTop = params[0];
    paGe.scrollLeft = params[1];
    return;
  }
  onClickZoomOut() {
    this.props.setScalePageDown(this.props.scaleZoomFactorVal.scaleFactor);
  }
  onClickZoomReset() {
    this.props.setScalePageReset();
  }
  handleStart = () => {};
  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition;

    // console.log("HANDLE DRAGGING", this.state.buttonsDragging);
    if (!this.state.buttonsDragging) {
      this.setState({
        buttonsDragging: true,
        deltaPosition: {
          x: x + ui.deltaX,
          y: y + ui.deltaY,
        },
      });
    }
  };
  submitCooords = (ownActivity) => {
    console.log("ownActivity", ownActivity);
    if (ownActivity) {
      this.submit(confirmMessages.title, confirmMessages.message);
    }
  };
  handleStop = (e, ui, object) => {
    console.log("!>>>>ui: ", ui);
    const myRatio =
      this.props.canvasVals.canvasBackgroundImageWidth /
      this.props.canvasVals.canvasWidth; //ratio of the book page
    const originRatio = 1000 / this.props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
    const oldLeft = myRatio * parseInt(ui.lastX) * originRatio; // * props.canvasVals.vpW) / 1000, // -
    const oldTop = myRatio * parseInt(ui.lastY) * originRatio; // * props.canvasVals.vpW) / 1000, // -
    const newLeft = parseFloat(parseFloat(object.left) + oldLeft).toFixed(2);
    const newTop = parseFloat(parseFloat(object.top) + oldTop).toFixed(2);

    if (ui.deltaX !== 0 || ui.deltaY !== 0) {
      // if (Math.abs(ui.x) > 5 || Math.abs(ui.y) > 5) {
      console.log("!object.user_id: ", object.user_id);
      console.log("!this.props.userId: ", this.props.userId);
      // console.log('ui.deltaX !== 0 || ui.deltaY!==0', ui.deltaX !== 0 , ui.deltaY!==0, ui.deltaX !== 0 || ui.deltaY!==0)
      this.setState(
        {
          newCoords: { newLeft, newTop, objId: object.id, object },
        },

        this.submitCooords(
          object.user_id === this.props.userId || this.props.userHasAnyAdminRole
        )
      );
    } else {
      this.openModal(e, object);
    }
    if (this.state.buttonsDragging) {
      setTimeout(() => {
        console.log("timeout done");
        this.setState({ buttonsDragging: false });
      }, 500);
    }
  };

  sendNewCoord = () => {
    const { newLeft, newTop, objId, object } = this.state.newCoords;
    console.log("newLeft, newTop, objId", newLeft, newTop, objId);
    if (this.state.recordNewButtonCoordinats) {
      const formData = new FormData();
      formData.append("left", parseInt(newLeft));
      formData.append("top", parseInt(newTop));
      console.log(`objId object`, object);
      const sendData = {
        ...object,
        // id:objId,
        left: newLeft,
        top: newTop,
      };
      // console.error("____formData", formData);
      updateButtonService(
        formData,
        String(this.props.domain).toLowerCase(),
        objId
      )
        .then(async (response) => {
          console.log("response FORMMM", response);
          this.props.updateActivity(sendData, this.props.currentPageId, false);
        })
        .catch((error) => {
          console.log("logged error", error);
        });
    }
  };

  renderBookmarkIcon = (data) => {
    // const top = 0;
    // const left = this.props.canvasVals.canvasWidth - 40 || -40;

    let scaleProperty = this.props.canvasVals
      ? this.props.canvasVals.canvasWidth / 1000
      : 1;

    let transform = "scale(" + scaleProperty + ")";
    return (
      <button
        className={`bookmark  ${this.state.isPageBookmarked ? "active" : ""}

               `}
        // style={{ top, left }}
        data-tip={"Bookmark"}
        onClick={() => {
          if (this.state.isPageBookmarked) {
            unBookmarkPage(data.pageNumber);
            this.setState({ isPageBookmarked: false });
          } else {
            bookmarkPage(data.pageNumber);
            this.setState({ isPageBookmarked: true });
          }
        }}
      >
        <span className="icon bookmark-svg-container">
          <svg
            // width="80px"
            className="bookmark-svg"
            // height="109px"
            viewBox="0 0 80 109"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="insignia"
                fillRule="nonzero"
                stroke="#000000"
                strokeWidth="4"
              >
                <path
                  d="M5,2 C3.34314575,2 2,3.34314575 2,5 L2,103.369193 C2,104.067698 2.24374108,104.74431 2.68919042,105.282347 C3.74579632,106.558572 5.63692933,106.736608 6.91315422,105.680002 L35.1838721,82.2742473 C37.7546673,80.145847 41.4705424,80.1284281 44.0611788,82.2326331 L73.1085942,105.825954 C73.643288,106.260251 74.3111522,106.49731 75,106.49731 C76.6568542,106.49731 78,105.154164 78,103.49731 L78,5 C78,3.34314575 76.6568542,2 75,2 L5,2 Z"
                  id="Rectangle"
                ></path>
              </g>
            </g>
          </svg>
        </span>
      </button>
    );
  };

  renderInteractiveButtons = (data) => {
    const zoomMode =
      this.props.pageZoomPartObjectToShow.orderId &&
      this.props.pageZoomPartMode === "zoom-show";
    // const zoomAreaPercentage =
    //   process.env.REACT_APP_ZOOM_AREA_PERCENTAGE !== undefined
    //     ? parseInt(process.env.REACT_APP_ZOOM_AREA_PERCENTAGE) / 100
    //     : 0.85;
    const fullScale = this.props.settings.pageZoomPartFullScaleVal;
    // console.log("fullScale: ", fullScale);
    const scaleX = this.props.settings.pageZoomPartInitialScaleX;
    const difference = this.props.scaleZoomFactorVal.canvas.scaleX;
    const difference2 = this.props.scaleZoomFactorVal.canvas.scaleX / scaleX;
    let scaleProperty = this.props.canvasVals
      ? !zoomMode
        ? this.props.canvasVals.canvasWidth / 1300
        : // : (this.props.canvasVals.canvasWidth / 1300) * fullScale/difference //
          (this.props.canvasVals.canvasBackgroundImageWidth *
            fullScale *
            (difference / scaleX)) /
          1300 //* fullScale/difference //
      : 1;

    scaleProperty = scaleProperty > 2 ? 2 : scaleProperty;
    scaleProperty = isMobile && scaleProperty > 1.5 ? 1 : scaleProperty;

    const buttons = data.buttons.map((object, index) => {
      // console.log(`scaleProperty`, scaleProperty)
      // console.log(`object, index`, object, index)
      const buttonShow = zoomMode
        ? object.button_type === "zoomPagePart" ||
          object.button_type === "zoomAnswer"
          ? false
          : true
        : true;
      // console.log(`scaleProperty/difference`, scaleProperty/difference)
      // console.log(`fullScale/difference`, fullScale/difference)
      // console.log("!object.keepCoord: ", object.keepCoord);
      let objLeft = object.left;
      let objTop = object.top;
      if (
        object.keepCoords &&
        object.keepCoords.pageId === this.props.currentPageId
      ) {
        // if its current page we don't want to get new coords of the object because the dragger puts style transform left and top additional values and this makes the object jump to a different part of the page.
        objLeft = object.keepCoords.left;
        objTop = object.keepCoords.top;
        // this.props.setClearKeepCoords(true);
      }
      const zoomSecondAreaLeft = zoomMode
        ? this.props.pageZoomPartObjectToShow.cutCoords.x
          ? this.props.pageZoomPartObjectToShow.cutCoords.x * difference
          : null
        : null;
      const zoomSecondAreaWidth = zoomMode
        ? this.props.pageZoomPartObjectToShow.cutCoordsUp.x
          ? zoomSecondAreaLeft +
            this.props.pageZoomPartObjectToShow.cutCoordsUp.x * difference
          : null
        : null;

      const zoomSecondAreaTop = zoomMode
        ? this.props.pageZoomPartObjectToShow.cutCoords.x
          ? this.props.pageZoomPartObjectToShow.cutCoords.y * difference
          : null
        : 1;
      const zoomSecondAreaHeight = zoomMode
        ? this.props.pageZoomPartObjectToShow.cutCoordsUp.x
          ? zoomSecondAreaTop +
            this.props.pageZoomPartObjectToShow.cutCoordsUp.y * difference
          : null
        : 1;

      let bLeft =
        (parseInt(objLeft) * this.props.canvasVals.canvasWidth) / 1000 || -300;

      const zoomAreaLeft = zoomMode
        ? this.props.pageZoomPartObjectToShow.downCoords.x * difference
        : 1;

      const zoomAreaWidth = zoomMode
        ? zoomAreaLeft +
          this.props.pageZoomPartObjectToShow.upCoords.x * difference
        : 1;

      let bTop =
        (parseInt(objTop) * this.props.canvasVals.canvasWidth) / 1000 || -300;

      const zoomAreaTop = zoomMode
        ? this.props.pageZoomPartObjectToShow.downCoords.y * difference
        : 1;

      const zoomAreaHeight = zoomMode
        ? zoomAreaTop +
          this.props.pageZoomPartObjectToShow.upCoords.y * difference
        : 1;

      const isInsideOfFirstZoomArea = zoomMode
        ? bLeft < zoomAreaWidth && ///isOutsideOfFirstZoomArea
          parseInt(bLeft) + 2 > parseInt(zoomAreaLeft) &&
          bTop < zoomAreaHeight &&
          bTop > zoomAreaTop
        : false;
      const isInsideOfSecondZoomArea =
        zoomMode && this.props.pageZoomPartObjectToShow.downCoords.x
          ? bLeft < zoomSecondAreaWidth && ///isOutsideOfFirstZoomArea
            parseInt(bLeft) + 2 > parseInt(zoomSecondAreaLeft) &&
            bTop < zoomSecondAreaHeight &&
            bTop > zoomSecondAreaTop
          : false;

      const zoomAreaTopToCalculate = zoomMode
        ? isInsideOfFirstZoomArea
          ? zoomAreaTop
          : isInsideOfSecondZoomArea
          ? zoomSecondAreaTop
          : null
        : null;
      let left = zoomMode
        ? isInsideOfFirstZoomArea
          ? (((bLeft - zoomAreaLeft) * fullScale) /
              this.props.scaleZoomFactorVal.canvas.scaleX) * //Calculating the left zoom columns' buttons' left
            difference2
          : (((zoomAreaWidth - zoomAreaLeft) * fullScale) / //Calculating the right zoom column
              this.props.scaleZoomFactorVal.canvas.scaleX) *
              difference2 +
            (((bLeft - zoomSecondAreaLeft) * fullScale) / //Calculating the right zoom columns' buttons' left
              this.props.scaleZoomFactorVal.canvas.scaleX) *
              difference2
        : bLeft;

      let top = zoomMode
        ? (((bTop - zoomAreaTopToCalculate) * fullScale) /
            this.props.scaleZoomFactorVal.canvas.scaleX) *
          difference2
        : bTop;

      const isHiddenForZoom = !zoomMode
        ? false
        : !buttonShow
        ? false
        : !isInsideOfFirstZoomArea && !isInsideOfSecondZoomArea;
      // : bLeft > zoomAreaWidth || ///isOutsideOfFirstZoomArea
      //   parseInt(bLeft) < parseInt(zoomAreaLeft) - 2 ||
      //   bTop > zoomAreaHeight ||
      //   bTop < zoomAreaTop;

      //   console.log(`zoomAreaWidth: ${zoomAreaWidth},
      //   zoomAreaWidth:, ${zoomAreaWidth},
      //   zoomAreaTop:, ${zoomAreaTop},
      //   isInsideOfFirstZoomArea:, ${isInsideOfFirstZoomArea},
      //   isInsideOfSecondZoomArea:, ${isInsideOfSecondZoomArea},
      //  ||isHiddenForZoom:, ${isHiddenForZoom},
      //   this.props.pageZoomPartObjectToShow.cutCoords.x:,
      //    ${this.props.pageZoomPartObjectToShow?.cutCoords?.x},

      //  ||~~zoomSecondAreaLeft:, ${zoomSecondAreaLeft},
      //  zoomSecondAreaWidth:, ${zoomSecondAreaWidth},
      //  zoomSecondAreaTop:, ${zoomSecondAreaTop},
      //  zoomSecondAreaHeight:, ${zoomSecondAreaHeight},
      //  bLeft:, ${bLeft},
      //  parseInt(objLeft):, ${parseInt(objLeft)},
      //  zoomAreaLeft:, ${zoomAreaLeft},
      //  left:, ${left},
      //  object: , ${object.button_type}, ${object}
      //   `);
      let transform = "scale(" + scaleProperty + ")";
      object.scale = scaleProperty;

      return (
        <div key={index}>
          {this.props.moveButtonsVal && !isMobile ? (
            <Draggable
              allowAnyClick={false}
              axis="both"
              handle=".handle"
              defaultPosition={{ x: 0, y: 0 }}
              position={null}
              grid={[2, 2]}
              scale={1}
              onStart={this.handleStart}
              onDrag={this.handleDrag}
              onStop={(e, ui) => this.handleStop(e, ui, object)}
              disabled={!this.props.moveButtonsVal}
              key={index}
              // onStop={this.handleStop}
            >
              <div
                className={`interactive-object-area handle ${
                  object.button_type
                }-object  ${object.style} ${
                  this.props.hideObjects || isHiddenForZoom
                    ? "hide-element"
                    : ""
                } ${object.button_type === "words" ? "hide-element" : ""}`}
                onClick={(e) => {
                  !this.props.moveButtonsVal && this.openModal(e, object);
                }}
                style={{
                  top,
                  left,
                  transform,
                }}
                key={index}
              >
                {buttonShow && this.renderObjectIcon(object, index)}
              </div>
            </Draggable>
          ) : (
            <div
              className={`interactive-object-area handle ${
                object.button_type ? object.button_type : ""
              }-object no-cpu ${object.style} ${
                this.props.hideObjects || isHiddenForZoom ? "hide-element" : ""
              } ${object.button_type === "words" ? "hide-element" : ""}`}
              // className={`interactive-object-area handle ${
              //   object.button_type
              // }-object ${object.style} ${
              //   this.props.hideObjects ? "hide-element" : ""
              // } ${object.button_type === "words" ? "hide-element" : ""}`}

              onClick={(e) => this.openModal(e, object)}
              style={{ top, left }}
              key={index}
            >
              {buttonShow && this.renderObjectIcon(object)}
            </div>
          )}
        </div>
      );
    });

    return buttons;
  };

  interactiveButtonsSizeChanged = (buttonSizeFactor) => {
    if (this.state._isMounted) {
      this.setState({ buttonSizeFactor: buttonSizeFactor });
    }
  };

  submit = (title, message) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.setState(
              { recordNewButtonCoordinats: true },
              this.sendNewCoord
            ),
        },
        {
          label: "No",
          onClick: () => this.setState({ recordNewButtonCoordinats: false }),
        },
      ],
    });
  };
  // componentWillUnmount = () => {
  //   alert("componentWillUnmount");
  //   if (this.state.clearKeepCoords)
  //     this.props.deletePageActivityKeepCoordsByPage(this.props.currentPageId);
  // };
  componentDidUpdate = (prevProps, prevState) => {
    // console.log('didUpdate')
    // Object.entries(this.props).forEach(
    //   ([key, val]) =>
    //     prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    // );
    // if (this.state) {
    //   Object.entries(this.state).forEach(
    //     ([key, val]) =>
    //       prevState[key] !== val && console.log(`State '${key}' changed`)
    //   );
    // }
    // console.log(
    //   "!0-0-0-this.props.currentPageNumber: ",
    //   this.props.currentPageNumber
    // );
    // console.log(
    //   "!90909prevProps.currentPageNumber: ",
    //   prevProps.currentPageNumber
    // );
    // console.log("! this.props.clearKeepCoords: ", this.props.clearKeepCoords);
    if (
      this.props.clearKeepCoords &&
      this.props.currentPageNumber !== prevProps.currentPageNumber
    ) {
      // console.log("! >>>>>>>>>>> clearKeepCoords", this.props.clearKeepCoords);
      // alert("clearKeepCoords");
      // this.props.deletePageActivityKeepCoordsByPage(this.props.currentPageId);
      // this.props.setClearKeepCoords(false);
    }
    if (
      this.props.settings.zoomIn &&
      this.props.settings.zoomIn !== prevProps.settings.zoomIn
    ) {
      this.onClickZoomIn();
      this.props.triggerZoomIn(false);
    }
    if (
      this.props.settings.zoomOut &&
      this.props.settings.zoomOut !== prevProps.settings.zoomOut
    ) {
      this.onClickZoomOut();
      this.props.triggerZoomOut(false);
    }
    if (
      this.props.backgroundImageOpacity !== prevProps.backgroundImageOpacity
    ) {
      console.log("DID UPDATE     BAG", this.props.backgroundImageOpacity);
      this.setState({
        localBackgroundOpacity:
          this.props.backgroundImageOpacity === 1
            ? ""
            : this.props.backgroundImageOpacity === 0
            ? "bg-fade-0"
            : "bg-fade-05",
      });
    }
  };

  closeBars = () => {
    if (isMobile) {
      if (this.props.sideBarOpened) {
        // console.log("tik tik");
        this.props.toggleSideBar(this.props.sideBarOpened);
      }
      if (this.props.searchBarOpened) {
        this.props.toggleSearchBar(this.props.searchBarOpened);
      }
    }
  };

  render() {
    const data = this.props.data;
    let { modalData } = this.state;

    // this.context.winboxState?.map((current)=>
    // {
    //   if(current){
    //     current?.body?.parentNode?.classList?.toggle("your_name"))
    //   }

    // }

    return (
      <div
        className={`${this.props.scaleZoomFactorVal.scaleCss}`}
        key={this.props.pageNumber}
      >
        {/* {!this.props.settings.pageZoomPartShowLoading && <LoadingAnim />} */}

        {/* {modalData ? (
          <WinModal
            isOpen={this.state.modalIsOpen}
            toggleModal={(e) => this.closeModal(e)}
            data={modalData}
          />
        ) : (
          ""
        )} */}
        {/* {
          this.state.modalIsOpen && <NewModal data={this.state.modalData} />
        } */}

        <li
          className={"pdf-page " + this.state.localBackgroundOpacity}
          id={data.pageNumber.toString()}
          // key={this.props.pageNumber}
          key={this.props.subKey + "A"}
          onClick={this.closeBars}
        >
          <figure className="image" key={this.props.subKey + "C"}>
            {!this.props.imagesLoaded ? <Loading /> : ""}
          </figure>
          <Sketch
            image={data}
            // sketchSettings={this.props.sketchSettings}
            // sidebarIsOpened={this.props.sidebarIsOpened}
            onChangeTool={(param) => this.props.onChangeTool(param)}
            // onClickDrag={(param) => this.onClickDrag(param)}
            interactiveButtonsSizeChanged={this.interactiveButtonsSizeChanged}
            pageNumber={this.props.pageNumber}
            key={this.props.subKey + "B"}
          />
          {this.state._isMounted &&
            !this.props.hideInteractiveObjectsVal &&
            !this.props.settings.pageZoomPartShowLoading &&
            this.renderInteractiveButtons(data)}
          {this.state._isMounted &&
            !this.props.annotationSelectionMode &&
            this.props.pageZoomPartMode !== "zoom-show" &&
            this.renderBookmarkIcon(data)}
          {this.state._isMounted &&
            this.props.settings.addInteractivityMode === "init" &&
            this.props.settings.addInteractivityType !== "qhideable" && (
              <RenderTempInteractivityButtons />
            )}
        </li>
        <AddButton
          isOpen={this.state.modalIsOpen}
          toggleModal={(e) => this.toggleModal(e)}
        />
        <ShowMCQuestionForm
          isOpen={this.state.modalIsOpen}
          toggleModal={(e) => this.toggleModal(e)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPageNumber: state.pages.currentPageNo,
    // currentPageId: state.pages.currentPage[0].id,
    currentPageId: selectCurrentPageId(state),
    currentPageState: state.pages,
    scaleZoomFactorVal: state.scaleZoomFactor,
    imagesLoaded: state.imagesLoaded,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    canvasVals: state.scaleZoomFactor.canvas,
    domain: state.login.user_profile.domain_name,
    moveButtonsVal: state.settings.moveButtons,
    sideBarOpened: state.sideBarOpened,
    backgroundImageOpacity: state.settings.backgroundImageOpacity,
    selectedTool: state.selectedTool.selectedTool,
    // captured: state.captured,
    settings: state.settings,
    searchBarOpened: state.searchBarOpen,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    annotationIdToShow: state.annotationSelections.annotationIdToShow,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    userId: selectUserId(state),
    userHasAnyAdminRole: selectUserHasAnyAdminRole(state),
    clearKeepCoords: state.pages.clearKeepCoords,
    theme: state.theme,
    // backgroundImageOpacity: state.settings.backgroundImageOpacity,
    domain: state.login.user_profile.domain_name,
    userCanEditActivity: state.login.user_profile.userCanEditActivity,

    // temp: state.temp,
  };
};

export default connect(
  mapStateToProps,
  {
    setScalePageDown,
    setScalePageReset,
    setScalePageUp,
    pageNoChange,
    toggleCapture,
    mcQuestionModalOpen,
    mcQuestionModalClose,
    triggerZoomIn,
    triggerZoomOut,
    toggleSideBar,
    toggleSearchBar,
    showAnnotationsById,
    setPagePartZoomMode,
    setPagePartZoomObjectToShow,
    updateActivity,
    toaster,
    deletePageActivityKeepCoordsByPage,
    setClearKeepCoords,
    setBackgroundImageOpacity,
    editActivity,
    addButtonModalOpen,
    deleteActivity,
  },
  null,
  { forwardRef: true }
)(Page);

// const { data } = useSelector((status) => status);
// return <div>TitleBox</div>;

const Component2 = (data) => {
  return <div className="m-1">{renderModalData(data.data)}</div>;
};
