import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { pageNoChange } from "../actions/pages";
import NextZoomPagePartButton from "./SketchSelectedButtons/NextZoomPagePartButton";
import PreviousZoomPagePartButton from "./SketchSelectedButtons/PreviousZoomPagePartButton";
import ExitButton from "./SketchSelectedButtons/ExitButton";
import ApprovZoomPagePartButtons from "./SketchSelectedButtons/ApprovZoomPagePartButtons";
import CutPagePartButton from "./SketchSelectedButtons/CutPagePartButton";
import { showInteractiveObjects } from "../actions/interactiveObjects";
import { toaster } from "../actions/showToast";
import {
  setAnnotationSelectionMode,
  resetAnnotationSelectionMode,
} from "../actions/annotationSelection";
import {
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  setPagePartZoomObject,
  setBackgroundImageOpacity,
} from "../actions/settings";
// import "react-toastify/dist/ReactToastify.css";
import NextPageButton from "./SketchSelectedButtons/NextPageButton.js";
import PreviousPageButton from "./SketchSelectedButtons/PreviousPageButton";
import ZoomAnswerBottomButton from "./SketchSelectedButtons/ZoomAnswerBottomButton";
import ZoomAnswerVideoBottomButton from "./SketchSelectedButtons/ZoomAnswerVideoBottomButton";
import NewModal from "./NewModal";
import {
  selectCurrentPageId,
  selectCurrentPageWithMyContent,
  selectZoomAnswers,
  selectZooms,
  selectZoomVideoAnswers,
} from "../store/configureStore";
import { WinboxContext } from "./WinBoxWrapper";
import { renderModalData } from "./ModalButtons/RenderButtons";
import { isMobile } from "react-device-detect";

const BottomMenu = (props) => {
  const context = useContext(WinboxContext);

  const exitHandler = () => {
    props.setPagePartZoomObject(
      null,
      { x: null, y: null },
      { x: null, y: null }
    );
    exitZoomMode(props);
  };
  const approveHandler = async () => {
    await props.saveZoomButton();
    exitZoomMode(props);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    setModalOpen(false);
    setModalData(null);
  }, []);
  useEffect(() => {
    setModalOpen(false);
    setModalData(null);
  }, [props.currentPageNumber, props.pageZoomPartObjectToShow]);

  const { t, i18n } = useTranslation();
  if (!props.currentPage[0] || !props.currentPage[0]?.buttons) return <></>;

  const qZoomButtons = props.zooms;
  const zoomAnswerButtons = props.zoomAnswers;
  const zoomVideoAnswerButtons = props.zoomVideoAnswers;
  const currentZoomObjectOrderId = parseInt(
    props.pageZoomPartObjectToShow.orderId
  );
  let currentZoomButtonId =
    qZoomButtons &&
    qZoomButtons.findIndex((qz) => qz.orderId === currentZoomObjectOrderId);

  // console.log(
  //   "!!currentZoomButtonId: ",
  //   currentZoomButtonId,
  //   currentZoomObjectOrderId,
  //   props.currentPageNumber
  // );
  const showNextButton =
    props.pageZoomPartMode === "zoom-show"
      ? currentZoomButtonId <= qZoomButtons.length - 1 ||
        props.currentPageNumber < props.totalPageNumber
      : false;
  const showPrevButton =
    props.pageZoomPartMode === "zoom-show"
      ? currentZoomButtonId !== 0 || props.currentPageNumber > 1
      : false;

  const { pageZoomPartShowLoading: isLoading } = props.settings;
  // const zoomAnswerButton = currentZoomObjectOrderId
  //   ? zoomAnswerButtons[currentZoomObjectOrderId - 1]
  //   : null;
  const callWinBoxHandler = (_modalData) => {
    context.CallWinBox({
      childComp: <Component2 data={_modalData} />,
      classNames: ["no-full", "my-theme-" + props.theme],
      title: _modalData.description || "",

      // minheight:  _modalData.button_type === "video" ? 100 : 100,
      minwidth: 250,
      handler1: toggleBackgroundOpacity,
      itemId: _modalData,
      // editHandler: showEdit ? this.winBoxEditHandler : undefined,
      // sunHandler: winBoxEditHandler,
      currentPageId: !isMobile ? props.currentPageId : undefined,
      closeHelperFunc: resetBackgroundOpacity,
      // submitHandler:this.winBoxEditHandler,
      // deleteHandler: showDelete ? this.deleteContent : undefined,
      winboxX: isMobile ? "0px" : "center",
      // winboxY: "top",
    });
  };
  const resetBackgroundOpacity = () => {
    // alert('resetBackgroundOpacity')
    if (props.backgroundImageOpacity !== 1) {
      props.setBackgroundImageOpacity(1);
    }
  };
  const zoomAnswerButton =
    currentZoomButtonId > -1
      ? props.zoomAnswers?.filter((btn) => {
          const zoomBtn = qZoomButtons[currentZoomButtonId];
          // console.log("btn: ", btn.zoomId, zoomBtn.id);
          // console.log("zoomBtn: ", btn.zoomId === zoomBtn.id);
          return btn.zoomId === zoomBtn.id;
        })[0]
      : null;
  const zoomVideoAnswerButton =
    currentZoomButtonId > -1
      ? zoomVideoAnswerButtons?.filter((btn) => {
          const zoomBtn = qZoomButtons[currentZoomButtonId];
          // console.log("btn: ", btn.zoomId, zoomBtn.id);
          // console.log("zoomBtn: ", btn.zoomId === zoomBtn.id);
          return btn.zoomId === zoomBtn.id;
        })[0]
      : null;
  // const zoomVideoAnswerButton = currentZoomObjectOrderId
  //   ? zoomVideoAnswerButtons[currentZoomObjectOrderId - 1]
  //   : null;

  const winBoxEditHandler = (data) => {
    // console.log("data: ", data);
    const winBoxData = data[0];
    const winBoxReference = data[1];
    // if (data[2] == "edit") {
    //   console.log({ modalIsOpen: true, modalData: winBoxData });
    //   this.setState({ modalIsOpen: true, modalData: winBoxData });
    //   this.props.editActivity(
    //     winBoxData.id,
    //     winBoxData.button_type,
    //     winBoxData.left,
    //     winBoxData.top,
    //     winBoxData.style,
    //     winBoxData.show
    //   );
    //   // addButtonModalOpen
    //   this.props.addButtonModalOpen();
    // }
    if (data[2] == "sun") {
      // console.log(winBoxData);
      // console.log("delete");
      toggleBackgroundOpacity();
      return;
    }
    // if (data[2] == "delete") {
    //   console.log(winBoxData);
    //   console.log("delete");
    // }
    // if (data[2] == "save") {
    //   console.log(winBoxData);
    //   console.log("save");
    // }
    winBoxReference && winBoxReference.close(true);
  };
  const toggleBackgroundOpacity = () => {
    // console.log('props.backgroundImageOpacity: ', props.backgroundImageOpacity);
    switch (props.backgroundImageOpacity) {
      case 0:
        props.setBackgroundImageOpacity(1);
        break;
      case 0.5:
        props.setBackgroundImageOpacity(0);
        alert('here')
        break;
      case 1:
        props.setBackgroundImageOpacity(0.5);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div
        className={`navbar is-fixed-bottom  bottom-annotation-menu ${
          props.shouldBottomMenuOpened && props.selectedTool !== "curtain"
            ? "open"
            : "hide"
        } `}
      >
        {props.pageZoomPartMode === "zoom-show" && (
          <>
            {/*  <PreviousPageButton
            onClickHandler={() =>
              !isLoading && props.pageNoChange(props.currentPageNumber - 1)
            }
            disabled={props.currentPageNumber === 1 || isLoading}
          /> */}

            <PreviousZoomPagePartButton
              onClickHandler={() => {
                if (!isLoading) {
                  if (currentZoomButtonId <= 0) {
                    props.pageNoChange(parseInt(props.currentPageNumber) - 1);
                  } else {
                    zoomNavigationButtonHandler(
                      qZoomButtons,
                      currentZoomButtonId - 1,
                      props
                    );
                  }
                }
              }}
              disabled={!showPrevButton || isLoading}
            />
            {zoomAnswerButton && (
              <ZoomAnswerBottomButton
                onClickHandler={() =>
                  props.toaster(
                    "Doğru Cevap: " +
                      String(zoomAnswerButton.answer).toLocaleUpperCase(),
                    "warning",
                    3000,
                    true,
                    "center",
                    true
                  )
                }
              />
            )}
            {zoomVideoAnswerButton && (
              <ZoomAnswerVideoBottomButton
                onClickHandler={() => {
                  // setModalData({
                  //   button_type: "zoomVideoAnswer",
                  //   content: zoomVideoAnswerButton.answer,
                  //   label: "",
                  // });
                  callWinBoxHandler({
                    button_type: "zoomVideoAnswer",
                    content: zoomVideoAnswerButton.answer,
                    label: "",
                    description:zoomVideoAnswerButton.description
                  });
                }}
              />
            )}
            <NextZoomPagePartButton
              onClickHandler={() => {
                if (!isLoading) {
                  if (currentZoomButtonId >= qZoomButtons.length - 1) {
                    props.pageNoChange(parseInt(props.currentPageNumber) + 1);
                  } else {
                    zoomNavigationButtonHandler(
                      qZoomButtons,
                      currentZoomButtonId + 1,
                      props
                    );
                  }
                }
              }}
              disabled={!showNextButton || isLoading}
            />
            {/*  <NextPageButton
            onClickHandler={() =>
              !isLoading && props.pageNoChange(props.currentPageNumber + 1)
            }
            disabled={
              props.currentPageNumber === props.totalPageNumber || isLoading
            }
          /> */}
          </>
        )}

        <ExitButton onClickHandler={exitHandler} />
        {props.annotationSelectionMode && (
          <ApprovZoomPagePartButtons
            onClickHandler={() => {
              props.setAnnotationSelectionMode(false);
            }}
          />
        )}
        {props.pageZoomPartMode === "zoomed" && (
          <>
            <CutPagePartButton />
            <ApprovZoomPagePartButtons onClickHandler={approveHandler} />
          </>
        )}
      </div>
      {/* {modalOpen && props.shouldBottomMenuOpened && (
        <NewModal
          isOpen={modalOpen}
          toggleModal={(e) => setModalOpen(!modalOpen)}
          data={modalData}
        />
      )} */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    pages: state.pages.pages,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageId: selectCurrentPageId(state),
    currentPageNumber: state.pages.currentPageNo,
    // currentPage: state.pages.currentPage,
    currentPage: selectCurrentPageWithMyContent(state),
    selectedTool: state.selectedTool.selectedTool,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    settings: state.settings,
    // zooms: state.pages.zooms,
    zooms: selectZooms(state),
    zoomAnswers: selectZoomAnswers(state),
    zoomVideoAnswers: selectZoomVideoAnswers(state),
    backgroundImageOpacity: state.settings.backgroundImageOpacity,
  };
};

export default connect(mapStateToProps, {
  pageNoChange,
  setAnnotationSelectionMode,
  resetAnnotationSelectionMode,
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  setPagePartZoomObject,
  showInteractiveObjects,
  toaster,
  setBackgroundImageOpacity,
})(BottomMenu);

function zoomNavigationButtonHandler(qZoomButtons, targetZoomButtonId, props) {
  try {
    const {
      orderId,
      downCoords,
      upCoords,
      cutCoords,
      cutCoordsUp,
    } = qZoomButtons[targetZoomButtonId];

    props.setPagePartZoomObjectToShow(
      orderId,
      downCoords,
      upCoords,
      cutCoords,
      cutCoordsUp
    );
  } catch (error) {}
}

function exitZoomMode(props) {
  if (props.pageZoomPartMode) {
    props.setPagePartZoomMode(null);
    props.setPagePartZoomObjectToShow(
      null,
      { x: null, y: null },
      { x: null, y: null },
      { x: null, y: null },
      { x: null, y: null }
    );
  } else {
    props.resetAnnotationSelectionMode();
    props.showInteractiveObjects();
  }
}
const Component2 = (data) => {
  return <div className="m-1">{renderModalData(data.data)}</div>;
};
