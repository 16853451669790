import React from "react";
import {
  // HiXCircle,
  BiLink,
  // MdSkipPrevious,
  // MdExitToApp
} from "react-icons/bi";
import { useTranslation } from "react-i18next";

export default function LinkMargins(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button  is-small modal-button ${
          props.disabled ? "button-disabled" : ""
        } ${
          props.shouldShowButtonLabels ? " show-label-top-menu " : ""
          }`}
        onClick={props.onClickHandler}
        title={t("Link")}
      >
        <span className="icon icon-tema-color is-small has-text-light">
          <BiLink className="fas fa-2x" />
        </span>
        {props.shouldShowButtonLabels && <span className=" mr-1 show-label-text">{t("Link")}</span>}
      </button>
    </>
  );
}
