import {GET_WIKIPEDIA,CLOSE_WIKIPEDIA} from '../actions/actionTypes'

export default (state = false, action) => {
    switch (action.type) {

        case GET_WIKIPEDIA:
            return true;

        case CLOSE_WIKIPEDIA:
            return false;

        default:
            return state;
    }
};