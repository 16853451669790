export default {
  Line: "line",
  Highlighter: "highlighter",
  Circle: "circle",
  Arrow: "arrow",
  Pencil: "pencil",
  Rectangle: "rectangle",
  Select: "select",
  Pan: "pan",
  HardDrag:'harddrag',
  Text: "text",
  EmptyRectangle: "emptysquare",
  EmptyCircle: "emptycircle",
  Capture: "capture",
  Curtain: "curtain",
  AddNote: "addnote",
  AddVideo: "addvideo",
  AddH5p: "addh5p",
  AddAudio: "addaudio",
  AddImage: "addimage",
  AddLink: "addlink",
  AddMCQuestion: "addmcquestion",
  AddMRQuestion: "addmrquestion",
  QHideable: "qhideable",
  AddZoom: "addzoom",
  QShowable: "qshowable",
  CaptureZoom: "capturezoom",
  ZoomArea: "zoomarea",
  BatchZoomArea: "batchzoomarea",
  Eraser: "eraser",
};
