/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Thumbnail extends Component {
  constructor() {
    super();
    this.state = {
      imageIsReady: false,
    };
  }

  onLoadImage = () => {
    this.setState({ imageIsReady: true });
  };

  render() {
    const { pageNumber, data } = this.props;
    // console.log('this.props.data', this.props.data)
    // let itemId = `${data.ebook_id}_thumbnail_${data.id}`;
    // const pageThumbnail = localStorage.getItem(itemId);
    // console.log("pageThumbnail", itemId, pageThumbnail);
    return (
      <li
        onClick={() => this.props.onClick(data.pageNumber)}
        className={`pdf-thumbnail ${
          pageNumber == data.pageNumber && this.state.imageIsReady
            ? "active"
            : ""
        }`}
      >
        <Link to={`${data.pageNumber}`} className="pdf-thumbnail-link">
          <figure className="image">
            <img
              src={
                // localStorage.getItem(`${data.ebook_id}_thumbnail_${data.id}`) || data.thumbnail || require("../assets/img/thumbnail.jpg")}
                data.thumbnail
                  ? data.thumbnail
                  : localStorage.getItem(
                      `${data.ebook_id}_thumbnail_${data.id}`
                    ) || require("../assets/img/thumbnail.jpg")
              }
              alt=""
              style={{ display: !this.state.imageIsReady ? "none" : "" }}
              onLoad={this.onLoadImage}
            />
          </figure>

          {!this.state.imageIsReady ? (
            <div className="preloader-mini-frame">
              <div className="preloader-mini-icn">
                <div className="preloader-mini-cut">
                  <div className="preloader-mini-donut" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Link>
        <div className="thumbnail-page-num">{data.pageNumber}</div>
      </li>
    );
  }
}
