import React from "react";
import { useTranslation } from "react-i18next";

export default function ZoomModePrevButton(props) {
  const { t } = useTranslation();
  // console.log("props.hideButton",props.hideButton);
  return (

    <li onClick={!props.disabled? props.onClick : undefined}>
      <span
      disabled={props.disabled}
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        }  ${ props.hideButton ? "passive" : "" } ${
          props.activeButton === "select" ? "active" : ""
        }`}
        title={t("Go to Next Presentation") + "->"}
        data-tip={t("GGo to Next Presentation") + " ->"}
      >
        <img
          src={require("../../assets/img/svg/tools-zoom-mode-prev.svg")}
          alt={t("Go to Next Presentation") + "->"}
        />
         {props.shouldShowButtonLabels && <span className="show-label-text">{t("Prev")}</span>}
      </span>
    </li>
  );
}
