import React from "react";
import { BiMinusCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";

export default function TimerMinusButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`button is-small modal-button ${
          props.shouldShowButtonLabels ? " show-label-top-menu" : ""
        }`}
        onClick={props.onClickHandler}

      >
        <span className="icon is-small has-text-light">
          <BiMinusCircle className="fas fa-2x" />
        </span>

        {props.shouldShowButtonLabels && (
          <span className="show-label-text">{t("Subtract Minute")}</span>
        )}
      </button>
    </>
  );
}
