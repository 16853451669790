import { SHOW_TOAST } from "./actionTypes";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";

export function toastDark(message) {
  toast.dark(message);
  return function (dispatch) {
    dispatch({
      type: SHOW_TOAST,
    });
  };
}
export function toastWarning(message) {
  toast.warning(message);
  return function (dispatch) {
    dispatch({
      type: SHOW_TOAST,
    });
  };
}
export function clearToasts() {
  toast.dismiss();
  return function (dispatch) {
    dispatch({
      type: SHOW_TOAST,
    });
  };
}
export function toaster(
  message,
  type,
  duration = 5000,
  dismiss = false,
  position = "right",
  slide = false,
  toastId = null
) {
  dismiss && toast.dismiss();
  toast[type](message, {
    toastId,
    position:
      position === "right"
        ? toast.POSITION.TOP_RIGHT
        : toast.POSITION.TOP_CENTER,
    autoClose: duration,
    closeButton: false,
    transition: slide ? Flip : Zoom,
  });
  return function (dispatch) {
    dispatch({
      type: SHOW_TOAST,
    });
  };
}
