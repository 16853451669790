import {GET_WIKTIONARY,CLOSE_WIKTIONARY} from "./actionTypes";

export const getWiktionary = () => {
    return {
        type: GET_WIKTIONARY
    }
};


export const closeWiktionary = () => {
    return {
        type: CLOSE_WIKTIONARY
    }
};