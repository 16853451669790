import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toggleSideBar } from "../../actions/sidebar";
import { connect } from "react-redux";

const SidebarMenu = (props) => {
  const { t, i18n } = useTranslation();
  let collapseIconStyle = "collapse-icon";
  let collapseSidebarStyle = "";
  let corporationLinkStyle = "";
  let sidebarSpanStyle = "";
  // const collapseIconStyle='collapse-icon';
  if (props.toolbarSize === "1.25") {
    collapseIconStyle = "collapse-icon-1-25";
    collapseSidebarStyle = "collapse-sidebar-1-25";
    corporationLinkStyle = "corporation-link-1-25";
    sidebarSpanStyle = "sidebar-span-1-25";
  } else if (props.toolbarSize === "1.5") {
    collapseIconStyle = "collapse-icon-1-5";
    collapseSidebarStyle = "collapse-sidebar-1-5";
    corporationLinkStyle = "corporation-link-1-5";
    sidebarSpanStyle = "sidebar-span-1-5";
  }

  return (
    <div className={` corporation `}>
      <Link
        to="/library"
        className={`corporation-link is-pulled-left ${corporationLinkStyle}`}
        data-tip={t("Library")}
      >
        <div data-step="3" data-intro={t("intro_2")}>
          <figure className="sidebar-icon">
            {/* <img src={'http://cenibook.com/storage/' + this.props.icon} alt="Logo" /> */}
            <img
              // src={require("../assets/img/cenibook-logo.png")}
              src={require("../../assets/img/svg/library.svg")}
              alt={t("Library")}
              title={t("Library")}
            />
          </figure>
        </div>
        {!props.shouldShowButtonLabels && (
          <span className={`show-label-text sidebar-span ${sidebarSpanStyle}`}>{t("Library")}</span>
        )}
      </Link>

      <a
        className={`index-icon ${corporationLinkStyle}`}
        // href=""
        onClick={() => props.toggleModal()}
        data-tip={t("TOC")}
      >
        {/*INTRO JS STEP 2 */}
        <div data-step="2" data-intro={t("intro_2")}>
          <figure className={`sidebar-icon`}>
            <img
              src={require("../../assets/img/svg/sidebar-index.svg")}
              alt={t("TOC")}
              title={t("TOC")}
            />
          </figure>
        </div>
        {!props.shouldShowButtonLabels && (
          <span className={`show-label-text sidebar-span ${sidebarSpanStyle}`}>{t("TOC")}</span>
        )}
      </a>
      <button
        className={`${collapseSidebarStyle} collapse-sidebar is-pulled-right ${
          props.sidebarOpened ? "sidebar-close" : ""
        }`}
        onClick={() => props.toggleSideBar(props.sidebarOpened)}
        data-tip={props.sidebarOpened ? t("Close Sidebar") : t("Open Sidebar")}
      >
        <span className="sidebar-icon ">
          <img
            src={require("../../assets/img/svg/sidebar-collapse-left.svg")}
            alt={t("Collapse Sidebar")}
            className={collapseIconStyle}
            title={props.sidebarOpened ? t("Close Sidebar") : t("Open Sidebar")}
          />
        </span>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarOpened: state.sideBarOpened,
    toolbarSize: state.toolbar.toolbarSize,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
})(SidebarMenu);
