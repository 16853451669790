export default {
  translation: {
    "My IndiviBooks": "Kitaplarım",
    language: "TR",
    library: "Kütüphane",
    Welcome: "Hoşgeldiniz",
    Logout: "Çıkış",
    Loading: "Yükleniyor",
    intro_1:
      "Arama Çubuğu: Görselleri, tanımları ve diğer kaynakları arayın! Ayrıca önceden tanımlanmış kelime listesini burada görebilirsiniz.",
    intro_2:
      "Dizin: İçindekiler, Etkinlik Dizini, Yer İmleriniz ve Ek İşaretçiler burada!",
    intro_3:
      "Yeni özelliklere göz atın! Klavye kısayolları, Ekran alıntısı, Temalar, Gezinme düğmeleri ve daha fazlası!",
    "Interactive Objects": "Etkileşimli Nesneler",
    "Drawing Objects": "Çizim Nesneleri",
    "Load All Images": "Tüm Resimleri Yükle",
    Drag: "Taşı",
    "Drag the page": "Sayfayı Sürükle",
    "Zoom out": "Uzak",
    "Zoom in": "Yakın",
    "Full Screen": "Tam",
    "Draw Toolbar": "Çizim Araç Çubuğu",
    "Navigation Tools": "Gezinme Araçları",
    Capture: "Alan Seç",
    Curtain: "Perde",
    Settings: "Ayarlar",
    "Vertical Toolbar": "Dikey Araç Çubuğu",
    "Horizontal Toolbar": "Yatay Araç Çubuğu",
    Pen: "Kalem",
    Highlighter: "Fosforlu Kalem",
    "Clear All": "Tümünü Temizle",
    Eraser: "Silgi",
    Shapes: "Şekiller",
    Line: "Çizgi",
    "Empty Circle": "Boş Daire",
    Circle: "Daire",
    "Empty Rectangle": "Boş Dikdörtgen",
    Rectangle: "Dikdörtgen",
    "Line Size": "Çizgi Boyutu", // eklendi
    "Text and stroke color": "Metin ve Çizgi Rengi ",
    "Fill color": "Dolgu Rengi",
    Undo: "Geri Al",
    Redo: "İleri Al",
    Language: "Dil",
    Dark: "Koyu",
    Light: "Aydınlık",
    "Dark Blue": "Mavi",
    Green: "Yeşil",
    "Go to Previous Page": "Önceki Sayfaya Git",
    "Go to Next Page": "Sonraki Sayfaya Git",
    "Scroll to top of the page": "Sayfanın başına kaydırın",
    "Scroll to end of the page": "Sayfanın sonuna kaydırın",
    Erase: "Sil",
    "Select Drawing Objects": "Çizim Nesneleri Seçim Aracı",
    Layers: "Katmanlar",
    Theme: "Tema",
    "Change Toolbar Position": "Araç Çubuğunun Konumunu Değiştir",
    "Toolbar Pin": "Araç Çubuğu Sabitleyici",
    Text: "Metin",
    Search: "Arama",
    "Please enter email and password for login.":
      "Lütfen e-posta adresinizi ve şifrenizi girin.",
    "Sign in": "Kullanıcı Girişi",
    Login: "Giriş Yap",
    "If you experience a problem, please contact your domain administrator.":
      "Bir sorun yaşarsanız, lütfen alan yöneticinize başvurun.",
    Email: "E-posta",
    "Email is required": "E-posta gereklidir",
    "Please enter your email": "Lütfen e-posta adresinizi girin",
    "Please enter your password": "Lütfen şifrenizi girin",
    "Please enter IndiviBook Domain": "Lütfen IndiviBook alan adınızı girin",
    "IndiviBook domain is required": "IndiviBook alan adı gereklidir",
    "Password domain is required": "Şifre gereklidir",
    "Please enter a valid email, password and IndiviBook domain.":
      "Lütfen geçerli bir e-posta, şifre ve IndiviBook alan adı girin.",
    "Please check your login credentials.":
      "Lütfen giriş bilgilerinizi kontrol edin.",
    "Please fill both the email and password fields.":
      "Lütfen hem e-posta hem de şifre alanlarını doldurun.",
    "IndiviBook Domain": "IndiviBook Alan Adı",
    Page: "Sayfa",
    "My Bookmarks": "Ayraç (Yer İmlerim)",
    Note: "Not",
    Audio: "Ses",
    Video: "Video",
    Answer: "Cevap",
    Activity: "Etkinlik",
    Image: "Resim",
    "Open Sidebar": "Kenar Çubuğunu Aç",
    "Close Sidebar": "Kenar Çubuğunu Kapat",
    Library: "Kütüphane",
    Back: "Geri", // en de yok
    Next: "İleri", // en de yok
    Done: "Bitti", // en de yok
    Skip: "Geç", // yeni eklendi
    TOC: "İçindekiler", // en de yok
    Activities: "Etkinlikler", // en de yok
    "My Annotations": "Notlarım ve Çizimlerim", // en de yok
    Bookmark: "Yer İmi", // en de yok
    "Stroke Size": "Çizgi Boyutu", // en de yok
    Password: "Şifre", // en de yok,
    "Suggested Words": "Önerilen Kelimeler",
    "Search images from Pixabay": "Pixabay'ta görsel ara",
    "Search images from Unsplash": "Unsplash'ta görsel ara",
    "Search on Wikizero": "Wikizero'da ara",
    "Search on Wikipedia": "Wikipedia'da ara",
    "Search on Merriam Dictionary": "Merriam Sözlüğünde ara",
    "Search on Wiktionary": "Wiktionary'da ara",
    "Search on WordWeb": "WordWeb'da ara",
    "Search on PBS Learning Media": "PBS Learning Media'da ara",
    "Search on Google": "Google'da ara",
    "Search on Google Images": "Google görsellerde ara",

    "Activity Tools": "Aktivite Araçları",
    "Add Note": "Not Ekle",
    "Add Video": "Video Ekle",
    "Add Activity": "Aktivite Ekle",
    "Add Audio": "Ses Ekle",
    "Add Image": "Resim Ekle",
    "Question Tools": "Soru Araçları",
    "Add a Multiple Choice Question": "Çoktan Seçmeli Soru Ekleyin",
    "Add a Multiple Response Question": "Çoklu Seçmeli Soru Ekleyin",
    "True False": "Doğru Yanlış Sorusu Ekle",
    "Drag Drop": "Sürükle Bırak Sorusu Ekle",
    "Fill in the Blank": "Boşluk Doldurma Sorusu Ekle",
    "Next Page": "Sonraki Sayfa",
    "Previous Page": "Önceki Sayfa",
    "Close Search Bar": "Arama Bölmesini Kapat",
    "Open Search Bar": "Arama Bölmesini Aç",
    search: "Ara",
    "Movable Buttons": "Taşınabilir Butonlar",
    "You moved your own activity. Would you like to save the new position to the server?":
      "Kendi aktivitenizi taşıdınız. Yeni pozisyonu sunucuya kaydetmek ister misiniz?",
    Yes: "Evet",
    No: "Hayır",
    "Search images": "Görsellerde ara",
    "Search Video": "Video Ara",
    "Search Vector": "Vektör Ara",
    "Button Labels": "Düğme Adları",
    "Please enter your email, password and domain name.":
      "Lütfen e-postanızı, şifrenizi ve alan adınızı girin.",
    "Click a point to add a zoomed page part":
      "Sunum Modu için bölge eklemek için seçim yapabilirsiniz.",
    "Link Name": "Bağlantı Adı",
    "Advanced Mode": "Gelişmiş Arayüz",
    Prev: "Geri",
    "Teacher Tools": "Öğretmen Araçları",
    "Zoom Tools": "Zoom Araçları",
    "Add Hideable Answer": "Gizlenebilir Cevap Ekle",
    "This object can't be inserted.": "Bu nesne eklenemez.",
    Submit: "Tamam",
    Cancel: "Vazgeç",
    "Upload audio": "Ses Yükle",
    Public: "Herkese Açık",
    Private: "Bana Özel",
    Top: "Üst",
    Left: "Sol",
    audio: "Ses",
    "Upload image": "Resim Yükle",
    image: "Resim",
    "Find an image": "Resim Arayın",
    Style: "Stil",
    Link: "Bağlantı",
    "Please enter a valid link like": "Lütfen gibi geçerli bir bağlantı girin",
    "Video Link (Youtube, Vimeo, Facebook, Twitch,SoundCloud, Streamable, Wistia, DailyMotion)":
      "Video Bağlantı Adres Çeşitleri; Youtube, Vimeo, Facebook, Twitch,SoundCloud, Streamable, Wistia, DailyMotion",
    "Video Link": "Video Bağlantı",
    text: "Yazı",
    link: "Bağlantı",

    "Click a point to add a button": "Eklemek için bir yere tıklayabilirsiniz",

    Select: "Seç",
    Scroll: "Kaydır",
    "Content is loading": "Content is loading",
    "Delete Activity": "Aktiviteyi Sil",
    "Button Name": "Buton Adı",
    "Caps Lock On": "Büyük Harf Açık",
    "Group Selection": "Grup Seç",
    "UnGroup Selection": "Grubu Çöz",
    "Duplicate Selection": "Seçili Nesneyi Çoğalt",
    "Toolbar Size": "Araç Kutusu",
    "Brightness Settings": "Parlaklık Ayarları",
    "Page Brightness": "Sayfa Parlaklığı",
    "Page Contrast": "Sayfa Kontrastı",
    Reset: "Normal",
    Timer: "Sayaç",
    Exit: "Çıkış",
    Presentation: "Sunum",
    "Presentation Mode": "Sunum Modu",
    Full: "Tam",
    Highlight: "Marker",
    Cut: "Kes",
    Confirm: "Onayla",
    "Open/Close": "Aç-Kapa",
    "Add Hide Area": "Gizlenebilir Bölge Ekle",
    "Add Blank Page": "Boş Sayfa Ekle",
    "Add Presentation Mode": "Sunum Modu Ekle",
    Clear: "Temizle",
    Size: "Boyut",
    "Login with Google": "Google ile Giriş Yapın",

    "You have no indivibooks.": "Kitabınız Bulunmamaktadır",
    "IndiviBook Portable": "Indivibook Taşınabilir",
    "No internet connection.": "İnternet Bağlantınız Yok.",
    "company-logo": "Logo",
    Attachment: "Ek Dosya",
    Color: "Renk",
    Filling: "Dolgu",
    Toolbar: "Araç Kutusu",
    Drawing: "Çizim",
    Simple: "Basit",
    Horizontal: "Yatay",
    Vertical: "Dikey",
    Close: "Kapat",
    Content: "İçerik",
    Tools: "Araçlar",
    Hide: "Gizle",
    Show: "Göster",
    "Change Toolbar Mode": "Araç Kutusu Görünümünü Değiştir",
    Advanced: "Gelişmiş",
    Group: "Grupla",
    Ungroup: "Grubu Çöz",
    Duplicate: "Çoğalt",
    Delete: "Sil",
    "Are you sure you want to delete this content":
      "Bu içeriği silmek istediğinizden emin misiniz?",

    "Hide Margins": "Kenarlıkları Gizle",
    "Show Margins": "Kenarlıkları Göster",
    Unlink: "Ayır",
    "Add Margin": "Kenarlık Ekle",
    "Can't link. Only 2 objects are allowed.":
      "Birleştirmek için en az 2 nesne seçiniz.",
    "You switched to the Presentation Mode": "Sunum Moduna Geçtiniz",
    "Editor Mode": "Editor Modu",
    "Are you sure you want to delete this content?":
      "Bu içeriği silmek istediğinizden emin misiniz?",
    "Are you sure you want to delete all the activities in this page?":
      "Bu sayfadaki tüm etkinlikleri silmek istediğinizden emin misiniz?",
    "Delete All Activities": "Tüm Aktiviteleri Sil",
    "You can not group these objects!": "Bu nesneleri gruplayamazsınız",
    "Select a question area to add annotations.":
      "Gizlenebilir Cevap, Yazı vs. Ekleyebilirsiniz.",

    "Add Zoom Area": "Sunum Ekle",
    "Add Hidden Area": "Aç-Kapa Ekle",
    "Show/Hide Zoom Areas": "Sunumları Göster/Gizle",
    "Hide Zoom Areas": "Sunumları Gizle",
    "Show Zoom Areas": "Sunumları Göster",
    "Show Answer": "Cevabı Göster",
    "Answer Video": "Cevap Videosu",
    "Can't copy multiple objects. If you want to copy multiple objects, please group the objects and then try again.":
      "Birden çok nesne kopyalanamaz. Birden çok nesneyi kopyalamak istiyorsanız, lütfen nesneleri gruplandırın ve tekrar deneyin.",
    "You can use hotkeys after exiting the current action.":
      "Şu anki işlemden çıktıktan sonra kısayol tuşlarını kullanabilirsiniz.",
    "Subtract Minute": "Azalt",
    "Add Minute": "Artır",
    "Warning! You do not have an internet connection!":
      "İnternet bağlantınız bulunmamaktadır.",
    "My Favorites": "Favorilerim",
    "Select a course": "Ders Filtresi",
    "Clear the Filter": "Filtreyi Temizle",
    or: "veya",
    "Sign Up": "Kullanıcı Kaydı",
    "Server Error. Please try again later.":
      "Sunucu hatası. Lütfen bir müddet sonra tekrar deneyiniz.",
    "Don't you have an account?": "Hesabınız yok mu?",
    "Do you have an account?": "Hesabınız var mı?",
    "Sign up for free!": "Ücretsiz Kayıt Olun",
    "Log In": "Giriş Yapın",
    Register: "Kayıt Ol",
    "Phone Number": "Cep Telefonu",
    "Course Group": "Ders Grubu",
    Name: "Ad",
    "SMS Verification Code": "Cep Telefonu Doğrulama Kodu",
    "Please enter verification code": "Lütfen doğrulama kodunu giriniz",
    "Please enter your phone number": "Lütfen cep telefonu numaranızı giriniz",
    "Please enter your name": "Lütfen adınızı giriniz",
    "Select a course group": "Bir ders grubu seçin",
    "email is a required field": "Email alanı gereklidir",
    "email must be a valid email": "Email geçerli bir e-posta olmalıdır",
    "phone is a required field": "Telefon alanı gereklidir",
    "name is a required field": "Ad alanı gereklidir",
    "password is a required field": "Şifre alanı gereklidir",
    "groupId is a required field": "Ders Grubu alanı gereklidir",
    "We will send a pin code to your phone for confirmation":
      "Telefonunuza doğrulama kodu göndereceğiz",
    "Didn't you have received a code?": "Telefonunuza kod gelmedi mi?",
    "Send the code again": "Kodu tekrar gönder",
    Hello: "Merhaba",
    "Your phone is not verified": "Telefon numaranız doğrulanmamış",
    "Sign Out": "Çıkış Yap",
    "Password is too short - should be 8 chars minimum.":"Şifreniz en az 8 karakter olmalı",
    "Verify":"Doğrula",
    "You have no favorites":"Hiç favoriniz yok",
    "Search Book Title":"Kitap Arayabilirsiniz",
    "Blank Page":"Boş Sayfa",
  },
};
