import React, { Component } from "react";
import { connect } from "react-redux";
import {  Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactAudioPlayer from "react-audio-player";
import Draggable, { DraggableCore } from "react-draggable";
import MCQuestion from "./Quiz/MCQuestion";
import MRQuestion from "./Quiz/MRQuestion";
import { withTranslation } from "react-i18next";

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.h5p = React.createRef();
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
    console.log("Hide spinner");
  };
  showSpinner = () => {
    this.setState({
      loading: true,
    });
    // setTimeout(() => {
    //   this.hideSpinner();
    // }, 5000);
    console.log("Show spinner");
  };

  // componentDidMount() {
  //   // document.querySelectorAll('iframe').addEventListener('load',()=>{
  //   //     console.log()
  //   // });
  //   // //this.iframe.getDOMNode().addEventListener("load", this.hideSpinner);
  //   // var dom = ReactDOM.findDOMNode(this);
  //   // //dom.addEventListener("load", this.hideSpinner);
  //   // console.log(dom);
  // }

  renderModalData(data) {
    // console.log(data.button_type);
    switch (data.button_type) {
      case "video":
        return (
          <ModalBody style={{ minHeight: 400 }}>
            {/* <Spinner
              className="loading text-center divCentered"
              name="three-bounce"
              color="black"
              fadeIn="none"
            /> */}

            <div
              className="interactive-video-player"
              dangerouslySetInnerHTML={this.iframe(data.content)}
            />
          </ModalBody>
        );

      case "videoUrl":
        return (
          <ModalBody style={{ minHeight: 400 }}>
            {/* <Spinner
                className="loading text-center divCentered"
                name="three-bounce"
                color="black"
                fadeIn="none"
              /> */}

            <video controls>
              <source src={data.content} type="video/mp4" />
            </video>
          </ModalBody>
        );

      case "h5p":
        let myData = this.iframe(data.content);
        // console.log(myData.__html);
        var s = myData.__html;
        var temp = document.createElement("div");
        temp.innerHTML = s;
        const h5pMinHeight = parseInt(temp.firstChild.getAttribute("height"));
        // console.log(h5pMinHeight);
        return (
          <ModalBody style={{ minHeight: h5pMinHeight }}>
            <div className="loadingText">Content is loading ...</div>
            <div
              className="interactive-h5p-player"
              dangerouslySetInnerHTML={myData}
            />
          </ModalBody>
        );

      case "h5p-answer":
        let myData2 = this.iframe(data.content);
        var s2 = myData2.__html;
        var temp2 = document.createElement("div");
        temp2.innerHTML = s2;
        const h5pMinHeight2 = parseInt(temp2.firstChild.getAttribute("height"));
        return (
          <ModalBody style={{ minHeight: h5pMinHeight2 }}>
            <div className="loadingText">Content is loading ...</div>
            <div
              className="interactive-h5p-player"
              dangerouslySetInnerHTML={myData2}
            />
          </ModalBody>
        );

      case "audio":
        return (
          <div className="audioPlayer">
            <ReactAudioPlayer
              controls
              style={{ width: "100%" }}
              src={data.content}
            />
          </div>
        );

      case "image":
        return (
          <ModalBody>
            {/* {this.state.loading ? (
              <Spinner
                className="loading text-center divCentered"
                name="three-bounce"
                color="black"
                fadeIn="none"
              />
            ) : (
              ""
            )} */}
            <img
              src={data.content}
              alt={data.label}
              onLoad={this.hideSpinner}
            />
          </ModalBody>
        );

      case "link":
        return (
          <ModalBody style={{ minHeight: 430 }}>
            <div className="interactive-link-player">
              <iframe
                src={data.content}
                title="Link"
                onLoad={this.hideSpinner}
              ></iframe>
            </div>
          </ModalBody>
        );

      case "text":
        // return <ModalBody>{data.content}</ModalBody>;

        return (
          <ModalBody>
            <p
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          </ModalBody>
        );
      case "component":
        //
        return <ModalBody>{data.content}</ModalBody>;

      case "answer":
        // return <ModalBody>{data.content}</ModalBody>;

        return (
          <ModalBody>
            <p
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          </ModalBody>
        );
      case "mc_question": //multiple choice question
        console.log("!!!!!!!------MCQ!");
        return (
          <ModalBody>
            <MCQuestion data={data} />
          </ModalBody>
        );
      case "mr_question": //multiple Response question
        console.log("!!!-------MRQuestion");
        return (
          <ModalBody>
            <MCQuestion data={data} />
          </ModalBody>
        );

      default:
        return <ModalBody>{data.content}</ModalBody>;
    }
  }

  iframe = (iframe) => {
    return {
      __html: iframe,
      // onLoad: this.hideSpinner
    };
  };

  render() {
    const data = this.props.data;
    const externalCloseBtn = <button className="newdeneme" style={{ position: 'absolute', top: '15px', right: '15px' }}>&times;</button>;

    return (
      <Draggable
        handle=".modal-header"
        disabled={
          data.button_type === "h5p" ||
          data.button_type === "link" ||
          data.button_type === "h5p-answer"
        }
        axis={"both"}
        // bounds={{ left: 10, top: 10, right: 10, bottom: 10 }}
        // bounds=".modal"
      >
        <Modal
          centered={false}
          className={
            data.button_type === "text" ? "modal-theme-auto" : "modal-theme"
          }
          data-theme={this.props.theme}
          isOpen={this.props.isOpen}
          toggle={this.props.toggleModal}
          backdrop={"static"}
          keyboard={true}
          scrollable={false}
          backdropClassName="backdrop"
          // external={externalCloseBtn}
        >
          <ModalHeader
            className="modal-title text-white"
            toggle={() => {
              this.props.toggleModal();
            }}
          >
            <button
              type="button"
              draggable="false"
              className="close"
              aria-label="Close"
              disabled
            >
              <img
                src={require("../assets/img/svg/toolbar-drag.svg")}
                alt="Drag"
                draggable="false"
                className="non-margin"
                aria-hidden="true"
                style={
                  data.button_type === "h5p" || data.button_type === "link"
                    ? { display: "none" }
                    : { display: "auto" }
                }
              />{" "}
              <span className="custom-modal-title">
                {data.button_type === "component" && this.props.activityTitle}
              </span>
            </button>
            {/* <button
              type="button"
              draggable="false"
              className="modal-button-light"
              aria-label="Close"
              disabled
            >
              <img
                src={require("../assets/img/light-bulb.svg")}
                alt="Drag"
                draggable="false"
                className="non-margin"
                aria-hidden="true"
                style={
                  data.button_type === "h5p" || data.button_type === "link"
                    ? { display: "none" }
                    : { display: "auto" }
                }
              />{" "}
              <span className="custom-modal-title">
                {data.button_type === "component" && this.props.activityTitle}
              </span>
            </button> */}
          </ModalHeader>
          {this.renderModalData(data)}
          {/* <ModalFooter>
            <Button color="danger" onClick={this.props.toggleModal}>
              Close
            </Button>
          </ModalFooter> */}
        </Modal>
      </Draggable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activityTitle: state.activities.activityTitle,
    theme: state.theme,
  };
};

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withTranslation()(CustomModal));
