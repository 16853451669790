import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function AddZoom(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickAddZoom = () => {
    // console.log("--Add a note..", props);
    !props.hideButton && props.onClick();
    dispatch(toaster(t("Click a point to add a zoomed page part"), "warning", 3000));
    // props.changeMyTool("addzoom");

  };
  return (
    <li onClick={onClickAddZoom}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "addzoom" ? "active" : ""
        }`}
        title={t("Add Presentation Mode")}
        data-tip={t("Add Presentation Mode")}
      >
        <img
          src={require("../../assets/img/svg/tools-zoom.svg")}
          alt={t("Add Presentation Mode")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Presentation")}</span>}
      </span>
    </li>
  );
}
