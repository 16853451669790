import i18n from "../i18n";

export const confirmMessages = {
  title: i18n.t("Confirm"),
  message: i18n.t(
    "You moved your own activity. Would you like to save the new position to the server?"
  ),
  deleteActivity: i18n.t("Delete Activity"),
  deleteActivityYes: i18n.t("Yes"),
  deleteActivityNo: i18n.t("No"),
  deleteActivityConfirm: i18n.t("Are you sure you want to delete this content?"),
};
export const blankPageLabel = i18n.t(
  "Blank Page"
);
export const nothingSelectedMessage = i18n.t(
  "Please select an object first, then press this button to delete the selected object."
);
export const cantCopyMessage = i18n.t(
  "Can't copy multiple objects. If you want to copy multiple objects, please group the objects and then try again."
);
export const cantDeleteMessage = i18n.t("This object can't be deleted.");
export const cantInsertMessage = i18n.t("This object can't be inserted.");
export const cantLinkMessage = i18n.t(
  "Can't link. Only 2 objects are allowed."
);
export const cantInitModeKeyMessage = i18n.t(
  "You can use hotkeys after exiting the current action."
);
export const eraserAlertMessage = i18n.t(
  "You can release mouse to use eraser."
);
export const eraserAlertMessage2 = i18n.t(
  "Switched to eraser"
);

export const ANS_OPTIONS = ["A", "B", "C", "D", "E", "F", "G"];

export const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    ["link", "image", "video", "formula"],
    [{ align: [] }],
    ["clean"],
  ],
};

export const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "script",
  "align",
  "background",
  "video",
  "formula",
];




