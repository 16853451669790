import React from "react";
import { useTranslation } from "react-i18next";

export default function VideoButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  // console.log("----object",object);
  return (
    <div
      className="interactive-object"
      style={{ transform, "transformOrigin": "left top" }}
      >
        <span className="object-icon">
          <svg
            width={object.width} // width={object.width}
            height={object.height}
            viewBox={`0 0 ${object.width} ${object.height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx={object.width / 2}
              cy={object.height / 2}
              r={object.width / 2}
              fill="white"
              stroke="#FD6D87"
              strokeWidth="0" // Was 2 = Mustafa changed to 0
            />
            {(object?.style?.includes("styleinfo")) ?
              <path d="M14.1148 4C15.28 4 15.8626 4.7296 15.8626 5.5656C15.8626 6.6096 14.8504 7.5752 13.533 7.5752C12.4296 7.5752 11.7861 6.9752 11.8165 5.9832C11.8165 5.1488 12.5826 4 14.1148 4ZM10.5296 20C9.60956 20 8.93565 19.4784 9.57913 17.1808L10.6348 13.1072C10.8183 12.456 10.8487 12.1944 10.6348 12.1944C10.3591 12.1944 9.16609 12.644 8.45913 13.088L8 12.384C10.2365 10.6352 12.8096 9.6104 13.9139 9.6104C14.833 9.6104 14.9861 10.6288 14.527 12.1944L13.3174 16.476C13.1035 17.232 13.1948 17.4928 13.4096 17.4928C13.6852 17.4928 14.5896 17.1792 15.4783 16.5272L16 17.1784C13.8243 19.216 11.4478 20 10.5296 20Z" fill="white">
              </path>:
              <path
                d="M9 16.8889V6.97899C9 6.15885 9.93293 5.68743 10.5932 6.17394L16.9638 10.8681C17.4892 11.2552 17.509 12.0335 17.0041 12.4469L10.6335 17.6626C9.98061 18.1972 9 17.7327 9 16.8889Z"
                fill="#FD6D87"
              />
            }

          </svg>
        </span>
        <span className="object-text">{(!object.description || object.description===undefined || object.description==='undefined')? t("Video") :object.description}</span>
      </div>
  );
}

