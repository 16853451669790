import React from "react";
import { useTranslation } from "react-i18next";

export default function ChangeLanguage(props) {
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (e) => {
    localStorage.lang = e.target.value;
    i18n.changeLanguage(e.target.value);
  };
  return (
    <select value={i18n.language} onChange={changeLanguageHandler}>
      <option value="tr">TR</option>
      <option value="en">EN</option>
      <option value="fr">FR</option>
      <option value="ar">AR</option>
      <option value="ja">JA</option>
    </select>
  );
}
