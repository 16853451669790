/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Hand from "./ToolbarItems/Hand";
import ZoomOut from "./ToolbarItems/ZoomOut";
import ZoomIn from "./ToolbarItems/ZoomIn";
import ZoomNormal from "./ToolbarItems/ZoomNormal";
import FullScreen from "./ToolbarItems/FullScreen";
// import DrawToolbar from "./ToolbarItems/DrawToolbar";
// import ZoomToolbar from "./ToolbarItems/ZoomToolbar";
// // import Layers from "./ToolbarItems/Layers";
// import Menu from "./ToolbarItems/Menu";
// import VerticalToolbar from "./ToolbarItems/VerticalToolbar";
import PinToolbar from "./ToolbarItems/PinToolbar";
import Pen from "./ToolbarItems/Pen";
import Highliter from "./ToolbarItems/Highliter";
import Text from "./ToolbarItems/Text";
import Eraser from "./ToolbarItems/Eraser";
import Shapes from "./ToolbarItems/Shapes";
import StrokeSize from "./ToolbarItems/StrokeSize";
import TextStrokeColor from "./ToolbarItems/TextStrokeColor";
import FillColor from "./ToolbarItems/FillColor";
// import UndoRedo from "./ToolbarItems/UndoRedo";
import Draggable from "react-draggable";
import Selecter from "./ToolbarItems/Selecter";
// import TransparentFillColor from "./ToolbarItems/TransparentFillColor";
import { CompactPicker } from "react-color";
// import { SketchPicker } from "react-color";
import AddNote from "./ToolbarItems/AddNote";
import ActivityOptions from "./ToolbarItems/ActivityOptions";
// import TeacherTools from "./ToolbarItems/TeacherTools";
import QuestionOptions from "./ToolbarItems/QuestionOptions";
import { isMobileOnly } from "react-device-detect";
import { setScalePageReset } from "../actions/scalePageReset";
import AddHideableAnnotation from "./ToolbarItems/AddHideableAnnotation";
import AddHideArea from "./ToolbarItems/AddHideArea";
import AddBlankPage from "./ToolbarItems/AddBlankPage";
import AddTimer from "./ToolbarItems/AddTimer";
import PresentationMode from "./ToolbarItems/PresentationMode";
import ToolbarMode from "./ToolbarItems/ToolbarMode";
import ZoomModeNextButton from "./ToolbarItems/ZoomModeNextButton";
import ZoomModePrevButton from "./ToolbarItems/ZoomModePrevButton";
// import { CompactPickerColor } from "react-color/color";
import Undo from "./ToolbarItems/Undo";
import Redo from "./ToolbarItems/Redo";
import { connect } from "react-redux";

import NextPage from "./ToolbarItems/NextPage";
import PrevPage from "./ToolbarItems/PrevPage";
import EraseOne from "./ToolbarItems/EraseOne";
import { pageNoChange } from "../actions/pages";
import { toggleButtons } from "../actions/buttonShowHide";
import {
  setToolbarPosition,
  resetToolbarPosition,
  pinToolbar,
  unpinToolbar,
  setToolbarVertical,
  setToolbarMode,
} from "../actions/toolbar";

import { changeSelectedTool } from "../actions/selectedTool";
import { setAnnotationSelectionMode } from "../actions/annotationSelection";

import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../actions/interactiveObjects";
import {
  showAnnotations,
  hideAnnotations,
  // showArea,
  // hideArea,
  triggerRedo,
  triggerUndo,
  triggerRemove,
  triggerRemoveAll,
  triggerZoomIn,
  triggerZoomOut,
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  setPagePartZoomObject,
  showTimer,
  setZoomModeMenu,
  setAddInteractivityMode,
} from "../actions/settings.js";
// import { captureInProcess, hideCaptureImage } from "../actions/imageCapture";
import Capture from "./ToolbarItems/CaptureTool";
import Curtain from "./ToolbarItems/CurtainTool";
// import AddNote from "./ToolbarItems/AddNote";
import AddVideo from "./ToolbarItems/AddVideo";
import AddH5p from "./ToolbarItems/AddH5p";
import AddAudio from "./ToolbarItems/AddAudio";
import AddImage from "./ToolbarItems/AddImage";
import AddLink from "./ToolbarItems/AddLink";
import AddMCQuestion from "./ToolbarItems/AddMCQuestion";
import AddMRQuestion from "./ToolbarItems/AddMRQuestion";
// import Mouse from "./ToolbarItems/Mouse";
// import HardDragger from "./ToolbarItems/HardDragger";
import DevModeTools from "./ToolbarItems/DevModeTools";
import { setLineWidth, setLineColor, setFillColor } from "../actions/toolbar";
import AddZoom from "./ToolbarItems/AddZoom";
import { insertPage } from "../actions/addActivity";
import { guidGenerator } from "../services/util";
// import configuration from "../config.json";
import { toastWarning } from "../actions/showToast";
import ReactGA from "react-ga";
import {
  selectCurrentPageWithMyContent,
  selectUserHasDeveloperRole,
  selectUserHasDomainAdminRole,
  selectUserHasNoRole,
  selectUserHasProgramCoordinatorRole,
  selectUserHasStudentRole,
  selectUserHasSuperAdminRole,
  selectUserHasTeacherRole,
  selectZooms,
} from "../store/configureStore";

const CompactPickerColors = (<CompactPicker />).props.colors;

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curtainState: 0,
      verticalToolbar: this.props.toolbar.verticalToolbar,
      toolbarMode: this.props.toolbar.toolbarMode,
      toolbarPin: this.props.toolbar.toolbarPin,
      activeButton: this.props.toolbar.activeButton,
      activeSubButton: null,
      activeSubChildButton: null,
      activeMouseButton: null,
      activeDrags: 0,
      localControlledPosition: this.props.toolbar.toolbarPosition,
      toolbarVisibility:true
    };
  }
  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
    const { x, y } = this.state.localControlledPosition;
    this.props.setToolbarPosition({ x, y });
  };

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ localControlledPosition: { x, y } });
  };

  onClickNextPage = () => {
    if (!this.props.pageIsLoading) {
      let cPNo = this.props.currentPageNumber;
      this.props.pageNoChange(++cPNo);
    }
  };
  onClickPrevPage = () => {
    if (!this.props.pageIsLoading) {
      this.props.pageNoChange(this.props.currentPageNumber - 1);
    }
  };
  onClickScrollTop = () => {
    let paGe = document.getElementById("pdfPageList");
    paGe.scrollTop = 0;
  };
  onClickScrollDown = () => {
    let paGe = document.getElementById("pdfPageList");
    paGe.scrollTo(0, paGe.scrollHeight);
  };

  onChangeInteractiveObjects = (event) => {
    if (event.target.checked) {
      this.props.showInteractiveObjects();
    } else {
      this.props.hideInteractiveObjects();
    }
  };

  onChangeDrawingObjects = (event) => {
    if (event.target.checked) {
      this.props.showAnnotations();
    } else {
      this.props.hideAnnotations();
    }
  };

  onCloseAllOpenItems() {
    console.log("close menu called");
    this.setState({
      activeSubButton: null,
      activeSubChildButton: null,
    });
  }

  onClickMouseButton = (button) => {
    this.setState({ activeButton: button, activeMouseButton: button }, () => {
      console.log(
        "this.props.selectedToolChangedFromOutsideShouldCloseTheMenu",
        this.props.selectedToolChangedFromOutsideShouldCloseTheMenu
      );
      // this.props.selectedToolChangedFromOutsideShouldCloseTheMenu && ;
      if (!this.props.selectedToolChangedFromOutsideShouldCloseTheMenu) {
        this.setState({ activeButton: "draw-toolbar" });
      } else {
        this.onCloseAllOpenItems();
        this.props.changeSelectedTool(button);
      }
    });
  };

  onClickZoomOut = () => {
    this.setState({ activeSubButton: "zoom-out" }, () => {
      // this.onCloseAllOpenItems();
      this.props.triggerZoomOut(true);
    });
  };
  onClickZoomReset = () => {
    console.log("onClickZoomReset TROGGEREd");
    this.setState({ activeSubButton: "zoom-reset" }, () => {
      // this.onCloseAllOpenItems();
      this.props.setScalePageReset();
    });
  };

  onClickFullScreen = () => {
    this.setState({ activeSubButton: "full-screen" }, () => {
      this.onCloseAllOpenItems();
    });

    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  onClickZoomIn = () => {
    this.setState({ activeSubButton: "zoom-in" }, () => {
      // this.onCloseAllOpenItems();
      this.props.triggerZoomIn(true);
    });
  };

  onClickButton = (buttonName) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        activeButton: prevState.activeButton === buttonName ? null : buttonName,
        activeSubButton: null,
        activeSubChildButton: null,
      };
    });
  };
  onClickMouseSelectButton = (buttonName) => {
    let isTheSameButton = false; //if the mouse icon is as the same as selected mouse icon
    let prevActiveButton = null;

    //this is to make the main mouse icon to be selectable
    this.setState(
      (prevState) => {
        isTheSameButton =
          !prevState.activeButton?.includes("options") && // if this is not a sub menu opener
          prevState.activeButton !== null;
        prevActiveButton = prevState.activeButton;
        console.log(
          "prevState.activeButton",
          prevState.activeButton,
          prevState.activeMouseButton
        );
        return {
          ...prevState,
          activeButton:
            prevState.activeButton === buttonName
              ? prevState.activeMouseButton
              : buttonName,
          activeMouseButton: isTheSameButton
            ? prevState.activeButton
            : prevState.activeMouseButton,
          activeSubButton: null,
          activeSubChildButton: null,
        };
      },
      // , isTheSameButton && this.onClickMouseButton(buttonName)
      () => {
        if (isTheSameButton) {
          console.log(
            "isTheSameButton",
            isTheSameButton,
            buttonName,
            prevActiveButton
          );
          this.onCloseAllOpenItems();
          // this.props.changeSelectedTool(prevActiveButton);
        }
      }
    );
  };

  onClickSubButton = (buttonName) => {
    this.setState(
      (prevState) => {
        console.log(
          " prevState.activeSubButton, buttonName",
          prevState.activeSubButton,
          buttonName
        );
        return {
          ...prevState,
          activeSubButton:
            prevState.activeSubButton === buttonName ? null : buttonName,
          activeSubChildButton: null,
        };
      },
      () => {
        this.props.changeSelectedTool(buttonName);
      }
    );
  };

  onClickSubChildButton = (childButtonName, selfClose = false) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          activeSubChildButton:
            prevState.activeSubChildButton === childButtonName
              ? null
              : childButtonName,
        };
      },
      () => {
        this.props.changeSelectedTool(childButtonName);
        selfClose &&
          setTimeout(() => {
            this.onCloseAllOpenItems();
          }, 1000);
      }
    );
  };
  onClickColorButton = (childButtonName) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        activeSubChildButton:
          prevState.activeSubChildButton === childButtonName
            ? null
            : childButtonName,
      };
    });
  };

  onClickVerticalToolbar = () => {
    this.props.setToolbarPosition({ x: 0, y: 20 });
    this.props.setToolbarVertical(!this.state.verticalToolbar);
    this.onCloseAllOpenItems();
  };

  onClickToolbarMode = () => {
    this.props.setToolbarMode(!this.state.toolbarMode);
    this.onCloseAllOpenItems();
  };

  onClickToolbarPin = () => {
    const toolbarWidth = document.getElementById("toolbar1").offsetWidth;
    //this.props.setToolbarPosition({ x:-100, y:40 });
    this.setState({
      localControlledPosition: { x: 0, y: 0 },
    });
    this.setState(
      {
        // activeButton: "toolbar-pin",
        toolbarPin: !this.state.toolbarPin,

        // drawToolbarOpened: false,
      },
      () => {
        this.state.toolbarPin
          ? this.props.pinToolbar()
          : this.props.unpinToolbar();
        this.onCloseAllOpenItems();
      }
    );
  };

  onClickPen = () => {
    console.log("Heyy");
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          activeSubButton:
            prevState.activeSubButton === "pencil" ? null : "pencil",
        };
      },
      () => {
        this.props.changeSelectedTool("pencil");
      }
    );
  };

  onClickHighliter = () => {
    console.log("HIGHHH");
    this.setState(
      {
        activeSubButton: "highlighter",
      },
      () => {
        // this.onCloseAllOpenItems();
        this.props.changeSelectedTool("highlighter");
      }
    );
  };

  onClickText = () => {
    console.log("shouldShowButtonLabels", this.props.showLabels);
    this.setState(
      {
        activeSubButton: "text",
      },
      () => {
        // this.onCloseAllOpenItems();
        this.props.changeSelectedTool("text");
      }
    );
  };

  onClickEraseSelected = () => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          activeSubButton:
            prevState.activeSubButton === "eraseselected"
              ? null
              : "eraseselected",
        };
      },
      () => {
        this.props.changeSelectedTool("eraseselected");
      }
    );
  };
  onClickCapture = () => {
    this.setState({ activeButton: "capturezoom" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("capturezoom");
      // console.log("select");
    });
  };
  onClickQHideable = () => {
    this.setState({ activeButton: "qhideable" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("qhideable");
      this.props.setAddInteractivityMode("init", "qhideable");
      this.props.changeSelectedTool("emptycircle");
      // console.log("select");
    });
  };
  onClickQShowable = () => {
    this.setState({ activeButton: "qshowable" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("qshowable");
      // console.log("select");
    });
  };
  onClickHideArea = () => {
    this.setState({ activeButton: "qshowable" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("qshowable");
      this.props.setAddInteractivityMode("init", "qshowable");
      // console.log("select");
    });
  };
  onClickShowArea = () => {
    this.setState({ activeButton: "showarea" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("showarea");
      // console.log("select");
    });
  };
  onClickAddZoom = () => {
    this.setState({ activeButton: "addzoom" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("addzoom");
      // console.log("select");
    });
  };
  onClickAddZoomArea = () => {
    this.setState({ activeButton: "zoomarea" }, () => {
      this.props.setToolbarMode(true);
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("zoomarea");
      this.props.setAddInteractivityMode("init", "zoomarea");
      // console.log("select");
    });
  };
  onClickCurtain = () => {
    this.props.changeSelectedTool("curtain");
  };

  onClickHand = () => {
    this.setState({ activeButton: "pan" }, () => {
      this.onCloseAllOpenItems();
      this.props.changeSelectedTool("pan");
      // console.log("select");
    });
  };

  onClickEraseAll = () => {
    this.onClickSubChildButton("eraseall", true);
    this.props.triggerRemoveAll(true);
  };

  onClickLineSubItem = (width) => {
    this.props.setLineWidth(width * 3);
  };

  onChangeLineColor = (color) => {
    // console.log(this.props.toolbar.lineColor);
    if (this.props.toolbar.lineColor === color.hex) {
      this.props.setLineColor(null);
      setTimeout(() => {
        this.props.setLineColor(color.hex);
      }, 100);
    } else {
      this.props.setLineColor(color.hex);
    }
  };

  onChangeFillColor = (color) => {
    // console.log(' this.props.toolbar.fillColor ===color ',  this.props.toolbar.fillColor ,color, this.props.toolbar.fillColor ===color.hex )
    if (this.props.toolbar.fillColor === color.hex) {
      this.props.setFillColor(null);
      setTimeout(() => {
        this.props.setFillColor(color.hex);
      }, 100);
    } else {
      this.props.setFillColor(color.hex);
    }
  };
  _resize = () => {
    if(isMobileOnly){
      this.setState({
        toolbarVisibility: false,
      });
    }else if (this.state.toolbarVisibility) {
      if (window.innerHeight <= 480 || window.innerWidth <= 480) {
        this.setState({
          toolbarVisibility: false,
        });
      } else {
        this.setState({
          localControlledPosition: this.state.verticalToolbar
            ? { x: 50, y: 100 }
            : { x: -100, y: 0 },
        });
      }
    } else {
      if (window.innerHeight > 480 || window.innerWidth > 480) {
        this.setState({
          toolbarVisibility: true,
          localControlledPosition: this.state.verticalToolbar
            ? { x: 50, y: 100 }
            : { x: -100, y: 0 },
        });
      }
    }
  };
  componentDidMount() {
    this._resize();

    window.addEventListener("resize", this._resize, false);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.selectedToolChangedFromOutside &&
      this.props.selectedTool !== prevProps.selectedTool
    ) {
      this.buttonHandler(this.props.selectedTool);
      // console.log('selectedToolChangedFromOutside' )
    }
    if (
      this.props.toolbar.toolbarPosition !== prevProps.toolbar.toolbarPosition
    ) {
      this.setState({
        localControlledPosition: this.props.toolbar.toolbarPosition,
      });
    }
    if (
      this.props.toolbar.verticalToolbar !== prevProps.toolbar.verticalToolbar
    ) {
      console.log(
        "SHOULD CHANGE VERTICAL TOOLBAR",
        this.props.toolbar.verticalToolbar
      );
      this.setState({
        verticalToolbar: this.props.toolbar.verticalToolbar,
      });
    }
    if (this.props.toolbar.toolbarPin !== prevProps.toolbar.toolbarPin) {
      this.setState({
        toolbarPin: this.props.toolbar.toolbarPin,
      });
    }
  }

  presentationMode = () => {
    const qZoomButtons = this.props.zooms;
    // .reverse();
    this.props.setPagePartZoomMode("zoom-show");
    if (qZoomButtons.length === 0) {
      toastWarning("Sorry no ....");
    } else {
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords,
        cutCoordsUp,
      } = qZoomButtons[0];
      this.props.setPagePartZoomObjectToShow(
        orderId,
        downCoords,
        upCoords,
        cutCoords,
        cutCoordsUp
      );
    }
  };

  addBlankPage = async () => {
    const newPage = {
      id: guidGenerator(),
      page_number: this.props.TotalPageNumber + 1,
      thumbnail: null,
      // thumbnailImage:
      path: null,
      ebook_id: this.props.ebookId,
      pageNumber: this.props.TotalPageNumber + 1,
      buttons: [],
      canvasBackgroundImageWidth: this.props.canvasVals
        .canvasBackgroundImageWidth,
      canvasBackgroundImageHeight: this.props.canvasVals
        .canvasBackgroundImageHeight,
    };
    await this.props.insertPage(newPage);
    await this.props.pageNoChange(this.props.TotalPageNumber);
    const itemId = `${this.props.ebookId}_localPages`;
    const localPages = JSON.parse(localStorage.getItem(itemId));

    if (
      localPages
      // &&localPages.indexOf(this.props.TotalPageNumber) > -1
    ) {
      // if(localPages)
      localStorage.setItem(itemId, JSON.stringify([...localPages, newPage]));
    } else {
      localStorage.setItem(itemId, JSON.stringify([newPage]));
    }
  };

  exitHandler = () => {
    this.props.setPagePartZoomObject(
      null,
      { x: null, y: null },
      { x: null, y: null }
    );
    this.exitZoomMode(this.props);
  };

  exitZoomMode = (props) => {
    if (props.pageZoomPartMode) {
      props.setPagePartZoomMode(null);
      props.setPagePartZoomObjectToShow(
        null,
        { x: null, y: null },
        { x: null, y: null },
        { x: null, y: null },
        { x: null, y: null }
      );
    } else {
      props.resetAnnotationSelectionMode();
      props.showInteractiveObjects();
    }
  };

  buttonHandler = (btn) => {
    ReactGA.event({
      category: "User",
      action: btn,
    });
    switch (btn) {
      case "capture":
        this.onClickCapture();
        break;
      case "qhideable":
        this.onClickQHideable();
        break;
      case "qshowable":
        this.onClickQShowable();
        break;
      case "addzoom":
        this.onClickAddZoom();
        break;
      case "zoomarea":
        this.onClickAddZoomArea();
        break;
      case "hand":
        this.onClickHand();
        break;
      case "curtain":
        this.onClickButton("curtain");
        break;
      case "menu":
        this.onClickButton("menu");
        break;
      case "pin":
        this.onClickToolbarPin();
        break;
      case "pencil":
        this.onClickPen();
        break;
      case "highlighter":
        this.onClickHighliter();
        break;
      case "text":
        this.onClickText();
        break;
      case "eraseselected":
        this.onClickEraseSelected();
        break;
      case "erase":
        this.onClickEraseSelected();
        break;
      case "eraseall":
        this.this.onClickEraseAll();
        break;
      case "shapeline":
        this.onClickSubButton("shapeline");
        break;
      case "circle":
        this.onClickSubButton("circle");
        break;
      case "emptysquare":
        this.onClickSubButton("emptysquare");
        break;
      case "emptycircle":
        this.onClickSubButton("emptycircle");
        break;
      case "rectangle":
        this.onClickSubButton("rectangle");
        break;
      case "linewidth":
        this.onClickSubButton("linewidth");
        break;
      case "line":
        this.onClickSubButton("line");
        break;
      case "textstroke":
        this.onClickColorButton("textstroke");
        break;
      case "fillcolor":
        this.onClickColorButton("fillcolor");
        break;
      case "zoomin":
        this.onClickZoomIn();
        break;
      case "zoomnormal":
        this.onClickZoomReset();
        break;
      case "zoomout":
        this.onClickZoomOut();
        break;
      case "fullscreen":
        this.onClickFullScreen();
        break;
      case "pan":
        this.onClickMouseButton("pan");
        break;
      case "select":
        this.onClickMouseButton("select");
        break;
      case "harddrag":
        this.onClickMouseButton("harddrag");
        break;
      case "showarea":
        this.onClickShowArea();
        break;
      case "hidearea":
        this.onClickHideArea();
        break;
      case "toolbar-mode":
        this.onClickToolbarMode();
        break;
      default:
        break;
      case "addblankpage":
        this.addBlankPage();
        break;
    }
  };

  render() {
    const {
      isTeacher,
      noRole,
      isStudent,
      isSuperAdmin,
      isDomainAdmin,
      isDeveloper,
      isProgramCoordinator,
    } = this.props;
    // console.log("isDeveloper: ", isDeveloper);
    // console.log("isProgramCoordinator: ", isProgramCoordinator);
    // console.log("isDomainAdmin: ", isDomainAdmin);
    // console.log("isTeacher: ", isTeacher);
    // console.log("isSuperAdmin: ", isSuperAdmin);
    // console.log("noRole: ", noRole);
    // // const noRole = this.props.roles ? this.props.roles.length === 0 : true;
    // console.log("isStudent: ", isStudent);
    // const isStudent =
    //   this.props.roles &&
    //   this.props.roles.findIndex((role) => role.name === "Student") === 0;
    // // const isTeacher =
    // //   this.props.roles &&
    // //   this.props.roles.findIndex((role) => role.name === "Teacher") === 0;
    // const isSuperAdmin =
    //   this.props.roles &&
    //   this.props.roles.findIndex((role) => role.name === "Super Admin") === 0;
    // const isDomainAdmin =
    //   this.props.roles &&
    //   this.props.roles.findIndex((role) => role.name === "Domain Admin") === 0;
    // const isDeveloper =
    //   this.props.roles &&
    //   this.props.roles.findIndex((role) => role.name === "Developer") === 0;
    // const isProgramCoordinator =
    //   this.props.roles &&
    //   this.props.roles.findIndex(
    //     (role) => role.name === "Program Coordinator"
    //   ) === 0;

    const isOnline = process.env.REACT_APP_OFFLINE === "false";

    const showTeacherTools = !noRole && !isStudent;
    const showEditorTools =
      isSuperAdmin || isProgramCoordinator || isDomainAdmin;

    //    let userRole = "student";

    /* if (this.props.roles && this.props.roles[0])
      userRole = String(this.props.roles[0].name).toLowerCase();
    // console.log("userRole", userRole);
    const showTeacherTools = userRole !== "student";

    */
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    let toolbarClass = "";
    switch (this.props.toolbarSize) {
      case "1.25":
        toolbarClass = "toolbarsize-1-25x";
        break;
      case "1.5":
        toolbarClass = "toolbarsize-1-5x";
        break;
      case "2":
        toolbarClass = "toolbarsize-2x";
        break;
      default:
        break;
    }

    const devMode = process.env.REACT_APP_DEV_MODE === "true";
    const zoomMode = this.props.settings.pageZoomPartMode === "zoom-show";

    // const isSmallScreen = this.props.canvasVals
    //   ? window.innerWidth < 641
    //   : false;
    // const isVerySmallScreen = this.props.canvasVals
    //   ? window.innerWidth < 341
    //   : false;
    const isSmallScreen = !this.state.toolbarVisibility;

    const qZoomButtons = this.props.zooms;
    const currentZoomObjectOrderId = zoomMode
      ? parseInt(this.props.pageZoomPartObjectToShow.orderId)
      : undefined;
    const currentZoomButtonId = zoomMode
      ? currentZoomObjectOrderId &&
        this.props.zooms &&
        this.props.zooms.findIndex(
          (qz) => qz.orderId === currentZoomObjectOrderId
        )
      : undefined;
    const { pageZoomPartShowLoading: isLoading } = this.props.settings;
    const showNextButton = zoomMode
      ? currentZoomButtonId <= this.props.zooms.length - 1 ||
        this.props.currentPageNumber < this.props.totalPageNumber
      : false;
    const showPrevButton = zoomMode
      ? currentZoomButtonId !== 0 || this.props.currentPageNumber > 1
      : false;

    const zoomGotoNext = () => {
      console.log(`zoomGotoNext currentZoomButtonId`, currentZoomButtonId);

      if (currentZoomButtonId >= qZoomButtons.length - 1) {
        this.props.pageNoChange(parseInt(this.props.currentPageNumber) + 1);
      } else {
        const {
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp,
        } = qZoomButtons[currentZoomButtonId + 1];
        this.props.setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp
        );
      }
    };
    const zoomGotoPrev = () => {
      console.log(`zoomGotoPrev currentZoomButtonId`, currentZoomButtonId);
      if (currentZoomButtonId <= 0) {
        this.props.pageNoChange(parseInt(this.props.currentPageNumber) - 1);
      } else {
        const {
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp,
        } = qZoomButtons[currentZoomButtonId - 1];
        this.props.setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp
        );
      }
    };

    return (
      !isSmallScreen && (
        <Draggable
          handle=".drag"
          scale={1}
          axis={"both"}
          grid={[2, 2]}
          defaultClassName="toolbar-draggable-handle"
          defaultPosition={this.state.localControlledPosition}
          position={this.state.localControlledPosition}
          {...dragHandlers}
          onDrag={this.onControlledDrag}
          onStart={this.onStart}
          onStop={this.onStop}
        >
          <div
            // data-step="4"
            // data-intro="Search Bar: Search images, definitions and other resources! You can also see predefined wordlist here."
            //${isSmallScreen ? "mobile-toolbar" : ""}
            className={`${
              this.state.verticalToolbar ? "vertical1" : "horizontal1"
            } toolbar-wrapper toolbar-new ${
              this.props.searchBarOpen ? "toolbar-wrapper-change-left" : ""
            }mobile-toolbar
          ${this.props.selectedTool === "curtain" ? " is-hidden " : ""}
          `}
            id="toolbar1"
          >
            {this.state.activeSubChildButton === "textstroke" && (
              <div
                className={`
              ${
                this.state.verticalToolbar
                  ? "line-color-picker"
                  : "line-color-picker-verticalOn"
              }
              ${
                this.state.activeSubChildButton === "textstroke" ? "" : "dOff"
              } ${
                  this.state.verticalToolbar && !isSmallScreen
                    ? "verticalOn"
                    : "verticalOff"
                }
              ${
                zoomMode
                  ? this.state.verticalToolbar
                    ? "zoomMode-toolbar-line-color"
                    : ""
                  : ""
              }`}
              >
                {this.state.activeSubChildButton === "textstroke" ? (
                  <CompactPicker onChange={this.onChangeLineColor} />
                ) : (
                  ""
                )}
              </div>
            )}
            {this.state.activeSubChildButton === "fillcolor" && (
              <div
                className={`fill-color-picker ${
                  this.state.activeSubChildButton === "fillcolor" ? "" : "dOff"
                } ${
                  this.state.verticalToolbar && !isSmallScreen
                    ? "verticalOn"
                    : "verticalOff"
                }`}
              >
                {this.state.activeSubChildButton === "fillcolor" ? (
                  <CompactPicker
                    colors={[...CompactPickerColors, "transparent"]}
                    onChange={this.onChangeFillColor}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            <div
              className={`toolbar ${
                this.state.verticalToolbar && !isSmallScreen
                  ? "vertical " + toolbarClass + "-v" //no space after vertical
                  : "horizontal " + toolbarClass + "-h" //add one space after horizontal
              }

            `}
              // ${this.state.toolbarPin ? " min-toolbar" : ""}
            >
              <div
                className="toolbar-items"
                // style={}
              >
                {/* TODO Use style top left to set min width/2 plus */}
                <div className="main-toolbar">
                  <div
                    // data-step="3"
                    // data-intro="Search Bar: Search images, definitions and other resources! You can also see predefined wordlist here."
                    className="buttons has-addons"
                  >
                    {!this.state.toolbarPin ? (
                      <ul>
                        <li
                          className="drag-element"
                          onMouseDown={() => this.onCloseAllOpenItems()}
                        >
                          <span
                            className="button drag"
                            id="drag1"
                            // title="Drag"
                            data-tip={"Drag"}
                          >
                            <img
                              src={require("../assets/img/svg/toolbar-drag.svg")}
                              alt="Drag"
                              draggable="false"
                            />
                          </span>
                        </li>

                        <Hand
                          onClick={() => this.buttonHandler("pan")}
                          activeButton={this.props.selectedTool}
                          hideButton={this.props.isButtonsShown}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <Selecter
                          onClick={() => this.buttonHandler("select")}
                          activeButton={this.props.selectedTool}
                          hideButton={this.props.isButtonsShown}
                          shouldShowButtonLabels={this.props.showLabels}
                        />

                        <Pen
                          onClick={() => this.buttonHandler("pencil")}
                          // onClick={() => this.onClickSubButton("pencil")}
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <TextStrokeColor
                          onClick={() => this.buttonHandler("textstroke")}
                          color={this.props.toolbar.lineColor}
                          activeButton={this.state.activeSubChildButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <EraseOne
                          onClick={() => this.buttonHandler("erase")}
                          //onClickEraseSelected
                          // onClick={() => this.onClickSubButton("pencil")}
                          activeButton={this.props.selectedTool}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <PresentationMode
                          onClick={
                            zoomMode
                              ? () => this.exitHandler()
                              : () => this.presentationMode()
                          }
                          activeButton={this.state.activeButton}
                          hideButton={this.props.isButtonsShown}
                          shouldShowButtonLabels={this.props.showLabels}
                          active={zoomMode ? true : false}
                          disabled={
                            qZoomButtons && qZoomButtons.length > 0
                              ? false
                              : true
                          }
                        />
                        {!isSmallScreen &&
                          // showTeacherTools &&
                          showEditorTools &&
                          !isTeacher &&
                          this.props.advancedMode &&
                          !zoomMode && 
                          isOnline && (
                            <>
                              <ActivityOptions
                                onClick={() =>
                                  this.onClickButton("activity-options")
                                }
                                activeButton={this.state.activeButton}
                                hideButton={this.props.isButtonsShown}
                                shouldShowButtonLabels={this.props.showLabels}
                              />
                            </>
                          )}

                        {devMode && showTeacherTools && !zoomMode && (
                          <>
                            <QuestionOptions
                              onClick={() =>
                                this.onClickButton("question-options")
                              }
                              activeButton={this.state.activeButton}
                              hideButton={this.props.isButtonsShown}
                            />
                            <DevModeTools
                              onClick={() =>
                                this.onClickButton("devmode-tools")
                              }
                              activeButton={this.state.activeButton}
                              /* hideButton={this.props.isButtonsShown}*/
                            />
                          </>
                        )}

                        {!isSmallScreen && (
                          <ToolbarMode
                            onClick={() => this.buttonHandler("toolbar-mode")}
                            activeButton={this.state.activeButton}
                            shouldShowButtonLabels={this.props.showLabels}
                            toolbarAlternative={false}
                          />
                        )}

                        {zoomMode && (
                          <ZoomModePrevButton
                            disabled={!showPrevButton || isLoading}
                            onClick={zoomGotoPrev}
                            shouldShowButtonLabels={this.props.showLabels}
                          />
                        )}
                        {zoomMode && (
                          <ZoomModeNextButton
                            shouldShowButtonLabels={this.props.showLabels}
                            disabled={!showNextButton || isLoading}
                            onClick={zoomGotoNext}
                          />
                        )}
                        {!zoomMode && (
                          <PrevPage
                            onClick={this.onClickPrevPage}
                            currentPageNumber={this.props.currentPageNumber}
                            TotalPageNumber={this.props.totalPageNumber}
                            shouldShowButtonLabels={this.props.showLabels}
                          ></PrevPage>
                        )}
                        {!zoomMode && (
                          <NextPage
                            onClick={this.onClickNextPage}
                            currentPageNumber={this.props.currentPageNumber}
                            totalPageNumber={this.props.totalPageNumber}
                            shouldShowButtonLabels={this.props.showLabels}
                          ></NextPage>
                        )}
                        <li
                          className="drag-element"
                          onMouseDown={() => this.onCloseAllOpenItems()}
                        >
                          <span
                            className="button text-bold drag"
                            id="drag2"
                            title="Drag"
                          >
                            <img
                              src={require("../assets/img/svg/toolbar-drag.svg")}
                              alt="Drag"
                              draggable="false"
                            />
                          </span>
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li
                          className="drag-element"
                          onMouseDown={() => this.onCloseAllOpenItems()}
                        >
                          <span className="button drag" id="drag1" title="Drag">
                            <img
                              src={require("../assets/img/svg/toolbar-drag.svg")}
                              alt="Drag"
                              draggable="false"
                            />
                          </span>
                        </li>

                        <PinToolbar
                          onClick={() => this.buttonHandler("pin")}
                          activeButton={this.state.activeButton}
                          shouldShowButtonLabels={this.props.showLabels}
                          pinToolbarSelect={false}
                        />

                        <li
                          className="drag-element"
                          onMouseDown={() => this.onCloseAllOpenItems()}
                        >
                          <span
                            className="button text-bold drag"
                            id="drag2"
                            title="Drag"
                          >
                            <img
                              src={require("../assets/img/svg/toolbar-drag.svg")}
                              alt="Drag"
                              draggable="false"
                            />
                          </span>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>

                <div
                  className={`draw-toolbar ${
                    zoomMode ? "draw-toolbar-zoom" : ""
                  }  ${
                    this.state.activeButton === "draw-toolbar" ? "open" : ""
                  }`}
                  id="draw-toolbar"
                >
                  <div className="buttons has-addons">
                    <ul>
                      <Pen
                        onClick={() => this.buttonHandler("pencil")}
                        // onClick={() => this.onClickSubButton("pencil")}
                        activeButton={this.state.activeSubButton}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <Highliter
                        onClick={() => this.buttonHandler("highlighter")}
                        // onClick={() => this.onClickSubButton("highlighter")}
                        activeButton={this.state.activeSubButton}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <Text
                        onClick={() => this.buttonHandler("text")}
                        // onClick={() => this.onClickSubButton("text")}
                        activeButton={this.state.activeSubButton}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <Eraser
                        onClickEraser={() => this.onClickSubButton("eraser")}
                        // onClick={() => this.onClickSubButton("eraser")}
                        onClickEraseSelected={this.onClickEraseSelected}
                        onClickEraseAll={this.onClickEraseAll}
                        activeSubButton={this.state.activeSubButton}
                        activeSubChildButton={this.state.activeSubChildButton}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <Shapes
                        onClick={() => {
                          this.onClickSubButton("shapes");
                        }}
                        onClickShapeLine={() =>
                          // this.onClickSubChildButton("shapeline")
                          this.buttonHandler("line")
                        }
                        onClickShapeCircle={() => this.buttonHandler("circle")}
                        onClickEmptySquare={() =>
                          this.buttonHandler("emptysquare")
                        }
                        onClickEmptyCircle={() =>
                          this.buttonHandler("emptycircle")
                        }
                        onClickShapeSquare={() =>
                          this.buttonHandler("rectangle")
                        }
                        activeButton={this.state.activeSubButton}
                        activeSubChildButton={this.state.activeSubChildButton}
                        shouldShowButtonLabels={this.props.showLabels}
                      />

                      <StrokeSize
                        onClick={() => this.buttonHandler("linewidth")}
                        onClickSubItem={this.onClickLineSubItem}
                        activeButton={this.state.activeSubButton}
                        lineWidth={this.props.toolbar.lineWidth}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <TextStrokeColor
                        onClick={() => this.buttonHandler("textstroke")}
                        color={this.props.toolbar.lineColor}
                        activeButton={this.state.activeSubChildButton}
                      />
                      <FillColor
                        onClick={() => this.buttonHandler("fillcolor")}
                        color={this.props.toolbar.fillColor}
                        activeButton={this.state.activeSubChildButton}
                      />
                      <Undo
                        onClickUndo={() => this.props.triggerUndo(true)}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <Redo
                        onClickRedo={() => this.props.triggerRedo(true)}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                    </ul>
                  </div>
                </div>

                <div
                  className={`draw-toolbar   ${
                    this.state.activeButton === "zoom-options" ? "open" : ""
                  }
                ${
                  !isSmallScreen
                    ? zoomMode
                      ? "zoom-options-zoom-mode"
                      : "zoom-options"
                    : "zoom-options-small-screen"
                } `}
                  id="zoom-toolbar"
                >
                  <div className="buttons has-addons">
                    <ul>
                      <ZoomIn
                        onClick={() => this.buttonHandler("zoomin")}
                        activeButton={this.state.activeSubButton}
                        hideButton={this.props.isButtonsShown}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <ZoomNormal
                        onClick={() => this.buttonHandler("zoomnormal")}
                        activeButton={this.state.activeSubButton}
                        hideButton={this.props.isButtonsShown}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <ZoomOut
                        onClick={() => this.buttonHandler("zoomout")}
                        activeButton={this.state.activeSubButton}
                        hideButton={this.props.isButtonsShown}
                        shouldShowButtonLabels={this.props.showLabels}
                      />

                      <FullScreen
                        onClick={() => this.buttonHandler("fullscreen")}
                        activeButton={this.state.activeSubButton}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                    </ul>
                  </div>
                </div>

                {!zoomMode && (
                  <div
                    className={`draw-toolbar
                  ${
                    showEditorTools
                      ? " activity-options-editormode"
                      : " activity-options"
                  } ${
                      this.state.activeButton === "activity-options"
                        ? "open"
                        : ""
                    }`}
                    id="add-media-toolbar"
                  >
                    <div className="buttons has-addons">
                      <ul>
                        {showEditorTools && (
                          <>
                            <AddZoom
                              onClick={() => {
                                this.buttonHandler("zoomarea");
                                // this.props.setAnnotationSelectionMode(true);
                              }}
                              activeButton={this.state.activeButton}
                              hideButton={this.props.isButtonsShown}
                              shouldShowButtonLabels={this.props.showLabels}
                            />
                            <AddHideableAnnotation
                              onClick={() => {
                                this.buttonHandler("qhideable");
                                // this.props.setAnnotationSelectionMode(true);
                              }}
                              activeButton={this.state.activeButton}
                              hideButton={this.props.isButtonsShown}
                              shouldShowButtonLabels={this.props.showLabels}
                            />
                            <AddHideArea
                              onClick={() => {
                                this.buttonHandler("hidearea");
                                // this.props.setAnnotationSelectionMode(true);
                              }}
                              activeButton={this.state.activeButton}
                              hideButton={this.props.isButtonsShown}
                              shouldShowButtonLabels={this.props.showLabels}
                            />
                          </>
                        )}
                        <AddNote
                          changeMyTool={(toolName) =>
                            this.onClickSubButton(toolName)
                          }
                          activeButton={this.state.activeSubButton}
                          activeSubButton={this.state.activeSubButton}
                          state={this.state}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <AddVideo
                          changeMyTool={(toolName) =>
                            this.onClickSubButton(toolName)
                          }
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <AddH5p
                          changeMyTool={() => this.onClickSubButton("addh5p")}
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <AddAudio
                          changeMyTool={() => this.onClickSubButton("addaudio")}
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <AddImage
                          changeMyTool={() => this.onClickSubButton("addimage")}
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <AddLink
                          changeMyTool={(toolName) =>
                            this.onClickSubButton(toolName)
                          }
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                      </ul>
                    </div>
                  </div>
                )}

                {devMode && (
                  <div
                    style={{ marginRight: 28 }}
                    className={`draw-toolbar question-options ${
                      this.state.activeButton === "question-options"
                        ? "open"
                        : ""
                    }
                ${!isSmallScreen ? "" : "question-options-small-screen"}

                `}
                    id="add-questions-toolbar"
                  >
                    <div className="buttons has-addons">
                      <ul>
                        <AddMCQuestion
                          changeMyTool={() =>
                            this.onClickSubButton("addmcquestion")
                          }
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                        <AddMRQuestion
                          changeMyTool={() =>
                            this.onClickSubButton("addmrquestion")
                          }
                          activeButton={this.state.activeSubButton}
                          shouldShowButtonLabels={this.props.showLabels}
                        />

                        <li>
                          <span
                            className="button normal-btn next-page"
                            title="Drag Drop"
                            data-tip={"Drag Drop"}
                            onClick={() => {
                              this.buttonHandler("qshowable");
                              // this.props.setAnnotationSelectionMode(true);
                            }}
                          >
                            <img
                              src={require("../assets/img/svg/tools-question2.svg")}
                              alt="Drag Drop"
                            />
                          </span>
                        </li>
                        <li>
                          <span
                            className="button normal-btn next-page"
                            title="Fill in the Blank"
                            data-tip={"Fill in the Blank"}
                          >
                            <img
                              src={require("../assets/img/svg/tools-fill-in-the-blank.svg")}
                              alt="Fill in the Blank"
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                <div
                  className={`draw-toolbar ${
                    zoomMode ? "teacher-tools-zoom" : "teacher-tools"
                  } ${
                    this.state.activeButton === "teacher-tools" ? "open" : ""
                  }`}
                  id="add-media-toolbar"
                >
                  <div className="buttons has-addons">
                    <ul>
                      <AddBlankPage
                        // onClick={this.onClickCapture}
                        onClick={() => this.buttonHandler("addblankpage")}
                        activeButton={this.state.activeButton}
                        hideButton={this.props.isButtonsShown}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      {!zoomMode && (
                        <Capture
                          // onClick={this.onClickCapture}
                          onClick={() => this.buttonHandler("capture")}
                          activeButton={this.state.activeButton}
                          hideButton={this.props.isButtonsShown}
                          shouldShowButtonLabels={this.props.showLabels}
                        />
                      )}
                      <Curtain
                        onClick={() => this.buttonHandler("curtain")}
                        onClick={this.onClickCurtain}
                        activeButton={this.state.activeButton}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      <AddTimer
                        // onClick={this.onClickCapture}
                        // onClick={() => this.buttonHandler("addtimer")}
                        onClick={() => this.props.showTimer(true)}
                        activeButton={this.state.activeButton}
                        hideButton={this.props.isButtonsShown}
                        shouldShowButtonLabels={this.props.showLabels}
                      />
                      {!zoomMode && (
                        <PresentationMode
                          onClick={() => this.presentationMode()}
                          activeButton={this.state.activeButton}
                          hideButton={this.props.isButtonsShown}
                          shouldShowButtonLabels={this.props.showLabels}
                          active={zoomMode ? true : false}
                          disabled={
                            qZoomButtons && qZoomButtons.length > 0
                              ? false
                              : true
                          }
                        />
                      )}
                    </ul>
                  </div>
                </div>

                <div
                  className={`draw-toolbar devmode-tools ${
                    this.state.activeButton === "devmode-tools" ? "open" : ""
                  }
                ${!isSmallScreen ? "" : "devmode-tools-small-screen"} `}
                  id="add-media-toolbar"
                >
                  <div className="buttons has-addons">
                    <ul></ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    currentPage: selectCurrentPageWithMyContent(state),
    TotalPageNumber: state.pages.totalPageNumber,
    scaleZoomFactorVal: state.scaleZoomFactor,
    isButtonsShown: state.isButtonsShown,
    selectedTool: state.selectedTool.selectedTool,
    selectedToolChangedFromOutside:
      state.selectedTool.selectedToolChangedFromOutside,
    selectedToolChangedFromOutsideShouldCloseTheMenu:
      state.selectedTool.shouldCloseTheMenu,
    searchBarOpen: state.searchBarOpen,
    // imageCapture: state.imageCapture,
    canvasVals: state.scaleZoomFactor.canvas,
    controlledPosition: state.toolbar.toolbarPosition,
    showAnnotations: state.settings.showAnnotations,
    toolbar: state.toolbar,
    showLabels: state.toolbar.showLabels,
    toolbarSize: state.toolbar.toolbarSize,
    roles: state.login.user_profile.roles,
    ebookId: state.pages.ebookId,
    advancedMode: state.toolbar.advancedMode,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    pageZoomPartObject: state.settings.pageZoomPartObject,
    interactivityMode: state.settings.addInteractivityMode,
    // zooms: state.pages.zooms,
    zooms: selectZooms(state),
    isTeacher: selectUserHasTeacherRole(state),
    noRole: selectUserHasNoRole(state),
    isStudent: selectUserHasStudentRole(state),
    isSuperAdmin: selectUserHasSuperAdminRole(state),
    isDomainAdmin: selectUserHasDomainAdminRole(state),
    isDeveloper: selectUserHasDeveloperRole(state),
    isProgramCoordinator: selectUserHasProgramCoordinatorRole(state),
    pageIsLoading: state.settings.pageIsLoading,
  };
};

export default connect(
  mapStateToProps,
  {
    pageNoChange,
    toggleButtons,
    // captureInProcess,
    // hideCaptureImage,
    hideInteractiveObjects,
    showInteractiveObjects,
    setToolbarPosition,
    resetToolbarPosition,
    pinToolbar,
    unpinToolbar,
    setScalePageReset,
    changeSelectedTool,
    setAnnotationSelectionMode,
    showAnnotations,
    hideAnnotations,
    triggerRedo,
    triggerUndo,
    triggerRemove,
    triggerRemoveAll,
    triggerZoomIn,
    triggerZoomOut,
    setLineWidth,
    setLineColor,
    setFillColor,
    setToolbarVertical,
    insertPage,
    setPagePartZoomMode,
    setPagePartZoomObjectToShow,
    setPagePartZoomObject,
    showTimer,
    setZoomModeMenu,
    setToolbarMode,
    setAddInteractivityMode,
  },
  null,
  { forwardRef: true }
)(Toolbar);
