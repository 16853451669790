import React from "react";
import { useTranslation } from "react-i18next";

export default function ImageButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object-2 qzoom" //qshow makes bg transparent
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className="object-icon icon-only">
      <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          // fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle  cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2} fill="#ffffff" />
          <path
            d="M12.0312 5C8.14789 5 5 8.14812 5 12.0312C5 15.9144 8.14812 19.0625 12.0312 19.0625C15.9144 19.0625 19.0625 15.9144 19.0625 12.0312C19.0625 8.14812 15.9144 5 12.0312 5ZM10.3965 16.25L10.3918 16.2453L10.3878 16.25L7.10938 12.875L8.7568 11.1959L10.392 12.8797L15.3139 7.81273L16.9531 9.49977L10.3965 16.25Z"
            fill="#09A129"  fillOpacity="0.5"
          />
          
        </svg>
      </span>
      {/* <span className="object-text">{t("Image")}</span> */}
    </div>
  );
}
