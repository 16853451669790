import {
  SELECTED_TOOL_CHANGED,
  SELECTED_TOOL_CHANGED_FROM_OUTSIDE,
  PAGE_NO_CHANGE,
  ZOOM_IN_TRIGGERED,
  ZOOM_OUT_TRIGGERED
} from "./actionTypes";

export const changeSelectedTool = (selectedTool) => {
  return {
    type: SELECTED_TOOL_CHANGED,
    payload: selectedTool,
  };
};
export const changeSelectedToolFromOutside = (
  selectedTool,
  closeMenu = true
) => {
  // console.error(`selectedTool`, selectedTool)
  return {
    type: SELECTED_TOOL_CHANGED_FROM_OUTSIDE,
    payload: [selectedTool, closeMenu],
  };
};
export const updatePageNo = (newPageNo) => {
  return {
    type: PAGE_NO_CHANGE,
    payload: newPageNo,
  };
};
export const triggerZoomIn = (payload) => {
  return {
    type: ZOOM_IN_TRIGGERED,
    payload,
  };
};
export const triggerZoomOut = (payload) => {
  return {
    type: ZOOM_OUT_TRIGGERED,
    payload,
  };
};
// export const changeSelectedSubButton = (button) => {
//   return {
//     type: SELECTED_TOOL_SUB_BUTTON_CHANGED,
//     payload: button,
//   };
// };

// export const changeSelectedSubChildButton = (button) => {
//   return {
//     type: SELECTED_TOOL_SUB_CHILD_BUTTON_CHANGED,
//     payload: button,
//   };
// };

// export const changeSelectedMouseButton = (button) => {
//   return {
//     type: SELECTED_TOOL_MOUSE_BUTTON_CHANGED,
//     payload: button,
//   };
// };
