import React, { createContext, useState } from "react";
import WinBox from "../../node_modules/winbox/src/js/winbox.js";
import ReactDOM from "react-dom";
import "../assets/css/winbox.css";
import "./winboxcu.css";
import { HiOutlineSun, HiOutlineTrash, HiPencilAlt } from "react-icons/hi";

window["buttonFIre"] = () => {
  console.log("working");
};

const WinboxContext = createContext();

const callWinBox = function ({
  title,
  id,
  closeHelper,
  moveHelper,
  focusHelper,
  blurHelper,
  resizeHelper,
  fullObj,
  width,
  height,
  minwidth,
  minheight,
  winboxX,
  classNames = ["no-full", "my-theme-"],
}) {
  return WinBox.new({
    id: id ? id : "my-window",
    class: classNames,
    root: document.body,
    title: "All Options",
    background: "#fff",
    width,
    height,
    minwidth,
    minheight,
    // border: 4,
    x: winboxX,
    // y: "center",
    max: false,
    // splitscreen: true,
    ...fullObj,
    modal: false,
    top: 5,
    // right: 50,
    // bottom: 0,
    // left: 50,
    // url,
    // ...div&&{
    //   mount: div
    // },
    onfocus: focusHelper,
    onblur: blurHelper,
    onresize: function (width, height) {
      resizeHelper && resizeHelper();
    },
    onmove: moveHelper,
    onclose: closeHelper,
  });
};

const WinboxProvider = ({ children }) => {
  const [winboxState, setWinboxState] = useState([]);
  /**
   *
   * @param {Object} title
   * @param {String} id
   * @param {Object} childComp
   * @param {Function} closeHelper
   * @param {Function} moveHelper
   * @param {Function} focusHelper
   * @param {Function} blurHelper
   * @param {Function} resizeHelper
   */
  const CallWinBox = ({
    title,
    id,
    childComp,
    url,
    closeHelperFunc,
    moveHelper,
    focusHelper,
    blurHelper,
    resizeHelper,
    fullObj,
    width,
    height,
    minwidth,
    minheight,
    classNames,
    itemId,
    editHandler,
    deleteHandler,
    submitHandler,
    sunHandler,
    currentPageId,
    winboxX,
  }) => {
    const oldLength = winboxState.filter((ele, index) => {
      const idc = `winbox_${itemId?.id}`;
      if (ele.id == idc) {
        ele.minimize();
        return true;
      }
    });
    console.log(oldLength);

    if (!oldLength.length) {
      const closeHelper = () => {
        setWinboxState((old) => {
          const oldStore = [...old];
          if (oldStore) {
            return oldStore.filter(
              (currentObject) => currentObject !== winBoxObject
            );
          }
          return oldStore;
        });
        closeHelperFunc();
      };

      const winBoxObject = callWinBox({
        title,
        id: `winbox_${itemId?.id}`,
        closeHelper,
        moveHelper,
        focusHelper,
        blurHelper,
        resizeHelper,
        fullObj,
        width,
        height,
        minwidth,
        minheight,
        classNames,
        winboxX,
      });
      if (url) {
        winBoxObject && winBoxObject.setUrl(url);
      }
      if (childComp) {
        let div;
        div = document.createElement("div");
        ReactDOM.render(childComp, div);
        winBoxObject && winBoxObject.mount(div);
      }

      const winBoxObjectTitle = winBoxObject?.dom?.getElementsByClassName(
        "wb-icon"
      )[0];
      const titleDIv = document.createElement("div");
      titleDIv.classList.add("newelmentcontainer");
      winBoxObjectTitle.prepend(titleDIv);
      ReactDOM.render(
        <div className="winbox-header-wrapper">
          <div className="display-flex">
            {editHandler && (
              <ButtonEdit
                callHandler={editHandler.bind(this, [
                  itemId,
                  winBoxObject,
                  "edit",
                ])}
                icon={
                  <span className="icon is-small has-text-link">
                    <HiPencilAlt className="fas fa-2x" />
                  </span>
                }
              />
            )}
            {submitHandler && (
              <ButtonEdit
                callHandler={submitHandler.bind(this, [
                  itemId,
                  winBoxObject,
                  "save",
                ])}
                icon={
                  <span className="icon is-small has-text-link">
                    <HiPencilAlt className="fas fa-2x" />
                  </span>
                }
              />
            )}
            {deleteHandler && (
              <ButtonEdit
                callHandler={deleteHandler.bind(this, [
                  itemId,
                  winBoxObject,
                  "delete",
                ])}
                icon={
                  <span className="icon is-small has-text-danger">
                    <HiOutlineTrash className="fas fa-2x" />
                  </span>
                }
              />
            )}
            {sunHandler && (
              <ButtonEdit
                // callHandler={modalWinbox}
                callHandler={sunHandler.bind(this, [
                  itemId,
                  winBoxObject,
                  "sun",
                ])}
                icon={
                  <span className="icon is-small has-text-warning">
                    <HiOutlineSun className="fas fa-2x" />
                  </span>
                }
              />
            )}
          </div>
        </div>,
        titleDIv
      );
      const winBoxObjectTitleONly = winBoxObject?.dom?.getElementsByClassName(
        "wb-title"
      )[0];
      ReactDOM.render(<span>{" | " + title}</span>, winBoxObjectTitleONly);
      setWinboxState((old) => {
        const oldStore = [...old];
        oldStore.push(winBoxObject);
        return oldStore;
      });
    }
  };

  const closeAllIfOpen = () => {
    try {
      setWinboxState((old) => {
        old.map((e) => e && e.close(true));
        return [];
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <WinboxContext.Provider value={{ CallWinBox, closeAllIfOpen, winboxState }}>
      {children}
    </WinboxContext.Provider>
  );
};
export { WinboxContext, WinboxProvider };

const Button = ({ callHandler }) => {
  return (
    <button className="winbox-header-button" onClick={callHandler}>
      <i class="fas fa-sun customeyellow"></i>
      {/* <span className="icon is-small has-text-warning">
        <HiOutlineSun className="fas fa-2x pt-2" />
      </span> */}
    </button>
  );
};
const ButtonEdit = ({ callHandler, icon }) => {
  return (
    <button className="winbox-header-button " onClick={callHandler}>
      {icon}
    </button>
  );
};
