import {
  GET_PAGES,
  GET_ZIP,
  PAGE_NO_CHANGE,
  // ZIP_DOWN_PROG,
  RESET_PAGES,
  INSERT_ACTIVITY,
  DELETE_ACTIVITY,
  INSERT_PAGE,
  UPDATE_ACTIVITY,
  DELETE_ALL_PAGE_ACTIVITIES,
  DELETE_ALL_PAGE_ZOOM_ACTIVITIES,
  // ZOOM_SUB_PAGE_NO_CHANGE,
  SET_MY_CONTENT,
  DELETE_ACTIVITY_KEEP_COORDS_BY_PAGE,
  SET_CLEAR_KEEP_COORDS,
} from "../actions/actionTypes";

const defaultPageState = {
  pages: [],
  totalPageNumber: 0,
  currentPageNo: 1,
  // currentPage: {},
  zipLoaded: false,
  firstImageProps: null,
  toc: {},
  icon: "",
  ebookId: null,
  currentZoomSubPageNo: 1,
  totalZoomSubPageNumber: 0,
  // zooms: null,
  myContent: [],
  clearKeepCoords: false,
  // addedActivities: 0,
};

export default (state = defaultPageState, action) => {
  switch (action.type) {
    case RESET_PAGES:
      return {
        pages: [],
        totalPageNumber: 0,
        currentPageNo: 1,
        // currentPage: {},
        firstImageProps: null,
        zipLoaded: false,
        toc: {},
        icon: "",
        ebookId: null,
        // zooms: [],
        myContent: [],
        // addedActivities: 0,
      };
    case GET_PAGES:
      // console.log("-------------action.payload: ", action.payload);
      let pages = action.payload.ebook_pages;
      pages.sort((page1, page2) => {
        if (page1.page_number > page2.page_number) return 1;
        if (page1.page_number < page2.page_number) return -1;
        return 0;
      });
      let pagesWithNums = pages.map((page, index) => ({
        ...page,
        pageNumber: index + 1,
      }));
      const itemId = `${pages[0].ebook_id}_localPages`;
      const localPages = JSON.parse(localStorage.getItem(itemId));
      if (localPages) {
        if (localPages[0].pageNumber !== pagesWithNums.length + 1) {
          for (let index = 0; index < localPages.length; index++) {
            const element = localPages[index];
            element.pageNumber = pagesWithNums.length + index + 1;
            element.page_number = pagesWithNums.length + index + 1;
          }

          localStorage.setItem(itemId, JSON.stringify(localPages));
        }
        pagesWithNums = [...pagesWithNums, ...localPages];
      }

      return {
        ...state,
        pages: pagesWithNums,
        totalPageNumber: pagesWithNums.length,
        currentPageNo: state.currentPageNo,
        firstImageProps: action.payload.first_image_props,
        zipLoaded: state.zipLoaded,
        toc: JSON.parse(action.payload.toc),
        icon: action.payload.icon,
        ebookId: pages[0].ebook_id,
      };
    case SET_MY_CONTENT: {
      const buttons = action.payload.buttons;
      // console.log("__________-----buttons: ", buttons);
      return {
        ...state,
        myContent: buttons,
      };
    }
    case INSERT_ACTIVITY: {
      let newButton = action.payload[0];
      const newButtonsPageId = action.payload[1];
      newButton.ebook_pages_id = newButtonsPageId;
      const buttonsPage = state.pages.find((pg) => pg.id === newButtonsPageId);
      const newButtons = [...buttonsPage.buttons, newButton];
      buttonsPage.buttons = newButtons;

      const pageIndex = state.pages.findIndex(
        (pg) => pg.id === newButtonsPageId
      );
      const newMyButtons = [
        ...state.myContent.filter((btn) => btn.id !== newButton.id),
        newButton,
      ];
      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          buttonsPage,
          ...state.pages.slice(pageIndex + 1),
        ],
        myContent: newMyButtons,
      };
    }
    case SET_CLEAR_KEEP_COORDS: {
      return {
        ...state,
        clearKeepCoords: action.payload,
      };
    }

    case DELETE_ACTIVITY_KEEP_COORDS_BY_PAGE: {
      //Delete the keep coords of the pages' all objects to reinstate the objects on moving to next page
      const newButtonsPageId = action.payload;
      const buttonsPage = state.pages.find((pg) => pg.id === newButtonsPageId);

      buttonsPage.buttons.map((bt) => {
        bt.keepCoords = null;
        return bt;
      }); //AfterUpdateCoordsOnly  If the user moved the button to change the buttons coords, we got the keep coords now we need to clear old keep coords for the current page
      const newMyButtons = state.myContent;
      newMyButtons.map((bt) => {
        if (bt.ebook_pages_id === newButtonsPageId) {
          bt.keepCoords = null;
        }
        return bt;
      }); //AfterUpdateCoordsOnly  If the user moved the button to change the buttons coords, we got the keep coords now we need to clear old keep coords for the current page

      const pageIndex = state.pages.findIndex(
        (pg) => pg.id === newButtonsPageId
      );

      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          buttonsPage,
          ...state.pages.slice(pageIndex + 1),
        ],
        myContent: newMyButtons,
        clearKeepCoords: null,
      };
    }
    case UPDATE_ACTIVITY: {
      let newButton = action.payload[0];
      const newButtonsPageId = action.payload[1];
      newButton.ebook_pages_id = newButtonsPageId;
      const shouldUpdateCurrentPage = action.payload[2];
      const buttonsPage = state.pages.find((pg) => pg.id === newButtonsPageId);
      if (!shouldUpdateCurrentPage) {
        const oldButton = buttonsPage.buttons.find(
          (bt) => bt.id === newButton.id
        );
        if (
          !oldButton.keepCoords ||
          oldButton.keepCoords.pageId !== newButtonsPageId
        ) {
          console.log("! HERE");
          newButton.keepCoords = {
            pageId: newButtonsPageId,
            left: oldButton.left,
            top: oldButton.top,
          }; //AfterUpdateCoordsOnly  If the user moved the button to change the buttons coords , we need to keep old coords for the current page
        }
      }
      // console.log(`newButton`, newButton);
      const buttonIndex = buttonsPage.buttons.findIndex(
        (bt) => bt.id === newButton.id
      );
      const buttonIndexMyContent = state.myContent.findIndex(
        (bt) => bt.id === newButton.id
      );
      const newButtons = [
        ...buttonsPage.buttons.slice(0, buttonIndex),
        newButton,
        ...buttonsPage.buttons.slice(buttonIndex + 1),
      ];

      const newMyButtons = [
        ...state.myContent.slice(0, buttonIndexMyContent),
        newButton,
        ...state.myContent.slice(buttonIndexMyContent + 1),
      ];
      buttonsPage.buttons = newButtons;

      const pageIndex = state.pages.findIndex(
        (pg) => pg.id === newButtonsPageId
      );

      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          buttonsPage,
          ...state.pages.slice(pageIndex + 1),
        ],
        myContent: newMyButtons,
        clearKeepCoords: newButtonsPageId,
      };
    }
    case DELETE_ACTIVITY: {
      let buttonIdToDelete = action.payload[0];
      console.log("buttonIdToDelete: ", buttonIdToDelete[0]);
      const newButtonsPageId = action.payload[1];
      const buttonsPage = state.pages.find((pg) => pg.id === newButtonsPageId);
      const newButtons = buttonsPage.buttons.filter(
        (btn) => btn.id !== buttonIdToDelete
      );
      buttonsPage.buttons = newButtons;

      const pageIndex = state.pages.findIndex(
        (pg) => pg.id === newButtonsPageId
      );
      const newMyButtons = state.myContent.filter(
        (btn) => btn.id !== buttonIdToDelete
      );

      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          buttonsPage,
          ...state.pages.slice(pageIndex + 1),
        ],
        myContent: newMyButtons,
      };
    }
    case DELETE_ALL_PAGE_ACTIVITIES: {
      const newButtonsPageId = action.payload;
      const buttonsPage = state.pages.find((pg) => pg.id === newButtonsPageId);
      buttonsPage.buttons = [];
      const pageIndex = state.pages.findIndex(
        (pg) => pg.id === newButtonsPageId
      );
      const newMyButtons = state.myContent.filter(
        (btn) => btn.ebook_pages_id !== newButtonsPageId
      );

      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          buttonsPage,
          ...state.pages.slice(pageIndex + 1),
        ],
        myContent: newMyButtons,
      };
    }
    case DELETE_ALL_PAGE_ZOOM_ACTIVITIES: {
      const newButtonsPageId = action.payload;
      const buttonsPage = state.pages.find((pg) => pg.id === newButtonsPageId);
      const newButtons = buttonsPage.buttons.filter(
        (btn) => btn.button_type !== "zoomPagePart"
      );
      buttonsPage.buttons = newButtons;
      const pageIndex = state.pages.findIndex(
        (pg) => pg.id === newButtonsPageId
      );
      const newMyButtons = state.myContent.filter(
        (btn) =>
          btn.ebook_pages_id !== newButtonsPageId &&
          btn.button_type !== "zoomPagePart"
      );

      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          buttonsPage,
          ...state.pages.slice(pageIndex + 1),
        ],
        myContent: newMyButtons,
      };
    }
    case PAGE_NO_CHANGE: {
      let newPageNo = action.payload;
      if (newPageNo === 0) {
        newPageNo = 1;
      } else if (state.totalPageNumber === 0) {
      } else if (newPageNo === state.totalPageNumber + 1) {
        newPageNo = state.totalPageNumber;
      } else if (newPageNo > state.totalPageNumber) {
        newPageNo = state.totalPageNumber;
      }
      window.location.hash = `#/indivibook/${newPageNo}`;

      return {
        ...state,
        currentPageNo: newPageNo,
        currentPage: [state.pages[newPageNo - 1]],
      };
    }
    // case ZOOM_SUB_PAGE_NO_CHANGE: {
    //   let newPageNo = action.payload;
    //   if (newPageNo === 0) {
    //     newPageNo = 1;
    //   } else if (state.totalZoomSubPageNumber === 0) {
    //   } else if (newPageNo === state.totalZoomSubPageNumber + 1) {
    //     newPageNo = state.totalZoomSubPageNumber;
    //   } else if (newPageNo > state.totalZoomSubPageNumber) {
    //     newPageNo = state.totalZoomSubPageNumber;
    //   }
    //   window.location.hash = `#/indivibook/${newPageNo}`;
    //   return {
    //     ...state,
    //     currentPageNo: newPageNo,
    //     currentPage: [state.pages[newPageNo - 1]],
    //   };
    // }
    case GET_ZIP:
      // var pagesAfterURLChange = state.pages;
      var blobURLs = action.payload;
      var pagesAfterURLChange = state.pages.map((page, ind) => {
        if (blobURLs[ind]) {
          page.path = blobURLs[ind];
        }
      });

      return {
        ...state,
        // pages: state.pages,
        // totalPageNumber: state.totalPageNumber,
        // currentPageNo: state,
        // currentPage: state.currentPage,
        zipLoaded: true,
        // toc: state.toc,
        // icon: state.icon,
      };

    case INSERT_PAGE:
      console.log("INSERT_PAGE action.payload", action.payload);

      return {
        ...state,
        pages: [...state.pages, action.payload],
        totalPageNumber: state.totalPageNumber + 1,
      };

    default:
      return state;
  }
};
