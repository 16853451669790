/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { pullImagesFromPixabay } from "../actions/pixabay";
import { pullImagesFromUnsplash } from "../actions/unsplash";
import { pullVideosFromPixabay } from "../actions/pixabayVideo";
import { pullWordFromMerriam } from "../actions/merriam";
import { getWikipedia } from "../actions/wikipedia";
import { closePixabay } from "../actions/pixabay";
import { closeUnsplash } from "../actions/unsplash";
import { closePixabayVideo } from "../actions/pixabayVideo";
import { getWiktionary } from "../actions/wiktionary";
import { getQwant } from "../actions/qwant";
import { toggleSearchBar } from "../actions/searchbar";
import { toggleSideBar } from "../actions/sidebar";
import PixabayVideoIcon from "../assets/img/pixabayVideo.png";
import VectorIcon from "../assets/img/vector.png";

import PixabayIcon from "../assets/img/pixabay4.png";
import UnsplashIcon from "../assets/img/unsplash.png";

import WiktionaryImg from "../assets/img/wiktionary.png";
import WikipediaIcon from "../assets/img/wikipedia.png";
import MerriamIcon from "../assets/img/merriam.png";
import WordWeb from "../assets/img/wordweb.png";
import Google from "../assets/img/google.png";
import Pbs from "../assets/img/pbs.png";
import GoogleImages from "../assets/img/google-images.png";
// import QwantImg from "../assets/img/qwant.png";
// import Select from "react-select";
import SearchButton from "./SubComponents/SearchBar/SearchButton";

import Pixabay from "./Pixabay";
import Unsplash from "./Unsplash";
import PixabayVideo from "./PixabayVideo";
import Wikipedia from "./Wikipedia";
import Wiktionary from "./Wiktionary";
import Merriam from "./Merriam";
// import Qwant from "./Qwant";
// import configrations from "../config.json";
import isElectron from "is-electron";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { setInputFocused } from "../actions/settings";
import SBNotFoundMessage from "./SBNotFoundMessage";
import { BsInfoCircleFill } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { FaKeyboard } from "react-icons/fa";

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      showSources: false,
      inputValue: "",
      pixabayClicked: false,
      unsplashClicked: false,
      pixabayVideoClicked: false,
      marriamClicked: false,
      showKeyboard: false,
      layoutName: "default",
    };
  }
  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "shift" ? "default" : "shift",
    });
  };
  handleCaps = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "caps" ? "default" : "caps",
    });
  };
  onChange = (input) => {
    console.log("Input changed", input);
    this.props.closePixabay();
    this.props.closeUnsplash();
    this.props.closePixabayVideo();

    if (input) {
      this.setState({
        showSources: true,
        inputValue: input,
      });
    } else {
      this.setState({ showSources: false, inputValue: "" });
    }
  };

  onKeyPress = (button) => {
    console.log("Button pressed", button);
  };
  handleOnChange = (e) => {
    this.props.closePixabay();
    this.props.closeUnsplash();
    this.props.closePixabayVideo();

    if (e.target.value) {
      this.setState({
        showSources: true,
        inputValue: e.target.value,
      });
    } else {
      this.setState({ showSources: false, inputValue: "" });
    }
  };

  pixabayHandler = () => {
    this.setState({
      pixabayClicked: true,
      unsplashClicked: false,
      pixabayVideoClicked: false,
    });
    this.props.pullImagesFromPixabay(this.state.inputValue, 1, "all");
  };
  pixabayHandlerVector = () => {
    this.setState({
      pixabayClicked: true,
      unsplashClicked: false,
      pixabayVideoClicked: false,
    });
    this.props.pullImagesFromPixabay(this.state.inputValue);
  };

  unSplashHandler = () => {
    this.setState({
      pixabayClicked: false,
      unsplashClicked: true,
      pixabayVideoClicked: false,
    });
    this.props.pullImagesFromUnsplash(this.state.inputValue);
  };

  pixabayVideoHandler = () => {
    this.setState({
      pixabayClicked: false,
      unsplashClicked: false,
      pixabayVideoClicked: true,
    });
    this.props.pullVideosFromPixabay(this.state.inputValue);
  };

  wikipediaHandler = () => {
    this.props.getWikipedia();
  };

  merriamHandler = () => {
    this.setState({
      pixabayClicked: false,
      unsplashClicked: false,
      pixabayVideoClicked: false,
    });
    this.props.pullWordFromMerriam(this.state.inputValue);
  };

  wiktionaryHandler = () => {
    this.props.getWiktionary();
  };
  wordwebHandler = () => {
    let url = `https://www.wordwebonline.com/search.pl?w=${this.state.inputValue}`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
  };

  pbslearningHandler = () => {
    let url = `https://www.pbslearningmedia.org/search/?q=${this.state.inputValue}&hl=${i18n.language}&`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
  };

  googleHandler = () => {
    let url = `https://www.google.com/search?q=${this.state.inputValue}&source=lmns&safe=active&hl=${i18n.language}`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
  };

  googleImagesHandler = () => {
    let url = `https://www.google.com/search?safe=active&q=${this.state.inputValue}&tbm=isch&hl=${i18n.language}`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
  };

  /**
   * hides and opens sidebar
   */
  toggleSearchBar = () => {
    if(this.props.searchBarOpened){
      this.setState({
        showKeyboard:false
      })
    }
    this.props.toggleSearchBar(this.props.searchBarOpened);
  };
  onSuggestionClick = (e) => {
    this.setState({ inputValue: e.target.dataset.value, showSources: true });
  };

  showContent = () => {
    confirmAlert({ customUI: this.showContentCustomUI });
  };

  showContentCustomUI = ({ onClose }) => {
    const handleClickedClose = () => {
      onClose();
    };
    return (
      <section className="hero is-large">
        <div className="hero-body has-text-centered">
          <div className="container">
            <button
              className="button is-danger is-rounded is-bold is-size-4 is-pulled-right pb-0 pt-0 pl-3 pr-3"
              style={{ marginRight: -15, marginTop: -15, height: "auto" }}
              onClick={handleClickedClose}
            >
              X
            </button>
            <div className="box">
              {this.props.t(
                "Suggested words are words from the current page. You can click a word and search definitions, images, etc."
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };
  toggleKeyboard = () => {
    this.setState({
      showKeyboard: !this.state.showKeyboard,
    });
  };

  render() {
    const isSmallScreen = this.props.canvasVals
      ? parseInt(this.props.canvasVals.vpW) < 768
      : false;
    return (
      <div
        className={`searchbar column is-narrow is-fullheight ${
          this.props.searchBarOpened ? "open" : "hide"
        }`}
        style={{ overflowY: "hidden" }}
      >
        <nav className="panel searchbar-list">
          <div className="is-flex is-flex-direction-row is-justify-content-center	">
            <p className="panel-heading ">{this.props.t("Search")}</p>
            <span
              className={`icon  ${
                this.state.showKeyboard ? "has-text-warning" : "has-text-light"
              } mt-2 `}
              onClick={this.toggleKeyboard}
            >
              <FaKeyboard className="fas fa-2x" />
            </span>
          </div>
          <div className="panel-block searchbar-top-search">
            <div className="control has-icons-left">
              <input
                className="input is-small pl-5"
                onChange={this.handleOnChange}
                onFocus={() => this.props.setInputFocused(true)}
                onBlur={() => this.props.setInputFocused(false)}
                type="text"
                placeholder={this.props.t("search")}
                value={this.state.inputValue}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.setState({
                      pixabayClicked: true,
                    });
                    this.props.closePixabay();
                    this.props.closePixabayVideo();
                    this.props.closeUnsplash();
                    this.props.pullImagesFromPixabay(this.state.inputValue);
                    this.props.pullImagesFromUnsplash(this.state.inputValue);
                    this.props.pullVideosFromPixabay(this.state.inputValue);
                  }
                }}
              />

              {this.props.searchWords.length > 0 && (
                <div>
                  <div>
                    <h4 className="suggested-words-title">
                      {this.props.t("Suggested Words")}
                      <span onClick={this.showContent}>
                        <BsInfoCircleFill className="fas fa mb-1 ml-2" />
                      </span>
                    </h4>
                  </div>

                  <div
                    style={{
                      backgroundColor: "white",
                      height: "100px",
                      overflow: "auto",
                    }}
                  >
                    <ul>
                      {this.props.searchWords.map((word) => {
                        return (
                          <li
                            data-value={word}
                            onClick={this.onSuggestionClick}
                            key={word}
                          >
                            {word}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              <span className="icon is-small is-left">
                <i className="fas fa-search" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div
            className="search-type-list"
            style={{ display: this.state.showSources ? "block" : "none" }}
          >
            <SearchButton
              title={this.props.t("Search Vector")}
              icon={VectorIcon}
              handler={this.pixabayHandlerVector}
            />
            <SearchButton
              title={this.props.t("Search images")}
              icon={PixabayIcon}
              handler={this.pixabayHandler}
            />
            <SearchButton
              title={this.props.t("Search images")}
              icon={UnsplashIcon}
              handler={this.unSplashHandler}
            />

            <SearchButton
              title={this.props.t("Search Video")}
              icon={PixabayVideoIcon}
              handler={this.pixabayVideoHandler}
            />

            <SearchButton
              title={this.props.t("Search on Wikipedia")}
              icon={WikipediaIcon}
              handler={this.wikipediaHandler}
            />
            {(i18n.language === "en" ||
              process.env.REACT_APP_MERRIAM_KEY !== undefined) && (
              <>
                <SearchButton
                  title={this.props.t("Search on Merriam Dictionary")}
                  icon={MerriamIcon}
                  handler={this.merriamHandler}
                />
                <SearchButton
                  title={this.props.t("Search on Wiktionary")}
                  icon={WiktionaryImg}
                  handler={this.wiktionaryHandler}
                />
                <SearchButton
                  title={this.props.t("Search on WordWeb")}
                  icon={WordWeb}
                  handler={this.wordwebHandler}
                />
                <SearchButton
                  title={this.props.t("Search on PBS Learning Media")}
                  icon={Pbs}
                  handler={this.pbslearningHandler}
                />
              </>
            )}

            <SearchButton
              title={this.props.t("Search on Google")}
              icon={Google}
              handler={this.googleHandler}
            />
            <SearchButton
              title={this.props.t("Search on Google Images")}
              icon={GoogleImages}
              handler={this.googleImagesHandler}
            />
          </div>
          {this.state.showKeyboard &&  (
            <Keyboard
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
              layoutName={
                this.state.layoutName+i18n.language
              }
              onKeyPress={(event) => {
                if (event === "{enter}") {
                  console.log("!event: ", event);
                  this.setState({
                    pixabayClicked: true,
                  });
                  this.props.closePixabay();
                  this.props.closePixabayVideo();
                  this.props.closeUnsplash();
                  this.props.pullImagesFromPixabay(this.state.inputValue);
                  this.props.pullImagesFromUnsplash(this.state.inputValue);
                  this.props.pullVideosFromPixabay(this.state.inputValue);
                } else if (event === "{lock}") {
                  this.handleCaps();
                } else if (event === "{shift}") this.handleShift();
              }}
              layout={{
                defaulten: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} q w e r t y u i o p [ ] \\",
                  "{lock} a s d f g h j k l ; ' {enter}",
                  "{shift} z x c v b n m , . / {shift}",
                  ".com @ {space}",
                ],
                shiften: [
                  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M < > ? {shift}",
                  ".com @ {space}",
                ],
                capsen: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  "{lock} A S D F G H J K L ; ' {enter}",
                  "{shift} Z X C V B N M , . / {shift}",
                  ".com @ {space}",
                ],
                defaulttr: [
                  '" 1 2 3 4 5 6 7 8 9 0 * - # {bksp}',
                  "{tab} q w e r t y u ı o p ğ ü [ ]",
                  "{lock} a s d f g h j k l ş i , {enter}",
                  "{shift} < z x c v b n m ö ç . | $ € {shift}",
                  ".com @ {space}",
                ],
                shifttr: [
                  "é ! ' ^ + % & / ( ) = ? _ ~ {bksp}",
                  "{tab} Q W E R T Y U I O P Ğ Ü { }",
                  "{lock} A S D F G H J K L Ş İ ; {enter}",
                  "{shift} > Z X C V B N M Ö Ç : \\ ` ´ {shift}",
                  ".com @ {space}",
                ],
                capstr: [
                  '" 1 2 3 4 5 6 7 8 9 0 * - # {bksp}',
                  "{tab} Q W E R T Y U I O P Ğ Ü [ ]",
                  "{lock} A S D F G H J K L Ş İ , {enter}",
                  "{shift} > Z X C V B N M Ö Ç . | $ € {shift}",
                  ".com @ {space}",
                ],
                defaultfr: [
                  "` 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}",
                  "{tab} a z e r t y u i o p ^ $",
                  "{lock} q s d f g h j k l m \u00F9 * {enter}",
                  "{shift} < w x c v b n , ; : ! {shift}",
                  ".com @ {space}",
                ],
                shiftfr: [
                  "\u00B2 & \u00E9 \" ' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}",
                  "{tab} A Z E R T Y U I O P \u00A8 \u00A3",
                  "{lock} Q S D F G H J K L M % \u00B5 {enter}",
                  "{shift} > W X C V B N ? . / \u00A7 {shift}",
                  ".com @ {space}",
                ],
                capsfr: [
                  "\u00B2 & \u00E9 \" ' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}",
                  "{tab} A Z E R T Y U I O P \u00A8 \u00A3",
                  "{lock} Q S D F G H J K L M % \u00B5 {enter}",
                  "{shift} > W X C V B N ? . / \u00A7 {shift}",
                  ".com @ {space}",
                ],
                defaultar: [
                  "\u0630 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} \u0636 \u0635 \u062B \u0642 \u0641 \u063A \u0639 \u0647 \u062E \u062D \u062C \u062F \\",
                  "{lock} \u0634 \u0633 \u064A \u0628 \u0644 \u0627 \u062A \u0646 \u0645 \u0643 \u0637 {enter}",
                  "{shift} \u0626 \u0621 \u0624 \u0631 \u0644\u0627 \u0649 \u0629 \u0648 \u0632 \u0638 {shift}",
                  ".com @ {space}",
                ],
                shiftar: [
                  "\u0651 ! @ # $ % ^ & * ) ( _ + {bksp}",
                  "{tab} \u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
                  '{lock} \u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : " {enter}',
                  "{shift} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F {shift}",
                  ".com @ {space}",
                ],
                capsar: [
                  "\u0651 ! @ # $ % ^ & * ) ( _ + {bksp}",
                  "{tab} \u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
                  '{lock} \u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : " {enter}',
                  "{shift} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F {shift}",
                  ".com @ {space}",
                ],
                defaultja: [
                  "1 2 3 4 5 6 7 8 9 0 - ^ \u00a5 {bksp}",
                  "{tab} \u305f \u3066 \u3044 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u309b \u309c \u3080",
                  "{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}",
                  "{shift} \u3064 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u306d \u308b \u3081 {shift}",
                  ".com @ {space}",
                ],
                shiftja: [
                  "! \" # $ % & ' ( ) \u0301 = ~ | {bksp}",
                  "{tab} \u305f \u3066 \u3043 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u300c \u300d \u3080",
                  "{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}",
                  "{shift} \u3063 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u3001 \u3002 \u30fb {shift}",
                  ".com @ {space}",
                ],
                capsja: [
                  "! \" # $ % & ' ( ) \u0301 = ~ | {bksp}",
                  "{tab} \u305f \u3066 \u3043 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u300c \u300d \u3080",
                  "{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}",
                  "{shift} \u3063 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u3001 \u3002 \u30fb {shift}",
                  ".com @ {space}",
                ],
              }}
              theme={"hg-theme-default hg-layout-default myTheme"}
              buttonTheme={[
                {
                  class: "hg-blue",
                  buttons: "I i",
                },
                // {
                //   class: "hg-blue",
                //   buttons: "B b",
                // },
                // {
                //   class: "hg-highlight",
                //   buttons: "ç",
                // },
              ]}
            />
          )}
          {this.state.pixabayClicked ? (
            this.props.pixabay.result?.total > 0 ? (
              <Pixabay inputValue={this.state.inputValue} />
            ) : (
              <SBNotFoundMessage hide={this.props.settings.inputFocused} />
            )
          ) : (
            <></>
          )}
          {this.state.unsplashClicked ? (
            this.props.unsplash.result?.total ? (
              <Unsplash inputValue={this.state.inputValue} />
            ) : (
              <SBNotFoundMessage hide={this.props.settings.inputFocused} />
            )
          ) : (
            <></>
          )}
          {this.state.pixabayVideoClicked ? (
            this.props.pixabayVideo.result?.total ? (
              <PixabayVideo inputValue={this.state.inputValue} />
            ) : (
              <SBNotFoundMessage hide={this.props.settings.inputFocused} />
            )
          ) : (
            <></>
          )}

          {this.props.wikipedia && (
            <Wikipedia
              url={`https://${i18n.language}.wikipedia.com/wiki/${this.state.inputValue}`}
            />
          )}
          {this.props.wiktionary && (
            <Wiktionary
              url={`https://${i18n.language}.wiktionary.org/wiki/${this.state.inputValue}`}
            />
          )}
          {!this.state.pixabayClicked &&
            !this.state.unsplashClicked &&
            this.props.merriam && <Merriam />}
        </nav>

        <div className="searchbar-footer" onClick={this.toggleSearchBar}>
          <div className="corporation has-text-centered">
            {/*INTRO JS STEP 1 */}
            <div data-step="1" data-intro={this.props.t("intro_1")}>
              {this.props.searchBarOpened && (
                <button
                  className={`collapse-searchbar no-border ${
                    isSmallScreen
                      ? "search-bar-small-screen"
                      : "is-pulled-right"
                  } ${this.props.searchBarOpened ? "searchbar-close" : ""}`}
                  data-tip={
                    this.props.searchBarOpened
                      ? this.props.t("Close Search Bar")
                      : this.props.t("Open Search Bar")
                  }
                >
                  <span>
                    <img
                      src={
                        this.props.searchBarOpened
                          ? require("../assets/img/svg/sidebar-collapse-left.svg")
                          : require("../assets/img/svg/toolbar-search.svg")
                      }
                      alt="Collapse Sidebar"
                      className="search-close-icon"
                    />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var searchWords = [];
  if (state.pages.pages[state.pages.currentPageNo - 1]) {
    var word_btn_type = state.pages.pages[
      state.pages.currentPageNo - 1
    ].buttons.filter((btn) => {
      return btn.button_type == "words";
    });
    if (word_btn_type[0] && word_btn_type[0].content) {
      searchWords = word_btn_type[0].content.split(",");
      searchWords = searchWords.map((word) => {
        return word.trim();
      });
    }
  }
  return {
    pixabay: state.pixabay,
    unsplash: state.unsplash,
    pixabayVideo: state.pixabayVideo,
    searchBarOpened: state.searchBarOpen,
    /* wikizero: state.wikizero, */
    wikipedia: state.wikipedia,
    wiktionary: state.wiktionary,
    qwant: state.qwant,
    merriam: state.merriam,
    settings: state.settings,
    // p:state.pages
    searchWords: searchWords,
    canvasVals: state.scaleZoomFactor.canvas,
  };
};

export default connect(mapStateToProps, {
  toggleSearchBar,
  toggleSideBar,
  pullImagesFromPixabay,
  pullImagesFromUnsplash,
  pullVideosFromPixabay,
  /*   getWikiZero, */
  getWikipedia,
  getWiktionary,
  getQwant,
  closePixabay,
  closeUnsplash,
  closePixabayVideo,
  pullWordFromMerriam,
  setInputFocused,
})(withTranslation()(SearchBar));
