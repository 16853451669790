export default {
    translation: {
        "My IndiviBooks": "私の本",
        language: "JA",
        library: "図書館",
        Welcome: "ようこそ",
        Logout: "ログアウト",
        Loading: "読み込み中",
        intro_1:
          "検索バー: 画像、定義、その他のリソースを検索してください！事前定義されたワードリストもここで確認できます.",
        intro_2:
          "インデックス: 目次, 活動のインデックス, あなたのブックマークと注釈はここにあります!",
        intro_3:
          "新機能をチェックしてください! キーボードショートカット, ページの一部をキャプチャする, テーマ, ナビゲーションボタンなど!",
        "Interactive Objects": "インタラクティブオブジェクト",
        "Drawing Objects": "オブジェクトの描画",
        "Load All Images": "すべての画像を読み込む",
        Drag: "引っ張る",
        "Drag the page": "ページをドラッグ",
        "Zoom out": "ズームアウトする",
        "Zoom in": "ズームイン",
        "Full Screen": "全画面表示",
        "Draw Toolbar": "描画ツールバー",
        "Navigation Tools": "ナビゲーションツール",
        Capture: "キャプチャー",
        Curtain: "カーテン",
        Settings: "設定",
        "Vertical Toolbar": "垂直ツールバー",
        "Horizontal Toolbar": "水平ツールバー",
        Pen: "ペン",
        Highlighter: "蛍光ペン",
        "Clear All": "すべてクリア",
        Eraser: "消しゴム",
        Shapes: "形",
        Line: "ライン",
        "Empty Circle": "空のサークル",
        Circle: "サークル",
        "Empty Rectangle": "空の長方形",
        Rectangle: "矩形",
        "Line Size": "ラインサイズ",
        "Text and stroke color": "テキストとストロークの色",
        "Fill color": "塗りつぶしの色",
        Undo: "元に戻す",
        Redo: "やり直し",
        Language: "言語",
        Dark: "闇",
        Light: "光",
        "Dark Blue": "青",
        Green: "緑",
        "Go to Previous Page": "前のページに移動",
        "Go to Next Page": "次のページに移動",
        "Scroll to top of the page": "ページの先頭までスクロールします",
        "Scroll to end of the page": "ページの最後までスクロールします",
        Erase: "消去",
        "Select Drawing Objects":
          "オブジェクトを描画するためのツールを選択します",
        Layers: "レイヤー",
        Theme: "テーマ",
        "Change Toolbar Position": "ツールバーの位置を変更する",
        "Toolbar Pin": "ツールバーピン",
        Text: "テキスト",
        Search: "探す",
        "Please enter email and password for login.":
          "ログイン用のメールアドレスとパスワードを入力してください",
        "Sign in": "サインイン",
        Login: "ログインする",
        "If you experience a problem, please contact your domain administrator.":
          "問題が発生した場合, ドメイン管理者に連絡してください",
        Email: "Eメール",
        "Email is required": "メールが必要です",
        "Please enter your email": "あなたのメールアドレスを入力してください",
        "Please enter your password": "パスワードを入力してください",
        "Please enter IndiviBook Domain": "ドメインを入力してください",
        "IndiviBook domain is required": "ドメインが必要です",
        "Password domain is required": "パスワードドメインが必要です",
        "Please enter a valid email, password and IndiviBook domain.":
          "正しいメールアドレスを入力してください, パスワードと",
        "Please check your login credentials.":
          "ログイン資格情報を確認してください",
        "Please fill both the email and password fields.":
          "メールアドレスとパスワードの両方のフィールドに入力してください",
        "IndiviBook Domain": "ドメイン",
        Page: "ページ",
        "My Bookmarks": "マイブックマーク",
        Note: "注意",
        Audio: "オーディオ",
        Video: "ビデオ",
        Answer: "回答",
        Activity: "アクティビティ",
        Image: "画像",
        "Open Sidebar": "サイドバーを開く",
        "Close Sidebar": "サイドバーを閉じる",
        Library: "図書館",
        Back: "バック",
        Next: "次",
        Done: "完了",
        Skip: "スキップ",
        TOC: "目次",
        Activities: "活動",
        "My Annotations": "私の注釈",
        Bookmark: "ブックマーク",
        "Stroke Size": "ストロークサイズ",
        Password: "パスワード",
        "Suggested Words": "提案された言葉",
        "Search images from Pixabay":"Pixabay から画像を検索",
        "Search images from Unsplash":"Unsplash から画像を検索",
        "Search on Wikizero":"Wikizeroで検索",
        "Search on Wikipedia":"ウィキペディアで検索",
        "Search on Merriam Dictionary":"Merriam メリアム辞書で検索",
        "Search on Wiktionary":"Wiktionaryで検索",
        "Search on WordWeb":"WordWebで検索",
        "Search on PBS Learning Media":"PBSラーニングメディアで検索",
        "Search on Google":"Googleで検索",
        "Search on Google Images":"Google画像検索",

        "Activity Tools":"アクティビティツール",
        "Add Note":"メモを追加",
        "Add Video":"ビデオを追加",
        "Add Activity":"アクティビティを追加",
        "Add Audio":"オーディオを追加",
        "Add Image":"画像を追加",
        "Question Tools":"質問ツール",
        "Add a Multiple Choice Question":"多肢選択問題を追加する",
        "Add a Multiple Response Question":"複数回答の質問を追加する",
        "True False":"真/偽",
        "Drag Drop":"ドラッグドロップ",
        "Fill in the Blank":"空欄を埋める",
        "Drag":"引っ張る",
        "Bookmark":"ブックマーク",
        "Next Page":"次のページ",
        "Previous Page":"前のページ",
        "Close Search Bar":"検索バーを閉じる",
        "Open Search Bar":"検索バーを開く",
        "search":"探す",
        "Moveable Buttons":"可動ボタン",
        "Confirm":"確認",
        "You moved your own activity. Would you like to save the new position to the server?":"あなたはあなた自身の活動を動かしました。 新しい位置をサーバーに保存しますか？",
        "Yes":"はい",
        "No":"番号",
        "Search images": "画像を検索",
        "Search Video": "ビデオを検索",
        "Search Vector": "検索ベクトル",
        "Button Labels": "ボタンラベル",
        "Please enter your email, password and domain name.": "メールアドレス、パスワード、ドメイン名を入力してください.",
        "Click a point to add a zoomed page part": "ポイントをクリックして、ズームしたページパーツを追加します",
        "Link Name": "リンク名",
        "Advanced Mode": "アドバンストモード",
        "Prev": "前へ",
        "Teacher Tools": "ティーチャーツール",
        "Zoom Tools": "ズームツール",
        "Add Hideable Answer": "非表示の回答を追加",
        "This object can't be inserted.": "このオブジェクトは挿入できません",
        "Submit":"参加する",
        "Cancel":"キャンセル",
        "Upload audio":"音声をアップロードする",
        "Public":"公衆",
        "Private":"民間",
        "Top":"上",
        "Left":"左",
        "audio":"オーディオ",
        "Upload image":"画像をアップロード",
        "image":"画像",
        "Find an image":"画像を探す",
        "Style":"スタイル",
        "Link":"リンク",
        "Please enter a valid link like":"次のような有効なリンクを入力してください",
        "Video Link (Youtube, Vimeo, Facebook, Twitch,SoundCloud, Streamable, Wistia, DailyMotion)":"ビデオリンク（Youtube、Vimeo、Facebook、Twitch、SoundCloud、Streamable、Wistia、DailyMotion）",
        "Video Link":"ビデオリンク",
        "text":"テキスト",
        "link":"リンク",

        "Click a point to add a button":"ポイントをクリックしてボタンを追加します",

        "Select":"選択する",
        "Scroll":"スクロール",
        "Click a point to add a zoomed page part":"ポイントをクリックして、ズームしたページパーツを追加します",
        "Content is loading":"コンテンツが読み込まれています",
        "Delete Activity":"アクティビティを削除する",
        "Button Name":"ボタン名",
        "Caps Lock On":"CapsLockオン",
        "Group Selection":"グループの選択",
        "UnGroup Selection":"グループ解除の選択",
        "Duplicate Selection":"重複した選択",
        "Toolbar Size":"ツールバーのサイズ",
        "Brightness Settings":"明るさの設定",
        "Page Brightness":"ページの明るさ",
        "Page Contrast":"ページコントラスト",
        "Reset":"リセット",
        "Timer":"タイマー",
        "Exit":"出口",
        "Presentation":"プレゼンテーション",
        "Presentation Mode":"プレゼンテーションモード",
        "Full":"フル",
        "Highlight": "ハイライト",
        "Cut": "切る",
        "Confirm": "確認",
        "Open/Close":"開閉",
        "Add Hide Area":"非表示領域を追加",
        "Add Blank Page":"空白ページを追加",
        "Add Presentation Mode":"プレゼンテーションモードの追加",

        "You have no indivibooks.": "あなたは個人の本を持っていません。",
        "IndiviBook Portable": "IndiviBookポータブル",
        "No internet connection.": "インターネットに接続できません。",
        "Login with Google": "Googleでログイン",
        "company-logo": "会社のロゴ",
        "Attachment": "アタッチメント",
        "Color": "色",
        "Filling": "充填",
        "Settings": "設定",
        "Toolbar": "ツールバー",
        "Drawing": "お絵かき",
        "View": "見る",
        "Horizontal": "水平",
        "Vertical": "垂直",
        "Close": "閉じる",
        "Content": "コンテンツ",
        "Tools": "ツール",
        "Change Toolbar Mode":"ツールバーモードの変更",
        "Advanced": "高度な",        
        "Group": "グループ",
        "Ungroup": "グループ化を解除する",
        "Duplicate": "複製",
        "Delete": "削除",
        "Are you sure you want to delete this content": "このコンテンツを削除してもよろしいですか",

        "Hide Margins": "マージンを隠す",
        "Show Margins": "マージンを表示",
        "Unlink": "リンクを解除する",
        "Add Margin": "マージンを追加",
        "Can't link. Only 2 objects are allowed.": "リンクできません。 2つのオブジェクトのみが許可されます。",
        "You switched to the Presentation Mode": "プレゼンテーションモードに切り替えました",
        "Editor Mode": "エディターモード",
        "Are you sure you want to delete this content?": "このコンテンツを削除してもよろしいですか？",
        "Are you sure you want to delete all the activities in this page?": "このページのすべてのアクティビティを削除してもよろしいですか？",
        "Delete All Activities": "すべてのアクティビティを削除する",
        "You can not group these objects!": "これらのオブジェクトをグループ化することはできません！",
        "Select a question area to add annotations.": "注釈を追加する質問領域を選択します。",

        "Add Zoom Area": "ズームエリアを追加",
        "Add Hidden Area": "隠しエリアを追加",
        "Show/Hide Zoom Areas": "ズーム領域の表示/非表示",
        "Hide Zoom Areas": "ズーム領域を非表示",
        "Show Zoom Areas": "ズームエリアを表示",
        "Show Answer": "回答を表示",
        "Answer Video": "アンサービデオ",

        "Can't copy multiple objects. If you want to copy multiple objects, please group the objects and then try again.": "複数のオブジェクトをコピーすることはできません。 複数のオブジェクトをコピーする場合は、オブジェクトをグループ化してからやり直してください。",
        "You can use hotkeys after exiting the current action.": "現在のアクションを終了した後、ホットキーを使用できます",
        "Subtract Minute": "分を差し引く",
        "Add Minute": "分を追加",
        "Warning! You do not have an internet connection!": "警告！ インターネットに接続していません!",
        "Search Book Title":"書籍のタイトルを検索",
        "Blank Page": "空白ページ",

      },
  };
