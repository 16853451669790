import React, {Component} from "react";
import {connect} from "react-redux";
import CustomModal from "./CustomModal";
import {closeWikipedia } from '../actions/wikipedia'

class Wikipedia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true
        };
    }

    toggleModal = (e, modalData) => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen, modalData });
        this.props.closeWikipedia ();
    };

    render() {
        return (
                    <CustomModal
                        isOpen={this.state.modalIsOpen}
                        toggleModal={e => this.toggleModal(e)}
                        data={{button_type:"link",content:this.props.url,label:""}}
                    />
        );
    }
}

const mapStateToProps = state => {
    return {
        scaleZoomFactorVal: state.scaleZoomFactor
    };
};

export default connect(mapStateToProps, {closeWikipedia })(Wikipedia );