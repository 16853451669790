/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

class Select extends FabricCanvasTool {
  configureCanvas(props) {
    // console.log(`props.selectedTool`, props.selectedTool)
    //  if(props.selectedTool==='select'){
    //    return;
    //  }

    let canvas = this._canvas;
    this.props = props.currentPage[0];
    canvas.isDrawingMode = false;
    // canvas.selection = props.pageZoomPartMode==='zoom-show' ? false : true;
    canvas.selection = true;
    canvas.defaultCursor = "default";
    const initStatus = props.settings.addInteractivityMode === "init" || props.settings.pageZoomPartMode==='capturezoomed';
    console.log(`initStatus`, initStatus)
    const shouldShow = (iType) => {
      // console.log("shouldShow");
      const initQhide = props.settings.addInteractivityType === "qhideable";
      const initQshow = props.settings.addInteractivityType === "qshowable";
      const initQzoom = props.settings.addInteractivityType === "zoomarea";
      const captureZoomed=props.settings.pageZoomPartMode==='capturezoomed'
      if (initQshow) {
        return iType === "qShow";
      } else if (initQzoom) {
        return iType === "zmAreaGroup" || iType === "zmArea";
      } else if (initQhide) {
        return iType === "qHide";
      } else if (captureZoomed) {
        return iType !== "zmArea";
      }
    };
    canvas.forEachObject((o) => {
      if (o.type === "i-text" && o.isEditing) {
        o.exitEditing();
      }
      // console.log(`shouldShow(o.iType)`, shouldShow(o.iType), o)
      o.selectable = o.evented = initStatus
        ? shouldShow(o.iType) || o.marginId
        : o.type === "zoom-image"
        ? false
        : props.annotationSelectionMode
        ? true
        : o.qId
        ? false
        : true;

      // console.log(">>>>>>>>>>>>>o", o.iType, o.selectable);
    });
  }
  doMouseDown(o) {
    // console.log("----", this._canvas);
    // console.log("----_canvas backgroundImage", this._canvas.backgroundImage);
    // console.log(
    //   "----_selectedToolTop",
    //   this._canvas.getActiveObject() && this._canvas.getActiveObject().top
    // );
    // console.log(
    //   "----_selectedTool",
    //   this._canvas.getActiveObject() && this._canvas.getActiveObject()
    // );
    // console.log("currentPage", this.props);
    // console.log('selected object> ', o)
    // if (this._canvas._iTextInstances) {
    //   this._canvas._iTextInstances.forEach((ob) => {
    //     console.log(`ob`, ob);
    //     ob && ob.exitEditing();
    //   });
    // }
  }
  doMouseMove(o) {}
}

export default Select;
