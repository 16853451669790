import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function AddHideableAnnotation(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickAddNote = () => {
    // console.log("--Add a note..", props);
    !props.hideButton && props.onClick();
    dispatch(
      toaster(t("Select a question area to add annotations."), "warning", 3000)
    );
    // props.changeMyTool("select");
  };

  return (
    <li onClick={onClickAddNote}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "qhideable" ? "active" : ""
        }`}
        title={t("Add Hideable Answer")}
        data-tip={t("Add Hideable Answer")}
      >
        <img
          src={require("../../assets/img/svg/toolbar-q-hideable.svg")}
          alt={t("Add Hideable Answer")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Answer")}</span>}
      </span>
    </li>
  );
}
