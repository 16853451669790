import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import SearchBar from "./SearchBar";
import PdfPageList from "./PdfPageList";
import { connect } from "react-redux";
import { toggleSideBar, sideBarOpen, sideBarClose } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
import { pullPages, pageNoChange, getPages } from "../actions/pages";
import Loading from "./Loading";

import ReactSlider from "react-slider";
import {
  setAnnotationSelectionMode,
  resetAnnotationSelectionMode,
  setEditSelectionMode,
  setAnnotationBeforeState,
} from "../actions/annotationSelection";
import {
  setPagePartZoomMode,
  setPagePartZoomObject,
  setPagePartZoomObjectToShow,
  setPageBrightness,
  setPageContrast,
  setBrightnessMenu,
  setAddInteractivityMode,
  saveBatchInteractivitiesToButtons,
} from "../actions/settings";

import {
  BrowserRouter as Router,
  useLocation,
  useParams,
  // useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Offline } from "react-detect-offline";
import startTour from "./Tour";
import TourIntro3 from "./TourIntro3";
// import {
//   captureInProcess,
//   hideCaptureImage,
//   captureNotInprocess,
// } from "../actions/imageCapture";
import {
  addButtonService,
  addButtonFormService,
  updateButtonService,
  deleteButtonService,
} from "../services/api";

import {
  insertActivity,
  updateActivity,
  deleteActivity,
  deletePageActivityKeepCoordsByPage,
} from "../actions/addActivity";
import BottomMenu from "./BottomMenu";
import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../actions/interactiveObjects";
import BottomEditMenu from "./BottomEditMenu";
import CurtainMenu from "./CurtainMenu";
import BrightnessButton from "./SketchSelectedButtons/BrightnessButton";
import ContrastButton from "./SketchSelectedButtons/ContrastButton";
import SubmitButton from "./SketchSelectedButtons/SubmitButton";
import ResetButton from "./SketchSelectedButtons/ResetButton";
import ReactGA from "react-ga";
import { setLibraryOffline } from "../actions/login";

import TopEditMenu from "./SubComponents/TopEditMenu";
import InteractivityButtonSenderMenu from "./SubComponents/InteractivityButtonSenderMenu";
// import CustomKeyboard from "./SubComponents/CustomKeyboard";
import { setReloadActivities } from "../actions/temp";
import Spinner from "./Spinner";

import _ from "lodash";

// import Keyboard from "react-simple-keyboard";
// import "react-simple-keyboard/build/css/index.css";
import {
  selectCurrentPageId,
  selectCurrentPageWithMyContent,
  selectUserHasAnyAdminRole,
  selectZooms,
} from "../store/configureStore";

// import { IsEqual } from "react-lodash";

// import RecordView from "./RecordView";

// import { useSwipeable } from "react-swipeable";

function MainPage(props) {
  const _pdfPageList = useRef(null);
  const { annotationSelectionMode } = props;
  const [ebooks, setEbooks] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  /* Keyboard */
  const [input, setInput] = useState("");
  // const [layout, setLayout] = useState("default");
  // const keyboard = useRef();

  // const onChange = (input) => {
  //   setInput(input);
  //   console.log("Input changed", input);
  // };

  // const handleShift = () => {
  //   const newLayoutName = layout === "default" ? "shift" : "default";
  //   setLayout(newLayoutName);
  // };

  // const onKeyPress = (button) => {
  //   console.log("Button pressed", button);
  //   /**
  //    * If you want to handle the shift and caps lock buttons
  //    */
  //   if (button === "{shift}" || button === "{lock}") handleShift();
  // };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setInput(input);
    // keyboard.current.setInput(input);
  };

  /* Keyboard */

  const { t, i18n } = useTranslation();
  let location = useLocation();
  let { page } = useParams();

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);
  const jsonLoader = async () => {
    const bookIds = process.env.REACT_APP_OFFLINE_EBOOKS.split(" ");
    console.log(bookIds[0]);
    console.log(`JSON LOADER bookIds`, bookIds, bookIds.length);
    const books = [];
    await bookIds.map((book) => {
      console.log(`book`, book);
      import("../assets/offline/" + book + "/data")
        .then((background) => {
          console.log(`background`, background.default[0]);
          return background.default;
        })

        .then(async (myJson) => {
          console.log(myJson[0]);
          myJson.map((mj) => {
            mj.icon = require("../assets/offline/" + book + "/" + mj.icon);
            return mj;
          });
          myJson[0].ebook_pages.map((mj) => {
            mj.path = require("../assets/offline/" + book + "/" + mj.path);
            mj.thumbnail = require("../assets/offline/" +
              book +
              "/" +
              mj.thumbnail);
            mj.buttons.map((btn) => {
              if (btn.button_type === "image" || btn.button_type === "audio") {
                btn.content = require("../assets/offline/" +
                  book +
                  "/" +
                  btn.content);
              }
              return btn;
            });
            return mj;
          });

          books.push(myJson[0]);
          if (myJson[0].id == book) {
            props.getPages(myJson[0]);
          }
        });
    });
    await props.setLibraryOffline(books);
    setEbooks(books);
  };

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: 123,
      },
    });
    ReactGA.pageview("/home");
    const isOffline = process.env.REACT_APP_OFFLINE === "true";

    const book = localStorage.cenibook_id;

    if (isOffline) {
      if (props.library.length === 0) {
        jsonLoader();
      }
      const booksLength = process.env.REACT_APP_OFFLINE_EBOOKS.split(" ")
        .length;
      console.log(`booksLength`, booksLength, ebooks);
      if (ebooks.length > 0 && ebooks.length === booksLength) {
        const ebook = ebooks.filter(
          (ebook) => ebook.id === localStorage.cenibook_id
        )[0];
        console.log(`ebook`, ebook);
        ebook && props.getPages(ebook);
      }
    } else {
      if (
        localStorage.cenibook_url == undefined ||
        localStorage.cenibook_url.length < 10
      ) {
      }
      props.pullPages(localStorage.cenibook_url, props.userHasAnyAdminRole);
      console.log("props.userHasAnyAdminRole: ", props.userHasAnyAdminRole);
    }

    if (localStorage.showIntro !== "false") {
      startTour();
    }

    if (window.innerWidth < 1024) {
      props.sideBarClose();
    }
  }, [props.userHasAnyAdminRole]);

  useEffect(() => {
    props.pageNoChange(parseInt(page));
    if (
      props.pageZoomPartObjectToShow.orderId &&
      props.pageZoomPartMode !== "zoom-show"
    ) {
      props.setPagePartZoomObjectToShow(
        null,
        { x: null, y: null },
        { x: null, y: null },
        { x: null, y: null },
        { x: null, y: null }
      );
      props.showInteractiveObjects();
    }
  }, [location]);

  const { currentPageId, settings, currentPageNumber, annotations } = props;
  const {
    interactivityTypeToAddAsButtons,
    interactivitiesToAddAsButtons,
  } = settings;
  useEffect(() => {
    if (props.pageZoomPartMode && props.pageZoomPartMode !== "zoom-show")
      props.setPagePartZoomMode(false);
    if (props.annotationSelectionMode) props.setAnnotationSelectionMode(false);
    if (props.editSelectionMode) props.setEditSelectionMode(false);
  }, [currentPageId]);

  useEffect(() => {
    if (
      interactivitiesToAddAsButtons &&
      (interactivityTypeToAddAsButtons === "qShow" ||
        interactivityTypeToAddAsButtons === "zmArea")
    )
      console.log(
        `qqqqqq interactivityTypeToAddAsButtons`,
        interactivityTypeToAddAsButtons
      );
    props.setAddInteractivityMode(null, null);
    interactivityTypeToAddAsButtons &&
      sendInteractivityButton(
        interactivityTypeToAddAsButtons,
        interactivitiesToAddAsButtons
      );
  }, [interactivitiesToAddAsButtons]);
  // console.log(
  //   `interactivityTypeToAddAsButtons`,
  //   interactivityTypeToAddAsButtons
  // );
  const prevPageNumber = useRef();
  useEffect(() => {
    if (
      props.currentPageNumber &&
      props.totalPageNumber > 0 &&
      parseInt(props.currentPageNumber) > parseInt(props.totalPageNumber)
    ) {
      props.pageNoChange(1);
    }

    if (
      props.pageZoomPartMode === "zoom-show" &&
      parseInt(props.currentPageNumber) !== parseInt(prevPageNumber.current)
    ) {
      console.log("!!props.currentPage[0]: ", props.currentPage[0]);
      const qZoomButtons = props.zoomButtons;
      if (!qZoomButtons) return;

      console.log(
        `!!qZoomButtons`,
        parseInt(props.currentPageNumber),
        parseInt(prevPageNumber.current),
        qZoomButtons
      );
      if (qZoomButtons.length === 0) {
        props.setPagePartZoomMode(null);
        return;
      }
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords,
        cutCoordsUp,
      } = qZoomButtons[
        parseInt(props.currentPageNumber) >= parseInt(prevPageNumber.current)
          ? 0 //qZoomButtons.length - 1
          : qZoomButtons.length - 1
      ];
      setTimeout(() => {
        props.setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords,
          cutCoordsUp
        );
      }, 10);
    }

    prevPageNumber.current = props.currentPageNumber;
  }, [currentPageNumber]);

  useEffect(() => {
    if (annotationSelectionMode)
      props.setAnnotationBeforeState({
        buttonState: props.hideInteractiveObjectsVal,
        drawingState: props.showAnnotations,
        zoomState: props.scaleZoomFactorVal.scaleFactor,
      });
  }, [annotationSelectionMode]);

  useEffect(() => {
    if (props.clearKeepCoords && props.clearKeepCoords !== props.currentPageId)
      props.deletePageActivityKeepCoordsByPage(props.clearKeepCoords);
  }, [props.currentPageId, props.clearKeepCoords]);

  const status = parseInt(props.totalPageNumber) > 0;
  const proportion = props.scaleZoomFactorVal.canvas.scaleX;
  const sendInteractivityButton = (type, buttonsToSend) => {
    console.log(
      "~~~~~~~~~~~~~~~~~~~~~!!!sendInteractivityButton",
      buttonsToSend
    );
    setIsLoading(true);
    // let qZoomCount =
    //   props.currentPage[0] &&
    //   props.currentPage[0].buttons.filter(
    //     (bt) => bt.button_type === "zoomPagePart"
    //   ).length;
    if (props.settings.addInteractivityType === "zoomarea") {
      const existingZoomButtons = props.currentPage[0]
        ? props.currentPage[0].buttons.filter(
            (pageButton) => pageButton.button_type === "zoomPagePart"
          )
        : [];
      const deletedButtons = existingZoomButtons.filter((btn) => {
        const result = props.temp.tempInteractivities.findIndex((tmp) => {
          console.log(`tmp.tempId, btn.id`, tmp.tempId, btn.id);
          return tmp.tempId === btn.id;
        });
        return result === -1;
      });
      console.log(`existingZoomButtons`, existingZoomButtons);
      console.log(`deletedButtons`, deletedButtons);
      deletedButtons.map((deletedButton) => {
        deleteButtonService(
          String(props.domain).toLowerCase(),
          deletedButton.id
        )
          .then(async (response) => {
            console.log("response FORMMM", response);
            await props.deleteActivity(deletedButton.id, props.currentPageId);
            await setIsLoading(false);
            props.saveBatchInteractivitiesToButtons(null, null);
            // await props.showAnnotationsById(tempId);
          })
          .catch((error) => {
            console.log("logged error", error);
            setIsLoading(false);
          });
      });

      console.log(`existingZoomButtons`, existingZoomButtons);
      console.log(`deletedButtons`, deletedButtons);
    }
    buttonsToSend &&
      buttonsToSend.map((btnToSend) => {
        // qZoomCount++;
        // console.log(`btnToSend.orderId`, btnToSend.orderId);
        // console.log(`btnToSend.tempId`, btnToSend.tempId);
        let zoomToSend = null;
        if (type === "zmArea") {
          const newOrderId = props.temp.tempInteractivityOrders.find(
            (ob) => ob.tempId === btnToSend.tempId
          ).orderId;
          console.log(
            `newOrderId`,
            newOrderId,
            btnToSend.iType,
            btnToSend.tempId
          );
          if (btnToSend.iType === "zmAreaGroup") {
            const obj1 = btnToSend.objects[0];
            const obj2 = btnToSend.objects[1];

            zoomToSend = {
              orderId: parseInt(newOrderId),
              cutCoords: {
                x:
                  (btnToSend.left + obj2.left + btnToSend.width / 2) /
                  proportion,
                y:
                  (btnToSend.top + obj2.top + btnToSend.height / 2) /
                  proportion,
              },
              cutCoordsUp: {
                x: obj2.width / proportion,
                y: obj2.height / proportion,
              },
              downCoords: {
                x:
                  (btnToSend.left + obj1.left + btnToSend.width / 2) /
                  proportion,
                y:
                  (btnToSend.top + obj1.top + btnToSend.height / 2) /
                  proportion,
              },
              upCoords: {
                x: obj1.width / proportion,
                y: obj1.height / proportion,
              },
            };
          } else {
            zoomToSend = {
              orderId: parseInt(newOrderId),
              downCoords: {
                x: btnToSend.left / proportion,
                y: btnToSend.top / proportion,
              },
              upCoords: {
                x: btnToSend.width / proportion,
                y: btnToSend.height / proportion,
              },
              cutCoords: { x: null, y: null },
              cutCoordsUp: { x: null, y: null },
            };
          }
        }
        if (type === "qShow") {
          btnToSend.stroke = "";
          btnToSend.strokeWidth = 0;
        }
        const myRatio =
          props.canvasVals.canvasBackgroundImageWidth /
          props.canvasVals.canvasWidth; //ratio of the book page
        const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000

        console.log(`btnToSend.left`, btnToSend.left);
        btnToSend.iType === "zmAreaGroup" &&
          console.log(`btnToSend.objects[0].left`, btnToSend.objects[0].left);
        console.log(`btnToSend.width / 2`, btnToSend.width / 2);
        console.log(`myRatio`, myRatio);
        console.log(`originRatio`, originRatio);
        const bLeft =
          btnToSend.iType === "zmAreaGroup"
            ? Math.floor(
                myRatio *
                  (parseInt(btnToSend.left) +
                    parseInt(btnToSend.objects[0].left) +
                    parseInt(btnToSend.width) / 2) *
                  originRatio
              ) - 20
            : Math.floor(myRatio * parseInt(btnToSend.left) * originRatio) - 20;
        const bTop =
          btnToSend.iType === "zmAreaGroup"
            ? Math.floor(
                myRatio *
                  (parseInt(btnToSend.top) +
                    parseInt(btnToSend.objects[0].top) +
                    parseInt(btnToSend.height) / 2) *
                  originRatio
              ) - 20
            : Math.floor(myRatio * parseInt(btnToSend.top) * originRatio);
        //
        const bWidth =
          type === "zoomPagePart"
            ? Math.floor(
                (myRatio *
                  parseInt(btnToSend.width) *
                  originRatio *
                  parseFloat(btnToSend.scaleX)) /
                  parseFloat(props.scaleZoomFactorVal.scaleFactor)
              ) - 20
            : Math.floor(
                (myRatio *
                  parseInt(btnToSend.width) *
                  originRatio *
                  parseFloat(btnToSend.scaleX)) /
                  parseFloat(props.scaleZoomFactorVal.scaleFactor)
              ) - 20;

        const shouldBeOnRightSide = !props.sidebarOpened
          ? btnToSend.left > window.innerWidth / 2 - 50
          : btnToSend.left > (window.innerWidth - 200) / 2 - 50;

        const sendData = {
          left:
            (props.settings.addInteractivityType === "zoomarea" ||
              props.settings.addInteractivityType === "qshowable") &&
            shouldBeOnRightSide
              ? // ? parseInt(bLeft )
                props.settings.addInteractivityType === "zoomarea"
                ? parseInt(bLeft + bWidth + 45)
                : parseInt(
                    bLeft +
                      bWidth *
                        parseFloat(props.scaleZoomFactorVal.scaleFactor) +
                      35 * parseFloat(props.scaleZoomFactorVal.scaleFactor)
                  )
              : bLeft > 0
              ? props.settings.addInteractivityType === "qshowable"
                ? parseInt(bLeft)
                : parseInt(bLeft) - 10
              : 0, // parseInt(props.currentSelection.left),
          top: parseInt(bTop), //parseInt(btnToSend.top),
          height: parseInt(btnToSend.height) * parseFloat(btnToSend.scaleY),
          width: parseInt(btnToSend.width) * parseFloat(btnToSend.scaleX),
          show: 1,
          button_type:
            type === "qShow"
              ? "questionShowable"
              : type === "qHide"
              ? "questionHideable"
              : "zoomPagePart",
          own_activity: 1,
          style: "style1",
          status: 1,
          public: 1,
          sharing: 1,
          content:
            type === "zmArea"
              ? JSON.stringify(zoomToSend)
              : JSON.stringify([btnToSend]),
        };

        const updatedButtonIndex = props.currentPage[0]?.buttons.findIndex(
          (pageButton) => pageButton.id === btnToSend.tempId
        );

        console.log(`updatedButtonIndex`, updatedButtonIndex);
        if (updatedButtonIndex > -1) {
          const updatedButton =
            props.currentPage[0]?.buttons[updatedButtonIndex];

          if (
            parseInt(updatedButton.top) === sendData.top &&
            parseInt(updatedButton.left) === sendData.left &&
            // parseInt(updatedButton.width) === sendData.width &&
            // parseInt(updatedButton.height) === sendData.height&&
            _.isEqual(updatedButton.content, sendData.content)
          ) {
            return;
          } else {
            const formData = new FormData();
            formData.append("content", sendData.content);
            // formData.append("button_type", sendData.button_type);
            // formData.append("style", sendData.style);
            formData.append("top", sendData.top);
            formData.append("left", sendData.left);
            // formData.append("width", sendData.width);
            // formData.append("height", sendData.height);
            // formData.append("show", sendData.show);
            // formData.append("status", sendData.status);
            // formData.append("sharing", sendData.sharing);
            console.log("Update !!!!!!!!!!", btnToSend.tempId);
            // return;
            updateButtonService(
              formData,
              String(props.domain).toLowerCase(),
              btnToSend.tempId
            )
              .then(async (response) => {
                sendData.id = response.id;
                sendData.user_id = response.user_id;
                await props.updateActivity(sendData, props.currentPageId);
                props.saveBatchInteractivitiesToButtons(null, null);
                type === "qShow" && props.setReloadActivities(true);

                // await props.setAnnotationSelectionMode(true);
                // await props.setAnnotationSelectionMode(false);

                setIsLoading(false);
              })
              .catch((error) => {
                console.log("saveBatchInteractivitiesToButtons error", error);
                props.saveBatchInteractivitiesToButtons(null, null);
                setIsLoading(false);
              });
          }
        } else {
          console.log("New Buttons added!", btnToSend.tempId);
          // return;
          const formData = new FormData();
          formData.append("content", sendData.content);
          formData.append("button_type", sendData.button_type);
          formData.append("style", sendData.style);
          formData.append("top", sendData.top);
          formData.append("left", sendData.left);
          formData.append("width", sendData.width);
          formData.append("height", sendData.height);
          formData.append("show", sendData.show);
          formData.append("status", sendData.status);
          formData.append("sharing", sendData.sharing);
          addButtonService(
            formData,
            String(props.domain).toLowerCase(),
            props.currentPageId
          )
            .then(async (response) => {
              sendData.id = response.id;
              sendData.user_id = response.user_id;
              await props.insertActivity(sendData, props.currentPageId);
              props.saveBatchInteractivitiesToButtons(null, null);
              (type === "qShow" || type === "qHide") &&
                props.setReloadActivities(true);

              // await props.setAnnotationSelectionMode(true);
              // await props.setAnnotationSelectionMode(false);

              setIsLoading(false);
            })
            .catch((error) => {
              console.log("saveBatchInteractivitiesToButtons error", error);
              props.saveBatchInteractivitiesToButtons(null, null);
              setIsLoading(false);
            });
        }
      });
  };
  useEffect(() => {
    if (
      props.annotations.annotation &&
      props.annotations.annotation.length > 0
    ) {
      const myRatio =
        props.canvasVals.canvasBackgroundImageWidth /
        props.canvasVals.canvasWidth; //ratio of the book page
      const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
      const bLeft =
        Math.floor(
          myRatio * parseInt(props.currentSelection.left) * originRatio
        ) - 10;

      const sendData = {
        // id: guidGenerator(), //For redux based id generation.
        left: bLeft > 0 ? bLeft : 0, // parseInt(props.currentSelection.left),
        top: Math.floor(
          myRatio * parseInt(props.currentSelection.top) * originRatio
        ), //parseInt(props.currentSelection.top),
        height: parseInt(props.currentSelection.height),
        width: parseInt(props.currentSelection.width),
        show: 1,
        button_type: "questionHideable",
        own_activity: 1,
        style: "style2",
        status: 1,
        public: 1,
        sharing: 1,
        content: JSON.stringify(props.annotations.annotation),
      };
      const formData = new FormData();
      formData.append("content", sendData.content);
      formData.append("button_type", sendData.button_type);
      formData.append("style", sendData.style);
      formData.append("top", sendData.top);
      formData.append("left", sendData.left);
      formData.append("width", sendData.width);
      formData.append("height", sendData.height);
      formData.append("show", sendData.show);
      formData.append("status", sendData.status);
      formData.append("sharing", sendData.sharing);
      addButtonService(
        formData,
        String(props.domain).toLowerCase(),
        props.currentPageId
      )
        .then(async (response) => {
          sendData.id = response.id;
          sendData.user_id = response.user_id;
          await props.insertActivity(sendData, props.currentPageId);
          props.setReloadActivities(true);
          // await props.setAnnotationSelectionMode(true);
          // await props.setAnnotationSelectionMode(false);

          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("logged error", error);

          // setIsLoading(false);
        });
      props.resetAnnotationSelectionMode();
      console.log("degiti");
    }
  }, [annotations]);

  const saveZoomButton = () => {
    sendZoomButton(props);
  };

  const shouldTopMenuOpened =
    props.editSelectionMode || props.timerMode || props.zoomModeMenuOpen;
  const shouldBrightnessMenuOpened = props.editSelectionMode || props.timerMode;
  const shouldBottomMenuOpened =
    props.annotationSelectionMode || props.pageZoomPartMode;
  // console.log("shouldBottomMenuOpened", shouldBottomMenuOpened);

  const onChangeBrightness = (val) => {
    props.setPageBrightness(val);
  };
  const onChangeContrast = (val) => {
    props.setPageContrast(val);
  };

  const bookContrastMenu = () => {
    return (
      <>
        {props.settings.brightnessMenuOpen && (
          <div
            className={`navbar is-fixed-top  brightness-menu top-annotation-menu  ${
              true ? "open" : "hide"
            } `}
          >
            <div className="columns">
              <div className="column  is-half">
                <BrightnessButton />
              </div>
              <div className="column  is-half 	">
                <ReactSlider
                  className="horizontal-slider slider-brightness"
                  thumbClassName="simple-thumb"
                  trackClassName="simple-track"
                  onAfterChange={onChangeBrightness}
                  defaultValue={props.settings.pageBrightness}
                  value={props.settings.pageBrightness}
                  min={-1}
                  max={1}
                  step={0.1}
                  renderThumb={(props, state) => <div {...props}></div>}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column  is-half 	">
                <ContrastButton />
              </div>
              <div className="column  is-half 	">
                <ReactSlider
                  className="horizontal-slider slider-brightness"
                  thumbClassName="simple-thumb"
                  trackClassName="simple-track"
                  onAfterChange={onChangeContrast}
                  defaultValue={props.settings.pageContrast}
                  value={props.settings.pageContrast}
                  min={-1}
                  max={1}
                  step={0.1}
                  renderThumb={(props, state) => <div {...props}></div>}
                />
              </div>
            </div>
            <div className="brightness-sub-div">
              <ResetButton
                onClickHandler={() => {
                  onChangeBrightness(0);
                  onChangeContrast(0);
                }}
              />
              <SubmitButton
                onClickHandler={() => props.setBrightnessMenu(false)}
              />
            </div>
          </div>
        )}
      </>
    );
  };
  const currentPageButtonsLength = props.currentPage[0]
    ? props.currentPage[0].buttons.length
    : 0;
  const checkIfUserCanDeleteActivity =
    props.annotationIdsToShow?.length === 1
      ? props.currentPage[0].buttons.filter((btn) => {
          return (
            (btn.own_activity || props.toolbar.editorMode) &&
            btn.id === props.annotationIdsToShow[0]
          );
        }).length > 0
      : false;

  let floatSearchButtonStyle = "";
  let searchIconStyle = "search-icon";
  if (props.toolbar.toolbarSize === "1.25") {
    floatSearchButtonStyle = "collapse-sidebar-1-25";
    searchIconStyle = "search-icon-1-25";
  } else if (props.toolbar.toolbarSize === "1.5") {
    floatSearchButtonStyle = "collapse-sidebar-1-5";
    searchIconStyle = "search-icon-1-5";
  }

  /*
  ZOOM Presentation
  */

  /* -------------- */
  return (
    <div>
      {status ? (
        <div>
          <div
            className="App"
            style={{
              display: "block",
            }}
          >
            <div
              className="hero is-fullhd is-fullheight"
              data-theme={props.theme}
            >
              {/* <RecordView/> */}
              <div className="columns is-gapless is-full">
                <ToastContainer autoClose={2000} hideProgressBar={true} />
                {bookContrastMenu()}
                {props.settings.addInteractivityMode === "init" && (
                  <InteractivityButtonSenderMenu />
                )}
                {/* {!props.settings.showKeyboard && (
                  <CustomKeyboard
                    // keyboardRef={(r) => (keyboard.current = r)}
                    keyboard={keyboard}
                    layout={layout}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                  />
                )} */}
                {/* {!props.settings.showKeyboard && (
                  <div className="keyboard-container">
                    <Keyboard
                      keyboardRef={(r) => (keyboard.current = r)}
                      layoutName={layout}
                      onChange={onChange}
                      onKeyPress={onKeyPress}
                    />
                  </div>
                )} */}
                <TopEditMenu shouldTopMenuOpened={shouldTopMenuOpened} />
                <BottomEditMenu
                  userCanDeleteActivity={checkIfUserCanDeleteActivity}
                  editorMode={
                    props.toolbar.editorMode &&
                    props.settings.addInteractivityMode !== "init"
                  }
                  currentPageButtonsLength={currentPageButtonsLength}
                />
                <BottomMenu
                  shouldBottomMenuOpened={shouldBottomMenuOpened}
                  saveZoomButton={saveZoomButton}
                />
                <Spinner
                  color={"#c4c4c4"}
                  size={150}
                  spin={
                    props.pageIsLoading ||
                    props.settings.pageZoomPartShowLoading
                  }
                />
                {props.selectedTool === "curtain" && <CurtainMenu />}
                {process.env.REACT_APP_OFFLINE === "false" && (
                  <Offline>
                    <div className="no-internet-ebook">
                      {t("No internet connection.")}
                    </div>
                  </Offline>
                )}
                <div className="column content" id="parentOne">
                  <TourIntro3 />

                  <PdfPageList
                    toolBarDraggable={false}
                    forwardedref={_pdfPageList}
                  />
                </div>
                <Sidebar />
                <div className={`is-pulled-right`}>
                  <SearchBar input={input} onChangeInput={onChangeInput} />
                </div>
                {props.selectedTool !== "curtain" && (
                  <button
                    className={` float-search-button collapse-searchbar is-pulled-right is-hidden-mobile ${floatSearchButtonStyle} `}
                    onClick={
                      () => props.toggleSearchBar(props.searchBarOpened) //TODO Add same for the left side bar-thumbnails
                    }
                  >
                    <span className={""}>
                      <img
                        src={require("../assets/img/svg/toolbar-search.svg")}
                        alt="Collapse Sidebar"
                        className={searchIconStyle}
                      />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="hero is-fullhd is-fullheight content"
          data-theme={props.theme}
        >
          <Loading />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    pages: state.pages.pages,
    pagesState: state.pages,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    // currentPageId: state.pages.currentPage[0]?.id,
    currentPageId: selectCurrentPageId(state),

    // currentPage: state.pages.currentPage,
    currentPage: selectCurrentPageWithMyContent(state),
    zoomButtons: selectZooms(state),
    // pageLoaded: state.pageLoaded,
    // imageCapture: state.imageCapture,
    imagesLoaded: state.imagesLoaded,
    scaleZoomFactorVal: state.scaleZoomFactor,
    selectedTool: state.selectedTool.selectedTool,
    searchBarOpened: state.searchBarOpen,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    editSelectionMode: state.annotationSelections.editSelectionMode,
    currentSelection: state.annotationSelections.currentSelection,
    annotations: state.annotationSelections.annotations,
    domain: state.login.user_profile?.domain_name,
    ebookId: state.pages.ebookId,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    showAnnotations: state.settings.showAnnotations,
    canvasVals: state.scaleZoomFactor.canvas,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObject: state.settings.pageZoomPartObject,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    sidebarOpened: state.sideBarOpened,
    searchBarOpen: state.searchBarOpen,
    timerMode: state.settings.timerMode,
    zoomModeMenuOpen: state.settings.zoomModeMenuOpen,
    annotationIdsToShow: state.annotationSelections.annotationIdsToShow,
    toolbar: state.toolbar,
    settings: state.settings,
    library: state.login.library,
    temp: state.temp,
    showLabels: state.toolbar.showLabels,
    userHasAnyAdminRole: selectUserHasAnyAdminRole(state),
    clearKeepCoords: state.pages.clearKeepCoords,
    pageIsLoading: state.settings.pageIsLoading,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  toggleSearchBar,
  pageNoChange,
  pullPages,
  // captureInProcess,
  // captureNotInprocess,
  // hideCaptureImage,
  sideBarOpen,
  sideBarClose,
  setAnnotationSelectionMode,
  setEditSelectionMode,
  resetAnnotationSelectionMode,

  setAnnotationBeforeState,
  insertActivity,
  updateActivity,
  setPagePartZoomMode,
  setPagePartZoomObject,
  hideInteractiveObjects,
  showInteractiveObjects,
  setPagePartZoomObjectToShow,
  deleteActivity,
  setPageBrightness,
  setPageContrast,
  setBrightnessMenu,
  setLibraryOffline,
  getPages,
  setAddInteractivityMode,
  saveBatchInteractivitiesToButtons,
  setReloadActivities,
  deletePageActivityKeepCoordsByPage,
})(MainPage);
function sendZoomButton(props) {
  const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
  const bLeft =
    Math.floor(parseInt(props.pageZoomPartObject.downCoords.x * originRatio)) -
    25;
  const bTop = Math.floor(
    parseInt(props.pageZoomPartObject.downCoords.y * originRatio) +
      parseInt(props.pageZoomPartObject.upCoords.y * originRatio) / 2
  );
  const sendData = {
    left: bLeft > 0 ? bLeft : 0,
    top: bTop > 0 ? bTop : 0,
    height: Math.floor(
      parseInt(props.pageZoomPartObject.upCoords.x * originRatio)
    ),
    width: Math.floor(
      parseInt(props.pageZoomPartObject.upCoords.y * originRatio)
    ),
    show: 1,
    button_type: "zoomPagePart",
    own_activity: 1,
    style: "style2",
    status: 1,
    public: 1,
    sharing: 1,
    content: JSON.stringify(props.pageZoomPartObject),
  };
  addButtonFormService(
    sendData,
    String(props.domain).toLowerCase(),
    props.currentPageId
  )
    .then((response) => {
      console.log("response", response);
      sendData.id = response.id;
      sendData.user_id = response.user_id;
      props.insertActivity(sendData, props.currentPageId);
      props.setPagePartZoomObject(
        null,
        { x: null, y: null },
        { x: null, y: null }
      );
    })
    .catch((error) => {
      console.log("logged error", error);
    });
  console.log("degisti");
}
