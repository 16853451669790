import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

class Eraser extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    let hasEraser = false;
    console.log('eraser triggered')
    canvas.getObjects().forEach(function(o) {
        if(o.type == "path"){
            o.set({ erasable: true });
        }
        // if(o.objId == "eraser"){
        //     hasEraser = true;
        // }
    });
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
    canvas.freeDrawingBrush.width = 60;
    canvas.defaultCursor = "pointer";
    // if(!hasEraser){
    //     let eraserCircle = new fabric.Circle({
    //         objId: 'eraser',
    //         radius: 30,
    //         left: canvas.width/2,
    //         top: 30,
    //         fill: "rgb(0,0,0)",
    //         opacity: 1,
    //       });
    //     canvas.add(eraserCircle);
    // }
  }
  doMouseDown(o) {
    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function(ob) {
        ob.isEditing && ob.exitEditing();
      });
    }
  }
  doMouseMove(o) {}

}

export default Eraser;
