import api from "../api";
import { getGroupEbooks } from "../services/api";

import { setLoginSuccess, setGroupEbooks } from "./login";

export const details = () => {
  const baseUrl =
  process.env.NODE_ENV === "_development"
  ? "http://" + localStorage.domain + ".localhost:8003/api/"
  : process.env.REACT_APP_MULTITENANT === "true"
  ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
  : process.env.REACT_APP_API_URL + "api/v2/"; //TODO cenibook.com

  // console.log("geldi",  baseUrl);

  return (dispatch) => {
    api
    .getData(baseUrl + "user_details/") //
    .then((res) => {
        // console.log('user_details: ');
        // console.log("res", res);
        dispatch(setLoginSuccess(res.data.success));
        const groups =  res?.data?.success?.details?.groups;
        if (groups.length > 0) {
          groups.map((group) => {
            getGroupEbooks(group["id"])
              .then(async (res) => {
                // console.log(" getGroupEbookResponse >>", res);
                dispatch(setGroupEbooks(res));
              })
              .catch((error) => {
                console.log("logged error", error);
              })
              .finally(() => {});
          });
        }
      })
      .catch((err) => {
        console.error('err', err);
        window.location.hash = `#/login`;

      });
  };
};
