import {
  PAGE_SCALE_UP,
  PAGE_SCALE_DOWN,
  SET_BUTTON_SCALE_FACTOR,
  PAGE_SCALE_RESET,
  PAGE_SCALE_MAX,
  PAGE_SCALE_SET,
} from "../actions/actionTypes";

export default (
  state = {
    scaleFactor: 1,
    scaleCss: "zoomPage1",
    canvas: {},
  },
  action
) => {
  let newScaleFactor = 1;
  let newScaleCss = "zoomPage" + newScaleFactor.toString().replace(".", "-");
  switch (action.type) {
    case PAGE_SCALE_RESET:
      console.log("setScalePageReset??????");
      newScaleFactor = 1;
      newScaleCss = "zoomPage" + newScaleFactor.toString().replace(".", "-");
      return { ...state, scaleFactor: newScaleFactor, scaleCss: newScaleCss };
      break;

    case PAGE_SCALE_SET:
      newScaleFactor = action.payload;
      newScaleCss = "zoomPage" + newScaleFactor.toString().replace(".", "-");
      return { ...state, scaleFactor: newScaleFactor, scaleCss: newScaleCss };
      break;

    case PAGE_SCALE_UP:
      console.log("action.payload", action.payload);
      console.log("PageScaleUp scaleFactor: ", action.payload);
      console.log(
        `newScaleFactor === 0.4`,
        action.payload,
        0.4,
        action.payload === 0.4,
        action.payload == 0.4,
        action.payload === "0.4"
      );
      if (action.payload === 0.4) {
        console.log("0444");
        newScaleFactor = 0.5;
      } else {
        newScaleFactor = action.payload + 0.25;
      }
      if (newScaleFactor > 3) {
        newScaleFactor = 3;
      }

      newScaleCss = "zoomPage" + newScaleFactor.toString().replace(".", "-");
      console.log(`newScaleCssUP`, newScaleCss);
      return { ...state, scaleFactor: newScaleFactor, scaleCss: newScaleCss };
      break;
    case PAGE_SCALE_MAX:
      //   console.log(action.type);
      //   console.log("PageScaleUp: ", action.payload);
      console.log("PageScaleUp scaleFactor: ", action.payload);
      newScaleFactor = action.payload + 0.25;
      if (newScaleFactor > 2) {
        newScaleFactor = 2;
      }

      newScaleCss = "zoomPage" + newScaleFactor.toString().replace(".", "-");
      return { ...state, scaleFactor: newScaleFactor, scaleCss: newScaleCss };
      break;
    case PAGE_SCALE_DOWN:
      if (newScaleFactor > 0.5) {
        newScaleFactor = action.payload - 0.25;
      } else {
        newScaleFactor = action.payload - 0.1;
      }
      if (newScaleFactor < 0.4) {
        newScaleFactor = 0.4;
      }
      newScaleCss = "zoomPage" + newScaleFactor.toString().replace(".", "-");
      console.log(`newScaleCss`, newScaleCss);
      return { ...state, scaleFactor: newScaleFactor, scaleCss: newScaleCss };
      break;
    case SET_BUTTON_SCALE_FACTOR:
      //   console.log(action.type);
      // console.log("canvas: ", action.payload);
      return { ...state, canvas: action.payload };
      break;
    //   return action.payload;

    default:
      return state;
  }
};
