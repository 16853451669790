import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import AddOuterMarginButton from "../SketchSelectedButtons/AddOuterMarginButton";
import AddInnerMarginButton from "../SketchSelectedButtons/AddInnerMarginButton";
import DeleteAllMargin from "../SketchSelectedButtons/DeleteAllMargin";
import ConnectMargins from "../SketchSelectedButtons/ConnectMargins";
import PageTypeLabel from "../SketchSelectedButtons/PageTypeLabel";
import CancelButton from "../SketchSelectedButtons/CancelButton";
import SubmitButton from "../SketchSelectedButtons/SubmitButton";
import {
  setAddInteractivityMode,
  triggerAddInnerMargin,
  triggerAddOuterMargin,
  setShowMargins,
} from "../../actions/settings";
import {
  setSelectionRemoveMode,
  setSelectionGroupMode,
  setSelectionUnGroupMode,
} from "../../actions/annotationSelection";

import { isOdd } from "../../services/util";
import UnConnectMargins from "../SketchSelectedButtons/UnConnectMargins";
import { toastWarning } from "../../actions/showToast";
import ShowZoomAreasButton from "../SketchSelectedButtons/ShowZoomAreasButton";
import { setShowZoomAreas } from "../../actions/temp";

const InteractivityButtonSenderMenu = (props) => {
  const { t, i18n } = useTranslation();
  const pageType = isOdd(props.currentPageNumber) ? "A" : "B";
  const currentMarginsLength =
    pageType === "A"
      ? props.settings.marginA.length
      : props.settings.marginB.length;
  console.log(`currentMarginsLength`, currentMarginsLength);

  useEffect(() => {
    props.setShowMargins(true);
  }, []);

  return (
    <>
      {props.settings.addInteractivityMode === "init" &&
        !props.annotationSelections.editSelectionMode && (
          <div
            className={`navbar is-fixed-top  top-annotation-menu  ${
              true ? "open" : "hide"
            } `}
          >
            {props.settings.addInteractivityType === "zoomarea" && (
              <>
                <PageTypeLabel
                  shouldShowButtonLabels={props.showLabels}
                  pageType={pageType}
                  showMargins={props.settings.showMargins}
                  onClickHandler={() =>
                    props.setShowMargins(!props.settings.showMargins)
                  }
                />
                <AddOuterMarginButton
                  shouldShowButtonLabels={props.showLabels}
                  onClickHandler={() =>
                    currentMarginsLength < 2
                      ? props.triggerAddOuterMargin(true)
                      : props.triggerAddInnerMargin(true)
                  }
                  disabled={currentMarginsLength >= 6}
                />

                {/* <AddInnerMarginButton
            shouldShowButtonLabels={props.showLabels}
            onClickHandler={() => props.triggerAddInnerMargin(true)}
            disabled={currentMarginsLength >= 4}
          /> */}

                {props.currentSelectedObject &&
                  props.currentSelectedObject.type === "activeSelection" &&
                  props.currentSelectedObject.objects.length === 2 && (
                    <ConnectMargins
                      shouldShowButtonLabels={props.showLabels}
                      onClickHandler={() => {
                        if (
                          props.currentSelectedObject.objects.filter(
                            (subObject) => subObject.iType === "zmArea"
                          ).length === 2
                        ) {
                          props.setSelectionGroupMode(true);
                        } else if (
                          props.currentSelectedObject.objects.filter(
                            (subObject) => subObject.iType === "qShow"
                          ).length >= 2
                        ) {
                          props.setSelectionGroupMode(true);
                        } else {
                          toastWarning(t("You can not group these objects!"));
                        }
                      }}
                    />
                  )}
                {props.currentSelectedObject &&
                  props.currentSelectedObject.iType === "zmAreaGroup" && (
                    <UnConnectMargins
                      shouldShowButtonLabels={props.showLabels}
                      onClickHandler={() => {
                        props.setSelectionUnGroupMode(true);
                      }}
                    />
                  )}
              </>
            )}
            {props.settings.addInteractivityType !== "zoomarea" && (
              <ShowZoomAreasButton
                shouldShowButtonLabels={props.showLabels}
                show={props.showZoomAreas}
                onClickHandler={() => {
                  props.setShowZoomAreas(!props.showZoomAreas);
                }}
              />
            )}
            {/* <DeleteAllMargin shouldShowButtonLabels={props.showLabels} /> */}
            <CancelButton
              onClickHandler={() => {
                props.setAddInteractivityMode("cancel", "cancel");
              }}
              shouldShowButtonLabels={props.showLabels}
            />
            <SubmitButton
              onClickHandler={() =>
                props.setAddInteractivityMode("submit", "submit")
              }
              shouldShowButtonLabels={props.showLabels}
            />
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    showLabels: state.toolbar.showLabels,
    currentPageNumber: state.pages.currentPageNo,
    currentSelectedObject: state.toolbar.currentSelectedObject,
    showZoomAreas: state.temp.showZoomAreas,
    annotationSelections: state.annotationSelections,
  };
};

export default connect(mapStateToProps, {
  setAddInteractivityMode,
  triggerAddOuterMargin,
  triggerAddInnerMargin,
  setSelectionRemoveMode,
  setSelectionGroupMode,
  setSelectionUnGroupMode,
  setShowMargins,
  setShowZoomAreas,
})(InteractivityButtonSenderMenu);
