import {
  ADD_TEMP_ACTIVITY,
  REMOVE_TEMP_ACTIVITY,
  SET_TEMP_ACTIVITY_ORDER,
  UPDATE_TEMP_ACTIVITY,
  SET_RELOAD_ACTIVITIES,
  SET_SHOW_ZOOM_AREAS,
  REMOVE_ALL_TEMP_ACTIVITIES
} from "../actions/actionTypes";
import { parseBoolean } from "../services/util";

const defaultState = {
  tempInteractivities: [],
  tempToDelete: null,
  tempInteractivityOrders: [],
  removedInteractivities: [],
  reloadActivities: false, //Reload sketch to load qhideable and qshowable
  showZoomAreas: false,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TEMP_ACTIVITY: {
      const { tempInteractivity } = action.payload;
      console.log(`tempInteractivity.orderId`, tempInteractivity.orderId);
      const newId = tempInteractivity.orderId
        ? tempInteractivity.orderId
        : state.tempInteractivities.length + 1;
      return {
        ...state,
        tempInteractivities: [...state.tempInteractivities, tempInteractivity],
        tempInteractivityOrders: [
          ...state.tempInteractivityOrders,
          { orderId: newId, tempId: tempInteractivity.tempId },
        ],
      };
    }
    case UPDATE_TEMP_ACTIVITY: {
      const tempInteractivity = action.payload;
      const oldTemp = state.tempInteractivities.filter(
        (ta) => ta.tempId !== tempInteractivity.tempId
      );
      console.log(`tempInteractivity`, tempInteractivity);
      console.log(`oldTemp`, oldTemp);

      return {
        ...state,
        tempInteractivities: [...oldTemp, tempInteractivity],
      };
    }
    case REMOVE_TEMP_ACTIVITY: {
      const tempId = action.payload;
      let newTemp = state.tempInteractivities;
      let tempInteractivityOrders = state.tempInteractivityOrders;
      if (tempId) {
        newTemp = state.tempInteractivities.filter(
          (ta) => ta.tempId !== tempId
        );
        tempInteractivityOrders = state.tempInteractivityOrders
          .filter((ta) => ta.tempId !== tempId)
          .sort((a, b) => a.orderId - b.orderId)
          .map((ob, index) => {
            ob.orderId = index + 1;
            return ob;
          });
        console.log(`tempInteractivityOrders`, tempInteractivityOrders);
      }
      // console.log(`newTemp`, newTemp);
      return {
        ...state,
        tempInteractivities: newTemp,
        tempToDelete: tempId,
        tempInteractivityOrders,
      };
    }
    case REMOVE_ALL_TEMP_ACTIVITIES: {
      return {
        ...state,
        tempInteractivities: [],
        tempToDelete: null,
        tempInteractivityOrders: [],
        removedInteractivities: [],
      };
    }
    case SET_TEMP_ACTIVITY_ORDER: {
      const { direction, tempId } = action.payload;
      const currentTempOrder = state.tempInteractivityOrders.filter(
        (ta) => ta.tempId === tempId
      )[0];
      const targetOrderId =
        direction === "up"
          ? parseInt(currentTempOrder.orderId + 1)
          : parseInt(currentTempOrder.orderId - 1);

      const otherTempOrder = state.tempInteractivityOrders.filter(
        (ta) => ta.orderId === targetOrderId
      )[0];

      const oldTemp = state.tempInteractivityOrders.filter(
        (ta) => ta.tempId !== otherTempOrder.tempId && ta.tempId !== tempId
      );
      return {
        ...state,
        tempInteractivityOrders: [
          ...oldTemp,
          { ...otherTempOrder, orderId: currentTempOrder.orderId },
          { ...currentTempOrder, orderId: targetOrderId },
        ],
      };
    }

    case SET_RELOAD_ACTIVITIES: {
      return {
        ...state,
        reloadActivities: action.payload,
      };
    }
    case SET_SHOW_ZOOM_AREAS: {
      return {
        ...state,
        showZoomAreas: action.payload,
      };
    }
    default:
      return state;
  }
};
