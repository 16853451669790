import {
  ADD_TEMP_ACTIVITY,
  REMOVE_TEMP_ACTIVITY,
  SET_TEMP_ACTIVITY_ORDER,
  UPDATE_TEMP_ACTIVITY,
  SET_RELOAD_ACTIVITIES,
  SET_SHOW_ZOOM_AREAS,
  REMOVE_ALL_TEMP_ACTIVITIES
} from "./actionTypes";

export function addTempActivity(tempInteractivity,) {
  return function (dispatch) {
    dispatch({
      type: ADD_TEMP_ACTIVITY,
      payload: {tempInteractivity},
    });
  };
}
export function updateTempActivity(tempInteractivity) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_TEMP_ACTIVITY,
      payload: tempInteractivity,
    });
  };
}
export function removeTempActivity(tempId) {
  return function (dispatch) {
    dispatch({
      type: REMOVE_TEMP_ACTIVITY,
      payload: tempId,
    });
  };
}
export function removeAllTempActivities() {
  return function (dispatch) {
    dispatch({
      type: REMOVE_ALL_TEMP_ACTIVITIES,
    });
  };
}
export function setTempActivityOrder(direction, tempId) {
  return function (dispatch) {
    dispatch({
      type: SET_TEMP_ACTIVITY_ORDER,
      payload: { direction, tempId },
    });
  };
}
export function setReloadActivities(status) {
  return function (dispatch) {
    dispatch({
      type: SET_RELOAD_ACTIVITIES,
      payload: status ,
    });
  };
}
export function setShowZoomAreas(status) {
  return function (dispatch) {
    dispatch({
      type: SET_SHOW_ZOOM_AREAS,
      payload: status ,
    });
  };
}
