import React, { Component } from "react";
import BookPlaceholder from "../assets/img/book-placeholder.png";
import { connect } from "react-redux";
// // import MainPage from "./MainPage";
import { withRouter } from "react-router-dom";
import { imagesUnloaded as imagesUnloadedAction } from "../actions/imageLoad";
import { resetPages } from "../actions/pages";
import { setLibraryFromLocal } from "../actions/login";
import { withTranslation } from "react-i18next";
import ChangeLanguage from "../components/SubComponents/ChangeLanguage";
import { Offline } from "react-detect-offline";
import ReactGA from "react-ga";
import { getPages } from "../actions/pages";
import { setLibraryOffline } from "../actions/login";
import { getEbook } from "../services/api";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import Select from "react-select";
import { WinboxContext } from "./WinBoxWrapper";

// import {asyncLocalStorage} from "../services/util"
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  control: (base, state) => ({
    ...base,
    background: "#fff",
    // paddingRight: "30px",
    border: state.menuIsOpen ? "0px solid #ccc" : "0px solid #ccc ",
    boxShadow: "none",
    "&:hover": {
      border: state.menuIsOpen ? "0px solid black" : "0px solid #ccc",
    },
  }),

  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ebooks: [],
      library: [],
      searchText: "",
      course: {
        value: "",
        label: this.props.i18n.t("Select a course"),
      },
      courseGroup: "",
      favs: [],
      searchWait: null,
    };
  }
  static contextType = WinboxContext;
  componentDidMount() {
    // const OtherComponent = React.lazy(() => import('../assets/offline/17/data'));
    // console.log(
    //   `process.env.REACT_APP_OFFLINE === "false"`,
    //   process.env.REACT_APP_OFFLINE === "false"
    // );
    // console.log(`OtherComponent`, OtherComponent)
    if (localStorage.favoriteIndivibooks) {
      this.setState({
        favs: JSON.parse(localStorage.getItem("favoriteIndivibooks")),
      });
    }
    if (process.env.REACT_APP_OFFLINE === "false") {
      this.props.imagesUnloadedAction();
      this.props.resetPages();
      // console.log(
      //   "09-099-09-09-09-0this.library",
      //   this.props.library,
      //   !this.props.loggedIn
      // );
      if (!this.props.loggedIn) {
        this.props.setLibraryFromLocal();
      }
    } else {
      // if(this.props.)
      console.log(`this.props.library`, this.props.library);
      if (this.props.library.length === 0) this.jsonLoader();
    }
    ///
    this.context.closeAllIfOpen();
  }
  toggleFav = (ebookId) => {
    const favs = this.state.favs.includes(ebookId)
      ? this.state.favs.filter((f) => f !== ebookId)
      : [...this.state.favs, ebookId];
    this.setState({
      favs,
    });
    localStorage.setItem("favoriteIndivibooks", JSON.stringify(favs));
  };

  logoutHandler = (e) => {
    // e.preventDefault();
    localStorage.clear(); //TODO DELETE ONLY AUTH , KEEP THE ANNOTS AND CREATE A UI TO DELETE OWN SETTINGS
    window.location.reload();
    // this.props.i18n.changeLanguage("en");
  };
  langHandler = (e) => {
    e.preventDefault();
    // localStorage.clear();
    // window.location.reload();
    console.log("e", e);
    const lang = this.props.i18n.language === "en" ? "tr" : "en";
    // console.log('this.props.i18n', this.props.i18n)
    this.props.i18n.changeLanguage(lang);
  };
  counter = (libBook) => {
    console.log("libBook", libBook);
    // this.setState({
    //   ebooks: 1,
    // });
  };

  jsonLoader = async () => {
    const bookIds = process.env.REACT_APP_OFFLINE_EBOOKS.split(" ");
    console.log(bookIds[0]);
    console.log(`JSON LOADER bookIds`, bookIds, bookIds.length);
    const newLibrary = [];
    const ebookJsons = [];
    bookIds.map((book) => {
      console.log(`book`, book);
      import("../assets/offline/" + book + "/data")
        .then((background) => {
          // this.setState({ background })
          console.log(`background`, background.default[0]);
          return background.default;
        })
        // fetch("./offline/" + book + "/data.json", {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Accept: "application/json",
        //   },
        // })
        //   .then((response) => {
        //     console.log(response);
        //     return response.json();
        //   })
        .then((myJson) => {
          console.log(myJson[0]);
          // console.log(myJson.json());

          myJson.map((mj) => {
            mj.icon = require("../assets/offline/" + book + "/" + mj.icon);
            return mj;
          });
          myJson[0].ebook_pages.map((mj) => {
            mj.path = require("../assets/offline/" + book + "/" + mj.path);
            mj.thumbnail = require("../assets/offline/" +
              book +
              "/" +
              mj.thumbnail);
            mj.buttons.map((btn) => {
              if (btn.button_type === "image" || btn.button_type === "audio") {
                btn.content = require("../assets/offline/" +
                  book +
                  "/" +
                  btn.content);
              }
              return btn;
            });
            return mj;
          });
          // console.log(`bookJson`, bookJson)
          console.log("myJson[0]", myJson[0]);
          const { id, icon } = myJson[0];
          const libBook = {
            id,
            icon: "/offline/" + book + "/" + icon,
            name: myJson[0].name,
          };
          console.log(`id, icon`, id, icon);
          console.log(`libBook`, libBook);
          // newLibrary.push(libBook);
          this.counter(libBook);
          this.setState(
            {
              ebooks: [...this.state.ebooks, myJson[0]],
              // library: [...this.state.library, libBook],
            },
            () => this.props.setLibraryOffline(this.state.ebooks)
          );

          // this.props.getPages(myJson[0])
        });
    });
    // console.log(`newLibrary`, newLibrary);

    // this.setState({
    //   ebooks:1
    // })
  };
  //🇬🇧  🇹🇷
  libraryClickHandler = async (id, url) => {
    localStorage.cenibook_id = id; //TODO Move this from localStorage to Redux
    localStorage.cenibook_url = url; //TODO Move this from localStorage to Redux

    const ebooks =
      this.props.library.length === 0
        ? this.state.ebooks.filter((ebook) => ebook.id === id)[0]
        : this.props.library.filter((ebook) => ebook.id === id)[0];
    console.log(`ebooks`, ebooks);
    // getEbook(ebooks['url']);
    process.env.REACT_APP_OFFLINE === "true" &&
      (await this.props.getPages(
        ebooks
        // this.state.ebooks.filter((ebook) => ebook.id === id)[0]
      ));
    this.props.history.push("/indivibook/1");
    // ReactGA.pageview("/indivibook/1");
  };
  handleChange = (selectedOption) => {
    this.setState({ course: selectedOption });
  };
  runSearchFilter = (value) => {
    this.setState({ searchText: value });
  };
  searchFilter = (event) => {
    const stringText = event.target.value;

    this.setState((oldvalue) => {
      if (oldvalue?.searchWait) {
        clearTimeout(oldvalue?.searchWait);
      }
      const data = setTimeout(this.runSearchFilter.bind(this, stringText), 500);
      return { searchWait: data };
    });
  };

  render() {
    const { profile, domain, t, theme } = this.props;
    // const options = [
    //   { value: "chocolate", label: "Chocolate" },
    //   { value: "strawberry", label: "Strawberry" },
    //   { value: "vanilla", label: "Vanilla" },
    // ];

    const isOffline = process.env.REACT_APP_OFFLINE === "true";
    const library =
      isOffline && this.props.library.length === 0
        ? this.state.library
        : this.props.library;

    const favLibrary = library
      ? library.filter((book) => this.state.favs.includes(book.id))
      : null;
    let notFavLibrary = library
      ? library.filter((book) => !this.state.favs.includes(book.id))
      : null;

    if (
      (notFavLibrary && this.state.course.value !== "") ||
      this.state.searchText
    ) {
      if (this.state.course.value !== "") {
        notFavLibrary = notFavLibrary.filter((book) => {
          return book.course_id === this.state.course.value;
        });
      }
      notFavLibrary = notFavLibrary.filter((book) => {
        const re = new RegExp(this.state.searchText, "gi");
        if (re.test(book.name)) return book;
      });
    }

    let options = [
      {
        value: "",
        label: this.props.i18n.t("Clear the Filter"),
      },
    ];
    const opt = library
      ? library.map((book) => {
          if (
            book.course_name &&
            options.findIndex((bk) => bk.value === book.course_id) === -1
          ) {
            options.push({ value: book.course_id, label: book.course_name });
          }
        })
      : [];
    console.log("notFavLibrary: ", notFavLibrary);
    console.log("profile", profile);
    console.log("library", library, this.props.library);

    return (
      <div className="library" data-theme={theme}>
        <div className="navbar library-header is-fixed-top">
          <div className="container column">
            <div className=" is-one-quarter">
              <img
                className={
                  process.env.REACT_APP_COMPANY_INNER_LOGO_PATH
                    ? "library-company-logo" //require("../assets/img/company_logo.png")
                    : "library-indivi-logo"
                }
                src={
                  process.env.REACT_APP_COMPANY_INNER_LOGO_PATH
                    ? process.env.REACT_APP_COMPANY_INNER_LOGO_PATH //require("../assets/img/company_logo.png")
                    : require("../assets/img/svg/indivibook_white.svg")
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = require("../assets/img/svg/indivibook_white.svg");
                }}
                alt={this.props.t("company-logo")}
              />
              {/* <h2 className=" is-hidden-tablet">{t("library")}</h2>
              <h1 className=" is-hidden-mobile">{t("library")}</h1> */}
            </div>
            {process.env.REACT_APP_OFFLINE === "false" && (
              <Offline>
                <div className="no-internet">
                  {t("Warning! You do not have an internet connection!")}
                </div>
              </Offline>
            )}
            {!isOffline ? (
              <div className="welcome-user">
                <div className="library-username">
                  {t("Welcome")}, {profile && profile.name}
                </div>
                <a
                  className="library-logout"
                  href=""
                  onClick={this.logoutHandler}
                >
                  {t("Logout")}
                </a>
                {/* <img src="https://source.unsplash.com/collection/190727/1600x900" /> */}
              </div>
            ) : (
              <div className="welcome-user">
                <div>{t("IndiviBook Portable")}</div>
              </div>
            )}
          </div>
          {/* <a onClick={this.langHandler}>{t("language")}</a> */}
          <div className="library-selection">
            <div className="select-area">
              {/* <label htmlFor="do-switchSmall">Theme</label> */}
              <div className="select is-small">
                <ChangeLanguage />
                <span className="icon">
                  <img
                    src={require("../assets/img/svg/pagi-combobox-arrow.svg")}
                    alt="First Page"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="library-content">
          <div className="container column">
            {favLibrary && favLibrary.length > 0 && (
              <div className="library-content-row">
                <div className="library-content-title">
                  <h2>{this.props.i18n.t("My Favorites")}</h2>
                </div>
                <div className="library-book-list">
                  <ul>
                    {favLibrary
                      ? favLibrary.length > 0
                        ? favLibrary.map((indivibook) => {
                            console.log("indivibook: ", indivibook);

                            const favorited = this.state.favs.includes(
                              indivibook.id
                            );

                            return (
                              <li key={"d" + indivibook.id}>
                                <div className="library-book-item">
                                  <a
                                    className="library-book-cover"
                                    onClick={() => {
                                      this.libraryClickHandler(
                                        indivibook.id,
                                        indivibook.url
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        indivibook.icon
                                          ? indivibook.icon
                                          : require("../assets/img/book-placeholder.png")
                                      }
                                      alt=""
                                    />
                                  </a>
                                  <div className="is-flex is-flex-direction-row">
                                    <a
                                      className="library-book-title"
                                      // href=""
                                      onClick={() => {
                                        this.libraryClickHandler(
                                          indivibook.id,
                                          indivibook.url
                                        );
                                      }}
                                    >
                                      {indivibook.name}
                                    </a>
                                    <a
                                      className="library-book-title-details"
                                      onClick={() => {
                                        this.toggleFav(indivibook.id);
                                      }}
                                    >
                                      {favorited ? (
                                        <span className="icon  has-text-danger">
                                          <MdFavorite className="fas fa-2x" />
                                        </span>
                                      ) : (
                                        <span className="icon  has-text-light">
                                          <MdFavoriteBorder className="fas fa-2x" />
                                        </span>
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        : t("You have no favorites") + "."
                      : t("Loading") + "..."}
                  </ul>
                </div>
              </div>
            )}
            <div className="library-content-row">
              <div className="library-content-title">
                <div className="container">
                  <div className="row">
                    <div className="col col-lg-4 ">
                      <h2>{t("My IndiviBooks")}</h2>
                    </div>

                    <div className="col col-lg-4">
                      <input
                        type="text"
                        className="input mb-1 lib-search"
                        onChange={this.searchFilter}
                        placeholder={this.props.i18n.t("Search Book Title")}
                      />
                    </div>

                    <div className="col col-lg-4 ">
                      <div className="library-book-filter ml-auto">
                        <Select
                          options={options}
                          placeholder={this.props.i18n.t("Select a course")}
                          value={this.state.course}
                          onChange={this.handleChange}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="library-book-list">
                <ul>
                  {notFavLibrary
                    ? notFavLibrary.length > 0
                      ? notFavLibrary.map((indivibook) => {
                          const favorited = this.state.favs.includes(
                            indivibook.id
                          );

                          return (
                            <li key={"f-" + indivibook.id}>
                              <div className="library-book-item">
                                <a
                                  className="library-book-cover"
                                  onClick={() => {
                                    this.libraryClickHandler(
                                      indivibook.id,
                                      indivibook.url
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      indivibook.icon
                                        ? indivibook.icon
                                        : require("../assets/img/book-placeholder.png")
                                    }
                                    alt=""
                                  />
                                </a>
                                <div className="is-flex is-flex-direction-row">
                                  <a
                                    className="library-book-title"
                                    // href=""
                                    onClick={() => {
                                      this.libraryClickHandler(
                                        indivibook.id,
                                        indivibook.url
                                      );
                                    }}
                                  >
                                    {indivibook.name}
                                  </a>
                                  <a
                                    className="library-book-title-details"
                                    onClick={() => {
                                      this.toggleFav(indivibook.id);
                                    }}
                                  >
                                    {favorited ? (
                                      <span className="icon  has-text-danger">
                                        <MdFavorite className="fas fa-2x" />
                                      </span>
                                    ) : (
                                      <span className="icon  has-text-light">
                                        <MdFavoriteBorder className="fas fa-2x" />
                                      </span>
                                    )}
                                  </a>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      : t("You have no IndiviBooks" + ".")
                    : t("Loading") + "..."}
                </ul>
              </div>
            </div>
          </div>
          <footer className="library-footer has-text-centered">
            <div className="has-text-centered">
              <p>
                <a href="https://indivibook.com">
                  <span className="lib-indivibook">indivibook</span>
                </a>{" "}
                v1.1b{" "}
                {isOffline && (
                  <span className="lib-offline-version">
                    {this.props.i18n.t("Offline Version")}
                  </span>
                )}
                Copyright © 2021-2031{" "}
                <a href="https://indivitech.com">
                  {" "}
                  <span className="lib-indivitech">indivitech</span>
                </a>
              </p>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    library: state.login.library,
    domain: state.login.domain,
    profile: state.login.user_profile,
    loggedIn: state.login.loggedIn,
    theme: state.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    imagesUnloadedAction: () => dispatch(imagesUnloadedAction()),
    resetPages: () => dispatch(resetPages()),
    setLibraryFromLocal: () => dispatch(setLibraryFromLocal()),
    getPages: (pages) => dispatch(getPages(pages)),
    // getPages: (pages) => dispatch(getPages(pages)),
    setLibraryOffline: (lib) => dispatch(setLibraryOffline(lib)),

    // spinner: (show) => dispatch(spinner(show)),
    // setLoginSuccess: (status) => dispatch(setLoginSuccess(status)),
  };
};
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(Library));

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Library))
);
