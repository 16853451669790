import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex:1000
};

export default function Spinner(props) {
  return (
    <div style={style}>
      <div className="loading">
        <ClipLoader color={props.color} loading={props.spin} size={props.size} />
      </div>
    </div>
  );
}
