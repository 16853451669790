import React from "react";
import { useTranslation } from "react-i18next";

export default function Hand(props) {
  // console.log('props.activeButton -----HAND', props.activeButton)
  const { t } = useTranslation();
  return (
    <li onClick={!props.hideButton ? props.onClick : undefined}>
      <span
        className={`button normal-btn hand ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "pan" ? "active" : ""
        }${props.hideButton ? "passive" : ""}`}
        title={t("Select Drawing Objects") + " (S)"}
        data-tip={t("Select Drawing Objects")}
      >
        <img
          src={require("../../assets/img/svg/toolbar-hand.svg")}
          alt={t("Select Drawing Objects") + " (S)"}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Scroll")}</span>}
      </span>
    </li>
  );
}
