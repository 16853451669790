import React, { Component } from "react";
import Toolbar from "./Toolbar";

import Page from "./Page";
import { toggleSideBar } from "../actions/sidebar";
import { pullZipImages } from "../actions/pages";
import { changeSelectedToolFromOutside } from "../actions/selectedTool";
import { imagesLoaded as imagesLoadedAction } from "../actions/imageLoad";
import { hideInteractiveObjects as hideInteractiveObjectsAction } from "../actions/interactiveObjects";
// import ReactTooltip from "react-tooltip";
import { selectCurrentPageWithMyContent } from "../store/configureStore";

import OnImagesLoaded from "react-on-images-loaded";
import { Progress } from "reactstrap";
import { connect } from "react-redux";
// import Pixabay from "./Pixabay";
import Loading from "./Loading";
import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isMobile,
} from "react-device-detect";
import NewToolbar from "./NewToolbar";
import ToolbarAlternative from "./ToolbarAlternative";


import {MobileTools} from "./MobileTools"

// import LoadingAnim from "./LoadingAnim";


class PdfPageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: "pan",
      // imagesLoaded: false,
      isMounted: false,
      // zipfileProgress:100,
      // zipFileLoaded:false,
    };
  }

  // hides and shows interactive objects on the page
  hideInteractiveObjects = () => {
    this.props.hideInteractiveObjectsAction();
  };

  onClickLoadAll = () => {
    if (this.props.zipFileLoaded) {
      return;
    }
    this.props.pullZipImages();
  };

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }

  runAfterImagesLoaded = () => {
    if (this.state.isMounted) {
      this.props.imagesLoadedAction();
    }
  };

  renderPages() {
    if (this.state.isMounted) {
      let cPage = this.props.currentPage;
      if (cPage.length === 0) return false;
      if (typeof cPage[0] === "undefined") return false;
      const page = cPage[0];
      return (
        <OnImagesLoaded
          onLoaded={this.runAfterImagesLoaded}
          timeout={100}
          key={1}
        >
          <Page
            data={page}
            key={page.id}
            subKey={page.id}
            hideObjects={this.state.hideInteractiveObjects}
            pageNumber={page.pageNumber}
            currentPageNumber={this.props.currentPageNumber}
          />
          );
        </OnImagesLoaded>
      );
    }
  }

  onChangeTool(selectedTool) {
    this.props.changeSelectedToolFromOutside(selectedTool);
  }
  onChangeToolIcon(activeButton) {
    this.setState({ activeButton });
  }

  renderLoading() {
    if (!this.props.imagesLoaded) {
      return <Loading />;
    }
  }

  render() {
    const isSmallScreen = this.props.canvasVals
      ? parseInt(window.innerWidth) < 640
      : // ? parseInt(this.props.canvasVals.vpW) < 768
        false;

    return (
      <div
        className={`pdf-page-list
        ${this.props.annotationSelectionMode ? "qhideable-top-margin" : ""} ${
          this.props.pageZoomPartMode === "zoom-show" ? "no-top-padding" : ""
        } `} // Set top margin more when the qhideable editor opens
        id="pdfPageList"
        ref={this.props.forwardedref}
        // onScroll={this.onScrollPage}
        style={{
          marginLeft: this.props.sidebarIsOpened
            ? !isSmallScreen
              ? 200
              : ""
            : "",
        }}
      >

        <MobileTools />
        {/* {this.props.settings.pageZoomPartShowLoading && <LoadingAnim />} */}

        {this.renderLoading()}
        <ul>
          <div id="mainOne" {...this.props.handlers}>
            {this.renderPages()}
          </div>
        </ul>
        <div
          className="page-number-bar-wrapper"
          style={{ marginLeft: this.props.sidebarIsOpened ? "-140px" : 0 }}
        >
          {/* <div in={this.state.showPageNumber} className="page-number-bar">
            {this.props.currentPageNumber} / {this.state.totalPageNumber}
          </div> */}
        </div>

        <div>
          {this.props.toolbarMode && (
            <ToolbarAlternative
              toolBarDraggable={this.props.toolBarDraggable}
              hideInteractiveObjects={this.hideInteractiveObjects}
              ref="child"
              onChangeTool={(param) => this.onChangeTool(param)}
              activeButton={this.state.activeButton}
              // zipFileLoaded={this.state.zipFileLoaded}
              {...this.props}
            />
          )}
          {!this.props.toolbarMode && (
            <Toolbar
              toolBarDraggable={this.props.toolBarDraggable}
              hideInteractiveObjects={this.hideInteractiveObjects}
              ref="child"
              onChangeTool={(param) => this.onChangeTool(param)}
              activeButton={this.state.activeButton}
              // zipFileLoaded={this.state.zipFileLoaded}
              {...this.props}
            />
          )}
          {/* <NewToolbar /> */}
        </div>
        {/* <div>...</div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.pixabay.data);
  return {
    sidebarIsOpened: state.sideBarOpened,
    currentPageNumber: state.pages.currentPageNo,
    pages: state.pages.pages,
    // currentPage: state.pages.currentPage,
    currentPage: selectCurrentPageWithMyContent(state),
    totalPageNumber: state.pages.totalPageNumber,
    zipfileProgress: state.zipDownloadProg,
    zipFileLoaded: state.pages.zipLoaded,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    imagesLoaded: state.imagesLoaded,
    selectedTool: state.selectedTool,
    // imageCapture: state.imageCapture,
    canvasVals: state.scaleZoomFactor.canvas,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    settings: state.settings,
    toolbarMode: state.toolbar.toolbarMode,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  pullZipImages,
  hideInteractiveObjectsAction,
  imagesLoadedAction,
  changeSelectedToolFromOutside,
})(PdfPageList);
