import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

class Pencil extends FabricCanvasTool {
  configureCanvas(props, lineColor) {
    let canvas = this._canvas;
    console.log('pencil triggered', props)
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = props.toolbar.lineWidth;
    canvas.freeDrawingBrush.color = props.toolbar.lineColor;
    canvas.defaultCursor = "crosshair";
  }
  doMouseDown(o) {
    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function(ob) {
        ob.isEditing && ob.exitEditing();
      });
    }
  }
  doMouseMove(o) {}

}

export default Pencil;
