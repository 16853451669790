import React from "react";
import { useTranslation } from "react-i18next";
import { BsFillVolumeUpFill } from "react-icons/bs";

export default function AudioButton(props) {
  const { t, i18n } = useTranslation();

  // or const [t, i18n] = useTranslation();
  const { object } = props;
  // console.log("object.description", object);
  const transform = "scale(" + object.scale + ")";
  const styleMini = object.style.includes("mini");
  const styleNoAnimation = object.style.includes("no-animation");
  // console.log(`styleNo`, styleNo, styleNo > 4, styleNo < 5);
  return (
    <div
      className={`interactive-object `}
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className={`object-icon ${styleMini ? "mini-icon" : ""}`}>
        {/* <BsFillVolumeUpFill className="fas fa-1x" /> */}
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="white"
            stroke="#FD6D87"
            strokeWidth="0" // Was 2 = Mustafa changed to 0
          />
          {(object?.style?.includes("styleinfo")) ? 
              <path d="M14.1148 4C15.28 4 15.8626 4.7296 15.8626 5.5656C15.8626 6.6096 14.8504 7.5752 13.533 7.5752C12.4296 7.5752 11.7861 6.9752 11.8165 5.9832C11.8165 5.1488 12.5826 4 14.1148 4ZM10.5296 20C9.60956 20 8.93565 19.4784 9.57913 17.1808L10.6348 13.1072C10.8183 12.456 10.8487 12.1944 10.6348 12.1944C10.3591 12.1944 9.16609 12.644 8.45913 13.088L8 12.384C10.2365 10.6352 12.8096 9.6104 13.9139 9.6104C14.833 9.6104 14.9861 10.6288 14.527 12.1944L13.3174 16.476C13.1035 17.232 13.1948 17.4928 13.4096 17.4928C13.6852 17.4928 14.5896 17.1792 15.4783 16.5272L16 17.1784C13.8243 19.216 11.4478 20 10.5296 20Z" fill="white">            
              </path>:
              <path
              d="M12.1538 6C11.6016 6 11.1538 6.44772 11.1538 7V11.1265C11.1538 11.645 10.6338 12 10.1154 12C8.39154 12 7 13.34 7 15C7 16.66 8.39154 18 10.1154 18C11.7088 18 13.0136 16.8418 13.1942 15.3496C13.1953 15.3404 13.2032 15.3333 13.2125 15.3333C13.2226 15.3333 13.2308 15.3251 13.2308 15.315V9C13.2308 8.44772 13.6785 8 14.2308 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6H12.1538Z"
              fill="#FD6D87"
            />
            }
        </svg>
      </span>

      <span className="object-text">
        {!object.description ||
        object.description === undefined ||
        object.description === "undefined"
          ? t("Audio")
          : object.description}
      </span>
    </div>
  );
}
