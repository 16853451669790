import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/bulma.min.css';
import './assets/css/bulma-switch.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';


import './assets/css/media-max-1025.css';
import './assets/css/media-max-1023.css';
import './assets/css/media-max-768.css';
import './assets/css/media-max-765.css';
import './assets/css/media-max-579.css';
import './assets/css/media-max-168.css';
import './assets/css/media-min-1024.css';
import './assets/css/styleZoom.css';
import './assets/css/styleQHide.css';
import './assets/css/styleQShow.css';
import './assets/css/styleAnswer.css';
import './assets/css/styleVideoAnswer.css';


import './assets/css/theme.css';
import '../node_modules/intro.js/introjs.css';
// import MainPage from './components/MainPage';
import './i18n';

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
