import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Timer from "../SketchSelectedButtons/Timer";
import ZoomModeButton1 from "../SketchSelectedButtons/ZoomModeButton1";
import GroupButton from "../SketchSelectedButtons/GroupButton";
import UnGroupButton from "../SketchSelectedButtons/UnGroupButton";
import ColorButton from "../SketchSelectedButtons/ColorButton";
import PasteButton from "../SketchSelectedButtons/PasteButton";
import RemoveButton from "../SketchSelectedButtons/RemoveButton";
import ExitButton from "../SketchSelectedButtons/ExitButton";
import {
  setLineWidth,
  setLineColor,
  setFillColor,
  setOpacity,
} from "../../actions/toolbar";
import ReactSlider from "react-slider";
import {
  setAnnotationSelectionMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionGroupMode,
  setSelectionUnGroupMode,
} from "../../actions/annotationSelection";
import { CompactPicker } from "react-color";
import { setZoomModeMenu, showTimer } from "../../actions/settings";

const TopEditMenu = (props) => {
  const [showColor, setShowColor] = useState(false);
  const CompactPickerColors = (<CompactPicker />).props.colors;
  const { t, i18n } = useTranslation();

  const onClickLineSubItem = (width) => {
    props.setLineWidth(width * 3);
  };
  const onChangeOpacity = (opacity) => {
    props.setOpacity(opacity);
  };
  const onChangeFillColor = (color) => {
    if (showColor === "fill") {
      if (props.toolbar.fillColor === color.hex) {
        props.setFillColor(null);
        setTimeout(() => {
          props.setFillColor(color.hex);
        }, 100);
      } else {
        props.setFillColor(color.hex);
      }
    } else {
      if (props.toolbar.lineColor === color.hex) {
        props.setLineColor(null);
        setTimeout(() => {
          props.setLineColor(color.hex);
        }, 100);
      } else {
        props.setLineColor(color.hex);
      }
    }
    setShowColor(false);
  };
  return (
    <>
      <div
        className={`navbar is-fixed-top top-annotation-menu ${
          props.shouldTopMenuOpened ? "open" : "hide"
        } `}
      >
        {props.timerMode && <Timer shouldShowButtonLabels={props.showLabels} />}
        {props.zoomModeMenuOpen && (
          <>
            <ZoomModeButton1
              onClickHandler={() => {
                props.setSelectionGroupMode(true);
              }}
            />
          </>
        )}

        {props.editSelectionMode && (
          <>
            <GroupButton
              onClickHandler={() => {
                props.setSelectionGroupMode(true);
              }}
              shouldShowButtonLabels={props.showLabels}
            />
            <UnGroupButton
              onClickHandler={() => {
                props.setSelectionUnGroupMode(true);
              }}
              shouldShowButtonLabels={props.showLabels}
            />
            <PasteButton
              onClickHandler={() => {
                props.setSelectionPasteMode(true);
              }}
              shouldShowButtonLabels={props.showLabels}
            />

            {props.editSelectionMode !== "image" && (
              <>
                {props.editSelectionMode !== "path" && (
                  <ColorButton
                    color={props.toolbar.currentFillColor}
                    title={t("Fill Color")}
                    onClickHandler={() => {
                      setShowColor(showColor !== "fill" ? "fill" : false);
                      // props.setSelectionRemoveMode(true);
                    }}
                    colortype={true}
                    shouldShowButtonLabels={props.showLabels}
                  />
                )}

                <ColorButton
                  title={t("Line Color")}
                  color={props.toolbar.currentLineColor}
                  onClickHandler={() => {
                    setShowColor(showColor !== "line" ? "line" : false);
                  }}
                  colortype={false}
                  shouldShowButtonLabels={props.showLabels}
                />
              </>
            )}
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="opacity-thumb"
              trackClassName="opacity-track"
              onAfterChange={onChangeOpacity}
              defaultValue={props.toolbar.currentOpacity}
              value={props.toolbar.currentOpacity}
              min={0}
              max={1}
              step={0.1}
              renderThumb={(props, state) => <div {...props}></div>}
            />

            <RemoveButton
              onClickHandler={() => {
                props.setSelectionRemoveMode(true);
              }}
              shouldShowButtonLabels={props.showLabels}
            />
          </>
        )}

        {(props.annotationSelectionMode ||
          props.timerMode ||
          props.zoomModeMenuOpen) && (
          <ExitButton
            hideTitle={props.timerMode}
            onClickHandler={() => {
              props.annotationSelectionMode &&
                props.setAnnotationSelectionMode(false);
              props.timerMode && props.showTimer(false);
              props.zoomModeMenuOpen && props.setZoomModeMenu(false);
            }}
          />
        )}
      </div>
      {showColor && (
        <div className="top-color-picker">
          <CompactPicker
            colors={[...CompactPickerColors, "transparent"]}
            onChange={onChangeFillColor}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // settings: state.settings,
    toolbar: state.toolbar,
    editSelectionMode: state.annotationSelections.editSelectionMode,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    showLabels: state.toolbar.showLabels,
    timerMode: state.settings.timerMode,

  };
};

export default connect(mapStateToProps, {
  setAnnotationSelectionMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionGroupMode,
  setSelectionUnGroupMode,
  setLineWidth,
  setLineColor,
  setFillColor,
  setOpacity,
  setZoomModeMenu,
  showTimer,
})(TopEditMenu);
