import React from "react";
import { useTranslation } from "react-i18next";

export default function PageTypeLabel(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button  is-small modal-button ${
          props.disabled ? "button-disabled" : ""
        } ${props.shouldShowButtonLabels ? " show-label-top-menu " : ""}`}
        onClick={props.onClickHandler}
        title={t("Connect")}
      >
        <span
          className={` icon  	${
            props.showMargins ? "circle-background" : "circle-background-disabled"
          }   `}
        >
          {props.pageType}
        </span>
        {props.shouldShowButtonLabels && (
          <span className=" mr-1 show-label-text">
            {t(props.showMargins ? "Hide Margins" : "Show Margins")}
          </span>
        )}
      </button>
    </>
  );
}
