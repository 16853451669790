import {
  ADD_ACTIVITY,
  CANCEL_ACTIVITY,
  DONE_ACTIVITY,
  INSERT_ACTIVITY,
  DELETE_ACTIVITY,
  EDIT_ACTIVITY,
  INSERT_PAGE,
  UPDATE_ACTIVITY,
  DELETE_ALL_PAGE_ACTIVITIES,
  DELETE_ALL_PAGE_ZOOM_ACTIVITIES,
  DELETE_ACTIVITY_KEEP_COORDS_BY_PAGE,
  SET_CLEAR_KEEP_COORDS,
} from "./actionTypes";

import { guidGenerator } from "../services/util";

export function addActivity(name, top, left, title) {
  return function (dispatch) {
    //send as object (name, top, left)
    dispatch({
      type: ADD_ACTIVITY,
      payload: { name, top, left, title },
    });
  };
}
export function editActivity(id, name, top, left, btnStyle, activityShow) {
  //name is activity type
  return function (dispatch) {
    dispatch({
      type: EDIT_ACTIVITY,
      payload: { id, name, top, left, btnStyle, activityShow },
    });
  };
}
export function cancelActivity() {
  //reset form when user cancels
  return function (dispatch) {
    dispatch({
      type: CANCEL_ACTIVITY,
    });
  };
}
export function doneActivity() {
  //reset when form closes
  return function (dispatch) {
    dispatch({
      type: DONE_ACTIVITY,
    });
  };
}
export function insertActivity(pageNumber, button) {
  //insert button
  return function (dispatch) {
    dispatch({
      type: INSERT_ACTIVITY,
      payload: [pageNumber, button],
    });
  };
}
export function updateActivity(button, pageNumber, updateCurrentPage = true) {
  //insert button
  return function (dispatch) {
    dispatch({
      type: UPDATE_ACTIVITY,
      payload: [button, pageNumber, updateCurrentPage],
    });
  };
}

export function deletePageActivityKeepCoordsByPage(pageNumber) {
  //delete temp keep cords after its used
  return function (dispatch) {
    dispatch({
      type: DELETE_ACTIVITY_KEEP_COORDS_BY_PAGE,
      payload: pageNumber,
    });
  };
}
export function setClearKeepCoords(status) {
  return function (dispatch) {
    dispatch({
      type: SET_CLEAR_KEEP_COORDS,
      payload: status,
    });
  };
}
export function deleteActivity(button, pageId) {
  return function (dispatch) {
    dispatch({
      type: DELETE_ACTIVITY,
      payload: [button, pageId],
    });
  };
}
export function deleteAllActivitiesOfPage(pageId) {
  console.log('!deleteAllActivitiesOfPage pageId: ', pageId);

  return function (dispatch) {
    dispatch({
      type: DELETE_ALL_PAGE_ACTIVITIES,
      payload: pageId,

    });
  };
}
export function deleteAllZoomActivitiesOfPage(pageId) {
  return function (dispatch) {
    dispatch({
      type: DELETE_ALL_PAGE_ZOOM_ACTIVITIES,
      payload: pageId,
    });
  };
}
export function insertPage(newPage) {
  return function (dispatch) {
    dispatch({
      type: INSERT_PAGE,
      // payload: guidGenerator(),
      payload: newPage,
    });
  };
}
