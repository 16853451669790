import React from "react";
import { HiDocumentDownload } from "react-icons/hi";

export default function InsertBtn(props) {
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button ${
          props.isLoading ? "is-loading" : ""
        }`}
        onClick={!props.isLoading && props.handleClick}
      >
        <span className="icon is-small has-text-info">
          <HiDocumentDownload className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
