import React from "react";
import { FaUserEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
export default function EditorMode(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`button is-small modal-button ${
          props.isLoading ? "is-loading" : ""
        }`}
        // onClick={!props.isLoading && props.deleteContent}
        title={t("Editor Mode")}
      >
        <span className="icon  has-text-warning mb-2">
          <FaUserEdit className="fas fa-2x" />
        </span>{" "}
        {props.shouldShowButtonLabels && (
          <span className="has-text-warning ml-2 is-hidden-tablet-only  is-hidden-mobile mb-2">
            {t("Editor Mode")}
          </span>
        )}
      </button>
    </>
  );
}
