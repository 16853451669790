import {
  HIDE_INTERACTIVE_OBJECTS,
  SHOW_INTERACTIVE_OBJECTS,
} from "../actions/actionTypes";

export default (state = false, action) => {
  switch (action.type) {
    case HIDE_INTERACTIVE_OBJECTS:
      return true;
    case SHOW_INTERACTIVE_OBJECTS:
      return false;

    default:
      return state;
  }
};
