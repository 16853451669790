import React, { Component, useState, useRef } from "react";
// import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
// import { HiOutlineSun, HiOutlineX } from "react-icons/hi";
// import { selectAllActivitiesArrayPerPage } from "../store/configureStore";
import Index from "./_SidebarIndex/Index";
import DrawingItems from "./_SidebarIndex/DrawingItems";
import Bookmarks from "./_SidebarIndex/Bookmarks";
import InteractiveObjects from "./_SidebarIndex/InteractiveObjects";

function SidebarIndex(props) {
  const { t } = useTranslation();

  const [clickedSection, setClickedSection] = React.useState('index');

  return (
    <div className="columns is-mobile">
      <div className="column modal-left is-narrow is-fullheight">
        <div className="tabs">
          <ul>
            <li className={clickedSection == "index" ? "active" : ""}>
              <a
                onClick={() => {
                  setClickedSection("index");
                }}
              >
                <span className="tabs-icon">
                  <img
                    src={require("../assets/img/svg/tabs-icon-list.svg")}
                    alt={t("TOC")}
                  />
                </span>
                <span className="tabs-text is-hidden-mobile	">{t("TOC")}</span>
              </a>
            </li>
            <li
              className={
                clickedSection == "interactive_objects" ? "active" : ""
              }
            >
              <a
                onClick={() => {
                  setClickedSection("interactive_objects");
                }}
              >
                <span className="tabs-icon">
                  <img
                    src={require("../assets/img/svg/tabs-icon-intreractive-objects.svg")}
                    alt={t("Activities")}
                  />
                </span>
                <span className="tabs-text is-hidden-mobile	">{t("Activities")}</span>
              </a>
            </li>
            <li className={clickedSection == "bookmarks" ? "active" : ""}>
              <a
                onClick={() => {
                  setClickedSection("bookmarks");
                }}
              >
                <span className="tabs-icon">
                  <img
                    src={require("../assets/img/svg/tabs-icon-bookmark.svg")}
                    alt={t("My Bookmarks")}
                  />
                </span>
                <span className="tabs-text is-hidden-mobile	">{t("My Bookmarks")}</span>
              </a>
            </li>
            <li className={clickedSection == "drawing_items" ? "active" : ""}>
              <a
                onClick={() => {
                  setClickedSection("drawing_items");
                }}
              >
                <span className="tabs-icon">
                  <img
                    src={require("../assets/img/svg/tabs-icon-draw-objects.svg")}
                    alt={t("My Annotations")}
                  />
                </span>
                <span className="tabs-text is-hidden-mobile	">{t("My Annotations")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {clickedSection === "index" && <Index />}
      {clickedSection === "drawing_items" && <DrawingItems />}
      {clickedSection === "bookmarks" && <Bookmarks />}
      {clickedSection === "interactive_objects" && <InteractiveObjects />}
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//   };
// };

export default // connect(
// mapStateToProps,
// {}
// mapDispatchToProps
// )
SidebarIndex;
