/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

class CaptureZoom extends FabricCanvasTool {
  configureCanvas(props) {
    console.log('capture zoom!')
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.defaultCursor = "crosshair";

  }
  doMouseDown(o) {
    this._canvas.requestRenderAll();

    let canvas = this._canvas;
    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;

    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function (ob) {
        ob.isEditing && ob.exitEditing();
      });
    }
  }

  doMouseMove(o) {}

}

export default CaptureZoom;
