import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { SketchField, Tools } from ".";
import { changeSelectedTool } from "../../actions/selectedTool";

const Sketch = (props) => {
  const _sketch = useRef(null);
  const [imgLoaded, setImgLoaded] = useState(false);

  const [state, setState] = useState({
    enableRemoveSelected: true,
    fillWithColor: true,
    drawings: [],
    canUndo: true,
    canRedo: true,
    imageUrl: props.image.path,
    enableCopyPaste: false,
    firstInit: false,
  });

  useEffect(() => {
    // setImgLoaded(false);
    addImageBG(); //Probably fixed an issue when the thumbnail image is showing after render.
  }, [props.image.path]);

  // useEffect(() => {
  //   _sketch.current.setBackgroundFromDataUrl(props.image.path, {}, reloadDraw);
  // }, [props.image.path]);
  useEffect(() => {
    !props.annotationSelectionMode &&
      _sketch.current.setBackgroundFromDataUrl(props.image.path, {}, true);
  }, [props.annotationSelectionMode]);

  // const reloadDraw = () => {
  //   const itemId = `${props.ebookId}_item_${props.currentPage[0].id}`;
  //   let last = JSON.parse(localStorage.getItem(itemId));
  //   console.log("last", last);

  //   if (last && _sketch.current) {
  //     if (last.length > 0) {
  //       console.log("rendering", last.length);
  //       last.map((obj) => {
  //         try {
  //           obj.scaleX = props.canvasScaleX / obj.oldScaleX;
  //           obj.scaleY = props.canvasScaleY / obj.oldScaleY;
  //           obj.fromJson = true;
  //           obj.visible = obj.qId ? false : true;
  //         } catch (err) {
  //           console.error(err);
  //         }
  //       });
  //       setTimeout(() => {
  //         _sketch.current && _sketch.current.addObjectsFromJson(last);
  //       }, 500);

  //     }
  //   }
  // };

  // const _setBg = async () => {
  //   await reloadDraw();
  // };
  const addImageBG = async () => {
    _sketch.current.setThumbnailBGFromDataUrl(
      props.image.thumbnail,
      props.image.path,
      {}
      // reloadDraw()
    );

    // _sketch.current.setBackgroundFromDataUrl(props.image.path, {}, _setBg);
  };

  // const setLoaded = () => {
  //   setImgLoaded(true);
  // };
  return (
    <div
    // style={{
    //   backgroundImage: "url(" + props.image.thumbnail + ")",
    //   backgroundPosition: "center",
    //   backgroundSize: "cover",
    //   backgroundRepeat: "no-repeat",
    // }}']
    key={props.pageNumber.toString()}
    >
      <SketchField
        name="sketch"
        //   ref={(c) => (_sketch.current = c)}
        ref={_sketch}

        // onChange={_onSketchChange}
        // saveCanvas={saveCanvas}
        // onChangeTool={(param) => props.onChangeTool(param)}
        // addImageBG={addImageBG}
        imagePath={props.image.path}
        image={props.image}
        // setBg={_setBg}
        // sidebarIsOpened={props.sidebarIsOpened}
        interactiveButtonsSizeChanged={props.interactiveButtonsSizeChanged}
        // onClickDrag={(param) => props.onClickDrag(param)}
        // panDrag={(param) => props.panDrag(param)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tool: state.selectedTool,
    ebookId: state.pages.ebookId,
    canvasScaleX: state.scaleZoomFactor.canvas.scaleX,
    canvasScaleY: state.scaleZoomFactor.canvas.scaleY,
    sideBarOpened: state.sideBarOpened,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    currentPage: state.pages.currentPage,
    currentSelection: state.annotationSelections.currentSelection,
  };
};

export default connect(mapStateToProps, {
  changeSelectedTool,
})(Sketch);
