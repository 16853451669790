import React from "react";
import {
  HiXCircle,
  // HiOutlineSun,
  // HiOutlineTrash,
  // HiOutlineArrowsExpand,
  // HiLightBulb,
  //   HiPencilAlt,
  IoCut,
} from "react-icons/io5";
import { useTranslation } from "react-i18next";
export default function CutPagePartButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button`}
        onClick={props.onClickHandler}
        title={t("Exit")}
      >
        <span className="has-text-warning ">{t('Cut')}</span>
        <span className="icon  has-text-warning">
          <IoCut className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
