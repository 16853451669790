import React from "react";


export default function FormButtonStyle(props) {
   console.log("props.buttonstyle",props.buttonstyle);

   let btnIcon = <path
   d="M17.3333 11.2H6.66667C6.3 11.2 6 11.56 6 12C6 12.44 6.3 12.8 6.66667 12.8H17.3333C17.7 12.8 18 12.44 18 12C18 11.56 17.7 11.2 17.3333 11.2ZM6.66667 16H13.3333C13.7 16 14 15.64 14 15.2C14 14.76 13.7 14.4 13.3333 14.4H6.66667C6.3 14.4 6 14.76 6 15.2C6 15.64 6.3 16 6.66667 16ZM17.3333 8H6.66667C6.3 8 6 8.36 6 8.8V8.808C6 9.248 6.3 9.608 6.66667 9.608H17.3333C17.7 9.608 18 9.248 18 8.808V8.8C18 8.36 17.7 8 17.3333 8Z"
   fill="#FD6D87"
 />

   const handleChange = (e) => {
    switch (e) {
      case "text":
        return <path
        d="M17.3333 11.2H6.66667C6.3 11.2 6 11.56 6 12C6 12.44 6.3 12.8 6.66667 12.8H17.3333C17.7 12.8 18 12.44 18 12C18 11.56 17.7 11.2 17.3333 11.2ZM6.66667 16H13.3333C13.7 16 14 15.64 14 15.2C14 14.76 13.7 14.4 13.3333 14.4H6.66667C6.3 14.4 6 14.76 6 15.2C6 15.64 6.3 16 6.66667 16ZM17.3333 8H6.66667C6.3 8 6 8.36 6 8.8V8.808C6 9.248 6.3 9.608 6.66667 9.608H17.3333C17.7 9.608 18 9.248 18 8.808V8.8C18 8.36 17.7 8 17.3333 8Z"
        fill="#FD6D87"
      />;
      case "video":
        return  <path
        d="M9 16.8889V6.97899C9 6.15885 9.93293 5.68743 10.5932 6.17394L16.9638 10.8681C17.4892 11.2552 17.509 12.0335 17.0041 12.4469L10.6335 17.6626C9.98061 18.1972 9 17.7327 9 16.8889Z"
        fill="#FD6D87"
      />;
      case "h5p":
        return <>
            <path
          d="M14.0858 8.13577C13.687 7.57859 12.8585 7.57859 12.4596 8.13577L11.3031 9.75122C11.0539 10.0993 11.0539 10.5674 11.3031 10.9154L12.3398 12.3635C12.5413 12.645 12.4856 13.0351 12.2135 13.249C11.9237 13.4766 11.5029 13.4171 11.2883 13.1175C10.7464 12.3612 10.1184 11.4839 9.63124 10.8031C9.23236 10.2457 8.40372 10.2456 8.00472 10.8029L6.13266 13.4179C5.6589 14.0796 6.1319 15 6.94577 15H17.0543C17.8681 15 18.3411 14.0796 17.8674 13.4179L14.0858 8.13577Z"
          fill="#FD6D87"
        />
        <path
          d="M10.5 8.25C10.5 8.94036 9.94037 9.5 9.25001 9.5C8.55965 9.5 8.00001 8.94036 8.00001 8.25C8.00001 7.55964 8.55965 7 9.25001 7C9.94037 7 10.5 7.55964 10.5 8.25Z"
          fill="#FD6D87"
        />
         </>
        case "audio":
        return  <path
          d="M12.1538 6C11.6016 6 11.1538 6.44772 11.1538 7V11.1265C11.1538 11.645 10.6338 12 10.1154 12C8.39154 12 7 13.34 7 15C7 16.66 8.39154 18 10.1154 18C11.7088 18 13.0136 16.8418 13.1942 15.3496C13.1953 15.3404 13.2032 15.3333 13.2125 15.3333C13.2226 15.3333 13.2308 15.3251 13.2308 15.315V9C13.2308 8.44772 13.6785 8 14.2308 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6H12.1538Z"
          fill="#FD6D87"
        />;
        case "image":
        return  <>
        <path
        d="M14.0858 8.13577C13.687 7.57859 12.8585 7.57859 12.4596 8.13577L11.3031 9.75122C11.0539 10.0993 11.0539 10.5674 11.3031 10.9154L12.3398 12.3635C12.5413 12.645 12.4856 13.0351 12.2135 13.249C11.9237 13.4766 11.5029 13.4171 11.2883 13.1175C10.7464 12.3612 10.1184 11.4839 9.63124 10.8031C9.23236 10.2457 8.40372 10.2456 8.00472 10.8029L6.13266 13.4179C5.6589 14.0796 6.1319 15 6.94577 15H17.0543C17.8681 15 18.3411 14.0796 17.8674 13.4179L14.0858 8.13577Z"
        fill="#FD6D87"
      />
      <path
        d="M10.5 8.25C10.5 8.94036 9.94037 9.5 9.25001 9.5C8.55965 9.5 8.00001 8.94036 8.00001 8.25C8.00001 7.55964 8.55965 7 9.25001 7C9.94037 7 10.5 7.55964 10.5 8.25Z"
        fill="#FD6D87"
      />
      </>;
        case "link":
        return  <>
          <path
            d="M13.2788 6.75702C13.0722 6.75702 12.8741 6.83907 12.7281 6.98513C12.582 7.13119 12.5 7.32928 12.5 7.53584C12.5 7.74239 12.582 7.94049 12.7281 8.08655C12.8741 8.2326 13.0722 8.31466 13.2788 8.31466H15.2928L10.3917 13.2158C10.3173 13.2876 10.258 13.3735 10.2172 13.4686C10.1763 13.5636 10.1549 13.6658 10.154 13.7692C10.1531 13.8726 10.1728 13.9752 10.2119 14.0709C10.2511 14.1666 10.3089 14.2535 10.382 14.3267C10.4552 14.3998 10.5421 14.4576 10.6378 14.4968C10.7336 14.5359 10.8361 14.5556 10.9395 14.5547C11.0429 14.5538 11.1451 14.5324 11.2401 14.4915C11.3352 14.4507 11.4211 14.3914 11.4929 14.317L16.394 9.4159V11.4299C16.394 11.6365 16.4761 11.8346 16.6222 11.9806C16.7682 12.1267 16.9663 12.2087 17.1729 12.2087C17.3794 12.2087 17.5775 12.1267 17.7236 11.9806C17.8696 11.8346 17.9517 11.6365 17.9517 11.4299V7.53584C17.9517 7.32928 17.8696 7.13119 17.7236 6.98513C17.5775 6.83907 17.3794 6.75702 17.1729 6.75702H13.2788Z"
            fill="#C4C4C4"
          />
          <path
            d="M8.60587 8.31467C8.19276 8.31467 7.79657 8.47877 7.50445 8.77089C7.21234 9.063 7.04823 9.45919 7.04823 9.8723V16.1029C7.04823 16.516 7.21234 16.9122 7.50445 17.2043C7.79657 17.4964 8.19276 17.6605 8.60587 17.6605H14.8364C15.2495 17.6605 15.6457 17.4964 15.9378 17.2043C16.2299 16.9122 16.3941 16.516 16.3941 16.1029V13.7664C16.3941 13.5598 16.312 13.3617 16.1659 13.2157C16.0199 13.0696 15.8218 12.9876 15.6152 12.9876C15.4087 12.9876 15.2106 13.0696 15.0645 13.2157C14.9185 13.3617 14.8364 13.5598 14.8364 13.7664V16.1029H8.60587V9.8723H10.9423C11.1489 9.8723 11.347 9.79025 11.493 9.64419C11.6391 9.49814 11.7211 9.30004 11.7211 9.09349C11.7211 8.88693 11.6391 8.68883 11.493 8.54278C11.347 8.39672 11.1489 8.31467 10.9423 8.31467H8.60587Z"
            fill="#C4C4C4"
          />
        </>;

      default:
        return;
    }

   };

   {btnIcon = handleChange(props.buttonstyle)}

  return (
    <span className="object-icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11"
        fill="white"
        stroke="#FD6D87"
        strokeWidth="2"
      ></circle>
       {btnIcon}      
    </svg>
    &nbsp;
  </span>
  );
}
