import React from "react";
import { useTranslation } from "react-i18next";

export default function FullScreen(props) {
  const { t, i18n } = useTranslation();

  return (
    <li id="fullscreen-btn" onClick={props.onClick}>
      <span
        className={`button normal-btn full-screen is-hidden-mobile	${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "full-screen" ? "active" : ""
        }`}
        title={t("Full Screen")}
        data-tip={t("Full Screen")}
      >
        <img
          src={require("../../assets/img/svg/toolbar-full-screen.svg")}
          alt={t("Full Screen")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Full")}</span>}
      </span>
    </li>
  );
}
