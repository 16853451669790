import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormButtonStyle from "./../SubComponents/FormButtonsStyle";


export default function ButtonStylesBulma(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const [oValue, setOValue] = useState(props.defaultStyle);
  const [scaleValue, setScaleValue] = useState("");
  const [animatedValue, setAnimatedValue] = useState("");

  // const handleChange = (e) => {
  //   setOValue(e);
  //   console.log("e.value", e);
  //   props.selectButtonStyle(e);
  // };
  // const handleScale = (e) => {
  //   setScaleValue(e);
  // };
  // const handleAnimated = (e) => {
  //   if (animatedValue === "") {
  //     setAnimatedValue("no-animation");
  //   } else {
  //     setAnimatedValue("");
  //   }
  // };
  const handleChange = (e) => {
    setOValue(e);
    // console.log("e.value", e, e + " " + scaleValue + " " + animatedValue);
    props.selectButtonStyle(e + " " + scaleValue + " " + animatedValue);
  };
  const handleScale = (e) => {
    setScaleValue(e);
    props.selectButtonStyle(oValue + " " + e + " " + animatedValue);
  };
  const handleAnimated = (e) => {
    if (e === "") {
      setAnimatedValue("no-animation");
      props.selectButtonStyle(oValue + " no-animation " + scaleValue );
    } else {
      setAnimatedValue("");
      props.selectButtonStyle(oValue + " " + scaleValue);
    }
  };
  return (
    <>
      <div className="field is-grouped is-grouped-multiline  is-grouped-left mt-3 mb-1">
        <div className="field-label is-normal q-input">
          <label className="label">{t("Style")}</label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style1");
              }}
              name="style"
              checked={oValue === "style1"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style1 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style1 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
                <FormButtonStyle
                buttonstyle={props.activityType}
                />
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style2");
              }}
              name="style"
              checked={oValue === "style2"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style2 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style2 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
               <FormButtonStyle
                buttonstyle={props.activityType}
                />
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style3");
              }}
              name="style"
              checked={oValue === "style3"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style3 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style3 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
              <FormButtonStyle
                buttonstyle={props.activityType}
                />
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style4");
              }}
              name="style"
              checked={oValue === "style4"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style4 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style4 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
              <FormButtonStyle
                buttonstyle={props.activityType}
                />
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>
     
        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("styleinfo");
              }}
              name="style"
              checked={oValue === "styleinfo"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`styleinfo ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object styleinfo ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
                <span className="object-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="#0096c7"
                      stroke="#0096c7"
                      strokeWidth="2"
                    ></circle>
                    <path d="M14.1148 4C15.28 4 15.8626 4.7296 15.8626 5.5656C15.8626 6.6096 14.8504 7.5752 13.533 7.5752C12.4296 7.5752 11.7861 6.9752 11.8165 5.9832C11.8165 5.1488 12.5826 4 14.1148 4ZM10.5296 20C9.60956 20 8.93565 19.4784 9.57913 17.1808L10.6348 13.1072C10.8183 12.456 10.8487 12.1944 10.6348 12.1944C10.3591 12.1944 9.16609 12.644 8.45913 13.088L8 12.384C10.2365 10.6352 12.8096 9.6104 13.9139 9.6104C14.833 9.6104 14.9861 10.6288 14.527 12.1944L13.3174 16.476C13.1035 17.232 13.1948 17.4928 13.4096 17.4928C13.6852 17.4928 14.5896 17.1792 15.4783 16.5272L16 17.1784C13.8243 19.216 11.4478 20 10.5296 20Z" fill="white">            
                    </path>
                  </svg>
                  &nbsp;
                </span>
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>
     
     
     
      </div>
      <div className="row w-100">
        <div className="col-md-6">
          <div className="field is-grouped is-grouped-multiline  is-grouped-left mt-3 mb-1">
            {/* <div className="field-label is-normal q-input">
              <label className="label">{t("Scale")}</label>
            </div>{" "} */}
            <div className="radio mx-3 mt-2">
              <label>
                <input
                  type="radio"
                  onChange={() => {
                    handleScale("");
                  }}
                  name="normal"
                  checked={scaleValue === ""}
                  value=""
                />
                {t("Normal")}
              </label>
            </div>
            <div className="radio mx-3  mt-2">
              <label>
                <input
                  type="radio"
                  onChange={() => {
                    handleScale("midi");
                  }}
                  name="midi"
                  checked={scaleValue === "midi"}
                  value="midi"
                />
                {t("Midi")}
              </label>
            </div>
            <div className="radio mx-3  mt-2">
              <label>
                <input
                  type="radio"
                  onChange={() => {
                    handleScale("mini");
                  }}
                  name="mini"
                  checked={scaleValue === "mini"}
                  value="mini"
                />
                {t("Mini")}
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="field is-grouped is-grouped-multiline  is-grouped-left mt-3 mb-1">
            {/* <div className="field-label is-normal q-input">
              <label className="label">{t("Animated")}</label>
            </div>{" "} */}
            <div className="radio mx-3 mt-2">
              <label>
                <input
                  type="checkbox"
                  onChange={() => {
                    handleAnimated(animatedValue);
                  }}
                  name="normal"
                  checked={animatedValue === ""}
                  value=""
                />
                {t("Animated")}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
