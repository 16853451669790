import {
  CHANGE_TOOLBAR_VIEW,
  SET_TOOLBAR_POSITION,
  RESET_TOOLBAR_POSITION,
  PIN_TOOLBAR,
  UNPIN_TOOLBAR,
  SET_LINE_WIDTH,
  SET_FILL_COLOR,
  SET_LINE_COLOR,
  SET_TOOLBAR_VERTICAL,
  SET_TOOLBAR_SIZE,
  SHOW_TOOLBAR_LABELS,
  SET_TOOLBAR_MODE,
  OPEN_ADVANCED_MODE,
  OPEN_EDITOR_MODE,
  SET_OPACITY,
  SET_CURRENT_FILL_COLOR,
  SET_CURRENT_LINE_COLOR,
  SET_CURRENT_LINE_WIDTH,
  SET_CURRENT_SELECTED_OBJECT,
} from "../actions/actionTypes";
import { parseBoolean } from "../services/util";

var defaultToolbarState = {
  toolbarPosition: { x: 0, y: 0 },
  verticalToolbar: !parseBoolean(localStorage.verticalToolbar), //Actually its horizontal toolbar prop
  toolbarMode: parseBoolean(localStorage.toolbarMode),//Ismi degismesi lazim.
  toolbarPin: parseBoolean(localStorage.toolbarPin),
  activeButton: null,
  selectedTool: null,
  // drawToolbarOpened: false,
  // openableItems: {
  //   layersOpened: false,
  //   menuOpened: false,
  //   eraserOpened: false,
  //   lineOpened: false,
  //   shapesOpened: false,
  //   // undoRedoOpened: false,
  //   textStrokeOpened: false,
  //   fillColorOpened: false,
  // },

  lineColor: localStorage.lineColor || "#ff0000",
  fillColor: localStorage.fillColor || "#00ff22",
  currentFillColor: null,
  currentLineColor: null,
  currentLineWidth: null,
  currentOpacity: 1,
  lineWidth: parseInt(localStorage.lineWidth) || 3,
  toolbarSize: localStorage.toolbarSize || 1,
  showLabels: !parseBoolean(localStorage.showLabels),
  advancedMode: !parseBoolean(localStorage.advancedMode),
  editorMode: parseBoolean(localStorage.editorMode),
  currentSelectedObject:null
};

export default (state = defaultToolbarState, action) => {
  switch (action.type) {
    case CHANGE_TOOLBAR_VIEW:
      return true;

    case SET_TOOLBAR_POSITION:
      return {
        ...state,
        toolbarPosition: { x: action.payload.x, y: action.payload.y },
      };
    case SET_TOOLBAR_VERTICAL:
      return {
        ...state,
        verticalToolbar: action.payload,
      };
    case SET_TOOLBAR_MODE:
        return {
          ...state,
          toolbarMode: action.payload,
        };
    case RESET_TOOLBAR_POSITION:
      return {
        ...state,
        toolbarPosition: { x: 0, y: 0 },
      };
    case PIN_TOOLBAR:
      return {
        ...state,
        toolbarPin: true,
      };
    case UNPIN_TOOLBAR:
      return {
        ...state,
        toolbarPin: false,
      };
    case SET_LINE_WIDTH:
      return {
        ...state,
        lineWidth: action.payload,
        currentLineWidth: action.payload,
      };
    case SET_LINE_COLOR:
      return {
        ...state,
        lineColor: action.payload,
        currentLineColor: action.payload,
      };
    case SET_FILL_COLOR:
      return {
        ...state,
        fillColor: action.payload,
        currentFillColor: action.payload,
      };
    case SET_CURRENT_LINE_WIDTH:
      return {
        ...state,
        currentLineWidth: action.payload,
      };
    case SET_CURRENT_SELECTED_OBJECT:
      return {
        ...state,
        currentSelectedObject: action.payload,
      };
    case SET_CURRENT_LINE_COLOR:
      return {
        ...state,
        currentLineColor: action.payload,
      };
    case SET_CURRENT_FILL_COLOR:
      return {
        ...state,
        currentFillColor: action.payload,
      };
    case SET_OPACITY:
      return {
        ...state,
        currentOpacity: action.payload,
      };
    case SET_TOOLBAR_SIZE:
      return {
        ...state,
        toolbarSize: action.payload,
      };
    case SHOW_TOOLBAR_LABELS:
      return {
        ...state,
        showLabels: action.payload,
      };
    case OPEN_ADVANCED_MODE:
      return {
        ...state,
        advancedMode: action.payload,
      };
    case OPEN_EDITOR_MODE:
      return {
        ...state,
        editorMode: action.payload,
      };
    default:
      return state;
  }
};
