import React from "react";
import { useTranslation } from "react-i18next";

export default function Pen(props) {
  const { t } = useTranslation();
  return (
    <li onClick={props.onClick}>
      <span
        className={`button normal-btn pen ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "pencil" ? "active" : ""
        } `}
        title={t("Pen") + " (P)"}
        data-tip={t("Pen")}
      >
        <img src={require("../../assets/img/svg/tools-pen.svg")}  alt={t("Pen") + " (P)"} />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Pen")}</span>}
      </span>
    </li>
  );
}
